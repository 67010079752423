import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

import EndpointGridComponent from "./EndpointGridComponent";
import AccordionComponent from "../../../core/AccordionComponent/AccordionComponent";

const CompatibilityPanelGroupComponent = (props) => {
  // const onPanelEntering = (activeKey) => {
  //   setActiveKeyGridUpdate({
  //     [`${activeKey}GridUpdate`]: true,
  //   });
  // };

  const { allApis, apisToShow, panelKey } = props;
  let activeKey = props.activeKey;

  return (
    <div className="panel-group">
      <Accordion id={panelKey}>
        {Object.keys(apisToShow).map((key, idx) => {
          const apiKeyList = apisToShow[key];
          return (
            <AccordionComponent title={key} eventKey={idx} activeKey={activeKey}>
              {apiKeyList.map((apiKey) => {
                return <EndpointGridComponent apiObject={allApis[apiKey]} key={apiKey} />;
              })}
            </AccordionComponent>
          );
        })}
      </Accordion>
    </div>
  );
};

export default CompatibilityPanelGroupComponent;
