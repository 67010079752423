import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Accordion } from "react-bootstrap";

import { PERSONAL_SUBSCRIPTIONS, CARDIO_SUBSCRIPTIONS, STRENGTH_SUBSCRIPTIONS, ASSET_SUBSCRIPTIONS } from "./LicenseConstants";
import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";
import CheckboxComponent from "../../core/CheckboxComponent/CheckboxComponent";
import PanelComponent from "../../core/PanelComponent/PanelComponent";
import TooltipComponent from "../../core/TooltipComponent/TooltipComponent";
import GridContainer from "../../../containers/GridContainer";
import LinkComponent from "../../core/LinkComponent/LinkComponent";

import "./licenseForm.scss";
import AddFacilitiesContainer from "../../../containers/AddFacilitiesContainer";
import { LICENSE_ACTIONS } from "../../../constants/common";
import { Form } from "react-final-form";
import { getValidationRules } from "../../../validation/validation";
import { useDispatch, useSelector } from "react-redux";
import { setGridItemsAction } from "../../../redux/slice/gridSlice";
import { removeFacilitiesAction } from "../../../redux/slice/licensesSlice";

const _ = require("lodash");

const subscriptions = [...PERSONAL_SUBSCRIPTIONS, ...CARDIO_SUBSCRIPTIONS, ...STRENGTH_SUBSCRIPTIONS, ...ASSET_SUBSCRIPTIONS];
const additional = [{ name: "assetApi" }, { name: "forcedLogIn" }];

const LicenseSubscriptionFormComponent = (props) => {
  const licensesState = useSelector((__state__) => __state__.licenses);
  const dispatch = useDispatch();
  useEffect(() => {
    const { initialValues, callbacks, additional, savedCallbacks, savedAdditional, inProgress2 } = props;

    if (savedCallbacks && inProgress2 && Object.keys(savedCallbacks).length > 0) {
      initSubscriptions(savedCallbacks);
    } else if (callbacks && inProgress2 && Object.keys(callbacks).length > 0) {
      initSubscriptions(callbacks);
    } else if (initialValues.id) {
      initSubscriptions(initialValues);
    }
    if (savedAdditional && inProgress2 && Object.keys(savedAdditional).length > 0) {
      initAdditional(savedAdditional);
    } else if (additional && inProgress2 && Object.keys(additional).length > 0) {
      initAdditional(additional);
    } else if (initialValues.id) {
      initAdditional(initialValues);
    }
  }, []);

  useEffect(() => {
    if (licensesState.fulfilled === true) {
      props.close();
    }
  }, [licensesState.fulfilled]);

  const subscriptionNames = {};
  subscriptions.forEach(({ alwaysEnable, name }) => {
    if (!alwaysEnable) {
      subscriptionNames[name] = false;
    }
  });

  const additionalNames = {};
  additional.forEach(({ name }) => {
    additionalNames[name] = false;
  });

  const [thisState, setThisState] = useState({
    ...subscriptionNames,
    ...additionalNames,
    activeKey: 0,
    activeKey2: 0,
    checkedFacilities: {},
    searchVal: "",
    show: false,
    filter: "all",
    value: "",
    showForceTooltip: false,
  });

  const GRID_PROPS = [
    {
      title: "Facility ID",
      className: "id",
      getter: (item) => <div className={"facility-id" + (item.status === "Unauthorized" || item.status === "Incorrect ID" ? " error" : "")}>{item.id}</div>,
    },
    {
      title: "Status",
      className: "status",
      getter: (item) => (
        <div className={"facility-status" + (item.status === "Unauthorized" || item.status === "Incorrect ID" ? " error" : "")}>
          <span className={item.status.toLowerCase().split(" ").join("-") + "-icon"} />
          <span>{item.status}</span>
        </div>
      ),
    },
    {
      title: "Checkbox",
      className: "checkbox",
      getter: (item) => (
        <CheckboxComponent
          input={{
            type: "checkbox",
            name: `${item.id}Checkbox`,
            checked: thisState.checkedFacilities[item.id],
            onChange: () => {
              let temp = thisState.checkedFacilities;
              if (!temp[item.id]) {
                temp[item.id] = true;
              } else {
                delete temp[item.id];
              }
              setThisState({
                ...thisState,
                checkedFacilities: temp,
              });
            },
          }}
        />
      ),
    },
  ];

  // const componentWillMount = () => {
  //   !props.inProgress2 && props.removeFacilitiesAction(null, props.initialValues);
  //   !props.inProgress && !props.inProgress2 && props.reset();
  // };

  // const componentDidUpdate = (prevProps) => {
  //   const { initialValues, callbacks, saveCallbacks } = props;

  //   saveCallbacks(callbacks);

  //   if (!_.isEqual(initialValues, prevProps.initialValues)) {
  //     initSubscriptions(initialValues);
  //     initAdditional(initialValues);
  //   }
  // };

  const initSubscriptions = (initialValues) => {
    subscriptions.forEach(({ name }) => {
      if (initialValues.hasOwnProperty(name) && initialValues[name].length > 0) {
        enableSubscription(name);
      }
    });
  };

  const initAdditional = (initialValues) => {
    additional.forEach(({ name }) => {
      if (initialValues.hasOwnProperty(name) && initialValues[name]) {
        const isAdditionalEnabled = thisState[name];

        if (isAdditionalEnabled && props.change) {
          props.change(name, "");
        }

        props.initialValues[name] = !isAdditionalEnabled;

        props.saveAdditional({ assetApi: props.initialValues["assetApi"], forcedLogIn: props.initialValues["forcedLogIn"] });
        setThisState((prevState) => ({
          ...prevState,
          [name]: !isAdditionalEnabled,
        }));
      }
    });
  };

  const enableSubscriptions = () => {
    if (props.subEnabled) {
      subscriptions.forEach(({ name }) => {
        setThisState((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      });
    }
  };

  const enableAdditional = (name) => {
    console.log("************ enableAdditional ********************* ", name);
    const isAdditionalEnabled = thisState[name];

    if (isAdditionalEnabled && props.change) {
      props.change(name, "");
    }

    props.initialValues[name + "-active"] = true;

    props.initialValues[name] = !isAdditionalEnabled;

    props.saveAdditional({ assetApi: props.initialValues["assetApi"], forcedLogIn: props.initialValues["forcedLogIn"] });

    setThisState((prevState) => ({
      ...prevState,
      [name]: !isAdditionalEnabled,
    }));
  };

  const enableSubscription = (name, __formProps__) => {
    const isSubscriptionEnabled = thisState[name];
    if (isSubscriptionEnabled && __formProps__ && __formProps__.form) {
      __formProps__.form.change(name, "");
    }

    setThisState((prevState) => ({
      ...prevState,
      [name]: !isSubscriptionEnabled,
    }));
  };

  const handleSelect = (activeKey) => {
    setThisState({ ...thisState, activeKey });
  };

  const handleSelect2 = (activeKey2) => {
    setThisState({ ...thisState, activeKey2 });
  };

  const getSubscriptionLayout = ({ name, placeholder, label, alwaysEnable, fieldPlaceholder }, key, __props__) => {
    return (
      <>
        <div className={`subscription${name === "facilitiesSubscribedFor" ? " facilities" : ""}`} key={key}>
          {!alwaysEnable && (
            <CheckboxComponent
              input={{
                type: "checkbox",
                name: `${name}Checkbox`,
                checked: thisState[name],
                onChange: () => {
                  enableSubscription(name, __props__);
                },
              }}
              label={`${placeholder}`}
              formProps={{}}
            />
          )}
          {thisState[name] && <FieldGroupComponent name={name} type="text" label={label} required placeholder={fieldPlaceholder} />}
        </div>
      </>
    );
  };

  const prevPage = () => {
    const { initialValues: licenseData, action, cancelButtonTitle, confirmButtonTitle, page, modalTitle } = props;
    props.prevPage({ ...licenseData, category: licenseData.category.value }, action, cancelButtonTitle, confirmButtonTitle, parseInt(page) - 1, modalTitle);
  };

  const handleSearch = (e) => {
    setThisState({ ...thisState, searchVal: e.target.value });
  };

  const handleRemove = () => {
    console.log("************* handleRemove.thisState.checkedFacilities: ", thisState.checkedFacilities);
    dispatch(removeFacilitiesAction({ facilities: Object.keys(thisState.checkedFacilities).join(","), licenseData: props.initialValues }));
    setThisState({ ...thisState, checkedFacilities: [] });
  };

  const changeShow = (e) => {
    let option = e.target.className.split("-");
    setThisState({ ...thisState, show: !thisState.show, filter: option[1] === "option" ? option[0] : thisState.filter });
  };

  const handleChange = (e) => {
    if (e === "") {
      setThisState({
        ...thisState,
        value: "",
      });
    } else {
      setThisState({
        ...thisState,
        value: e.target.value,
      });
    }
  };

  const { activeKey, activeKey2, show, filter, value } = thisState;
  let { onSubmit, initialValues, incorrectIds, addIncorrectIds, action, savedCallbacks, savedAdditional, updateSubEnabled, cancelButtonTitle, confirmButtonTitle, page, pageAmount, close } = props;
  let { facilitiesSubscribedFor, facilitiesSubscriptionPending, facilitiesSubscriptionRejected, facilitiesAdded } = initialValues;

  if (licensesState.rejected && licensesState.error.incorrectIds) {
    console.log("********************* INVALID FACILITY ID incorrectIds: ", licensesState.error.incorrectIds);
    console.log("********************* INVALID FACILITY ID typeof incorrectIds: ", typeof licensesState.error.incorrectIds);
    incorrectIds = licensesState.error?.incorrectIds?.trim();
  }

  savedCallbacks &&
    Object.keys(savedCallbacks).forEach((callback) => {
      initialValues[callback] = savedCallbacks[callback];
    });

  savedAdditional &&
    Object.keys(savedAdditional).forEach((additional) => {
      initialValues[additional] = savedAdditional[additional];
    });

  let incorrectNum = 0;
  let newIncorrect = "";
  incorrectIds &&
    incorrectIds.split(",").forEach((id) => {
      if (facilitiesAdded && facilitiesAdded.split(",").includes(id.trim())) {
        incorrectNum++;
        newIncorrect.length > 0 && (newIncorrect += ",");
        newIncorrect += id;
      }
    });

  // if (incorrectIds && incorrectIds.trim() !== newIncorrect.trim()) {
  //   newIncorrect !== "" && (newIncorrect = newIncorrect.slice(0, -1));
  //   addIncorrectIds(newIncorrect);
  // }

  let facilities = [];

  if (!facilitiesSubscribedFor && !facilitiesSubscriptionPending && !facilitiesSubscriptionRejected && !facilitiesAdded) {
    facilities = null;
  } else {
    let subscribedRevised = facilitiesSubscribedFor && action !== LICENSE_ACTIONS.move ? facilitiesSubscribedFor.split("-")[0] : "";
    subscribedRevised &&
      (filter === "all" || filter === "approved") &&
      subscribedRevised.split(",").forEach((item) => {
        item.includes(thisState.searchVal) && facilities.push({ id: parseInt(item, 10), ordering: 3, status: "Approved" });
      });

    let pendingRevised = facilitiesSubscriptionPending && action !== LICENSE_ACTIONS.move ? facilitiesSubscriptionPending.split("-")[0] : "";
    pendingRevised += facilitiesAdded !== "" && facilitiesAdded !== undefined ? (pendingRevised.length > 0 ? "," : "") + facilitiesAdded : "";
    pendingRevised &&
      (filter === "all" || filter === "pending") &&
      pendingRevised.split(",").forEach((item) => {
        if (
          !incorrectIds ||
          !incorrectIds
            .split(",")
            .map((i) => i.trim())
            .includes(item)
        ) {
          item.includes(thisState.searchVal) && facilities.push({ id: parseInt(item, 10), ordering: 2, status: "Pending" });
        }
      });

    let rejectedRevised = facilitiesSubscriptionRejected && action !== LICENSE_ACTIONS.move ? facilitiesSubscriptionRejected.split("-")[0] : "";
    rejectedRevised &&
      (filter === "all" || filter === "unauthorized") &&
      rejectedRevised.split(",").forEach((item) => {
        item.includes(thisState.searchVal) && facilities.push({ id: parseInt(item, 10), ordering: 1, status: "Unauthorized" });
      });

    incorrectIds &&
      (filter === "all" || filter === "incorrect") &&
      incorrectIds.split(",").forEach((item) => {
        item.trim().includes(thisState.searchVal) && facilitiesAdded && facilitiesAdded.includes(item.trim()) && facilities.push({ id: parseInt(item.trim(), 10), ordering: 0, status: "Incorrect ID" });
      });

    if (!(subscribedRevised || pendingRevised || rejectedRevised)) {
      facilities = null;
    }
  }

  const handleSubmit = (data) => {
    const formattedData = {
      ...data,
      facilitiesAdded: initialValues.facilitiesAdded,
      facilitiesSubscribedFor: initialValues.facilitiesSubscribedFor,
      facilitiesSubscriptionPending: initialValues.facilitiesSubscriptionPending,
      facilitiesSubscriptionRejected: initialValues.facilitiesSubscriptionRejected,
    };

    props.handleSubmit(formattedData);
  };

  const handleClose = () => {
    close(true);
  };

  updateSubEnabled(facilities && facilities.length > 0);

  return (
    <>
      <div className="add-facility container">
        <div className="back-option" onClick={prevPage}>
          <span> Back </span>
        </div>
        <div className="facility-info">
          <span>Add a Facility ID to request facility-level access to callback event subscriptions and additional functionality.</span>
        </div>
        <div className="facility-note">
          <b>TRUE Important Note:</b> Facility IDs are unique to Development and Production licenses and cannot transfer between environments.
        </div>
        <AddFacilitiesContainer license={initialValues} handleChange={handleChange} value={value} isMove={action === LICENSE_ACTIONS.move} />
      </div>
      <div>
        <Form
          validate={getValidationRules([].concat(subscriptions.map((s) => s.name)), null, false)}
          onSubmit={handleSubmit}
          initialValues={props.initialValues}
          render={(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                {facilities && (
                  <div className="form-group">
                    {/* <PanelGroup accordion id="callback-panel" activeKey={activeKey} onSelect={handleSelect}> */}
                    <Accordion id="callback-panel" onSelect={handleSelect}>
                      <div className="section-header">
                        Select Callback Event(s) <span className="req">*</span>
                      </div>
                      <div className="callback-note">At least one callback event must be selected.</div>
                      <PanelComponent title="Personal" eventKey={1} activeKey={activeKey}>
                        <div className="subscriptions">{PERSONAL_SUBSCRIPTIONS.map((x, y) => getSubscriptionLayout(x, y, props))}</div>
                      </PanelComponent>
                      <PanelComponent title="Cardio" eventKey={2} activeKey={activeKey}>
                        <div className="subscriptions">{CARDIO_SUBSCRIPTIONS.map((x, y) => getSubscriptionLayout(x, y, props))}</div>
                      </PanelComponent>
                      <PanelComponent title="Strength" eventKey={3} activeKey={activeKey}>
                        <div className="subscriptions">{STRENGTH_SUBSCRIPTIONS.map((x, y) => getSubscriptionLayout(x, y, props))}</div>
                      </PanelComponent>
                      <PanelComponent title="Asset" eventKey={4} activeKey={activeKey}>
                        <div className="subscriptions">{ASSET_SUBSCRIPTIONS.map((x, y) => getSubscriptionLayout(x, y, props))}</div>
                      </PanelComponent>
                    </Accordion>
                    {/* <PanelGroup accordion id="callback-panel" activeKey={activeKey2} onSelect={handleSelect2}> */}
                    <Accordion id="callback-panel" onSelect={handleSelect2}>
                      <div className="section-header">
                        Additional Functionality <span className="req">*</span>
                      </div>
                      <div className="callback-note">Developers can request access to a growing list of facility-level console API integration.</div>
                      <PanelComponent title="Asset and Facility APIs" eventKey={1} activeKey={activeKey2}>
                        <div className="subscriptions">
                          <div className="subscription">
                            <div className="description">Enables access to the Asset Management APIs for entered facilities.</div>
                            <CheckboxComponent
                              input={{
                                name: "assetApiCheckbox",
                                type: "checkbox",
                                checked: thisState["assetApi"],
                                onChange: () => {
                                  enableAdditional("assetApi");
                                },
                              }}
                              label={"Request Access to Asset & Facility APIs"}
                            />
                          </div>
                        </div>
                      </PanelComponent>
                      <PanelComponent title="Forced Log In" eventKey={2} activeKey={activeKey2}>
                        <div className="subscriptions">
                          <div className="subscription">
                            <div className="description">
                              Enables log in requirement to access workout options for compatible consoles of selected facility.
                              <TooltipComponent
                                overlayContent={
                                  <span>
                                    <div className="tooltip-header">
                                      <b>Compatible Consoles</b>
                                    </div>
                                    <ol>
                                      <li>Discover SE3</li>
                                      <li>Discover SE3HD</li>
                                      <li>Discover ST</li>
                                      <li>Cybex 70T</li>
                                    </ol>
                                    <LinkComponent url="../api/web/compatibility" title="Learn More" className="learn-more" isExternalLink />
                                  </span>
                                }
                                className={"force-login-tooltip"}
                                placement={"bottom"}
                                keepOpen
                                allowHover
                                show={thisState.showForceTooltip}
                                setShow={(val) => {
                                  setThisState({ ...thisState, showForceTooltip: val });
                                }}
                                children={<span className="info-icon-force" />}
                              />
                            </div>
                            <CheckboxComponent
                              input={{
                                type: "checkbox",
                                name: "forcedLogInCheckbox",
                                checked: thisState["forcedLogIn"],
                                onChange: () => {
                                  enableAdditional("forcedLogIn");
                                },
                              }}
                              label={"Request Access to Forced Log In"}
                              formProps={{
                                placeholder: "Request Access to Forced Log In",
                              }}
                            />
                          </div>
                        </div>
                      </PanelComponent>
                    </Accordion>
                    <hr className="license-form-divider" />
                    <div className="status-box">
                      <div className="section-header">Approval Status Per Facility ID</div>
                      <TooltipComponent
                        overlayContent={
                          <div className={"tooltip-content"}>
                            <div className={"all-option" + (filter === "all" ? " selected" : "")} onClick={changeShow}>
                              View All
                            </div>
                            <hr />
                            <div className={"incorrect-option" + (filter === "incorrect" ? " selected" : "")} onClick={changeShow}>
                              <span className={"error-icon"} /> Incorrect ID
                            </div>
                            <hr />
                            <div className={"unauthorized-option" + (filter === "unauthorized" ? " selected" : "")} onClick={changeShow}>
                              <span className={"error-icon"} /> Unauthorized
                            </div>
                            <hr />
                            <div className={"pending-option" + (filter === "pending" ? " selected" : "")} onClick={changeShow}>
                              <span className={"pending-icon"} /> Pending
                            </div>
                            <hr />
                            <div className={"approved-option" + (filter === "approved" ? " selected" : "")} onClick={changeShow}>
                              <span className={"approved-icon"} /> Approved
                            </div>
                          </div>
                        }
                        className={"filter-search-tooltip"}
                        placement={"bottom"}
                        keepOpen
                        show={show}
                        setShow={(val) => setThisState({ ...thisState, show: val })}
                        children={<span className="filter-button" onClick={changeShow} />}
                      />
                      <FieldGroupComponent name="search" type="search" placeholder="Search by Facility ID" onChange={handleSearch} formProps={{ placeholder: "Search by Facility ID" }} />
                      {incorrectNum !== undefined && incorrectNum > 0 && <div className="incorrect-text">{incorrectNum <= 1 ? incorrectNum + " Facility ID was added incorrectly. Please remove it to continue." : incorrectNum + " Facility IDs were added incorrectly. Please remove them to continue."}</div>}
                      {facilities && facilities.length > 0 ? <GridContainer name="facility-ids" gridProps={GRID_PROPS} items={facilities} itemsPerPage={facilities.length} sortBy={["ordering", "id"]} /> : <div>Your search did not match any Facility ID's.</div>}
                      <Button onClick={handleRemove} disabled={Object.keys(thisState.checkedFacilities).length < 1} className="btn btn-default">
                        Remove Selected{Object.keys(thisState.checkedFacilities).length > 0 ? " (" + Object.keys(thisState.checkedFacilities).length + ")" : ""}
                      </Button>
                    </div>
                  </div>
                )}
                <div className="buttons">
                  <Button className="btn btn-default dark" onClick={handleClose}>
                    {cancelButtonTitle}
                  </Button>
                  <Button disabled={props.invalid || props.submitting} type="submit">
                    {`${page < pageAmount ? "Next" : confirmButtonTitle}`}
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </>
  );
};

// LicenseSubscriptionFormComponent.defaultProps = {
//   description: "",
// };

// LicenseSubscriptionFormComponent.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   subEnabled: PropTypes.bool,
//   initialValues: PropTypes.object.isRequired,
//   change: PropTypes.func,
//   prevPage: PropTypes.func,
//   reset: PropTypes.func,
//   removeFacilitiesAction: PropTypes.func.isRequired,
//   incorrectIds: PropTypes.string,
//   addIncorrectIds: PropTypes.func,
//   inProgress: PropTypes.bool,
//   inProgress2: PropTypes.bool,
//   action: PropTypes.string,
//   callbacks: PropTypes.object,
//   additional: PropTypes.object,
//   savedCallbacks: PropTypes.object,
//   saveCallbacks: PropTypes.func,
//   savedAdditional: PropTypes.object,
//   saveAdditional: PropTypes.func,
//   updateSubEnabled: PropTypes.func,
// };

export default LicenseSubscriptionFormComponent;
