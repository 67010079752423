import { COMMON_RULES } from '../validation'

export default {
    password: [
        {
            name: 'Required',
            message: 'Please enter password.',
        },
    ],
    email: COMMON_RULES.email,
}
