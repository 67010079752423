const ON_SENDING_WORKOUT_PRESET = `@Override 
public List<WorkoutPreset> onSendingWorkoutPreset(){ 
    return myPresetList;
}`

const SAMPLE_WORKOUT_PRESET = `int equipmentFamily = WorkoutHelper.processEquipmentTypeId(deviceTypeId);

if (equipmentFamily == WorkoutHelper.EQ_TYPE_TREADMILL){
    workoutPreset = WorkoutHelper.createTreadmilPreset(
        "TreadmillPreset", ProgramTypes.P_MANUAL,
        GoalTypes.TIME, 10 , 100, 0,
        speedKph, 70, deviceTypeId);
}
else if (equipmentFamily == WorkoutHelper.EQ_TYPE_UPRIGHT_BIKE ||
  equipmentFamily == WorkoutHelper.EQ_TYPE_RECUMBENT_BIKE ||
  equipmentFamily == WorkoutHelper.EQ_TYPE_CROSS_TRAINER ||
  equipmentFamily == WorkoutHelper.EQ_TYPE_FLEX_STRIDER) {
    workoutPreset = WorkoutHelper.createBikeOrCross(
        "BikeCrossPreset", ProgramTypes.P_MANUAL,
        GoalTypes.TIME, 10 , 100, 0, 70, deviceTypeId);
} else if (equipmentFamily == WorkoutHelper.EQ_TYPE_POWER_MILL) {
    workoutPreset = WorkoutHelper.createPowerMill(
        "PWMillPreset", ProgramTypes.P_MANUAL,
        GoalTypes.TIME, 10, 100, 5, 70, deviceTypeId);
}`

const DISPLAY_SETTINGS_REQUEST = `@Override
public void onDisplaySettingsRequest() {
    WorkoutManager.getInstance().sendUserDisplaySettings(builDisplaySettings());
}`

const SETTINGS_FORMAT = `<?xml version="1.0" encoding="UTF-8"?>
<discover>
    <usrBookmarks/>
    <displaySettings/>
    <consoleLanguage/>
    <workoutSummaries>{"workoutSummaries":[{"workoutId":0,"fileName":" BikeCrossPreset ",
      "workoutName":"BikeCrossPreset","index":0,"workoutType":"WORKOUT_LIBRARY"}]}
    </workoutSummaries>
</discover>`

const EQUIPMENT_TYPE = `int deviceType = EquipmentManager.getInstance().getDeviceType()
int deviceFamily = WorkoutHelper.getInstance().processEquipmentTypeId(deviceType);
WorkoutHelper.getInstance().processEquipmentTypeId(deviceType);`

const WORKOUT_HELPERS = [
    'EQ_TYPE_TREADMILL',
    'EQ_TYPE_UPRIGHT_BIKE',
    'EQ_TYPE_RECUMBENT_BIKE',
    'EQ_TYPE_CROSS_TRAINER',
    'EQ_TYPE_FLEX_STRIDER',
    'EQ_TYPE_POWER_MILL',
]

export const WORKOUT_PRESET_CODES = {
    onSendingWorkOutPreset: ON_SENDING_WORKOUT_PRESET,
    sampleWorkOutPreset: SAMPLE_WORKOUT_PRESET,
    onDisplaySettingsRequest: DISPLAY_SETTINGS_REQUEST,
    settingsFormat: SETTINGS_FORMAT,
    equipmentType: EQUIPMENT_TYPE,
    workOutHelpers: WORKOUT_HELPERS,
}
