import CONFIG from "../config/config";
import { getToken } from "../storage/localStorage";

export function sendForgotPasswordEmail({ email }) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/sendForgotPasswordEmail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });
}

export function checkLinkToken(token) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/checkLinkToken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token,
    }),
  });
}

export function changePassword({ token, password }) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/changePassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token,
      password,
    }),
  });
}

export function updatePassword({ password, email, uid }) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/updatePassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password,
      email,
      uid,
    }),
  });
}

export function updateEmail({ userId, email }) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/updateEmail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
      email,
    }),
  });
}

export function signUp(user, company) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/signUp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user,
      company,
    }),
  });
}

export function validateEmailSignUp(userEmail) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/validateEmailSignUp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userEmail,
    }),
  });
}

export async function createCompany(company) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/createCompany`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(company),
  });
}

export async function updateCompany(company) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/updateCompany`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(company),
  });
}

export async function resendInvitationMemberEmail(memberId, companyId) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/resendInvitationMemberEmail`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ memberId, companyId }),
  });
}

export function completeInvitation({ token, password, old, companyId }) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/completeInvitation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token,
      password,
      old,
      companyId,
    }),
  });
}

export async function createMember(member, companyId) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/createMember`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      member,
      companyId,
    }),
  });
}

export async function updateMember(memberId, member, companyId) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/updateMember`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      memberId,
      member,
      companyId,
    }),
  });
}

export async function editPersonalInfo(memberId, member) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/editPersonalInfo`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      memberId,
      member,
    }),
  });
}

export async function removeMember(memberId, companyId) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/removeMember`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      memberId,
      companyId,
    }),
  });
}

export async function getMembers(companyId) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/getMembers?companyId=${companyId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
}

export async function generateToken(companies) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/generateToken?companies=${companies}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
}

export async function createOrganizations(userid) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/createOrganizations?userid=${userid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
}

export async function createZendeskUser(userObject) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/createZendeskUser`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userObject,
    }),
  });
}

export async function createTicket(ticketObj) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/createTicket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ticketObj,
    }),
  });
}

export async function updateTicket(ticketObj) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/updateTicket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ticketObj,
    }),
  });
}

export async function resolveTicket(resolveTicketObj) {
  const idToken = await getToken();
  return fetch(`${CONFIG.cloudFunctionsUrl}/resolveTicket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resolveTicketObj,
    }),
  });
}

export function updateEmailInPendingFacilities({ prevEmail, email }) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/updateEmailInPendingFacilities`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      prevEmail,
      email,
    }),
  });
}

export function updateUserNameInPendingFacilities({ developer }) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/updateUserNameInPendingFacilities`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      developer,
    }),
  });
}

export function updateApigeeCustomAttribute({ apigeeUpdateObj }) {
  return fetch(`${CONFIG.cloudFunctionsUrl}/updateApigeeCustomAttribute`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      apigeeUpdateObj,
    }),
  });
}
