import React, { useEffect } from "react";
import PropTypes from "prop-types";
// import { withRouter } from "react-router";
import { Col, Button, Row } from "react-bootstrap";
import { OVERVIEW_PATH } from "../../../constants/routes";
import ReactGA from "react-ga";

import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";
import "./support.scss";
import { useDispatch, useSelector } from "react-redux";
import { generateZendeskTokenAction } from "../../../redux/slice/sessionSlice";

const SupportComponent = (props) => {
  const dispatch = useDispatch();
  const sessionState = useSelector((__state__) => __state__.session);
  useEffect(() => {
    // window.addEventListener("scroll", handleScroll);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Support Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  const genToken = () => {
    dispatch(generateZendeskTokenAction({ userId: sessionState.user.uid }));
  };

  return (
    <div className="Support-component">
      {sessionState.pending && <LoadingComponent fullScreen />}
      <Row>
        <Col xs={6} align="left">
          <section className="section-one">
            <div className="contents">
              <h3>Support</h3>
              <p>
                We're here to help you succeed.
                <br />
                New to Developer Connect?
                <br />
                Be sure to checkout our improved&nbsp;
                <strong>
                  <a className="doc" href={OVERVIEW_PATH}>
                    Documentation
                  </a>
                </strong>
                .
              </p>
            </div>
            <hr />
          </section>
          <section className="section-two">
            <div className="contents">
              <h4>Knowledge Base</h4>
              <p>
                Have a specific question or don't know where to start?
                <br />
                Our Knowledge Base has what you're looking for.
              </p>
              <br />
              <a href="https://haloapisupport.zendesk.com" target="_blank" rel="noopener noreferrer">
                <Button className="knowledge-base-button">View Knowledge Base</Button>
              </a>
            </div>
            <hr />
          </section>
          <section className="section-three">
            <div className="contents">
              <h4>Support Desk</h4>
              <p>
                In need of some help? Contact us and we'll get back to
                <br />
                you within 24 hours.
              </p>
              <br />
              <Button className="support-desk-button btn btn-default light" onClick={genToken}>
                View Support Desk
              </Button>
            </div>
          </section>
        </Col>
        <Col xs={6} align="left">
          <span className="lightbulb" />
        </Col>
      </Row>
    </div>
  );
};

// SupportComponent.propTypes = {
//   genToken: PropTypes.func.isRequired,
//   isLoading: PropTypes.bool.isRequired,
// };

export default SupportComponent;
