import React, { useState } from "react";
import PropTypes from "prop-types";

import { Accordion } from "react-bootstrap";

import UpdateEmailContainer from "../../../containers/UpdateEmailContainer";
import UpdateInfoContainer from "../../../containers/UpdateInfoContainer";
import UpdatePwdContainer from "../../../containers/UpdatePwdContainer";

import PanelComponent from "../../core/PanelComponent/PanelComponent";

import "./update-profile.scss";

const UpdateProfilePanelComponent = (props) => {
  const [activeKeys, setActiveKeys] = useState({});
  const { handleSelect, activeKey, currentUserInfo } = props;
  const onPanelEntering = (activeKey) => {
    setActiveKeys({
      ...activeKeys,
      [`${activeKey}GridUpdate`]: true,
    });
    // this.setState({
    //   [`${activeKey}GridUpdate`]: true,
    // });
  };

  return (
    <div className="panel-group">
      <Accordion id="accordion" onSelect={handleSelect}>
        <PanelComponent
          title="Personal Info"
          activeKey={activeKey}
          eventKey="0"
          eventProps={{
            onEntering: () => onPanelEntering(activeKey),
          }}
        >
          <UpdateInfoContainer />
        </PanelComponent>
        <PanelComponent
          title="Change Password"
          activeKey={activeKey}
          eventKey="1"
          eventProps={{
            onEntering: () => onPanelEntering(activeKey),
          }}
        >
          <UpdatePwdContainer />
        </PanelComponent>
        <PanelComponent
          title="Contact Info"
          activeKey={activeKey}
          eventKey="2"
          eventProps={{
            onEntering: () => onPanelEntering(activeKey),
          }}
        >
          <UpdateEmailContainer />
        </PanelComponent>
      </Accordion>
    </div>
  );
};

UpdateProfilePanelComponent.propTypes = {
  activeKey: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  currentUserInfo: PropTypes.object,
};

export default UpdateProfilePanelComponent;
