import { useSelector } from "react-redux";
import FieldGroupComponent from "../../components/core/FieldGroupComponent/FieldGroupComponent";

const SignUpFormFieldsLayout = () => {
  const sessionState = useSelector((__state__) => __state__.session);
  console.log("***** SIgnUpFormFieldsLayout.sessionState.user: ", sessionState.user);
  return (
    <>
      <FieldGroupComponent
        required="true"
        label="First Name"
        name="firstName"
        type="text"
        className="form-group"
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 30,
        }}
      />
      <FieldGroupComponent
        required="true"
        label="Last Name"
        name="lastName"
        type="text"
        className="form-group"
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 30,
        }}
      />
      <FieldGroupComponent
        required="true"
        label="Email"
        name="email"
        type="text"
        className="form-group"
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 100,
        }}
      />
      <FieldGroupComponent
        required="true"
        label="Password"
        name="password"
        type="password"
        className="form-group"
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 16,
        }}
      />
      <FieldGroupComponent
        required="true"
        label="Confirm Password"
        name="confirmPassword"
        type="password"
        className="form-group"
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 16,
        }}
      />
    </>
  );
};

export default SignUpFormFieldsLayout;
