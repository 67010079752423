import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";

import "./companyForm.scss";

import CompanyFormFieldsLayout from "../../../layouts/Company/CompanyForm/CompanyFormFieldsLayout";
import { Button } from "react-bootstrap";
import { prepareCompanyDataBeforeSave } from "../../../common";
import { useDispatch } from "react-redux";
import { addCompanyAction, editCompanyAction } from "../../../redux/slice/companiesSlice";

const CompanyFormComponent = (props) => {
  const dispatch = useDispatch();

  const { otherFields, initialValues, updateInvalid, validationRules, confirmButtonTitle, close, action, cancelButtonTitle } = props;
  console.log("***** CompanyFormComponent.props: ", props);
  //updateInvalid(isInvalid);
  const handleSubmit = (company) => {
    prepareCompanyDataBeforeSave(company);
    //alert(`hello ${JSON.stringify(e)}`);
    if (action === "edit") dispatch(editCompanyAction({ company }));
    else dispatch(addCompanyAction({ company }));
    close();
  };
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validationRules}
        render={(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <CompanyFormFieldsLayout otherFields={otherFields} platform={initialValues.platform} />
              <div className="buttons">
                <Button className="btn btn-default dark" onClick={close}>
                  {cancelButtonTitle}
                </Button>
                <Button disabled={props.invalid || props.submitting} type="submit">
                  {confirmButtonTitle}
                </Button>
              </div>
            </form>
          );
        }}
      />
    </>
  );
};

CompanyFormComponent.propTypes = {
  otherFields: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  updateInvalid: PropTypes.func,
};

export default CompanyFormComponent;
