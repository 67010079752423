import { Button } from "react-bootstrap";
import { Form } from "react-final-form";
import ReactGA from "react-ga";

import "./signUp.scss";
import CONFIG from "../../../config/config";
import FormTitleComponent from "../../../components/core/FormTitleComponent/FormTitleComponent";
import SignUpFormCheckboxLayout from "../../../layouts/SignUp/SignUpFormCheckboxLayout";
//import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";

import COMPANY_RULES from "../../../validation/validationRules/company";
import SIGN_UP_RULES from "../../../validation/validationRules/signUp";

import { getValidationRules } from "../../../validation/validation";
import { validationRules } from "../../../containers/CompanyFormContainer";
import { useSelector } from "react-redux";
import CompanyFormFieldsLayout from "../../../layouts/Company/CompanyForm/CompanyFormFieldsLayout";
import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";

const { siteKey } = CONFIG.captcha;

const SignUpCompanyComponent = (props) => {
  const sessionState = useSelector((__state__) => __state__.session);
  console.log("***** SignUpCompanyComponent sessionState.user: ", sessionState.user);
  const handleSubmit = (e) => {
    console.log("***** handleSubmit e: ", e);
    props.onSubmit({ values: e });
    ReactGA.event({
      category: "Sign Up Company Component",
      action: "Clicked Create Account",
      label: "Button",
    });
  };

  const initialValues = () => {
    console.log("***** sessionState.user: ", sessionState.user);
    console.log("***** sessionState.company: ", sessionState.company);
    return {
      name: sessionState.company?.name || "",
      address: sessionState.company?.address || "",
      city: sessionState.company?.city || "",
      state: sessionState.company?.state || "",
      postalCode: sessionState.company?.postalCode || "",
      phoneNumber: sessionState.company?.phoneNumber || "",
      captcha: false,
      country: sessionState.company?.country || null,
      platform: sessionState.company?.platform || null,
      companySize: sessionState.company?.companySize || null,
      industry: sessionState.company?.industry || null,
      vertical: sessionState.company?.vertical || null,
      otherIndustry: sessionState.company?.otherIndustry || null,
      otherVertical: sessionState.company?.otherVertical || null,
      acceptTerms: false,
      acceptSubscription: false,
    };
  };

  const { prevPage } = props;

  return (
    <>
      {sessionState.pending && <LoadingComponent fullScreen />}
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues()}
        validate={getValidationRules(["captcha"].concat(validationRules), Object.assign(SIGN_UP_RULES, COMPANY_RULES))}
        render={(props) => (
          <div>
            <div className="back-option" onClick={() => prevPage(props.values)}>
              <span> Back </span>
            </div>
            <form onSubmit={props.handleSubmit}>
              <FormTitleComponent title="Company Information" />
              <CompanyFormFieldsLayout />
              {siteKey && <FieldGroupComponent name="captcha" type="captcha" />}
              <SignUpFormCheckboxLayout />
              <div className="buttons">
                <Button disabled={props.invalid || props.submitting} type="submit" className="next">
                  Create Account
                </Button>
              </div>
            </form>
          </div>
        )}
      />
    </>
  );
};

export default SignUpCompanyComponent;
