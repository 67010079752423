import { createAction } from "redux-actions";
import { toast } from "react-toastify";
// import { SubmissionError } from 'redux-form';

import { SET_TEAM_MEMBERS, ADD_TEAM_MEMBER, UPDATE_TEAM_MEMBER, DELETE_TEAM_MEMBER, IS_LOADING } from "../reducers/teamMembers";

import { getMembers, createMember, updateMember, removeMember, resendInvitationMemberEmail } from "../../firebase/cloud-functions";

import updateProfileApi from "../../api/updateProfileApi";

import { getUser } from "../../firebase/user";
import { getUserId } from "../../storage/localStorage";

const setTeamMembers = createAction(SET_TEAM_MEMBERS);
const addTeamMember = createAction(ADD_TEAM_MEMBER);
const updateTeamMember = createAction(UPDATE_TEAM_MEMBER);
const deleteTeamMember = createAction(DELETE_TEAM_MEMBER);
export const setLoading = createAction(IS_LOADING);

const UNPROCESSABLE_ENTITY_ERROR_CODE = 422;

const SUCCESSFUL_CREATE_TEAM_MEMBER = "Team member has been added successfully.";
const SUCCESSFUL_UPDATE_TEAM_MEMBER = " was successfully updated.";
const SUCCESSFUL_DELETE_TEAM_MEMBER = " was successfully deleted.";

const prepareUser = ({ developerId, email, firstName, lastName }) => ({
  ...(developerId && { id: developerId }),
  email,
  firstName,
  lastName,
});

export const getTeamMembers = (companyId) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await getMembers(companyId);
    let getLoggedInMember = await getUser(getUserId());

    if (response.ok) {
      let members = await response.json();

      //add logged in member to the member list
      members[getLoggedInMember.uid] = getLoggedInMember;
      members = Object.keys(members).map((memberId) => ({
        ...members[memberId],
      }));
      dispatch(setTeamMembers({ items: members }));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const createTeamMember = (companyId, member) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const currentDate = Date.now();

    member = {
      ...member,
      companies: {},
      createdAt: currentDate,
      updatedAt: currentDate,
    };

    member.companies[companyId] = { companyId: companyId, isAdmin: member.role === "Admin", status: "Pending" };
    delete member.role;

    const response = await createMember(member, companyId);
    if (response.ok) {
      const newMember = await response.json();
      member.uid = newMember.uid;
      member.firstName = newMember.firstName;
      member.lastName = newMember.lastName;
      dispatch(addTeamMember({ member }));
      toast(SUCCESSFUL_CREATE_TEAM_MEMBER);
    } else if (response.status === UNPROCESSABLE_ENTITY_ERROR_CODE) {
      throw new Error({
        email: await response.text(),
      });
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const editTeamMember = (memberId, member, companyId) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    let developer = {
      ...prepareUser(member),
    };

    //check if the member has a field : developerId, then
    //call the updateProfileApi
    if (member.developerId) {
      await updateProfileApi.update(developer);
    }

    const response = await updateMember(memberId, member, companyId);

    if (response.ok) {
      dispatch(updateTeamMember({ member }));
      toast(`${member.firstName} ${member.lastName} ${SUCCESSFUL_UPDATE_TEAM_MEMBER}`);
    } else if (response.status === UNPROCESSABLE_ENTITY_ERROR_CODE) {
      throw new Error({
        email: await response.text(),
      });
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const removeTeamMember =
  ({ firstName, lastName, uid, currentCompanyId }) =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await removeMember(uid, currentCompanyId);

      if (response.ok) {
        dispatch(deleteTeamMember({ memberId: uid }));
        toast(`${firstName} ${lastName} ${SUCCESSFUL_DELETE_TEAM_MEMBER}`);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const resendInvitation = (uid, currentCompanyId) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await resendInvitationMemberEmail(uid, currentCompanyId);

    if (response.ok) {
      toast("The invitation was successfully resent.");
    } else {
      toast("There was an error while sending the email. Please try to resend later.");
    }
  } finally {
    dispatch(setLoading(false));
  }
};
