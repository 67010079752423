import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import ReactGA from "react-ga";

import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";
import LicensesContainer from "../../../containers/LicensesContainer";
import TeamMembersContainer from "../../../containers/TeamMembersContainer";

import "./licensesTeam.scss";
import { useDispatch, useSelector } from "react-redux";
import { getLicensesAction } from "../../../redux/slice/licensesSlice";
import { Form } from "react-final-form";
import { getTeamMembersAction } from "../../../redux/slice/teamMembersSlice";

const LicensesTeamComponent = (props) => {
  const dispatch = useDispatch();
  const licensesState = useSelector((__state__) => __state__.licenses);
  const sessionState = useSelector((__state__) => __state__.session);
  const teamMembersState = useSelector((__state__) => __state__.teamMembers);
  const [modalState, setModalState] = useState({
    modalTitle: "",
    modalMessage: "",
    modalBody: "",
    modalConfirmButtonTitle: "Yes",
    modalCancelButtonTitle: "No",
    modalOnConfirm: () => {},
    showModal: false,
    isDev: true,
  });

  useEffect(() => {
    dispatch(getLicensesAction({ companyId: sessionState.companyId }));
    dispatch(getTeamMembersAction({ companyId: sessionState.companyId }));
  }, [sessionState.companyId]);

  const handleClick = (environment) => {
    ReactGA.event({
      category: "Licenses Component",
      action: "Clicked " + environment + " environment",
      label: "Toggle",
    });

    let develement = document.getElementById("dev");
    let prodelement = document.getElementById("prod");
    if (environment === "dev") {
      setModalState({
        ...modalState,
        isDev: true,
      });
      develement.classList.add("active");
      prodelement.classList.remove("active");
    } else {
      setModalState({
        ...modalState,
        isDev: false,
      });
      prodelement.classList.add("active");
      develement.classList.remove("active");
    }
  };

  let licenses = { dev: [], prod: [], devInProd: [] };
  if (licensesState.items && licensesState.items.length)
    licenses = {
      dev: licensesState.items
        .filter((license) => license.dev && !license.prod)
        .map((license) => ({
          firebaseId: license.id,
          ...license.dev,
        })),
      prod: licensesState.items
        .filter((license) => license.prod)
        .map((license) => ({
          firebaseId: license.id,
          ...license.prod,
        })),
      devInProd: licensesState.items
        .filter((license) => license.prod)
        .map((license) => ({
          firebaseId: license.id,
          inProd: true,
          ...license.dev,
        })),
    };

  const { companies, user, companyId: currentCompanyId } = sessionState;
  const { onCompanySelect, getMembers } = props;
  let companyNames =
    companies &&
    companies.map(({ name, firebaseId }, index) => {
      return { label: name, value: firebaseId };
    });

  return (
    <div className="licenses-team-container">
      <span>{sessionState.company?.name}</span>
      <Form
        initialValues={{ listofCompanies: { label: sessionState.company?.name, value: sessionState.company?.name } }}
        onSubmit={() => true}
        render={(props) => (
          <div>
            <FieldGroupComponent
              name="listofCompanies"
              type="select"
              formProps={{
                id: "listofCompanies",
                label: "List of Companies",
                options: companyNames,
                // onCompanySelect: onCompanySelect,
                getMembers: getMembers,
                // isLoading: isLoading,
                // getLicenses: getLicenses,
              }}
              className="company-select"
            />
            <Button className="environment-dev active" id="dev" onClick={() => handleClick("dev")}>
              DEV ({licenses.dev.length + licenses.prod.length})
            </Button>
            <Button className="environment-prod" id="prod" onClick={() => handleClick("prod")}>
              PROD ({licenses.prod.length})
            </Button>
          </div>
        )}
      />

      {currentCompanyId && <LicensesContainer filteredLicenses={licenses} isDev={modalState.isDev} backToDevelopment={handleClick} />}
      <br />
      {currentCompanyId && <TeamMembersContainer members={teamMembersState} />}
    </div>
  );
};

LicensesTeamComponent.propTypes = {
  licenses: PropTypes.object,
  companies: PropTypes.array.isRequired,
  onCompanySelect: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  getLicenses: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  currentCompanyId: PropTypes.string,
};

export default LicensesTeamComponent;
