// import CompaniesComponent from "../components/profile/Companies/CompaniesComponent";
// const CompaniesContainer = () => {
//   return <h1>Companies Container</h1>;
// };

// export default CompaniesContainer;

import { connect } from "react-redux";
// import { submit } from 'redux-form';
// import { withRouter } from 'react-router';

// import { getLoadingStatus } from './selectors/licenseSelectors';
// import { getAllCompanies } from './selectors/companySelectors';
// // import { checkForOtherFields } from './CompanyFormContainer';
import { selectCompany } from "../redux/actions/companies";
import CompaniesComponent from "../components/profile/Companies/CompaniesComponent";
// import { getCurrentUser } from './selectors/userSelectors';

// const COMPANY_FORM = 'newCompany';
// // const selector = formValueSelector(COMPANY_FORM);

const mapStateToProps = (state, { company }) => ({
  // history,
  // locationState,
  company,
  // ...checkForOtherFields(state, selector, history),
});

const mapDispatchToProps = {
  selectCompany,
};

const mergeProps = ({ company, ...stateProps }, { selectCompany }) => {
  return {
    ...stateProps,
    activePanel: null,
    selectCompany,
    // viewLicenses: (companyId) => {
    //     let promise;

    //     return selectCompany(companyId);
    //     return promise.then(() => {
    //         history.push('/auth/profile/licenses');
    //     });
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CompaniesComponent);
