import BaseApi from "./BaseApi";

class CompanyApi {
  constructor() {
    this.api = new BaseApi();
    this.headers = {
      "Content-Type": "application/json",
    };
  }

  update(company) {
    console.log("***** CompanyApi.update(): ", JSON.stringify(company));
    return this.api.post("developer/update_company", JSON.stringify(company), this.headers);
  }
}

export default new CompanyApi();
