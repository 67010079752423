import { useEffect } from "react";
import { Image } from "react-bootstrap";
import ReactGA from "react-ga";

import { CONSOLES, LICENSE_KEY, SDK_CAPABILITIES } from "./GettingStartedConstants";
import CodeComponent from "../../../../core/CodeComponent/CodeComponent";

import "./gettingStarted.scss";

const GettingStartedComponent = (props) => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, []);

  const isAndroid = props.isAndroid;
  const platform = isAndroid ? "Android" : "iOS";
  const platformPhone = isAndroid ? "Android Phone" : "iPhone";
  return (
    <div className="getting-started">
      <div className="section console">
        <h3>About Life Fitness consoles</h3>
        {!isAndroid && <p>Developer Connect SDK for iOS supports Discover, Explore, and the Track Connect Consoles from Life Fitness.</p>}
        <p>
          Discover Tablet Consoles may provide {isAndroid ? "micro USB cable connectivity to Android devices" : "30-pin cable connectivity to iOS devices"}. Android and iOS devices are able to connect to Discover Tablet Consoles wirelessly using Bluetooth LE{isAndroid && " and classic"}.
        </p>

        <p>Track Connect Consoles provide wireless connectivity from mobile devices. Third-party app are able to connect to Track Connect using Bluetooth LE.</p>

        <div className="row">
          {CONSOLES.map(({ imageUrl, title }, i) => (
            <div className="image" key={i}>
              <Image src={require(`${imageUrl}`)} />
              <h3>{title}</h3>
            </div>
          ))}
        </div>
      </div>

      <div className="section sdk">
        <h3>Developer Connect SDK Capabilities</h3>
        <p>Developer Connect SDK for {platform} capability to communicate with compatible Life Fitness consoles can be summarized below:</p>
        <div className="row">
          {SDK_CAPABILITIES.map(({ imageUrl, action, desc }, i) => (
            <div className="image" key={i}>
              <Image src={require(`${imageUrl}`)} />
              <p>
                <i>
                  <span className="strawberry-text">{action}</span> {desc}
                </i>
              </p>
            </div>
          ))}
        </div>

        <p className="title">Create & Send Workout Presets</p>
        <p>A workout preset is a set of workout attributes representing a workout that can be performed on cardio equipment. By selecting a workout preset, the equipment is programmed to start a workout with specific parameter values like workout goal (calorie, distance, duration, etc), initial speed, level, etc.</p>
        <p>Developer Connect SDK provides API to create workout presets that can be sent and executed on a compatible Life Fitness console.</p>

        <p className="title">Obtain In-progress Workout Stream</p>
        <p> After a workout is started, the console pushes workout data to the connected mobile device every second. Developer Connect SDK provides a callback that enables the app to receive the workout data.</p>

        <p className="title">Receive Workout Result Summary</p>
        <p>After the workout has ended, Developer Connect SDK makes a callback to pass the complete summary of the workout to the app.</p>

        <p className="title">Control In-progress Workout</p>
        <p>During a workout, the app may send commands to control the on-going workout. The available commands are set level, set watts, set incline, and set target heart rate. If the command is not supported for the equipment connected to the mobile device, then the command will be ignored.</p>

        <p>
          <b>Other Capabilities</b>
        </p>
        <ul>
          <li>Identify QR codes on Life Fitness strength equipment</li>
          <li>Send a custom message to the console display</li>
          <li>Send user’s preferences like language and unit of measurement</li>
          <li>Send user’s biometric data like body weight and gender</li>
        </ul>
      </div>

      <div className="section">
        <h3>Developer Connect SDK Capabilities</h3>
        <p>Developer Connect SDK can be obtained from Developer Connect website. After registration, the developer has the access to the SDK and this documentation.</p>
        <p>The SDK is provided in the form of a ZIP file. After unpacking the ZIP file, there are 2 folders:</p>

        <ol>
          <li>
            <b>Library</b>
            {!isAndroid ? <p>The LFopen.framework to be included in your app project</p> : <p>The LFopen aar and its javadoc</p>}
          </li>
          <li>
            <b>Demo app</b>
            <p>The source code and {!isAndroid ? "Xcode" : "Android Studio"} project of the demo app to demonstrate how to use Developer Connect SDK and access the workout data from supported Life Fitness consoles</p>
          </li>
        </ol>

        <p>The next section will describe how to build the demo app and explain the steps needed to integrate Developer Connect SDK into an app.</p>
      </div>

      <div className="section">
        <h3>Getting Started With Demo App</h3>
        <h4>Quick start: build the demo app</h4>
        <p>
          <b>Pre-requisite:</b>
        </p>
        <ol>
          {!isAndroid ? <li>Xcode version 7.0 and above</li> : <li>Android SDK version with API level 22 and 25</li>}
          {isAndroid && <li>Android Studio version 2.3</li>}
          <li>
            Developer Connect app license (see License section in{" "}
            <a href="/auth/api/overview" className="blue-text">
              Overview
            </a>
            )
          </li>
        </ol>
        <p>
          <b>The steps to build the demo app:</b>
        </p>
        <ol>
          {!isAndroid ? <li>Unzip the zip file, it includes Lfopen.framework file and sample project</li> : <li>Unzip the SDK</li>}
          {!isAndroid ? <li>Open sample project with Xcode</li> : <li>Open Android Studio</li>}
          {!isAndroid ? <li>Open project plist file and replace “licenseKeyHere” with your app license</li> : <li>Import the project into Android Studio</li>}
          {!isAndroid ? <CodeComponent code={LICENSE_KEY} /> : <li>Open MainActivity.Java and replace “licenseKeyHere” with your app license</li>}
          {!isAndroid ? <li>All set to play with the demo app.</li> : <li>Sync and run the demo app</li>}
        </ol>
      </div>

      <div className="section sdk">
        <h4>About sample Developer Connect demo app</h4>
        <p>In this sample mobile app using Developer Connect SDK, we build a simple {platform} app which:</p>
        <ul>
          <li>Connects to supported Life Fitness consoles using QR code via Bluetooth LE or USB cable for selected console</li>
          <li>Displays the current workout data during an on-going workout</li>
          <li>Provides UI to control the equipment and console during an on-going workout</li>
        </ul>

        <p>In addition to the above features, the app provides information about the connected equipment and communication logs between the app and Life Fitness console</p>
        <p>Source code of the demo app is included in the Developer Connect SDK archive.</p>
      </div>

      <div className="section">
        <h4>Running the demo app</h4>
        <p>You can execute and test the demo app:</p>
        <ol>
          {!isAndroid ? <li>Open the demo app using Xcode. Install the demo app on an iPhone</li> : <li>Compile the apk and install it on a device (Android 5.0 and above is recommended for Bluetooth LE)</li>}
          {!isAndroid ? (
            <li>
              Connect the {platformPhone} to a Discover console. If you are using a cable, connect the cable to your {platformPhone}. If you are using Bluetooth, scan the QR code by opening the scanner.
            </li>
          ) : (
            <li>Connect the device to Discover console. If you are using cable, plug the cable to your device and choose “App” when a dialog is prompted on the console screen. If you are using Bluetooth, scan the QR code by opening the scanner.</li>
          )}
          <li>Once connected, you should see a green dot on the “Mobile” button on the Discover console.</li>
          <li>Start a workout from the console.</li>
          <li>You can view the in-workout display by viewing the “Current Workout Data” section.</li>
          <li>At the end of the workout, the complete workout summary can be seen in the logs.</li>
        </ol>
      </div>
    </div>
  );
};

export default GettingStartedComponent;
