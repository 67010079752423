import BaseApi from './BaseApi';

class LicenseApi {
    constructor() {
        this.api = new BaseApi();
        this.headers = {
            'Content-Type': 'application/json',
        };
    }

    create(user, company, license) {
        const input = {
            developer: user,
            company,
            application: license,
        };

        return this.api.post('developer/create_app', JSON.stringify(input), this.headers);
    }

    update(license, env) {
        const input = {
            ...license,
            appEditMode: env.toUpperCase(),
        };

        return this.api.post('developer/update_app', JSON.stringify(input), this.headers);
    }

    move(license) {
        const input = {
            ...license,
        };

        return this.api.post('developer/release_app', JSON.stringify(input), this.headers);
    }

    delete(licenseId, apigeeAppId) {
        const appId = licenseId.toString() + ((apigeeAppId === 0) ? '' : ('_' + apigeeAppId.toString()));
        return this.api.delete(`developer/app/${appId}`, '', this.headers);
    }

    approve(licenseId, facilities, env, consumerKey, assetApiRequested) {
        const input = {
            id: licenseId,
            consumerKey: consumerKey,
            facilities: facilities,
            appEditMode: env.toUpperCase(),
            assetApiRequested: assetApiRequested,
        }
        return this.api.post('developer/approve_callbacks', JSON.stringify(input), this.headers);
    }

    deny(licenseId, facilities, env, consumerKey, assetApiRequested) {
        const input = {
            id: licenseId,
            consumerKey: consumerKey,
            facilities: facilities,
            appEditMode: env.toUpperCase(),
            assetApiRequested: assetApiRequested,
        }
        return this.api.post('developer/deny_callbacks', JSON.stringify(input), this.headers);
    }
}

export default new LicenseApi();
