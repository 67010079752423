import { createAction } from 'redux-actions'

import { SET_FILE, IS_LOADING } from '../reducers/storage'
import { getUrl, getArchiveFiles } from '../../firebase/storage'
import { SDK_LATEST, ARCHIVES } from '../../constants/storage'
import { API_NAMES } from '../../constants/common'

const setFile = createAction(SET_FILE)
const setLoading = createAction(IS_LOADING)

export const getLatestSdk = (api) => async dispatch => {
    const path = `metadata/${API_NAMES[api]}/SDK/latest`

    dispatch(setLoading(true))

    try {
        const downloadUrl = await getUrl(path)

        dispatch(setFile({ path: `${api}.${SDK_LATEST}`, file: { downloadUrl } }))
    } catch (error) {
        //TODO handle storage errors
    } finally {
        dispatch(setLoading(false))
    }
}

export const getArchives = (api, version) => async dispatch => {
    const path = `metadata/${API_NAMES[api]}`

    dispatch(setLoading(true))

    try {
        const archives = await getArchiveFiles(path, version)

        dispatch(setFile({ path: `${api}.${ARCHIVES}`, file: archives, version, api }))
    } catch (error) {
        //TODO handle storage errors
    } finally {
        dispatch(setLoading(false))
    }
}
