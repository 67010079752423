import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GRIDS } from "../Constants";

// INITIAL STATE
const initialState = GRIDS.INITIAL_STATE.GRID;

// CREATE ACTIONS
export const setGridAction = createAsyncThunk(GRIDS.ACTION.SET_GRID, async (payload, { rejectWithValue, getState, dispatch }) => {
  return payload;
});

export const setGridItemsAction = createAsyncThunk(GRIDS.ACTION.SET_GRID_ITEMS, async (payload, { rejectWithValue, getState, dispatch }) => {
  return payload;
});

// CREATE SLICE
const gridStoreSlice = createSlice({
  name: GRIDS.SLICE,
  //name: "DevPortalDataTypes",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setGridAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(setGridAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.items = action.payload;
      state.grids[action.payload.name] = { ...GRIDS.INITIAL_STATE.GRID_ITEM, items: action.payload.items, sortBy: action.payload.sortBy, pagination: action.payload.pagination };
    });

    builder.addCase(setGridAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(setGridItemsAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(setGridItemsAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.items = action.payload;
      state.grids[action.payload.name].items = action.payload.items;
    });

    builder.addCase(setGridItemsAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });
  },
});

// CREATE REDUCER
const gridStoreReducer = gridStoreSlice.reducer;
export default gridStoreReducer;
