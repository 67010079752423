import { useEffect } from "react";
import ReactGA from "react-ga";

import { WORKOUT_PRESET_CODES } from "../../WorkoutPreset/IOS/WorkoutPresetConstants";
import CodeComponent from "../../../../core/CodeComponent/CodeComponent";

import "./workoutPreset.scss";

const WorkoutPresetComponent = () => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Workout Preset Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="workout-preset">
      <div>
        <p>
          The mobile app can pass a workout to the console and the user can use the workout presets to start a workout on the equipment. This can be done by implementing <code>LFPresetDataSource </code>
          delegate:
        </p>

        <CodeComponent code={WORKOUT_PRESET_CODES.presetDataSource} />

        <p>The list of workout presets can be generated using LFEquipmentWorkoutBuilder class. The sample code below generates a time goal manual workout preset for bike equipment with duration set as 60 minutes and level set as level 5.</p>

        <CodeComponent code={WORKOUT_PRESET_CODES.workoutBuilder} />
      </div>

      <div>
        <h3>Getting information about console and equipment</h3>
        <p>After the connection between the mobile device and the console is established, the app can obtain some information about the console and equipment below:</p>
        <p>Developer Connect will provide equipment information using the below delegate method:</p>
        <CodeComponent code={WORKOUT_PRESET_CODES.equipmentInfo} />
        LFEquipment object contains basic information about connected equipment.
        <br />
        <br />
        <div className="indent">
          <CodeComponent title="equipmentId" titleClassName="bold" code={WORKOUT_PRESET_CODES.equipmentId} />

          <CodeComponent title="eqType" titleClassName="bold" code={WORKOUT_PRESET_CODES.equipmentType} />
        </div>
      </div>
    </div>
  );
};

export default WorkoutPresetComponent;
