const _ = require("lodash");

export const ACTIONS = {
  move: "move",
  delete: "delete",
  add: "add",
  editDev: "editDev",
  editProd: "editProd",
};

export const API_NAMES = {
  android: "Android",
  ios: "iOS",
  web: "Web",
};

export const MEMBER_ROLES = {
  admin: "Admin",
  member: "Member",
};

export const PLATFORM_NAMES = {
  android: "Android",
  ios: "iOS",
  web: "Web API",
  iosCaps: "IOS",
};

export const PLATFORMS = {
  [PLATFORM_NAMES.android]: "ADR",
  [PLATFORM_NAMES.ios]: "APL",
  [PLATFORM_NAMES.web]: "WEB",
  [PLATFORM_NAMES.iosCaps]: "APL",
};

export const PLATFORM_MAP = _.invert(PLATFORM_NAMES);

export const LICENSE_CATEGORY_MAP = {
  Nutrition: 1,
  Tracking: 2,
  Rewards: 3,
  Gaming: 4,
  Wellness: 5,
  "Personal Training": 6,
  Other: 7,
};

export const LICENSE_ACTIONS = {
  create: "create",
  update: "update",
  move: "move",
};

export const ENVIRONMENTS = {
  dev: "dev",
  prod: "prod",
};

export const GA_ANALYTICS = {
  dev: "UA-132570787-1",
};

export const WEB_WIDGET_URL = {
  url: "https://static.zdassets.com/ekr/snippet.js?key=2920d064-e174-4b4c-9e81-f56cc6059f40",
};
