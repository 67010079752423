import Cookies from "universal-cookie";

import { refreshToken } from "../firebase/auth";

const cookies = new Cookies();

const STORAGE_TOKEN_KEY = "TOKEN";
const STORAGE_USER_KEY = "USER";
const STORAGE_COOKIE_BANNER_KEY = "HIDE_COOKIE_POLICY_BANNER";
const STORAGE_COMPANY_KEY = "COMPANY";

const handleStorage = (action, name, value, useCookie) => {
  let result = localStorage[`${action}Item`](name, value);

  if (useCookie) {
    switch (action) {
      case "set":
        cookies.set(name, value, { path: "/" });
        break;
      case "get":
        const cookie = cookies.get(name, { doNotParse: true });

        if (cookie) {
          localStorage.setItem(name, cookie);
          result = cookie;
        }

        break;
      case "remove":
        cookies.remove(name, { path: "/" });
        break;
      default:
        break;
    }
  }

  return result;
};

export const hideCookiePolicyBanner = () =>
  handleStorage("set", STORAGE_COOKIE_BANNER_KEY, true, true);

export const isCookiePolicyBannerHidden = () =>
  handleStorage("get", STORAGE_COOKIE_BANNER_KEY, null, true);

export const removeToken = () =>
  handleStorage("remove", STORAGE_TOKEN_KEY, null, true);

export const removeUserId = () =>
  handleStorage("remove", STORAGE_USER_KEY, null, true);

export const removeCompanyId = (useCookie) =>
  handleStorage("remove", STORAGE_COMPANY_KEY, null, useCookie);

export const getToken = async () => {
  await refreshToken();

  return handleStorage("get", STORAGE_TOKEN_KEY, null, true);
};

export const doCredentialsExist = () => {
  const token = handleStorage("get", STORAGE_TOKEN_KEY, null, true);
  const user = handleStorage("get", STORAGE_USER_KEY, null, true);

  return user && token;
};

export const getUserId = () =>
  handleStorage("get", STORAGE_USER_KEY, null, true);

export const getCompanyId = () =>
  handleStorage("get", STORAGE_COMPANY_KEY, null, true);

export const setToken = (token, useCookie) =>
  handleStorage("set", STORAGE_TOKEN_KEY, token, useCookie);

export const setUserId = (user, useCookie) =>
  handleStorage("set", STORAGE_USER_KEY, user, useCookie);

export const setCompanyId = (companyId, useCookie) =>
  handleStorage("set", STORAGE_COMPANY_KEY, companyId, useCookie);

export const isStaySignedIn = () => {
  const tokenCookie = cookies.get(STORAGE_TOKEN_KEY, { doNotParse: true });
  const userCookie = cookies.get(STORAGE_USER_KEY, { doNotParse: true });

  return userCookie && tokenCookie;
};
