export const prepareCompanyDataBeforeSave = (values) => {
  console.log("***** prepareCompanyDataBeforeSave() values: ", values);
  // dispatch registerAction here...
  if (values.industry.value !== "Fitness") {
    values.vertical = null;
    values.otherVertical = null;
  } else if (values.vertical.value !== "Other") {
    values.otherVertical = null;
  }

  if (values.industry.value !== "Other") {
    values.otherIndustry = null;
  }

  getSelectValue(values, "country");
  getSelectValue(values, "platform", true);
  getSelectValue(values, "companySize");
  getSelectValue(values, "industry");
  getSelectValue(values, "vertical");
};

export const getSelectValue = (values, name, isArray = false) => {
  if (isArray && Array.isArray(values[name]) && values[name].length) {
    values[name] = values[name].map((e) => e.value).join(",");
  } else if (values[name] && values[name].value) values[name] = values[name].value;
};
