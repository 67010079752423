import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";
import ROLES from "./TeamMemberConstants";
import TEAM_MEMBER_RULES from "../../../validation/validationRules/teamMember";
import { getTooltip } from "../TeamMembers/TeamMembersComponent";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";

import "./team-member.scss";
import { Form } from "react-final-form";
import { getValidationRules } from "../../../validation/validation";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createTeamMemberAction, editTeamMemberAction } from "../../../redux/slice/teamMembersSlice";

const _ = require("lodash");

const TeamMemberComponent = (props) => {
  const sessionState = useSelector((__state__) => __state__.session);
  const teamMembersState = useSelector((__state__) => __state__.teamMembers);
  const dispatch = useDispatch();
  const [selectedMember, setSelectedMember] = useState({
    email: props.member && props.member.email,
    firstName: props.member && props.member.firstName,
    lastName: props.member && props.member.lastName,
    role: props.member && props.member.companies[sessionState.companyId].isAdmin ? { label: "Admin", value: "Admin" } : { label: "Member", value: "Member" },
  });

  useEffect(() => {
    if (teamMembersState.fulfilled === true) {
      props.close();
    }
  }, [teamMembersState.fulfilled]);

  const { cancelButtonTitle, confirmButtonTitle, close } = props;

  const handleSubmit = (__teamMember__) => {
    setSelectedMember({ ...selectedMember, ...__teamMember__ });
    const { companyId } = sessionState;
    if (props.member) {
      dispatch(editTeamMemberAction({ companyId, member: __teamMember__, memberId: props.member.uid, members: teamMembersState.items }));
    } else {
      dispatch(createTeamMemberAction({ companyId, member: __teamMember__, members: teamMembersState.items }));
    }
  };

  const initialValues = () => {
    if (props.member) {
      return selectedMember;
    } else return null;
  };

  return (
    <div>
      {teamMembersState.pending && <LoadingComponent fullScreen />}
      {/* <Form onSubmit={handleSubmit} className="team-member"> */}
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues(sessionState.companyId)}
        validate={getValidationRules(["email", "firstName", "lastName", "role"], Object.assign(TEAM_MEMBER_RULES))}
        render={(__props__) => (
          <form onSubmit={__props__.handleSubmit}>
            <FieldGroupComponent
              label="Email"
              name="email"
              type="text"
              formProps={{
                maxLength: 100,
              }}
              submissionError={teamMembersState.rejected && teamMembersState.error}
            />
            <FieldGroupComponent
              label="First Name"
              name="firstName"
              type="text"
              formProps={{
                maxLength: 100,
              }}
            />
            <FieldGroupComponent
              label="Last Name"
              name="lastName"
              type="text"
              formProps={{
                maxLength: 100,
              }}
            />
            <div className="role">
              <FieldGroupComponent
                label="User Role"
                name="role"
                placeholder="Select"
                type="select"
                formProps={{
                  id: "role",
                  options: Object.keys(ROLES).map((roleName) => _.capitalize(roleName)),
                }}
              />
              {getTooltip(this)}
            </div>
            <div className="buttons">
              <Button className="btn btn-default dark" onClick={close}>
                {cancelButtonTitle}
              </Button>
              <Button disabled={props.invalid || props.submitting} type="submit">
                {confirmButtonTitle}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

// TeamMemberComponent.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   submitting: PropTypes.bool.isRequired,
//   isInvalid: PropTypes.bool,
//   updateInvalid: PropTypes.func,
// };

export default TeamMemberComponent;
