import React, { useEffect, useState } from "react";
//import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import GridContainer from "../../../containers/GridContainer";

import "./licensePanelGroup.scss";
import { ENVIRONMENTS } from "../../../constants/common";
import { useSelector } from "react-redux";

const LicensePanelGroupComponent = (props) => {
  const licensesState = useSelector((__state__) => __state__.licenses);
  const [thisState, setThisState] = useState({ devGridUpdate: false, prodGridUpdate: false });

  useEffect(() => {
    setThisState({
      ...thisState,
      [`${props.activeKey}GridUpdate`]: true,
    });
  }, [licensesState.items]);
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     devGridUpdate: false,
  //     prodGridUpdate: false,
  //   };
  // }

  const onPanelEntering = (activeKey) => {
    setThisState({
      ...thisState,
      [`${activeKey}GridUpdate`]: true,
    });
    // this.setState({
    //   [`${activeKey}GridUpdate`]: true,
    // });
  };

  const {
    devLicenses,
    prodLicenses,
    gridProps,
    //areSelectedItemsEmpty,
    //canEdit,
    // handleSelect,
    isDev,
    addLicenseMethod,
    backToDevelopment,
  } = props;
  let activeKey = props.activeKey;
  let isDevPanelDisabled = !devLicenses || devLicenses.length === 0;
  let isProdPanelDisabled = !prodLicenses || prodLicenses.length === 0;

  if ((isDevPanelDisabled && activeKey === ENVIRONMENTS.dev) || (isProdPanelDisabled && activeKey === ENVIRONMENTS.prod)) {
    activeKey = null;
  }

  return (
    <div className="panel-group">
      {isDev && devLicenses.length + prodLicenses.length > 0 && <GridContainer name="dev" gridProps={gridProps} items={devLicenses} forceUpdate={thisState.devGridUpdate} />}
      {isDev && devLicenses.length + prodLicenses.length < 1 && (
        <div className="no-dev-license">
          <h3>You've come to the right place</h3>
          <p>
            Add a license to begin trying our APIs. Test it out and once
            <br /> you're ready to launch the application, move it to production
          </p>
          <span className="addLicense" onClick={() => addLicenseMethod("add")}>
            Add License
          </span>
        </div>
      )}
      {!isDev && prodLicenses.length > 0 && <GridContainer name="prod" gridProps={gridProps} items={prodLicenses} forceUpdate={thisState.prodGridUpdate} />}
      {!isDev && prodLicenses.length < 1 && (
        <div className="no-prod-license">
          <h3>There aren't any apps in Production</h3>
          <br />
          <p>
            When you’re ready to launch the application, <br />
            click on "Move to Production".
          </p>
          <Image responsive src={require("./MoveToProdExample.png")} />
          <span className="addLicense" onClick={() => backToDevelopment("dev")}>
            Back to Development
          </span>
        </div>
      )}

      {/* <PanelGroup accordion id="accordion" onSelect={handleSelect} activeKey={activeKey}>
                <PanelComponent
                    title="Development"
                    eventKey={ENVIRONMENTS.dev}
                    activeKey={activeKey}
                    disabled={isDevPanelDisabled}
                    badge={devLicenses.length}
                    eventProps={{
                        onEntering: () => this.onPanelEntering(activeKey),
                    }}
                >
                    {canEdit && <Button
                        className="move-to-production-button"
                        disabled={areSelectedItemsEmpty}
                        onClick={moveLicenseToProd}
                    >Move to Production</Button>}
                    <GridContainer
                        name="dev"
                        gridProps={gridProps}
                        items={devLicenses}
                        enableCheckbox={canEdit}
                        singleSelection
                        enablePagination
                        forceUpdate={this.state.devGridUpdate}
                    />
                </PanelComponent>
                <PanelComponent
                    title="Production"
                    eventKey={ENVIRONMENTS.prod}
                    activeKey={activeKey}
                    disabled={isProdPanelDisabled}
                    badge={prodLicenses.length}
                    eventProps={{
                        onEntering: () => this.onPanelEntering(activeKey),
                    }}
                >
                    <GridContainer
                        name="prod"
                        gridProps={gridProps}
                        items={prodLicenses}
                        enableCheckbox={false}
                        singleSelection
                        enablePagination
                        forceUpdate={this.state.prodGridUpdate}
                    />
                </PanelComponent>
            </PanelGroup> */}
    </div>
  );
};

LicensePanelGroupComponent.propTypes = {
  // devLicenses: PropTypes.array.isRequired,
  // prodLicenses: PropTypes.array.isRequired,
  // gridProps: PropTypes.array.isRequired,
  //areSelectedItemsEmpty: PropTypes.bool.isRequired,
  //canEdit: PropTypes.bool.isRequired,
  // activeKey: PropTypes.string,
  // isDev: PropTypes.bool.isRequired,
  // addLicenseMethod: PropTypes.func.isRequired,
  // backToDevelopment: PropTypes.func.isRequired,
};

export default LicensePanelGroupComponent;
