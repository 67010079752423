import PropTypes from "prop-types";
import { Accordion, Image, Table } from "react-bootstrap";
import AccordionComponent from "../../../../core/AccordionComponent/AccordionComponent";
import NamespaceGridComponent from "../NamespaceGridComponent";
import GridContainer from "../../../../../containers/GridContainer";
import CodeComponent from "../../../../core/CodeComponent/CodeComponent";
import { DATA_NAMES } from "../DataTypesConstants";

import "./data.scss";

const _ = require("lodash");

const DataComponent = (props) => {
  const ABBREVIATIONS = ["LF", "GPS"];

  const DESCRIPTION_GRID_PROP = {
    id: "description",
    title: "Description",
    className: "description",
  };

  const __GRID_PROPS__ = {
    XML_GRID_PROPS: [
      {
        title: "Name (type)",
        className: "name",
        getter: ({ isArray, name, type }) => (
          <div>
            <span>
              {name}&nbsp; ({isArray ? <a onClick={() => handleSelect(DATA_NAMES.types, getTypePanelKey(type))}>{type}</a> : type})
            </span>
          </div>
        ),
      },
      {
        title: "Min/Max Occurs",
        className: "occurs",
        getter: ({ minOccurs, maxOccurs }) => (
          <div>
            <span>{`${minOccurs}/${maxOccurs}`}</span>
          </div>
        ),
      },
      DESCRIPTION_GRID_PROP,
    ],
    JSON_GRID_PROPS: [
      {
        id: "name",
        title: "Property",
        className: "property",
      },
      {
        title: "Type",
        className: "type",
        getter: ({ isArray, name, type }) => (
          <div>
            <span>
              {isArray ? "array of " : ""}
              {name}&nbsp; ({isArray ? <a onClick={() => handleSelect(DATA_NAMES.types, getTypePanelKey(type))}>{type}</a> : type})
            </span>
          </div>
        ),
      },
      DESCRIPTION_GRID_PROP,
    ],
  };
  const XML_GRID_PROPS = [
    {
      title: "Name (type)",
      className: "name",
      getter: ({ isArray, name, type }) => (
        <div>
          <span>
            {name}&nbsp; ({isArray ? <a onClick={() => handleSelect(DATA_NAMES.types, getTypePanelKey(type))}>{type}</a> : type})
          </span>
        </div>
      ),
    },
    {
      title: "Min/Max Occurs",
      className: "occurs",
      getter: ({ minOccurs, maxOccurs }) => (
        <div>
          <span>{`${minOccurs}/${maxOccurs}`}</span>
        </div>
      ),
    },
    DESCRIPTION_GRID_PROP,
  ];

  const JSON_GRID_PROPS = [
    {
      id: "name",
      title: "Property",
      className: "property",
    },
    {
      title: "Type",
      className: "type",
      getter: ({ isArray, name, type }) => (
        <div>
          <span>
            {isArray ? "array of " : ""}
            {name}&nbsp; ({isArray ? <a onClick={() => handleSelect(DATA_NAMES.types, getTypePanelKey(type))}>{type}</a> : type})
          </span>
        </div>
      ),
    },
    DESCRIPTION_GRID_PROP,
  ];

  const getCamelCaseText = (text) => {
    text = _.camelCase(text);

    const existingAbbreviations = text.toUpperCase().match(new RegExp(`(${ABBREVIATIONS.join("|")})`, "g"));

    if (existingAbbreviations) {
      existingAbbreviations.forEach((abbreviation) => {
        text = text.replace(new RegExp(abbreviation, "ig"), abbreviation);
      });
    }

    return text;
  };

  const getTypePanelKey = (type) => parseInt(document.getElementById(`${type}-panel`).children[0].id.split("-").pop(), 10);

  const scrollTo = (dataName, activeKey, panelName) => {
    if (typeof activeKey === "number") {
      const activeElementTop = document.querySelector(`#${dataName}-accordion #${panelName}-panel #${dataName}-accordion-heading-${activeKey}`).offsetTop;
      const headerElementHeight = document.getElementById("header").offsetHeight;
      const navBarElementHeight = document.getElementsByClassName("nav nav-tabs")[0].offsetHeight;

      window.scrollTo({
        top: activeElementTop - headerElementHeight - navBarElementHeight,
        behavior: "smooth",
      });
    }
  };

  const handleSelect = (dataName, activeKey, title) => {
    const { activeKeys, setActiveKey, namespaceName } = props;

    if (activeKeys[dataName] === activeKey) {
      scrollTo(dataName, activeKey, title);
    } else {
      setActiveKey(namespaceName, dataName, activeKey);
    }
  };

  const generateTypeGridItems = (properties) => {
    return Object.keys(properties).map((name) => {
      const property = properties[name];
      const { minOccurs, maxOccurs, description = "", ref } = property;
      let { type } = property;
      let isArray = type === "array" && ref;

      if (ref) {
        type = ref;
      }

      return {
        name,
        type: getCamelCaseText(type),
        minOccurs,
        maxOccurs,
        description,
        isArray,
      };
    });
  };

  const getSection = (key, type, isElements, items, code) => (
    <div className={type}>
      <h4>
        {isElements ? "Example " : " "}
        {type}
      </h4>
      {!isElements && <GridContainer className="data-grid" name={`${key}-${type}-grid`} gridProps={__GRID_PROPS__[`${type.toUpperCase()}_GRID_PROPS`]} items={items} />}
      {isElements && <CodeComponent code={code} />}
    </div>
  );

  const { namespace, namespaceName, dataNames, activeKeys } = props;
  return (
    <>
      {dataNames.map((dataName, index) => {
        const activeKey = activeKeys[dataName];
        const data = namespace[dataName];
        const isElements = dataName === DATA_NAMES.elements;

        return (
          data && (
            <div className="data" key={index}>
              <h4>Data {_.capitalize(dataName)}</h4>
              <Accordion id={`${dataName}-accordion`} onSelect={(activeKey) => handleSelect(dataName, activeKey)} className="panel-group">
                {Object.keys(data).map((key, index) => {
                  const value = data[key];
                  const title = getCamelCaseText(key);
                  let gridItems = [];
                  let typeElementIndex = index;

                  if (!isElements) {
                    gridItems = generateTypeGridItems(value.properties);
                  } else {
                    typeElementIndex = Object.keys(namespace[DATA_NAMES.types]).indexOf(key);
                  }

                  return (
                    <AccordionComponent
                      key={index}
                      eventKey={index}
                      activeKey={activeKey}
                      title={title}
                      eventProps={{
                        onEntered: () => scrollTo(dataName, activeKey, title),
                      }}
                    >
                      <NamespaceGridComponent namespace={namespace} name={namespaceName} elementName={title} dataName={dataName} onTypeClick={() => handleSelect(DATA_NAMES.types, typeElementIndex, title)} />
                      {getSection(title, "xml", isElements, gridItems, value.xml)}
                      {getSection(title, "json", isElements, gridItems, value.json)}
                    </AccordionComponent>
                  );
                })}
              </Accordion>
            </div>
          )
        );
      })}
    </>
  );
};

DataComponent.propTypes = {
  namespace: PropTypes.object.isRequired,
  namespaceName: PropTypes.string.isRequired,
  dataNames: PropTypes.array.isRequired,
  activeKeys: PropTypes.object.isRequired,
  setActiveKey: PropTypes.func.isRequired,
};

export default DataComponent;
