import { createAction } from "redux-actions";
import { toast } from "react-toastify";
// import { replace } from 'react-router-redux';
import licenseApi from "../../api/licenseApi";
import facilityConfigurationApi from "../../api/facilityConfigurationApi";
import moment from "moment";

import { SET_LICENSES, SET_LICENSE, CREATE_LICENSE, UPDATE_LICENSE, REMOVE_LICENSE, IS_LOADING } from "../reducers/licenses";
import { getLicenses as getFirebaseLicenses, createLicense as createFirebaseLicense, updateLicense as updateFirebaseLicense, removeLicense as removeFirebaseLicense } from "../../firebase/licenses";
import { createTicket, updateTicket, resolveTicket, createOrganizations, createZendeskUser, updateApigeeCustomAttribute } from "../../firebase/cloud-functions";
import { updateUser as updateFirebaseUser } from "../../firebase/user";
import { updateCompany as updateFirebaseCompany, getPendingFacilities as getPendingFacilitiesFunc, getPendingFacilitiesAdmin as getPendingFacilitiesAdminFunc, updatePendingFacilities as updatePendingFacilitiesFunc } from "../../firebase/company";

import { updateReduxUser } from "./session";
import { updateCompany } from "./companies";
import { LICENSES_PATH } from "../../constants/routes";
import { PLATFORMS, LICENSE_CATEGORY_MAP, ENVIRONMENTS, PLATFORM_NAMES } from "../../constants/common";
import { ZENDESK_STATUS, ZENDESK_MESSAGE } from "../../constants/zendesk";

const _ = require("lodash");

const setLicenses = createAction(SET_LICENSES);
const setLicense = createAction(SET_LICENSE);
const createLicense = createAction(CREATE_LICENSE);
const updateLicense = createAction(UPDATE_LICENSE);
const removeLicense = createAction(REMOVE_LICENSE);
const setLoading = createAction(IS_LOADING);

const prepareUser = ({ developerId, userName, email, firstName, lastName }) => ({
  ...(developerId && { id: developerId }),
  userId: userName,
  email,
  firstName,
  lastName,
  termsAndConditionAccepted: true,
  subscriptionAgreementAccepted: true,
});

const prepareCompany = ({ id, name, platform, phone, size, companySize, industry, vertical, country, address, city, state, postalCode, otherIndustry, otherVertical }) => ({
  ...(id && { id }),
  name,
  enrollmentType: platform.map((type) => PLATFORMS[type.trim()]).join(","),
  phoneNo: phone,
  companySize: size || companySize,
  industry,
  vertical: industry === "Fitness" ? vertical : null,
  country,
  address,
  city,
  state,
  postalCode,
  otherIndustry,
  otherVertical,
});

const prepareLicense = ({ id, name, description, category, platform, license, consumerSecret, consumerKey, redirectUrl, subEnabled, facilitiesSubscribedFor, accountCallback, manualCallback, lfConsoleCallback, gpsCallback, lifefitnessCallback, lfUserLoginCallback, lfUserLogoutCallback, lfUserWorkoutStartCallback, lfUserWorkoutEndCallback, workoutdataCallback, strengthAssetLoginCallback, strengthAssetLogoutCallback, strengthResultsCallback, assetStatsCallback, apigeeAppId }) => ({
  id,
  name,
  description,
  appCategoryId: LICENSE_CATEGORY_MAP[category],
  platform: PLATFORMS[platform],
  license,
  redirectUrl,
  subEnabled: subEnabled || false,
  ...(consumerSecret && { consumerSecret }),
  ...(consumerKey && { consumerKey }),
  ...(facilitiesSubscribedFor && { facilitiesSubscribedFor }),
  ...(subEnabled && accountCallback && { accountCallback }),
  ...(subEnabled && manualCallback && { manualCallback }),
  ...(subEnabled && lfConsoleCallback && { lfConsoleCallback }),
  ...(subEnabled && gpsCallback && { gpsCallback }),
  ...(subEnabled && lifefitnessCallback && { lifefitnessCallback }),
  ...(subEnabled && lfUserLoginCallback && { lfUserLoginCallback }),
  ...(subEnabled && lfUserLogoutCallback && { lfUserLogoutCallback }),
  ...(subEnabled && lfUserWorkoutStartCallback && { lfUserWorkoutStartCallback }),
  ...(subEnabled && lfUserWorkoutEndCallback && { lfUserWorkoutEndCallback }),
  ...(subEnabled && workoutdataCallback && { workoutdataCallback }),
  ...(subEnabled && strengthAssetLoginCallback && { strengthAssetLoginCallback }),
  ...(subEnabled && strengthAssetLogoutCallback && { strengthAssetLogoutCallback }),
  ...(subEnabled && strengthResultsCallback && { strengthResultsCallback }),
  ...(subEnabled && assetStatsCallback && { assetStatsCallback }),
  ...(apigeeAppId && { apigeeAppId }),
});

const prepareLicenseFirebase = ({ id, name, description, appCategoryId, platform, license, consumerSecret, consumerKey, redirectUrl, subEnabled, facilitiesSubscribedFor, facilitiesSubscriptionPending, facilitiesSubscriptionRejected, facilitiesAdded, accountCallback, manualCallback, lfConsoleCallback, gpsCallback, lifefitnessCallback, lfUserLoginCallback, lfUserLogoutCallback, lfUserWorkoutStartCallback, lfUserWorkoutEndCallback, workoutdataCallback, strengthAssetLoginCallback, strengthAssetLogoutCallback, strengthResultsCallback, assetStatsCallback, apigeeAppId }) => ({
  id,
  name,
  description,
  appCategoryId,
  platform,
  license,
  redirectUrl,
  subEnabled: subEnabled || false,
  ...(consumerSecret && { consumerSecret }),
  ...(consumerKey && { consumerKey }),
  ...(facilitiesSubscribedFor && { facilitiesSubscribedFor }),
  ...(facilitiesSubscriptionPending && { facilitiesSubscriptionPending }),
  ...(facilitiesSubscriptionRejected && { facilitiesSubscriptionRejected }),
  ...(facilitiesAdded && { facilitiesAdded }),
  accountCallback: subEnabled && accountCallback ? accountCallback : null,
  manualCallback: subEnabled && manualCallback ? manualCallback : null,
  lfConsoleCallback: subEnabled && lfConsoleCallback ? lfConsoleCallback : null,
  gpsCallback: subEnabled && gpsCallback ? gpsCallback : null,
  lifefitnessCallback: subEnabled && lifefitnessCallback ? lifefitnessCallback : null,
  lfUserLoginCallback: subEnabled && lfUserLoginCallback ? lfUserLoginCallback : null,
  lfUserLogoutCallback: subEnabled && lfUserLogoutCallback ? lfUserLogoutCallback : null,
  lfUserWorkoutStartCallback: subEnabled && lfUserWorkoutStartCallback ? lfUserWorkoutStartCallback : null,
  lfUserWorkoutEndCallback: subEnabled && lfUserWorkoutEndCallback ? lfUserWorkoutEndCallback : null,
  workoutdataCallback: subEnabled && workoutdataCallback ? workoutdataCallback : null,
  strengthAssetLoginCallback: subEnabled && strengthAssetLoginCallback ? strengthAssetLoginCallback : null,
  strengthAssetLogoutCallback: subEnabled && strengthAssetLogoutCallback ? strengthAssetLogoutCallback : null,
  strengthResultsCallback: subEnabled && strengthResultsCallback ? strengthResultsCallback : null,
  assetStatsCallback: subEnabled && assetStatsCallback ? assetStatsCallback : null,
  ...(apigeeAppId && { apigeeAppId }),
});

const formatLicense = (license, env) => ({
  ...license,
  platform: _.findKey(PLATFORMS, (type) => type === license.platform),
  category: _.findKey(LICENSE_CATEGORY_MAP, (categoryId) => categoryId === license.appCategoryId),
  env,
});

const TOAST_MESSAGE = {
  moved: " was successfully moved to Production. To edit the app expand the production section below.",
  created: (licenseNumber) => ` was successfully created. Your license number is ${licenseNumber}.
        You can download our client library and use this license to integrate with us.
        For more info please refer to our docs.`,
  edited: " was successfully edited.",
  deleted: " has been successfully deleted.",
};

export const getLicenses = (companyId) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    let licenses = await getFirebaseLicenses(companyId);

    licenses = Object.keys(licenses).map((licenseKey) => {
      const { dev, prod } = licenses[licenseKey];
      const license = {
        [ENVIRONMENTS.dev]: formatLicense(dev, ENVIRONMENTS.dev),
        ...(prod && { [ENVIRONMENTS.prod]: formatLicense(prod, ENVIRONMENTS.prod) }),
      };

      return {
        ...license,
        id: licenseKey,
      };
    });

    dispatch(setLicenses({ licenses }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getLicense = (licenseId) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    dispatch(setLicense({ licenseId }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addLicense =
  ({ user, company, licenseData }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let data = { ...licenseData };
    let facilitiesAdded = data.facilitiesAdded;
    data.facilitiesSubscribedFor = facilitiesAdded || "";
    data.subEnabled = ((data.facilitiesSubscribedFor && data.facilitiesSubscribedFor.length > 0) || (data.facilitiesSubscriptionPending && data.facilitiesSubscriptionPending.length > 0) || (data.facilitiesSubscriptionRejected && data.facilitiesSubscriptionRejected.length > 0) || (facilitiesAdded && facilitiesAdded.length > 0)) && data.platform === PLATFORM_NAMES.web;

    try {
      let apiResult = await licenseApi.create(prepareUser(user), prepareCompany(company), prepareLicense(data));

      apiResult = apiResult.data;

      if (data.platform === PLATFORM_NAMES.web) {
        apiResult.facilitiesSubscriptionPending = data.facilitiesSubscribedFor;
        apiResult.facilitiesSubscribedFor = "";
        apiResult.facilitiesSubscriptionRejected = "";
        apiResult.facilitiesAdded = "";
        data.assetApi && data.assetApi !== "" ? (apiResult.assetApi = true) : (apiResult.assetApi = null);
        data.forcedLogIn && data.forcedLogIn !== "" ? (apiResult.forcedLogIn = true) : (apiResult.forcedLogIn = null);
      } else {
        delete apiResult.facilitiesSubscribedFor;
        delete apiResult.facilitiesSubscriptionPending;
        delete apiResult.facilitiesSubscriptionRejected;
        delete apiResult.facilitiesAdded;
      }

      const license = {
        ..._.omit(apiResult, ["developerId", "companyId"]),
        lastUpdated: moment().valueOf(),
      };

      let licenseObjectForFirebase = prepareLicenseFirebase(license);
      licenseObjectForFirebase = {
        ...licenseObjectForFirebase,
        lastUpdated: moment().valueOf(),
      };

      if (!user.id) {
        const params = { developerId: apiResult.developerId };

        await updateFirebaseUser(user.uid, params);

        dispatch(updateReduxUser({ params }));
      }

      if (!company.id) {
        const params = { id: apiResult.companyId };

        await updateFirebaseCompany(company.firebaseId, params);

        dispatch(updateCompany({ params }));
      }

      await createFirebaseLicense(company.firebaseId, licenseObjectForFirebase);

      dispatch(
        createLicense({
          license: {
            [ENVIRONMENTS.dev]: formatLicense(license, ENVIRONMENTS.dev),
            id: license.id,
          },
        })
      );

      let callbacksRequested =
        Object.keys(license)
          .filter((el) => el.includes("Callback"))
          .join(",") || "";
      let additionalRequestedArr = [];
      data.assetApi && additionalRequestedArr.push("assetApi");
      data.forcedLogIn && additionalRequestedArr.push("forcedLogIn");
      let additionalRequested = additionalRequestedArr.join(",");

      if (data.platform === PLATFORM_NAMES.web) {
        // Check if user exists in Zendesk
        if (!user.zendeskId) {
          const response = await createOrganizations(user.uid);
          let orgZendeskObj = await response.json();
          let userObject = {
            user: user,
            organizationId: orgZendeskObj.zendeskId,
          };
          const response2 = await createZendeskUser(userObject);
          const userZendeskId = await response2.json();
          if (response2.ok) {
            dispatch(updateReduxUser({ params: { zendeskId: userZendeskId } }));
          }
        }

        let zendeskTicketId = 0;

        let pendingObj = await getPendingFacilitiesFunc(company.firebaseId);
        pendingObj = {
          ...pendingObj,
          backendId: company.id,
          companyName: company.name,
          userEmail: user.email,
          userName: user.firstName + " " + user.lastName,
        };

        if (!pendingObj["dev"]) {
          pendingObj["dev"] = {};
        }

        if (facilitiesAdded && license.facilitiesSubscriptionPending !== "") {
          let ticketObj = {
            facilitiesAdded: facilitiesAdded,
            companyName: company.name,
            userName: user.firstName + " " + user.lastName,
            userEmail: user.email,
            licenseName: license.name,
            callbacksRequested: callbacksRequested,
            additionalRequested: additionalRequested,
            licenseEnv: ENVIRONMENTS.dev,
          };
          let callSuccess = false;
          await createTicket(ticketObj)
            .then((response) => {
              if (response.ok) {
                callSuccess = true;
              }
              return response.json();
            })
            .then((ticketId) => {
              if (callSuccess) {
                zendeskTicketId = ticketId;
              }
            });
        }

        pendingObj["dev"][license.id] = {
          callbacks: callbacksRequested,
          additional: additionalRequested,
          consumerKey: license.consumerKey,
          facilities: license.facilitiesSubscriptionPending,
          licenseName: license.name,
          zendeskTicketId: zendeskTicketId > 0 ? zendeskTicketId : null,
        };

        if (pendingObj["dev"][license.id].facilities === "") {
          delete pendingObj["dev"][license.id];
          if (Object.keys(pendingObj["dev"]).length === 0) {
            delete pendingObj["dev"];
            if (!pendingObj.dev && !pendingObj.prod) {
              pendingObj = null;
            }
          }
        }
        await updatePendingFacilitiesFunc(company.firebaseId, pendingObj);
      }

      // dispatch(replace(LICENSES_PATH));
      toast(data.name + TOAST_MESSAGE.created(license.license));
    } catch (error) {
      if (error.response && error.response.data.split("\n")[0].split(": ")[1] === "111") {
        return error.response.data.split("\n")[1].split(":")[2].replace("[", "").replace("]", "");
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const changeLicense =
  ({ user, company, licenseData }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let zendeskUpdateRequired = false;
    let data = { ...licenseData };
    let facilitiesAdded = data.facilitiesAdded;
    let facilitiesRemoved = data.facilitiesSubscribedFor ? data.facilitiesSubscribedFor.split("-")[1] : "";
    data.facilitiesSubscribedFor = data.facilitiesSubscribedFor ? data.facilitiesSubscribedFor.split("-")[0] : "";
    data.facilitiesSubscriptionPending = data.facilitiesSubscriptionPending && data.facilitiesSubscriptionPending.split("-")[0].length > 0 ? data.facilitiesSubscriptionPending.split("-")[0] + (facilitiesAdded ? "," + facilitiesAdded : "") : facilitiesAdded ? "" + facilitiesAdded : "";
    data.facilitiesSubscriptionRejected = data.facilitiesSubscriptionRejected ? data.facilitiesSubscriptionRejected.split("-")[0] : "";
    if ((data["assetApi-active"] && data.assetApi) || (data["forcedLogIn-active"] && data.forcedLogIn)) {
      data.facilitiesSubscriptionPending = data.facilitiesSubscriptionPending && data.facilitiesSubscriptionPending.split("-")[0].length > 0 ? data.facilitiesSubscriptionPending + (data.facilitiesSubscribedFor && data.facilitiesSubscribedFor.split("-")[0].length > 0 ? "," + data.facilitiesSubscribedFor : "") : data.facilitiesSubscribedFor;
      data.facilitiesSubscribedFor = "";
      zendeskUpdateRequired = true;
    }
    data.subEnabled = (data.facilitiesSubscribedFor && data.facilitiesSubscribedFor.length > 0) || (data.facilitiesSubscriptionPending && data.facilitiesSubscriptionPending.length > 0) || (data.facilitiesSubscriptionRejected && data.facilitiesSubscriptionRejected.length > 0) || (facilitiesAdded && facilitiesAdded.length > 0) || false;

    let tempSubscribed = data.facilitiesSubscribedFor;
    data.facilitiesSubscribedFor += (data.facilitiesSubscribedFor.length > 0 ? "," : "") + data.facilitiesSubscriptionPending;

    try {
      const licenseEnv = data.env;
      const prodDate = data.prodDate;
      let license = prepareLicense(data);
      let licenseObjectForApi = _.cloneDeep(license);

      await licenseApi.update(licenseObjectForApi, licenseEnv);

      if (data.platform === PLATFORM_NAMES.web) {
        licenseObjectForApi.facilitiesSubscribedFor = tempSubscribed;
        licenseObjectForApi.facilitiesSubscriptionPending = data.facilitiesSubscriptionPending;
        licenseObjectForApi.facilitiesSubscriptionRejected = data.facilitiesSubscriptionRejected;
        licenseObjectForApi.facilitiesAdded = "";
        data.assetApi && data.assetApi !== "" ? (licenseObjectForApi.assetApi = true) : (licenseObjectForApi.assetApi = null);
        data.forcedLogIn && data.forcedLogIn !== "" ? (licenseObjectForApi.forcedLogIn = true) : (licenseObjectForApi.forcedLogIn = null);
        licenseData.facilitiesSubscribedFor = tempSubscribed;
        licenseData.facilitiesSubscriptionPending = data.facilitiesSubscriptionPending;
        licenseData.facilitiesSubscriptionRejected = data.facilitiesSubscriptionRejected;
        licenseData.facilitiesAdded = "";
        data.assetApi && data.assetApi !== "" ? (licenseData.assetApi = true) : (licenseData.assetApi = null);
        data.forcedLogIn && data.forcedLogIn !== "" ? (licenseData.forcedLogIn = true) : (licenseData.forcedLogIn = null);
        if (!data.subEnabled) {
          licenseData.subEnabled = false;
          delete licenseData.accountCallback;
          delete licenseData.manualCallback;
          delete licenseData.lfConsoleCallback;
          delete licenseData.gpsCallback;
          delete licenseData.lifefitnessCallback;
          delete licenseData.lfUserLoginCallback;
          delete licenseData.lfUserLogoutCallback;
          delete licenseData.lfUserWorkoutStartCallback;
          delete licenseData.lfUserWorkoutEndCallback;
          delete licenseData.workoutdataCallback;
          delete licenseData.strengthAssetLoginCallback;
          delete licenseData.strengthAssetLogoutCallback;
          delete licenseData.strengthResultsCallback;
          delete licenseData.assetStatsCallback;
        }
      } else {
        delete licenseObjectForApi.facilitiesSubscribedFor;
        delete licenseObjectForApi.facilitiesSubscriptionPending;
        delete licenseObjectForApi.facilitiesSubscriptionRejected;
        delete licenseObjectForApi.facilitiesAdded;
        delete licenseData.facilitiesSubscribedFor;
        delete licenseData.facilitiesSubscriptionPending;
        delete licenseData.facilitiesSubscriptionRejected;
        delete licenseData.facilitiesAdded;
      }

      let licenseObjectForFirebase = prepareLicenseFirebase(licenseObjectForApi);

      licenseObjectForApi = {
        ...licenseObjectForApi,
        ..._.pick(licenseObjectForApi, ["license", "consumerKey", "consumerSecret"]),
        ...(prodDate && { prodDate: prodDate.valueOf() }),
        lastUpdated: moment().valueOf(),
      };

      licenseObjectForFirebase = {
        ...licenseObjectForFirebase,
        ..._.pick(licenseObjectForFirebase, ["license", "consumerKey", "consumerSecret"]),
        ...(prodDate && { prodDate: prodDate.valueOf() }),
        lastUpdated: moment().valueOf(),
      };

      await updateFirebaseLicense(company.firebaseId, licenseObjectForFirebase, licenseEnv);

      dispatch(updateLicense({ license: formatLicense(licenseObjectForApi, licenseEnv), env: licenseEnv }));

      let callbacksRequested =
        Object.keys(licenseObjectForApi)
          .filter((el) => el.includes("Callback"))
          .join(",") || "";
      let additionalRequestedArr = [];
      data.assetApi && additionalRequestedArr.push("assetApi");
      data.forcedLogIn && additionalRequestedArr.push("forcedLogIn");
      let additionalRequested = additionalRequestedArr.join(",");

      if (data.platform === PLATFORM_NAMES.web) {
        // Check if user exists in Zendesk
        if (!user.zendeskId) {
          const response = await createOrganizations(user.uid);
          let orgZendeskObj = await response.json();
          let userObject = {
            user: user,
            organizationId: orgZendeskObj.zendeskId,
          };
          const response2 = await createZendeskUser(userObject);
          const userZendeskId = await response2.json();
          if (response2.ok) {
            dispatch(updateReduxUser({ params: { zendeskId: userZendeskId } }));
          }
        }

        // Create ticket for the user's facility request and add to pending facilities table
        let zendeskTicketId = 0;

        if (data["assetApi-active"] && !data.assetApi) {
          let apigeeUpdateObj = {};
          apigeeUpdateObj.apigeeAppId = data.apigeeAppId;
          apigeeUpdateObj.environment = licenseEnv;
          await updateApigeeCustomAttribute({ apigeeUpdateObj });
        } else if (facilitiesRemoved && facilitiesRemoved.length > 0 && data.assetApi) {
          let apigeeUpdateObj = {};
          apigeeUpdateObj.apigeeAppId = data.apigeeAppId;
          apigeeUpdateObj.environment = licenseEnv;
          if (licenseObjectForApi.facilitiesSubscribedFor && licenseObjectForApi.facilitiesSubscribedFor.length > 0) {
            apigeeUpdateObj.facilities = licenseObjectForApi.facilitiesSubscribedFor;
          }
          await updateApigeeCustomAttribute({ apigeeUpdateObj });
        }

        if (data["forcedLogIn-active"] && !data.forcedLogIn) {
          let facilitiesObj = {
            facilities: data.facilitiesSubscribedFor
              .replace(/(^,)|(,$)/g, "")
              .split(",")
              .map((fId) => parseInt(fId, 10)),
          };
          await facilityConfigurationApi.disableForcedLogin(facilitiesObj, licenseEnv);
        } else if (facilitiesRemoved && facilitiesRemoved.length > 0 && data.forcedLogIn) {
          let facilitiesObj = {
            facilities: facilitiesRemoved
              .replace(/(^,)|(,$)/g, "")
              .split(",")
              .map((fId) => parseInt(fId, 10)),
          };
          await facilityConfigurationApi.disableForcedLogin(facilitiesObj, licenseEnv);
        }

        let pendingObj = await getPendingFacilitiesFunc(company.firebaseId);
        pendingObj = {
          ...pendingObj,
          backendId: company.id,
          companyName: company.name,
          userEmail: user.email,
          userName: user.firstName + " " + user.lastName,
        };

        if (!pendingObj[licenseEnv]) {
          pendingObj[licenseEnv] = {};
        }

        if (pendingObj[licenseEnv][licenseObjectForApi.id] && pendingObj[licenseEnv][licenseObjectForApi.id].zendeskTicketId) {
          zendeskTicketId = pendingObj[licenseEnv][licenseObjectForApi.id].zendeskTicketId;

          let pendingObjFacilities = pendingObj[licenseEnv][licenseObjectForApi.id].facilities.split(",").sort();
          let licenseObjFacilities = licenseObjectForApi.facilitiesSubscriptionPending.split(",").sort();

          if ((pendingObj[licenseEnv][licenseObjectForApi.id].callbacks !== callbacksRequested || pendingObj[licenseEnv][licenseObjectForApi.id].additionalRequested !== additionalRequested) && licenseObjectForApi.facilitiesSubscriptionPending !== "") {
            zendeskUpdateRequired = true;
          } else if (licenseObjectForApi.facilitiesSubscriptionPending !== "") {
            if (pendingObjFacilities.length !== licenseObjFacilities.length) {
              zendeskUpdateRequired = true;
            } else {
              for (var i in pendingObjFacilities) {
                if (pendingObjFacilities[i] !== licenseObjFacilities[i]) {
                  zendeskUpdateRequired = true;
                  break;
                }
              }
            }
          }
        } else if (facilitiesAdded) {
          zendeskUpdateRequired = true;
        }

        if (zendeskUpdateRequired) {
          let ticketObj = {
            facilitiesAdded: licenseObjectForApi.facilitiesSubscriptionPending,
            companyName: company.name,
            userName: user.firstName + " " + user.lastName,
            userEmail: user.email,
            licenseName: licenseObjectForApi.name,
            callbacksRequested: callbacksRequested,
            additionalRequested: additionalRequested,
            licenseEnv: licenseEnv,
            organizationId: company.zendeskId,
          };
          let callSuccess = false;

          if (zendeskTicketId > 0) {
            ticketObj.zendeskTicketId = zendeskTicketId;
            await updateTicket(ticketObj)
              .then((response) => {
                if (response.ok) {
                  callSuccess = true;
                }
                return response.json();
              })
              .then((ticketId) => {
                if (callSuccess) {
                  zendeskTicketId = ticketId;
                }
              });
          } else {
            await createTicket(ticketObj)
              .then((response) => {
                if (response.ok) {
                  callSuccess = true;
                }
                return response.json();
              })
              .then((ticketId) => {
                if (callSuccess) {
                  zendeskTicketId = ticketId;
                }
              });
          }
        }
        pendingObj[licenseEnv][licenseObjectForApi.id] = {
          callbacks: callbacksRequested,
          additional: additionalRequested,
          consumerKey: licenseObjectForApi.consumerKey,
          facilities: licenseObjectForApi.facilitiesSubscriptionPending,
          licenseName: licenseObjectForApi.name,
          zendeskTicketId: zendeskTicketId > 0 ? zendeskTicketId : null,
        };
        if (pendingObj[licenseEnv][licenseObjectForApi.id].facilities === "") {
          if (pendingObj[licenseEnv][licenseObjectForApi.id].zendeskTicketId) {
            let resolveTicketObj = {
              closingMessage: ZENDESK_MESSAGE.noFacilities,
              userName: user.firstName + " " + user.lastName,
              zendeskTicketId: pendingObj[licenseEnv][licenseObjectForApi.id].zendeskTicketId,
              status: ZENDESK_STATUS.closed,
            };
            await resolveTicket(resolveTicketObj);
          }

          delete pendingObj[licenseEnv][licenseObjectForApi.id];
          if (Object.keys(pendingObj[licenseEnv]).length === 0) {
            delete pendingObj[licenseEnv];
            if (!pendingObj.dev && !pendingObj.prod) {
              pendingObj = null;
            }
          }
        }
        await updatePendingFacilitiesFunc(company.firebaseId, pendingObj);
      }

      // dispatch(replace(LICENSES_PATH));
      toast(data.name + TOAST_MESSAGE.edited, { className: "update-license-toast" });
    } catch (error) {
      if (error.response && error.response.data.split("\n")[0].split(": ")[1] === "111") {
        return error.response.data.split("\n")[1].split(":")[2].replace("[", "").replace("]", "");
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const moveLicense =
  ({ user, company, licenseData }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let data = { ...licenseData };
    let facilitiesAdded = data.facilitiesAdded;
    data.facilitiesSubscribedFor = facilitiesAdded || "";
    data.subEnabled = facilitiesAdded && facilitiesAdded.length > 0;

    try {
      let license = {
        ...prepareLicense(data),
      };

      await licenseApi.move(license);

      if (data.platform === PLATFORM_NAMES.web) {
        license.facilitiesSubscriptionPending = data.facilitiesSubscribedFor;
        license.facilitiesSubscribedFor = "";
        license.facilitiesSubscriptionRejected = "";
        license.facilitiesAdded = "";
        data.assetApi && data.assetApi !== "" ? (license.assetApi = true) : (license.assetApi = null);
        data.forcedLogIn && data.forcedLogIn !== "" ? (license.forcedLogIn = true) : (license.forcedLogIn = null);
      } else {
        delete license.facilitiesSubscribedFor;
        delete license.facilitiesSubscriptionPending;
        delete license.facilitiesSubscriptionRejected;
        delete license.facilitiesAdded;
      }

      let licenseObjectForFirebase = prepareLicenseFirebase(license);

      license = {
        ...license,
        prodDate: moment().valueOf(),
        lastUpdated: moment().valueOf(),
      };

      licenseObjectForFirebase = {
        ...licenseObjectForFirebase,
        prodDate: moment().valueOf(),
        lastUpdated: moment().valueOf(),
      };

      await updateFirebaseLicense(company.firebaseId, licenseObjectForFirebase, ENVIRONMENTS.prod);

      dispatch(updateLicense({ license: formatLicense(license, ENVIRONMENTS.prod), env: ENVIRONMENTS.prod }));

      let callbacksRequested =
        Object.keys(license)
          .filter((el) => el.includes("Callback"))
          .join(",") || "";
      let additionalRequestedArr = [];
      data.assetApi && additionalRequestedArr.push("assetApi");
      data.forcedLogIn && additionalRequestedArr.push("forcedLogIn");
      let additionalRequested = additionalRequestedArr.join(",");

      if (data.platform === PLATFORM_NAMES.web) {
        // Check if user exists in Zendesk
        if (!user.zendeskId) {
          const response = await createOrganizations(user.uid);
          let orgZendeskObj = await response.json();
          let userObject = {
            user: user,
            organizationId: orgZendeskObj.zendeskId,
          };
          const response2 = await createZendeskUser(userObject);
          const userZendeskId = await response2.json();
          if (response2.ok) {
            dispatch(updateReduxUser({ params: { zendeskId: userZendeskId } }));
          }
        }

        let zendeskTicketId = 0;

        let pendingObj = await getPendingFacilitiesFunc(company.firebaseId);
        pendingObj = {
          ...pendingObj,
          backendId: company.id,
          companyName: company.name,
          userEmail: user.email,
          userName: user.firstName + " " + user.lastName,
        };

        if (!pendingObj["prod"]) {
          pendingObj["prod"] = {};
        }

        if (facilitiesAdded && license.facilitiesSubscriptionPending !== "") {
          let ticketObj = {
            facilitiesAdded: facilitiesAdded,
            companyName: company.name,
            userName: user.firstName + " " + user.lastName,
            userEmail: user.email,
            licenseName: license.name,
            callbacksRequested: callbacksRequested,
            additionalRequested: additionalRequested,
            licenseEnv: ENVIRONMENTS.prod,
          };
          let callSuccess = false;
          await createTicket(ticketObj)
            .then((response) => {
              if (response.ok) {
                callSuccess = true;
              }
              return response.json();
            })
            .then((ticketId) => {
              if (callSuccess) {
                zendeskTicketId = ticketId;
              }
            });
        }

        pendingObj["prod"][license.id] = {
          callbacks: callbacksRequested,
          additional: additionalRequested,
          consumerKey: license.consumerKey,
          facilities: license.facilitiesSubscriptionPending,
          licenseName: license.name,
          zendeskTicketId: zendeskTicketId > 0 ? zendeskTicketId : null,
        };

        if (pendingObj["prod"][license.id].facilities === "") {
          delete pendingObj["prod"][license.id];
          if (Object.keys(pendingObj["prod"]).length === 0) {
            delete pendingObj["prod"];
            if (!pendingObj.dev && !pendingObj.prod) {
              pendingObj = null;
            }
          }
        }
        await updatePendingFacilitiesFunc(company.firebaseId, pendingObj);
      }

      // dispatch(replace(LICENSES_PATH));
      toast(data.name + TOAST_MESSAGE.moved);
    } catch (error) {
      if (error.response && error.response.data.split("\n")[0].split(": ")[1] === "111") {
        return error.response.data.split("\n")[1].split(":")[2].replace("[", "").replace("]", "");
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteLicense = (company, licenseId, licenseName, user, apigeeAppId) => async (dispatch) => {
  dispatch(setLoading(true));
  var status;

  try {
    await licenseApi.delete(licenseId, apigeeAppId);

    status = "OK";

    await removeFirebaseLicense(company.firebaseId, licenseId);

    let pendingObj = await getPendingFacilitiesFunc(company.firebaseId);

    if (pendingObj["dev"]) {
      if (pendingObj["dev"][licenseId]) {
        if (pendingObj["dev"][licenseId].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: ZENDESK_MESSAGE.deletingLicense,
            userName: user.firstName + " " + user.lastName,
            zendeskTicketId: pendingObj["dev"][licenseId].zendeskTicketId,
            status: ZENDESK_STATUS.closed,
          };
          await resolveTicket(resolveTicketObj);
        }

        delete pendingObj["dev"][licenseId];

        await updatePendingFacilitiesFunc(company.firebaseId, pendingObj);
      }
    }

    dispatch(removeLicense({ licenseId: licenseId }));
    toast(licenseName + TOAST_MESSAGE.deleted);
  } catch (error) {
    status = error.response.data.split("\n")[0].split(": ")[1];
    if (status === "305") {
      await removeFirebaseLicense(company.firebaseId, licenseId);
      let pendingObj = await getPendingFacilitiesFunc(company.firebaseId);

      if (pendingObj["dev"]) {
        if (pendingObj["dev"][licenseId]) {
          if (pendingObj["dev"][licenseId].zendeskTicketId) {
            let resolveTicketObj = {
              closingMessage: ZENDESK_MESSAGE.deletingLicense,
              userName: user.firstName + " " + user.lastName,
              zendeskTicketId: pendingObj["dev"][licenseId].zendeskTicketId,
              status: ZENDESK_STATUS.closed,
            };
            await resolveTicket(resolveTicketObj);
          }

          delete pendingObj["dev"][licenseId];

          await updatePendingFacilitiesFunc(company.firebaseId, pendingObj);
        }
      }
      dispatch(removeLicense({ licenseId: licenseId }));
      toast(licenseName + TOAST_MESSAGE.deleted);
    }
  } finally {
    dispatch(setLoading(false));
  }
  return status;
};

export const addFacilitiesAction = (facilities, licenseData, moveAction) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const licenseEnv = licenseData.env;
    let str = licenseData.facilitiesAdded || "";
    if (facilities === null) {
      licenseData.facilitiesSubscriptionPending = licenseData.facilitiesSubscriptionPending ? licenseData.facilitiesSubscriptionPending.split("-").join(",") : "";
    } else {
      let facilitiesSplit = facilities.split(",");
      facilitiesSplit.forEach((id) => {
        if ((!licenseData.facilitiesSubscribedFor || licenseData.facilitiesSubscribedFor.split(",").find((el) => el === parseInt(id, 10).toString()) === undefined || moveAction) && (!licenseData.facilitiesSubscriptionPending || licenseData.facilitiesSubscriptionPending.split(",").find((el) => el === parseInt(id, 10).toString()) === undefined || moveAction) && (!licenseData.facilitiesSubscriptionRejected || licenseData.facilitiesSubscriptionRejected.split(",").find((el) => el === parseInt(id, 10).toString()) === undefined || moveAction) && (!licenseData.facilitiesAdded || licenseData.facilitiesAdded.split(",").find((el) => el === parseInt(id, 10).toString()) === undefined) && (!str || str.split(",").find((el) => el === parseInt(id, 10).toString()) === undefined)) {
          if (str !== "") {
            str += ",";
          }
          str += parseInt(id, 10).toString();
        }
      });
      licenseData.facilitiesAdded = str;
    }

    let license = prepareLicense(licenseData);

    license.facilitiesAdded = licenseData.facilitiesAdded;
    license.facilitiesSubscribedFor = licenseData.facilitiesSubscribedFor;
    license.facilitiesSubscriptionPending = licenseData.facilitiesSubscriptionPending;
    license.facilitiesSubscriptionRejected = licenseData.facilitiesSubscriptionRejected;
    licenseData.assetApi && licenseData.assetApi !== "" ? (license.assetApi = true) : (license.assetApi = null);
    licenseData.forcedLogIn && licenseData.forcedLogIn !== "" ? (license.forcedLogIn = true) : (license.forcedLogIn = null);

    dispatch(updateLicense({ license: formatLicense(license, licenseEnv), env: licenseEnv }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const removeFacilitiesAction = (facilities, licenseData) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const licenseEnv = licenseData.env;
    let subscribedSplit = licenseData.facilitiesSubscribedFor ? licenseData.facilitiesSubscribedFor.split(",") : [];
    let pendingSplit = licenseData.facilitiesSubscriptionPending ? licenseData.facilitiesSubscriptionPending.split(",") : [];
    let rejectedSplit = licenseData.facilitiesSubscriptionRejected ? licenseData.facilitiesSubscriptionRejected.split(",") : [];
    let addedSplit = licenseData.facilitiesAdded ? licenseData.facilitiesAdded.split(",") : [];
    let removedSubscribedSplit = [];
    let removedPendingSplit = [];
    let removedRejectedSplit = [];

    if (facilities === null) {
      subscribedSplit.join(",").startsWith("-") ? (subscribedSplit = subscribedSplit.join(",").slice(1)) : (subscribedSplit = subscribedSplit.join(","));
      pendingSplit.join(",").startsWith("-") ? (pendingSplit = pendingSplit.join(",").slice(1)) : (pendingSplit = pendingSplit.join(","));
      rejectedSplit.join(",").startsWith("-") ? (rejectedSplit = rejectedSplit.join(",").slice(1)) : (rejectedSplit = rejectedSplit.join(","));

      subscribedSplit = subscribedSplit.split("-").join(",").split(",");
      pendingSplit = pendingSplit.split("-").join(",").split(",");
      rejectedSplit = rejectedSplit.split("-").join(",").split(",");
      addedSplit = [];
    } else {
      let facilitiesSplit = facilities.split(",");

      facilitiesSplit.forEach((id) => {
        if (addedSplit.find((el) => el === parseInt(id, 10).toString()) !== undefined) {
          addedSplit.splice(addedSplit.indexOf(parseInt(id, 10).toString()), 1);
        } else if (subscribedSplit.find((el) => el.split("-")[0] === parseInt(id, 10).toString()) !== undefined) {
          removedSubscribedSplit.push(subscribedSplit.splice(subscribedSplit.indexOf(parseInt(id, 10).toString()), 1));
        } else if (pendingSplit.find((el) => el.split("-")[0] === parseInt(id, 10).toString()) !== undefined) {
          removedPendingSplit.push(pendingSplit.splice(pendingSplit.indexOf(parseInt(id, 10).toString()), 1));
        } else if (rejectedSplit.find((el) => el.split("-")[0] === parseInt(id, 10).toString()) !== undefined) {
          removedRejectedSplit.push(rejectedSplit.splice(rejectedSplit.indexOf(parseInt(id, 10).toString()), 1));
        }
      });
    }

    licenseData.facilitiesSubscribedFor = subscribedSplit.join(",") + (removedSubscribedSplit.length > 0 ? "-" + removedSubscribedSplit.join("-") : "");
    licenseData.facilitiesSubscriptionPending = pendingSplit.join(",") + (removedPendingSplit.length > 0 ? "-" + removedPendingSplit.join("-") : "");
    licenseData.facilitiesSubscriptionRejected = rejectedSplit.join(",") + (removedRejectedSplit.length > 0 ? "-" + removedRejectedSplit.join("-") : "");
    licenseData.facilitiesAdded = addedSplit.join(",");

    licenseData.subEnabled = (licenseData.facilitiesSubscribedFor && licenseData.facilitiesSubscribedFor.length > 0) || (licenseData.facilitiesSubscriptionPending && licenseData.facilitiesSubscriptionPending.length > 0) || (licenseData.facilitiesSubscriptionRejected && licenseData.facilitiesSubscriptionRejected.length > 0) || (licenseData.facilitiesAdded && licenseData.facilitiesAdded.length > 0) || false;

    let license = prepareLicense(licenseData);

    license.facilitiesAdded = licenseData.facilitiesAdded;
    license.facilitiesSubscribedFor = licenseData.facilitiesSubscribedFor;
    license.facilitiesSubscriptionPending = licenseData.facilitiesSubscriptionPending;
    license.facilitiesSubscriptionRejected = licenseData.facilitiesSubscriptionRejected;
    licenseData.assetApi && licenseData.assetApi !== "" ? (license.assetApi = true) : (license.assetApi = null);
    licenseData.forcedLogIn && licenseData.forcedLogIn !== "" ? (license.forcedLogIn = true) : (license.forcedLogIn = null);

    dispatch(updateLicense({ license: formatLicense(license, licenseEnv), env: licenseEnv }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getPendingFacilitiesAdmin = () => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const companies = await Promise.all([getPendingFacilitiesAdminFunc()]);
    return companies[0];
  } catch (error) {
    //TODO handle get pending company errors
  } finally {
    dispatch(setLoading(false));
  }
};

export const updatePendingFacilities = (companyId, licenseId, env, company, facilities, action, consumerKey, additional) => async (dispatch) => {
  dispatch(setLoading(true));
  let status = "OK";
  let temp = JSON.parse(JSON.stringify(company));

  try {
    let licenses = await getFirebaseLicenses(companyId);
    let licenseObj = licenses ? licenses[licenseId] : null;
    let license = licenseObj ? licenseObj[env.toLowerCase()] : null;
    let additionalSplit = additional ? additional.split(",") : [];
    if (license) {
      let filteredFacilities = facilities.split(",").filter((facility) => license.facilitiesSubscriptionPending.split(",").includes(facility));
      license.facilitiesSubscriptionPending = license.facilitiesSubscriptionPending
        .split(",")
        .filter((facility) => !filteredFacilities.includes(facility))
        .join(",");
      if (filteredFacilities && filteredFacilities.length > 0) {
        if (action === "approve") {
          license.facilitiesSubscribedFor = license.facilitiesSubscribedFor && license.facilitiesSubscribedFor.length > 0 ? license.facilitiesSubscribedFor + "," + filteredFacilities.join(",") : filteredFacilities.join(",");
        } else {
          license.facilitiesSubscriptionRejected = license.facilitiesSubscriptionRejected && license.facilitiesSubscriptionRejected.length > 0 ? license.facilitiesSubscriptionRejected + "," + filteredFacilities.join(",") : filteredFacilities.join(",");
        }
      }

      if (action === "approve") {
        let assetApiRequested = false;
        if (additionalSplit.find((el) => el === "assetApi")) {
          assetApiRequested = true;
        }
        await licenseApi.approve(licenseId, facilities, env, consumerKey, assetApiRequested);
      } else {
        await licenseApi.deny(licenseId, facilities, env, consumerKey, false);
      }

      if (additionalSplit.find((el) => el === "assetApi")) {
        let apigeeUpdateObj = {};
        apigeeUpdateObj.apigeeAppId = license.apigeeAppId;
        apigeeUpdateObj.environment = env;
        if (license.facilitiesSubscribedFor && license.facilitiesSubscribedFor.length > 0) {
          apigeeUpdateObj.facilities = license.facilitiesSubscribedFor;
        }
        await updateApigeeCustomAttribute({ apigeeUpdateObj });
      }

      if (additionalSplit.find((el) => el === "forcedLogIn")) {
        if (action === "approve") {
          let facilitiesObj = {
            facilities: facilities
              .replace(/(^,)|(,$)/g, "")
              .split(",")
              .map((fId) => parseInt(fId, 10)),
          };
          await facilityConfigurationApi.enableForcedLogin(facilitiesObj, env.toLowerCase());
        } else {
          let facilitiesObj = {
            facilities: facilities
              .replace(/(^,)|(,$)/g, "")
              .split(",")
              .map((fId) => parseInt(fId, 10)),
          };
          await facilityConfigurationApi.disableForcedLogin(facilitiesObj, env.toLowerCase());
        }
      }

      license = {
        ...license,
        ..._.pick(license, ["license", "consumerKey", "consumerSecret"]),
        lastUpdated: moment().valueOf(),
      };

      await updateFirebaseLicense(companyId, license, env.toLowerCase());

      if (temp[env.toLowerCase()][licenseId].facilities === "") {
        if (temp[env.toLowerCase()][licenseId].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: "Facility Id(s) " + facilities + (action === "approve" ? ZENDESK_MESSAGE.approvedClose : ZENDESK_MESSAGE.deniedClose),
            userName: "Life Fitness",
            zendeskTicketId: temp[env.toLowerCase()][licenseId].zendeskTicketId,
            status: ZENDESK_STATUS.closed,
          };
          await resolveTicket(resolveTicketObj);
        }

        delete temp[env.toLowerCase()][licenseId];
        if (Object.keys(temp[env.toLowerCase()]).length === 0) {
          temp[env.toLowerCase()] = {};
          if ((!temp.dev || Object.keys(temp.dev).length === 0) && (!temp.prod || Object.keys(temp.prod).length === 0)) {
            temp = null;
          }
        }
      } else {
        if (temp[env.toLowerCase()][licenseId].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: "Facility Id(s) " + facilities + (action === "approve" ? " approved" : " denied"),
            userName: "Life Fitness",
            zendeskTicketId: temp[env.toLowerCase()][licenseId].zendeskTicketId,
            status: ZENDESK_STATUS.open,
          };
          await resolveTicket(resolveTicketObj);
        }
      }

      await updatePendingFacilitiesFunc(companyId, temp);
    } else {
      if (temp[env.toLowerCase()][licenseId].facilities === "") {
        if (temp[env.toLowerCase()][licenseId].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: "Facility Id(s) " + facilities + (action === "approve" ? ZENDESK_MESSAGE.approvedClose : ZENDESK_MESSAGE.deniedClose),
            userName: "Life Fitness",
            zendeskTicketId: temp[env.toLowerCase()][licenseId].zendeskTicketId,
            status: ZENDESK_STATUS.closed,
          };
          await resolveTicket(resolveTicketObj);
        }

        delete temp[env.toLowerCase()][licenseId];
        if (Object.keys(temp[env.toLowerCase()]).length === 0) {
          temp[env.toLowerCase()] = {};
          if ((!temp.dev || Object.keys(temp.dev).length === 0) && (!temp.prod || Object.keys(temp.prod).length === 0)) {
            temp = null;
          }
        }
      } else {
        if (temp[env.toLowerCase()][licenseId].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: "Facility Id(s) " + facilities + (action === "approve" ? " approved" : " denied"),
            userName: "Life Fitness",
            zendeskTicketId: temp[env.toLowerCase()][licenseId].zendeskTicketId,
            status: ZENDESK_STATUS.open,
          };
          await resolveTicket(resolveTicketObj);
        }
      }

      await updatePendingFacilitiesFunc(companyId, temp);
    }
  } catch (error) {
    return error.response.data.split("\n")[1].split(":")[1].trim();
  } finally {
    dispatch(setLoading(false));
  }
  return status;
};
