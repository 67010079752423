export const PRESET_DATA_SOURCE = `- (NSArray*)didRequestPresetListFor:(enum LFEquipmentId)equipmentId;`

const EQUIPMENT_WORKOUT_BUILDER = `- (void)generatePresetWorkout {
    LFEquipmentWorkoutBuilder *equipmentWorkoutBuilder =
      [LFEquipmentWorkoutBuilder getSharedInstance];
    
    if (nil != self.connectingEquipment) {
        BOOL hasSendingPresetFeature = [
            equipmentWorkoutBuilder equipmentHasFeature:self.connectingEquipment
            feature:LFUploadCustomWorkoutPreset
        ];
        if (YES == hasSendingPresetFeature) {
            LFCardioProgram *program = [
                equipmentWorkoutBuilder getCardioProgram:P_MANUAL
                forEquipment:self.connectingEquipment
            ];
            LFPresetGoal *goal = [
                equipmentWorkoutBuilder getPresetGoal:TIME forCardioProgram:program
            ];
            for (LFPresetWorkoutParam *param in goal.workoutParameters) {
                if (LFWorkoutParamsTime == param.type)
                    param.value = 600;
                if (LFWorkoutParamsLevel == param.type)
                    param.value = 5;
            }
            LFWorkoutPreset *workoutPreset = [
                equipmentWorkoutBuilder getWorkOutFor:goal withUser:self.user
            ];
        }
    }
}`

const EQUIPMENT_INFO = `- (void)didReceivedEquipmentInfo:(LFEquipment*)equipment;`

const EQUIPMENT_ID = `typedef enum LFEquipmentId {
    LFEQUIPMENT_ID_TREADMILL                            =                    1,
    LFEQUIPMENT_ID_UPRIGHT_BIKE                         =                    2,
    LFEQUIPMENT_ID_RECUMBENT_BIKE                       =                    3,
    LFEQUIPMENT_ID_CROSS_TRAINER                        =                    4,
    LFEQUIPMENT_ID_F1_SMART_TREADMILL                   =                    5,
    LFEQUIPMENT_ID_CONSUMER_LIFE_CYCLE                  =                    6,
    LFEQUIPMENT_ID_CONSUMER_CROSS_TRAINER               =                    7,
    LFEQUIPMENT_ID_T3_TREADMILL                         =                    8,
    LFEQUIPMENT_ID_F3_TREADMILL                         =                    9,
    LFEQUIPMENT_ID_SMART_LIFE_CYCLE                     =                   10,
    LFEQUIPMENT_ID_SMART_CROSS_TRAINER                  =                   11,
    LFEQUIPMENT_ID_SUMMIT_TRAINER                       =                   12,
    LFEQUIPMENT_ID_STAIR_CLIMBS                         =                   13,
    LFEQUIPMENT_ID_T5_TREADMILL                         =                   14,
    LFEQUIPMENT_ID_FLEX_STRIDER                         =                   15,
    LFEQUIPMENT_ID_POWER_MILL                           =                   16,
    LFEQUIPMENT_ID_ROWER                                =                   17,
    LFEQUIPMENT_ID_ARC_TRAINER                          =                   18,
} LFEquipmentId;`

const EQUIPMENT_TYPE = `typedef enum LFEquipmentType {
    LFEquipmentType_Unknown                             =                   -1,
    LFEquipmentType_Treadmill_Classic_Refresh           =                   41,
    LFEquipmentType_Bike_Classic_Refresh                =                   42,
    LFEquipmentType_Crosstrainer_Classic_Refresh        =                   43,
    LFEquipmentType_Step_Classic_Refresh                =                   44,
    LFEquipmentType_Steper                              =                    3,
    LFEquipmentType_Summittrainer_Classic_Refresh       =                   45,
    LFEquipmentType_LED_Summit_Trainer                  =                   10,
    LFEquipmentType_Integrated_LCD_Stepper              =                   13,
    LFEquipmentType_Integrated_LCD_Summit_Trainer       =                   20,
    LFEquipmentType_Treadmill_Led                       =                    4,
    LFEquipmentType_Treadmill_Lcd                       =                   14,
    LFEquipmentType_Treadmill_Lcd_Con                   =                   18,
    LFEquipmentType_Treadmill_Acheive                   =                   21,
    LFEquipmentType_Treadmill_Inspire                   =                   22,
    LFEquipmentType_Treadmill_Engage                    =                   23,
    LFEquipmentType_Treadmill_1_1                       =                    1,
    LFEquipmentType_Treadmill_Discover_Engage           =                   49,
    LFEquipmentType_Treadmill_Discover_Inspire          =                   53,
    LFEquipmentType_Treadmill_Explore                   =                   64,
    LFEquipmentType_Treadmill_Aquaman                   =                   68,
    LFEquipmentType_Treadmill_Archive                   =                   64,
    LFEquipmentType_Treadmill_NDT_Achieve_Console       =                   36,
    LFEquipmentType_Treadmill_Activate                  =                   46,
    LFEquipmentType_Treadmill_Hercules_Simple           =                   79,
    LFEquipmentType_Treadmill_Hercules_Feature          =                   80,
    LFEquipmentType_Treadmill_Superman                  =                   94,
    LFEquipmentType_Crosstrainer_Fd_Led                 =                    5,
    LFEquipmentType_Crosstrainer_Rd_Led                 =                    6,
    LFEquipmentType_Crosstrainer_Lcd                    =                   16,
    LFEquipmentType_Crosstrainer_Lcd_Adj                =                   17,
    LFEquipmentType_Crosstrainer_Acheive                =                   24,
    LFEquipmentType_Crosstrainer_Inspire                =                   25,
    LFEquipmentType_Crosstrainer_Engage                 =                   26,
    LFEquipmentType_Crosstrainer_1_1                    =                    2,
    LFEquipmentType_Crosstrainer_Discover_Engage        =                   50,
    LFEquipmentType_Crosstrainer_Discover_Inspire       =                   54,
    LFEquipmentType_Crosstrainer_Archive                =                   65,
    LFEquipmentType_Crosstrainer_Aquaman                =                   69,
    LFEquipmentType_Crosstrainer_NDT_Achieve_Console    =                   37,
    LFEquipmentType_CrossTrainer_ActivateCross          =                   48,
    LFEquipmentType_CrossTrainer_Rear_Fixed_Stride      =                   74,
    LFEquipmentType_CrossTrainer_Rear_Adjustable_Stride =                   75,
    LFEquipmentType_CrossTrainer_Rear_Side_Entry        =                   76,
    LFEquipmentType_CrossTrainer_Superman               =                   89,
    LFEquipmentType_UprightBike_Led                     =                   11,
    LFEquipmentType_UprightBike_Acheive                 =                   27,
    LFEquipmentType_UprightBike_Inspire                 =                   28,
    LFEquipmentType_UprightBike_Engage                  =                   29,
    LFEquipmentType_UprightBike_Discover_Engage         =                   51,
    LFEquipmentType_UprightBike_Discover_Inspire        =                   55,
    LFEquipmentType_Bike_1_1                            =                    0,
    LFEquipmentType_UprightBike_Archive                 =                   66,
    LFEquipmentType_UprightBike_Aquaman                 =                   70,
    LFEquipmentType_UprightBike_NDT_Achieve_Console     =                   38,
    LEEquipmentType_Bike_Activate                       =                   47,
    LFEquipmentType_Upright_Bike_Hercules               =                   77,
    LFEquipmentType_Upright_Bike_Superman               =                   90,
    LFEquipmentType_RecumbentBike_Led                   =                   12,
    LFEquipmentType_RecumbentBike_Acheive               =                   30,
    LFEquipmentType_RecumbentBike_Inspire               =                   31,
    LFEquipmentType_RecumbentBike_Engage                =                   32,
    LFEquipmentType_RecumbentBike_Discover_Engage       =                   52,
    LFEquipmentType_RecumbentBike_Discover_Inspire      =                   56,
    LFEquipmentType_RecumbentBike_Discover_Archive      =                   67,
    LFEquipmentType_RecumbentBike_Aquaman               =                   71,
    LFEquipmentType_RecumbentBike_NDT_Achieve_Console   =                   39,
    LFEquipmentType_RecumbentBike_Hercules              =                   78,
    LFEquipmentType_RecumbentBike_Superman              =                   91,
    LFEquipmentType_Consumer_Treadmill                  =                   19,
    LFEquipmentType_Consumer_Crosstrainer               =                  260,
    LFEquipmentType_Consumer_Lifecycle                  =                  261,
    LFEquipmentType_Consumer_Treadmill_T3               =                  262,
    LFEquipmentType_Consumer_Smart_Crosstrainer         =                  263,
    LFEquipmentType_Consumer_Smart_Lifecycle            =                  264,
    LFEquipmentType_Consumer_Treadmill_F3               =                  265,
    LFEquipmentType_Consumer_Treadmill_T5               =                  266,
    LFEquipmentType_Consumer_Treadmill_TrackConnect     =                  269,
    LFEquipmentType_Consumer_UprightBike_TrackConnect   =                  270,
    LFEquipmentType_Consumer_CrossTrainer_TrackConnect  =                  271,
    LFEquipmentType_Consumer_SummitTrainer_TrackConnect =                  272,
    LFEquipmentType_Consumer_Rower_TrackConnect         =                  273,
    LFEquipmentType_Consumer_Recumbent_TrackConnect     =                  267,
    LFEquipmentType_Consumer_Smart_Recumbent            =                  268,
    LFEquipmentType_Discover_FlexStrider_Engage         =                   57,
    LFEquipmentType_Discover_FlexStrider_Inspire        =                   58,
    LFEquipmentType_Discover_Achieve_FlexStrider        =                   59,
    LFEquipmentType_Aquaman_FlexStrider                 =                   72,
    LFEquipmentType_FlexStrider_Hercules                =                   81,
    LFEquipmentType_FlexStrider_Superman                =                   92,
    LFEquipmentType_Discover_PowerMill_Engage           =                   60,
    LFEquipmentType_Discover_PowerMill_Inspire          =                   61,
    LFEquipmentType_Integrity_PowerMill                 =                   62,
    LFEquipmentType_Achieve_PowerMill                   =                   63,
    LFEquipmentType_Aquaman_PowerMill                   =                   73,
    LFEquipmentType_PowerMill_Hercules                  =                   82,
    LFEquipmentType_PowerMill_Superman                  =                   95,
    LFEquipmentType_Falabella                           =                   83,
    LFEquipmentType_ArcTrainer_Superman                 =                   93,
} LFEquipmentType;`

export const WORKOUT_PRESET_CODES = {
    workoutBuilder: EQUIPMENT_WORKOUT_BUILDER,
    equipmentInfo: EQUIPMENT_INFO,
    equipmentId: EQUIPMENT_ID,
    equipmentType: EQUIPMENT_TYPE,
    presetDataSource: PRESET_DATA_SOURCE,
}
