import { useEffect, useState } from "react";
import CheckboxComponent from "../../CheckboxComponent/CheckboxComponent";
import { getCompanyId } from "../../../../storage/localStorage";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { selectSessionCompanyAction } from "../../../../redux/slice/sessionSlice";
// import { functions } from 'firebase';

const SelectComponent = (props) => {
  const dispatch = useDispatch();
  const [selectState, setSelectState] = useState({});

  useEffect(() => {
    const { multiWithCheckbox, options, id } = props.formProps;
    let { onCompanySelect, getMembers, getLicenses, versionMapping } = props.formProps;
    const { input } = props;

    if (multiWithCheckbox) {
      options.forEach((option) => {
        //retain the values for the Sign up and Edit company form
        if (input.value !== "") {
          let inputValueArray = typeof input.value === "string" ? input.value.split(",") : input.value;
          //convert the label to comma space separated values
          const inputPlatformLabel = typeof input.value === "string" ? input.value.split(",").join(", ") : input.value.join(", ");
          const inputPlatformValue = typeof input.value === "string" ? input.value : input.value.join(",");

          setSelectState({
            ...selectState,
            option: {
              label: inputPlatformLabel,
              value: inputPlatformValue,
            },
          });

          //check the values which were initally selected
          inputValueArray.forEach((option) => {
            setSelectState((prevState) => ({
              ...prevState,
              [option]: true,
            }));
            // this.setState(
            //   {
            //     [option]: true,
            //   },
            //   () => this.checkboxValueWrapper
            // );
          });
        } else {
          setSelectState({
            ...selectState,
            [option.value]: false,
          });
          // this.setState({
          //   [option.value]: false,
          // });
        }
      });
    }

    //set default company value only if the user is on Licenses page (company dropdown)
    // if (id === "listofCompanies") {
    //   let setDefaultValue = options[0];
    //   let companyId = getCompanyId();

    //   if (companyId) {
    //     setDefaultValue = options.filter((company) => {
    //       return company.value === companyId;
    //     })[0];
    //   }

    //   if (options.length < 1) {
    //     setDefaultValue = { value: "" };
    //     this.setState({
    //       noneSelected: true,
    //     });
    //   }

    //   this.setState(
    //     {
    //       option: setDefaultValue,
    //       [setDefaultValue.value]: setDefaultValue.value,
    //     },
    //     function () {
    //       input.onChange(setDefaultValue.value);

    //call the getLicenses and getMembers depending on the initial value of a Company
    //       onCompanySelect(setDefaultValue.value).then(function () {
    //         if (setDefaultValue.value.length > 0) {
    //           getMembers(setDefaultValue.value);
    //           getLicenses(setDefaultValue.value);
    //         }
    //       });
    //     }
    //   );
    // } else if (id === "sdkVersion") {
    if (id === "sdkVersion") {
      let defaultVersion = options[0];
      versionMapping.forEach((versionObj) => {
        if (versionObj.isSelected) {
          for (var index in options) {
            if (options[index] === versionObj.versionName) {
              defaultVersion = options[index];
            }
          }
        }
      });

      setSelectState((prevState) => {
        input.onChange({
          label: defaultVersion,
          value: defaultVersion,
        });
        return {
          ...prevState,
          option: {
            label: defaultVersion,
            value: defaultVersion,
          },
          [defaultVersion]: defaultVersion,
        };
      });
    }
  }, []);

  // componentDidUpdate() {
  //     const { options, id } = props.formProps;
  //     let { onCompanySelect, getMembers, getLicenses } = props.formProps;
  //     const { input } = props;
  //     const { noneSelected } = this.state;

  //     //set default company value only if the user is on Licenses page (company dropdown)
  //     if (id === 'listofCompanies' && noneSelected && options && options.length > 0) {
  //         this.handleInitialSelect(options, input, onCompanySelect, getMembers, getLicenses);
  //     }
  // }

  const handleInitialSelect = (options, input, onCompanySelect, getMembers, getLicenses) => {
    let setDefaultValue = options[0];
    let companyId = getCompanyId();

    if (companyId) {
      setDefaultValue = options.filter((company) => {
        return company.value === companyId;
      })[0];
    }

    setSelectState(
      {
        ...selectState,
        option: setDefaultValue,
        [setDefaultValue.value]: setDefaultValue.value,
        noneSelected: false,
      }
      // function () {
      //   input.onChange(setDefaultValue.value);

      //   //call the getLicenses and getMembers depending on the initial value of a Company
      //   onCompanySelect(setDefaultValue.value).then(function () {
      //     if (setDefaultValue.value.length > 0) {
      //       getMembers(setDefaultValue.value);
      //       getLicenses(setDefaultValue.value);
      //     }
      //   });
      // }
    );
  };

  const checkboxValueWrapper = (option) => {
    const title = option.label;

    return <CheckboxComponent name={title + "Checkbox"} onChange={new Function()} checked={selectState[title]} placeholder={title} />;
  };

  const { label, className } = props;
  let formProps = { ...props.formProps };
  const input = {
    ...props.input,
    onChange: (e) => {
      props.input.onChange(e);
      if (props.formProps.onChange) props.formProps.onChange(e);
    },
  };
  delete formProps.onChange;
  let { onCompanySelect, getMembers, isLoading, getLicenses, api, versionMapping, onVersionSelect } = props.formProps;

  formProps.options = formProps.options?.map((value) => (typeof value === "string" ? { label: value, value } : value));

  if (formProps.multiWithCheckbox) {
    formProps = Object.assign(formProps, {
      optionClassName: "checkbox-option",
      //optionRenderer: checkboxValueWrapper,
      closeOnSelect: false,
      value: selectState.option,
      onChange: (value) => {
        let { option } = selectState;
        const values = option.label ? option.label.split(", ") : [];

        if (selectState[value]) {
          values.splice(values.indexOf(value), 1);
        } else {
          values.push(value);
        }

        const inputLabel = values.join(", ");
        const inputValue = values.join(",");

        setSelectState({
          ...selectState,
          option: {
            label: inputLabel,
            value: inputValue,
          },
        });
        // this.setState({
        //   option: {
        //     label: inputLabel,
        //     value: inputValue,
        //   },
        //   [value]: !this.state[value],
        // });
        // input.onChange(inputValue);
      },
    });
  } else if (formProps.id === "sdkVersion") {
    formProps = Object.assign(formProps, {
      onChange: (versionName) => {
        formProps.options.forEach((version) => {
          if (version["value"] === versionName["value"]) {
            setSelectState((prevState) => {
              if (!isLoading) {
                versionMapping.forEach((versionObj) => {
                  if (versionObj.versionName === versionName["value"]) {
                    onVersionSelect(api, versionObj["version"].replace(/\./g, "-"));
                  }
                });
              }
              input.onChange(versionName["value"]);
              return {
                ...prevState,
                option: {
                  label: version["label"],
                  value: version["value"],
                },
                [version["value"]]: version["value"],
              };
            });
            // this.setState(
            //   {
            //     option: {
            //       label: version["label"],
            //       value: version["value"],
            //     },
            //     [version["value"]]: version["value"],
            //   },
            //   () => {
            //     if (!isLoading) {
            //       versionMapping.forEach((versionObj) => {
            //         if (versionObj.versionName === versionName) {
            //           onVersionSelect(api, versionObj["version"].replace(/\./g, "-"));
            //         }
            //       });
            //     }
            //     input.onChange(versionName);
            //   }
            // );
          }
        });
      },
    });
  } else if (formProps.id === "listofCompanies") {
    formProps = Object.assign(formProps, {
      onChange: (firebaseId) => {
        console.log("***** firebaseId: ", firebaseId);
        dispatch(selectSessionCompanyAction({ companyId: firebaseId }));
        // formProps.options.forEach((result) => {
        // if (result["value"] === firebaseId) {
        //   setSelectState({
        //     ...selectState,
        //     option: {
        //       label: result["label"],
        //       value: result["value"],
        //     },
        //     [result["value"]]: result["value"],
        //   });
        // this.setState(
        //   {
        //     option: {
        //       label: result["label"],
        //       value: result["value"],
        //     },
        //     [result["value"]]: result["value"],
        //   },
        //   () => {
        //     if (!isLoading) {
        //       onCompanySelect(firebaseId).then(function () {
        //         getMembers(firebaseId);
        //         getLicenses(firebaseId);
        //       });
        //     }
        //     input.onChange(firebaseId);
        //   }
        // );
        // }
        // });
      },
    });
  }

  return <Select {...input} {...formProps} className={className} label={label} onBlur={() => input.onBlur(input.value)} arrowRenderer={({ isOpen }) => <span className={"glyphicon " + (isOpen ? "open" : "closed")} />} />;
};

// SelectComponent.defaultProps = {
//     input: {},
//     formProps: {},
//     className: '',
// };

// SelectComponent.propTypes = {
//     label: PropTypes.string,
//     input: PropTypes.object.isRequired,
//     formProps: PropTypes.object.isRequired,
//     className: PropTypes.string.isRequired,
//     versionMapping: PropTypes.array,
// };

export default SelectComponent;
