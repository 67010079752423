import DataTypesComponent from "../components/developerApi/developerSdk/DataTypes/DataTypesComponent";

const DataTypesContainer = () => {
  return <DataTypesComponent />;
};

export default DataTypesContainer;
// const mapStateToProps = ({ session: { user }, dataTypes: { isLoading, items } }) => ({
//     isLoading,
//     namespaces: items,
//     user,
// });

// const mapDispatchToProps = {
//     getDataTypes,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(DataTypesComponent);
