import CompatibilityComponent from "../components/developerApi/developerSdk/Compatibility/CompatibilityComponent";

const CompatibilityContainer = () => {
  return <CompatibilityComponent />;
};

export default CompatibilityContainer;
// const mapStateToProps = ({ compatibility: { isLoading, apis, commonApis, consoleSpecificApis } }) => ({
//     isLoading,
//     apis,
//     commonApis,
//     consoleSpecificApis,
// })

// const mapDispatchToProps = {
//     getCompatibilityMapping,
// }

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompatibilityComponent));
