const text = `## MMS Integration Implementation

### PRE-REQUISITES
1. Create a Developer Connect account (developer account).
2. Create a license (API keys) and setup callback URLs for required events.

&nbsp;

### USER CREATION
1. Create new user account (excerciser) in Developer Connect database using PartnerAPI -

    ~~~
    POST https://api.halo.fitness/partnerapi/api/account/create_update_userprofile
    {
        "emailAddress": "john@example.com",
        "xid": "john_unique_xid",
        "dob": "1985-10-10",
        "weight": 65,
        "weightUnit": "M",
        "height": 165,
        "heightUnit": "M",
        "firstName": "John",
        "lastName": "Doe",
        "gender": "m",
        "password": "Password@2020",
        "preferredLanguageCode": "en",
        "preferredUnit": "M",
        "rfId": "12AB34CD",
        "facilityId": 20
    }
    ~~~

    **Note** - To automatically send welcome emails to the users created in Developer Connect Premium facility, please contact our support team.

&nbsp;

2. Generate an access token for the newly created user using PartnerAPI

    Using xid -
    ~~~
    GET https://api.halo.fitness/partnerapi/api/account/user_tokens?xid=john_unique_xid
    ~~~
    
    Using userid -
    ~~~
    GET https://api.halo.fitness/partnerapi/api/account/user_tokens?userId=2112122
    ~~~

&nbsp;

### WORKOUT SYNC
1. User login to equipment

    Login using PartnerAPI.
    ~~~
    POST https://api.halo.fitness/partnerapi/api/account/sso_login
    {
        "bodySerial": "HHT-902902",
        "userId": 2112122
    }
    ~~~

    Other login methods - 
    * RFID login 
    * NFC login
    * QR code scan login
    * Manual login using email and password on console
    * Login using Developer Connect app or LFconnect app
    
&nbsp;

2. User completes a workout and logs out of the equipment.

&nbsp;

3. Lifefitness event callback sent to the registered callback URL when new completed workout is saved.
    ~~~
    POST https://www.example.com/notification?subType=Lifefitness&timestamp=2020-07-13T22%3A09%3A51%2B08%3A00&access_token=1eowepw216dfdbe8df5a15cfa32cbn%7C5cd655cbc4a&device=HHT-902902&facilityId=20&activityId=1&guid=7xckwe732hjdseb4ecdbd6dsdsca5a
    ~~~

&nbsp;

4. Retrieve workout details using workout-guid retrieved from callback notification.
    ~~~
    GET https://api.halo.fitness/partnerapi/api2/workout_result/lifefitness/7xckwe732hjdseb4ecdbd6dsdsca5a
    ~~~

&nbsp;

### PROFILE SYNC

1. User profile changes trigger Account event callback to the registered callback URL.
    ~~~
    POST https://www.example.com/notification?subType=Account&access_token=1eowepw216dfdbe8df5a15cfa32cbn%7C5cd655cbc4a
    ~~~

&nbsp;

2. Retrieve user profile using PartnerAPI using the user specific access token.
    ~~~
    GET https://api.halo.fitness/partnerapi/api2/user
    ~~~

&nbsp;

### RFID SYNC

1. RFID updates to the user profile trigger RFID event callback to the registered callback URL.
    ~~~
    POST https://www.example.com/notification?subType=RFID&timestamp=2020-07-13T15%3A32%3A41.826&access_token=1eowepw216dfdbe8df5a15cfa32cbn%7C5cd655cbc4a&userId=2112122
    ~~~

&nbsp;

2. Retrieve user’s latest RFIDs using the user specific access token.
    ~~~
    GET https://api.halo.fitness/partnerapi/api2/user/rfid
    ~~~

&nbsp;

### DELETE USER ACCOUNT

1. Delete user account from Developer Connect database using PartnerAPI. This would delete the account permanently from our records.
    ~~~
    DELETE https://api.halo.fitness/partnerapi/api2/user
    ~~~

&nbsp;

For full details of our PartnerAPIs, see the API documentation in the [developer reference](https://apiconsole.halo.fitness/apis/partner-api-prod/index).
`;

export default text;
