import { createAction } from "redux-actions";
// import { replace } from "react-router-redux";
// import { toast } from "react-toastify";
// import { SubmissionError } from "redux-form";

//import { COMPANIES_PATH } from "../../constants/routes";
// import { PLATFORMS } from "../../constants/common";
//import { SET_COMPANIES, SET_CURRENT_COMPANY, UPDATE_CURRENT_COMPANY, UNSET_CURRENT_COMPANY, IS_LOADING } from "../reducers/companies";
//import { SET_CURRENT_COMPANY, UPDATE_CURRENT_COMPANY } from "../reducers/companies";
//import { UPDATE_USER } from "../reducers/session";
import { getCompany /* createCompany, changeCompany, getPendingFacilities, updatePendingFacilities */ } from "../../firebase/company";
import { removeCompanyId, setCompanyId } from "../../storage/localStorage";
//import companyApi from "../../api/companyApi";
import { COMPANIES } from "../Constants";

//const setCompanies = createAction(SET_COMPANIES);
// export const setCompany = createAction(SET_CURRENT_COMPANY);
// export const updateCompany = createAction(UPDATE_CURRENT_COMPANY);
// const unsetCompany = createAction(UNSET_CURRENT_COMPANY);
// const setLoading = createAction(IS_LOADING);
// const updateReduxUser = createAction(UPDATE_USER);

// const prepareCompany = ({ id, name, platform, phone, size, companySize, industry, vertical, country, address, city, state, postalCode, otherIndustry, otherVertical }) => ({
//   ...(id && { id }),
//   name,
//   enrollmentType: platform.map((type) => PLATFORMS[type.trim()]).join(","),
//   phoneNo: phone,
//   companySize: size || companySize,
//   industry,
//   vertical: industry === "Fitness" ? vertical : null,
//   country,
//   address,
//   city,
//   state,
//   postalCode,
//   otherIndustry,
//   otherVertical,
// });

export const getCompanies = async (companyIds) => {
  let companies = null;
  try {
    companies = await Promise.all(companyIds.length > 0 && companyIds.split(",").map((companyId) => getCompany(companyId)));
  } catch (error) {
    throw error;
  } finally {
    return companies;
  }
};

export const selectCompany = async (companyId) => {
  try {
    await setCompanyId(companyId);
  } catch (error) {
    throw error;
  } finally {
    return companyId;
  }
};

export const removeCurrentCompany = async () => {
  try {
    await removeCompanyId();
  } catch (error) {
    throw error;
  }
};

// export const addCompany = (company) => async (dispatch) => {
//   dispatch(setLoading(true));

//   try {
//     const response = await createCompany(company);
//     const result = await response.json();

//     if (response.ok) {
//       dispatch(updateReduxUser({ params: { companies: result.companies } }));
//       dispatch(getCompanies(Object.keys(result.companies).join(",")));
//       toast(COMPANIES.RESPONSE.MESSAGE.SUCCESSFUL_CREATE_COMPANY);

//       dispatch(replace(COMPANIES_PATH));
//     } else if (response.status !== COMPANIES.RESPONSE.STATUS_CODE.INTERNAL_SERVER_ERROR_CODE) {
//       throw new SubmissionError(result);
//     }
//   } finally {
//     dispatch(setLoading(false));
//   }
// };

// export const editCompany = (company, companies) => async (dispatch) => {
//   dispatch(setLoading(true));
//   try {
//     company.platform = typeof company.platform === "string" ? company.platform.split(",") : company.platform;
//     if (company.id) {
//       await companyApi.update(prepareCompany(company));
//     }
//     const response = await changeCompany(company);
//     const result = await response.json();

//     if (response.ok) {
//       let companIds = "";
//       let companiesLength = companies.length;
//       for (var index = 0; index < companiesLength; index++) {
//         companIds += companies[index].firebaseId;
//         if (index !== companiesLength - 1) {
//           companIds += ",";
//         }
//       }
//       dispatch(getCompanies(companIds));
//       toast(COMPANIES.RESPONSE.MESSAGE.SUCCESSFUL_UPDATE_COMPANY);

//       //Check if the company has license with pending facilities and update if company name changed
//       let pendingApprovalObj = await getPendingFacilities(company.firebaseId);

//       if (pendingApprovalObj && pendingApprovalObj.companyName !== company.name.trim()) {
//         pendingApprovalObj.companyName = company.name.trim();
//         await updatePendingFacilities(company.firebaseId, pendingApprovalObj);
//       }
//     } else if (response.status !== COMPANIES.RESPONSE.STATUS_CODE.INTERNAL_SERVER_ERROR_CODE) {
//       throw new SubmissionError(result);
//     }
//   } finally {
//     dispatch(setLoading(false));
//   }
// };
