import { useParams } from "react-router-dom";
import web from "../../components/developerApi/developerSdk/Structure/Web/PubSubComponent";

import "./pubSubLayout.scss";

const PUB_SUB = {
  web,
  android: () => <h1>Not Implemented Yet</h1>,
  ios: () => <h1>Not Implemented Yet</h1>,
};

const PubSubLayout = () => {
  const params = useParams();
  const api = params.api;
  const PubSubComponent = PUB_SUB[api];

  return (
    <div className="pub-sub-layout">
      <h2>Pub Sub</h2>
      <PubSubComponent />
    </div>
  );
};

export default PubSubLayout;
