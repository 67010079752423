import { createAction } from "redux-actions";
// import { replace } from "react-router-redux";
import { toast } from "react-toastify";
// import { SubmissionError, reset } from "redux-form";

import { SIGN_IN, SIGN_OUT, IS_LOADING, COOKIE_POLICY_BANNER, UPDATE_USER } from "../reducers/session";
import { signUp, signIn, signOut, validateEmailSignUp } from "../../firebase/auth";
import { getUserId, getToken, isCookiePolicyBannerHidden, getCompanyId, isStaySignedIn } from "../../storage/localStorage";
import { getCompanies, setCompany, selectCompany } from "./companies";
import updateProfileApi from "../../api/updateProfileApi";
import { getUser } from "../../firebase/user";
import { sendForgotPasswordEmail, checkLinkToken, changePassword, updatePassword, updateEmail, completeInvitation, editPersonalInfo, generateToken, createOrganizations, updateEmailInPendingFacilities, updateUserNameInPendingFacilities } from "../../firebase/cloud-functions";
import { LANDING_PATH, HOME_PATH, SIGN_IN_PATH, INVITE_PATH } from "../../constants/routes";

const loginAction = createAction(SIGN_IN);
const logoutAction = createAction(SIGN_OUT);
export const setLoading = createAction(IS_LOADING);
export const showCookiePolicyBanner = createAction(COOKIE_POLICY_BANNER);
export const updateReduxUser = createAction(UPDATE_USER);

const NOT_FOUND = 404;
const UNPROCESSABLE_ENTITY = 422;
const FORBIDDEN = 403;

const SUCCESSFUL_AUTH_MESSAGE = "Thank you for signing up for Developer Connect.";
const FAIL_AUTH_MESSAGE = "Sorry, that username or password is incorrect.";
const FAIL_EMAIL_EXISTS_MESSAGE = "Email id already exists.";
const FAIL_EMAIL_PWD_MESSAGE = "Email/Password incorrect";
const FAIL_PWD_MESSAGE = "Sorry, that password is incorrect.";
const SAME_PWD_MESSAGE = "Please use a new password.";
const SUCCESSFUL_UPDATE_TEAM_MEMBER = " was successfully updated.";

const prepareUser = ({ developerId, email, firstName, lastName }) => ({
  ...(developerId && { id: developerId }),
  email,
  firstName,
  lastName,
});

const doLogin = async (dispatch, { email, password, staySignedIn }) => {
  const { user, token } = await signIn(email, password, staySignedIn);
  dispatch(getCompanies(user.companies ? Object.keys(user.companies).join(",") : ""));
  dispatch(selectCompany(user.companies ? user.companies[Object.keys(user.companies)[0]].companyId : ""));
  // dispatch(replace(HOME_PATH));
  dispatch(loginAction({ user, token, staySignedIn }));
};

const invalidEmail = new Error({
  email: FAIL_AUTH_MESSAGE,
});

const invalidEmailPassword = new Error({
  email: FAIL_EMAIL_PWD_MESSAGE,
});

const invalidPassword = new Error({
  currentPassword: FAIL_PWD_MESSAGE,
});

const samePassword = new Error({
  password: SAME_PWD_MESSAGE,
});

const emailExists = new Error({
  email: FAIL_EMAIL_EXISTS_MESSAGE,
});

export const register = (params) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const { errors, user, token } = await signUp(params);
    if (errors) {
      throw new Error(errors);
    }
    dispatch(showCookiePolicyBanner(true));
    // dispatch(replace(HOME_PATH));
    toast(SUCCESSFUL_AUTH_MESSAGE, {
      className: "sign-up-toast",
    });
    dispatch(loginAction({ user, token, staySignedIn: false }));
    // dispatch(reset("signUp"));
  } finally {
    dispatch(setLoading(false));
  }
};

export const registerEmail = (params) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { errors } = await validateEmailSignUp(params);
    if (errors) {
      throw new Error(errors);
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const login = (params) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    await doLogin(dispatch, params);
  } catch (error) {
    throw invalidEmail;
  } finally {
    dispatch(setLoading(false));
  }
};

export const loginSupport = (params) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    await signIn(params.email, params.password, params.staySignedIn);
  } catch (error) {
    throw invalidEmail;
  } finally {
    const uid = await getUserId();
    const response2 = await createOrganizations(uid);
    let companiesString = await response2.json();
    const response = await generateToken(companiesString.companies);
    let zendeskTokenJSON = await response.json();
    window.location.replace(`https://haloapisupport.zendesk.com/access/jwt?jwt=${zendeskTokenJSON["token"]}`);
    dispatch(setLoading(false));
  }
};

const SUCCESSFUL_SEND_EMAIL_MESSAGE = "Reset password instructions have been sent to your email.";

export const sendForgotPasswordEmailAction = (params) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await sendForgotPasswordEmail(params);
      if (response.ok) {
        // dispatch(replace(SIGN_IN_PATH));
        toast(SUCCESSFUL_SEND_EMAIL_MESSAGE);
      } else if ([NOT_FOUND, FORBIDDEN].indexOf(response.status) > -1) {
        throw new Error({
          email: await response.text(),
        });
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const checkLinkTokenAction = (token, path) => {
  return async (dispatch) => {
    if (!token) {
      // return dispatch(replace(LANDING_PATH));
    }
    dispatch(setLoading(true));

    try {
      const response = await checkLinkToken(token);

      if ([NOT_FOUND, UNPROCESSABLE_ENTITY].indexOf(response.status) > -1) {
        toast(`Your ${path === INVITE_PATH ? "invite" : "password reset"} link has expired.`);
        // dispatch(replace(SIGN_IN_PATH));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const SUCCESSFUL_CHANGE_PASSWORD_MESSAGE = "Your password has been changed successfully.";

export const changePasswordAction = (params) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await changePassword(params);

      if (response.ok) {
        const { email } = await response.json();
        await doLogin(dispatch, {
          email,
          password: params.password,
        });
        toast(SUCCESSFUL_CHANGE_PASSWORD_MESSAGE);
      } else if (response.status === NOT_FOUND) {
        toast(await response.text());
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

//update password from the profile page
//1. Reauthenticate the user to make sure the user has recently signed in.
//2. Change password
const SUCCESSFUL_PWD_UPDATE_MESSAGE = "Password has been updated successfully.";

export const updatePasswordAction = (params) => async (dispatch) => {
  try {
    const { currentPassword, email, password } = params;
    if (currentPassword === password) {
      var sameErrorObj = { errorMessage: "samePassword" };
      throw sameErrorObj;
    }
    let response = await signIn(email, currentPassword);
    if (response) {
      let result = await updatePassword(params);

      if (result.ok) {
        //sign in again with the new password to reauthenticate the user
        await signIn(email, password);
        toast(SUCCESSFUL_PWD_UPDATE_MESSAGE);
      }
    }
  } catch (error) {
    if (error.errorMessage) {
      throw samePassword;
    }
    throw invalidPassword;
  } finally {
    dispatch(setLoading(false));
  }
};

const SUCCESSFUL_CHANGE_EMAIL_MESSAGE = "Your email has been updated successfully.";

//update email from the profile page
//1. Reauthenticate the user to make sure the user has recently signed in.
//2. Change email
export const updateEmailAction = (params) => async (dispatch) => {
  // dispatch(setLoading(true));
  try {
    const { userId, prevEmail, email, password, firstName, lastName, developerId } = params;
    //reauthenticate the user before changing the password
    let response = await signIn(prevEmail, password);
    if (response) {
      let fbResponse = await updateEmail({ userId, email });

      if (fbResponse.ok) {
        if (developerId) {
          let developer = {
            ...prepareUser({ developerId, firstName, lastName, email }),
          };
          await fetch(updateProfileApi.update(developer));
        }
        await signIn(email, password);
        dispatch(updateReduxUser({ params: { email: email } }));
        toast(SUCCESSFUL_CHANGE_EMAIL_MESSAGE);
        await updateEmailInPendingFacilities({ prevEmail, email });
      } else {
        var emailExistsObj = { errorMessage: "emailExists" };
        throw emailExistsObj;
      }
    }
  } catch (error) {
    if (error.errorMessage === "emailExists") {
      throw emailExists;
    }
    throw invalidEmailPassword;
  } finally {
    dispatch(setLoading(false));
  }
};

export const completeInvitationAction = (params) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await completeInvitation(params);

    if (response.ok) {
      const { email } = await response.json();
      await doLogin(dispatch, {
        email,
        password: params.password,
      });
    } else if (response.status === NOT_FOUND) {
      toast(await response.text());
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const logout = () => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    await signOut();
    // dispatch(replace(LANDING_PATH));
    dispatch(logoutAction());
  } catch (error) {
    //TODO handle auth error
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAuthInfo = () => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const user = await getUser(getUserId());

    const companies = user.companies ? Object.keys(user.companies).join(",") : "";
    await dispatch(getCompanies(companies));

    dispatch(setCompany({ companyId: getCompanyId() }));

    dispatch(
      loginAction({
        token: await getToken(),
        user,
        staySignedIn: isStaySignedIn(),
      })
    );

    if (!isCookiePolicyBannerHidden()) {
      dispatch(showCookiePolicyBanner(true));
    }
  } catch (error) {
    // dispatch(replace(SIGN_IN_PATH));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updatePersonalInfo = (memberId, member) => async (dispatch) => {
  try {
    let developer = {
      ...prepareUser(member),
    };

    //check if the member has a field : developerId, then
    //call the updateProfileApi
    if (member.developerId) {
      await updateProfileApi.update(developer);
    }

    let response = await editPersonalInfo(memberId, member);
    if (response.ok) {
      dispatch(updateReduxUser({ params: member }));
      toast(`${member.firstName} ${member.lastName} ${SUCCESSFUL_UPDATE_TEAM_MEMBER}`);
      updateUserNameInPendingFacilities({ developer });
    } else if (response.status === UNPROCESSABLE_ENTITY) {
      throw new Error({
        email: await response.text(),
      });
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const generateZendeskToken = () => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const uid = await getUserId();
    const response2 = await createOrganizations(uid);
    let companiesString = await response2.json();
    const response = await generateToken(companiesString.companies);
    let zendeskTokenJSON = await response.json();
    window.location.replace(`https://haloapisupport.zendesk.com/access/jwt?jwt=${zendeskTokenJSON["token"]}`);
  } finally {
    dispatch(setLoading(false));
  }
};
