import { connect } from "react-redux";
// import { formValueSelector, isInvalid } from 'redux-form'; EPEDRO

import LicenseOuterFormComponent from "../components/profile/License/LicenseOuterFormComponent";
import { addLicense, moveLicense, getLicense, changeLicense, removeFacilitiesAction } from "../store/actions/licenses";
import { getCurrentCompany } from "./selectors/companySelectors";
import { getCurrentUser } from "./selectors/userSelectors";

const LICENSE_FORM_NAME = "license";

// const getSelector = (form) => formValueSelector(form); EPEDRO

const mapStateToProps = (state, { initialValues, platforms, isCreateAction, handleSubmit, action, close, modalTitle, toolTipFlag, pageAmount, cancelButtonTitle, confirmButtonTitle, page, nextPage, prevPage, inProgress, inProgress2, changePlatform, addIncorrectIds, incorrectIds, updateInvalid, savedCallbacks, saveCallbacks, savedAdditional, saveAdditional, updateSubEnabled }) => {
  const form = LICENSE_FORM_NAME + (initialValues && initialValues.env ? `_${initialValues.env}` : "");
  console.log("LicenseOuterFormContainer.mapStateToProps.page: ", page);
  return {
    form,
    // EPEDRO
    // subEnabled: getSelector(form)(state, 'subEnabled'),
    // selectedPlatform: getSelector(form)(state, 'platform'),
    // description: getSelector(form)(state, 'description'),
    // formData: getSelector(form)(state, 'name', 'plaform', 'license', 'cosumerKey', 'consumerSecret', 'appCategoryId', 'redirectUrl', 'description'),
    // EPEDRO
    initialValues,
    isCreateAction,
    handleSubmit,
    action,
    platforms,
    user: getCurrentUser(state),
    company: getCurrentCompany(state),
    close,
    modalTitle,
    toolTipFlag,
    pageAmount,
    page,
    cancelButtonTitle,
    confirmButtonTitle,
    nextPage,
    prevPage,
    inProgress,
    inProgress2,
    changePlatform,
    addIncorrectIds,
    incorrectIds,
    /// isInvalid: isInvalid(form)(state), EPEDRO
    updateInvalid,
    // EPEDRO
    // callbacks: getSelector(form)(state, 'accountCallback', 'manualCallback', 'gpsCallback', 'lifefitnessCallback', 'lfUserLoginCallback', 'lfUserLogoutCallback', 'lfUserWorkoutStartCallback', 'lfUserWorkoutEndCallback', 'workoutdataCallback', 'strengthAssetLoginCallback', 'strengthAssetLogoutCallback', 'strengthResultsCallback', 'assetStatsCallback'),
    // additional: getSelector(form)(state, 'assetApiCheckbox', 'forcedLogInCheckbox'),
    // EPEDRO
    savedCallbacks,
    saveCallbacks,
    savedAdditional,
    saveAdditional,
    updateSubEnabled,
  };
};

const mapDispatchToProps = {
  addLicense,
  moveLicense,
  changeLicense,
  removeFacilitiesAction,
};

const mergeProps = ({ handleSubmit, action, user, company, close, modalTitle, isCreateAction, pageAmount, page, cancelButtonTitle, confirmButtonTitle, nextPage, prevPage, inProgress, inProgress2, changePlatform, initialValues, addIncorrectIds, incorrectIds, updateInvalid, callbacks, additional, facilities, savedAdditional, subEnabled, updateSubEnabled, ...stateProps }, { addLicense, moveLicense, changeLicense, removeFacilitiesAction }) => {
  return {
    ...stateProps,
    isCreateAction,
    handleSubmit,
    action,
    onSubmit: (licenseData) => {
      if (page === 1 && pageAmount !== 1) {
        nextPage();
      } else if (page === 2 || pageAmount === 1) {
        let promise;
        let prevPromise;
        let submitAction = isCreateAction ? addLicense : moveLicense;
        let setLicensePromise = getLicense;

        if (licenseData.id === initialValues.id) {
          licenseData.facilitiesSubscribedFor = initialValues.facilitiesSubscribedFor;
          licenseData.facilitiesSubscriptionPending = initialValues.facilitiesSubscriptionPending;
          licenseData.facilitiesSubscriptionRejected = initialValues.facilitiesSubscriptionRejected;
          licenseData.facilitiesAdded = initialValues.facilitiesAdded;
          licenseData.assetApi = initialValues.assetApi;
          licenseData.forcedLogIn = initialValues.forcedLogIn;
          licenseData["assetApi-active"] = initialValues["assetApi-active"];
          licenseData["forcedLogIn-active"] = initialValues["forcedLogIn-active"];
        } else {
          licenseData = { ...initialValues };
        }

        if (action === "editDev" || action === "editProd") {
          submitAction = changeLicense;
        }
        prevPromise = setLicensePromise(licenseData.id);

        promise = submitAction({ user, company, licenseData });

        if (isCreateAction || action !== "move") {
          return promise
            .then((ids) => {
              if (ids) {
                addIncorrectIds(ids);
              } else {
                close();
                removeFacilitiesAction(null, licenseData);
              }
            })
            .finally(() => {
              licenseData = {};
            });
        } else {
          return prevPromise().then(
            promise
              .then((ids) => {
                if (ids) {
                  addIncorrectIds(ids);
                } else {
                  close();
                }
              })
              .finally(() => {
                licenseData = {};
              })
          );
        }
      }
    },
    page,
    pageAmount,
    cancelButtonTitle,
    confirmButtonTitle,
    prevPage,
    inProgress,
    inProgress2,
    removeFacilitiesAction,
    changePlatform,
    initialValues,
    incorrectIds,
    addIncorrectIds,
    updateInvalid,
    callbacks,
    additional,
    savedAdditional,
    subEnabled,
    updateSubEnabled,
    close,
    modalTitle,
    nextPage,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LicenseOuterFormComponent);
