const _ = require('lodash');

export const INIT_GRID = 'INIT_GRID';
export const SET_ITEMS = 'SET_ITEMS';
export const SET_SORT_BY = 'SET_SORT_BY';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SELECT_ITEM = 'SELECT_ITEM';
export const REMOVE_GRID = 'REMOVE_GRID';

const INITIAL_STATE = {
    grids: {},
};

const ACTION_HANDLERS = {
    [INIT_GRID]: (state, { payload: { name } }) => {
        const { grids } = state;

        grids[name] = {
            selectedItems: {},
            items: [],
            sortBy: {
                props: '',
                directions: ['asc'],
            },
            pagination: {
                enabled: true,
                activePage: 1,
                totalPages: 1,
                itemsPerPage: 5,
            },
        };

        return {
            ...state,
            grids: { ...grids },
        };
    },
    [SET_ITEMS]: (state, { payload: { name, items } }) => {
        const { grids } = state;

        if (grids[name]) {
            grids[name].items = items;
        }

        return {
            ...state,
            grids: { ...grids },
        };
    },
    [SET_SORT_BY]: (state, { payload: { name, sortBy } }) => {
        const { props, directions = ['asc'] } = sortBy;
        const { grids } = state;

        if (grids[name]) {
            grids[name].sortBy = {
                props,
                directions,
            };
        }

        return {
            ...state,
            grids: { ...grids },
        };
    },
    [SET_PAGINATION]: (state, { payload: { name, options } }) => {
        const { grids } = state;
        const grid = grids[name];

        if (grid) {
            grids[name].pagination = {
                ...grid.pagination,
                ...options,
            };
        }

        return {
            ...state,
            grids: { ...grids },
        };
    },
    [SELECT_ITEM]: (state, { payload }) => {
        const { name, index, singleSelection, isChecked } = payload;
        const { grids } = state;

        if (singleSelection) {
            grids[name].selectedItems = _.mapValues(
                grids[name].selectedItems,
                (value, key) => parseInt(key, 10) === index ? value : false
            );
        }

        _.update(grids, `${name}.selectedItems[${index}]`, (value) => _.isBoolean(isChecked) ? isChecked : !value);

        return {
            ...state,
            grids: { ...grids },
        };
    },
    [REMOVE_GRID]: (state, { payload }) => {
        const { grids } = state;
        const { name } = payload;

        delete grids[name];

        return {
            ...state,
            grids: { ...grids },
        };
    },
}

export default (state = INITIAL_STATE, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
