import React from 'react'
import PropTypes from 'prop-types'

import './releasenotes.scss'
import DateComponent from '../../../core/DateComponent/DateComponent'

class ReleaseNotesComponent extends React.Component {
    render() {
        const { version, releaseNotes } = this.props
        const { notes, updated } = releaseNotes

        return <div className="ReleaseNotes-component">
            <span className="title"><b>WEB API Docs</b> | Release {version}</span><br />
            <DateComponent className="date" date={updated} />

            <div className="notes">
                <label>Release Notes</label>
                {notes.map((note, i) => <span key={i}>{note}</span>)}
            </div>
        </div>
    }
}

ReleaseNotesComponent.propTypes = {
    releaseNotes: PropTypes.object.isRequired,
    version: PropTypes.string.isRequired,
}

export default ReleaseNotesComponent
