import ScrollToTop from "../../ScrollToTop";

import { LANDING_PATH, SIGN_UP_PATH, SIGN_IN_PATH, RESET_PASSWORD_PATH, CHANGE_PASSWORD_PATH } from "../../constants/routes";
import { WEB_WIDGET_URL } from "../../constants/common";

import "./mainLayout.scss";
import HeaderContainer from "../../containers/HeaderContainer";
import { Image } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import ContactComponent from "../../components/footer/contact/ContactComponent";
import FooterContainer from "../../containers/FooterContainer";
import { useEffect } from "react";
import { doCredentialsExist } from "../../storage/localStorage";
import { useDispatch } from "react-redux";
import { getAuthInfoAction } from "../../redux/slice/sessionSlice";

const MainLayout = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let url = WEB_WIDGET_URL.url;
    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    script.id = "ze-snippet";
    document.body.appendChild(script);
    if (doCredentialsExist()) dispatch(getAuthInfoAction());
  }, []);

  const location = useLocation();

  const path = location?.pathname;
  const isLandingPage = path === LANDING_PATH;
  const isEntryPoint = [SIGN_UP_PATH, SIGN_IN_PATH, RESET_PASSWORD_PATH, CHANGE_PASSWORD_PATH].indexOf(path) > -1;
  const isLandingOrEntryPage = isLandingPage || isEntryPoint;

  const headerImageSource = isLandingPage ? require("./images/landing-banner.png") : require("./images/banner.png");

  return (
    <>
      <div className="Layout-component">
        <ScrollToTop>
          <HeaderContainer isEntryPoint={isEntryPoint} />
          {isLandingOrEntryPage && (
            <div className="img-box">
              <Image src={headerImageSource} className="img-responsive" />
              {!isLandingPage && (
                <span>
                  <h1>Developer Connect</h1>
                </span>
              )}
            </div>
          )}
          <Outlet />
          {!isLandingOrEntryPage && <ContactComponent />}
          <FooterContainer />
        </ScrollToTop>
      </div>
    </>
  );
};

export default MainLayout;
