import CONFIG from '../../../config/config';

export const MENU = {
    started: {
        title: 'Introduction',
    },
    // 'introduction': {
    //     title: 'Introduction',
    // },
    'authentication': {
        title: 'Authentication',
    },
    structure: {
        title: 'Structure',
        subMenu: {
            'pub-sub': {
                title: 'Pub Sub',
            },
        },
    },
    subscription: {
        title: 'Subscriptions',
    },
    'data-types': {
        title: 'Data Types',
    },
    documentation: {
        title: 'Documentation',
    },
    license: {
        title: 'Developer Connect License',
    },
    'simulation-mode': {
        title: 'Simulation Mode',
    },
    'workout-preset': {
        title: 'Workout Preset',
    },
    faq: {
        title: 'FAQ',
    },
    'api-keys': {
        title: 'API Keys',
    },
    'release-note': {
        title: 'Release Note',
    },
    compatibility: {
        title: 'API Compatibility',
    },
    archives: {
        title: 'Archives',
    },
    sdk: {
        title: 'Download SDK',
    },
    // download: {
    //     title: 'Download',
    // },
    restconsole: {
        extLink: CONFIG.restURL,
        title: 'Sandbox',
    },
};

export const ANDROID_MENU = {
    ...MENU,
    'data-types': null,
    structure: null,
    license: null,
    'simulation-mode': null,
    'api-keys': null,
    'release-note': null,
    restconsole: null,
    subscription: null,
    introduction: null,
    authentication: null,
    faq: null,
    compatibility: null,
    archives: null,
};

export const IOS_MENU = {
    ...ANDROID_MENU,
};

export const WEB_MENU = {
    ...MENU,
    license: null,
    'simulation-mode': null,
    'api-keys': null,
    'release-note': null,
    download: null,
    documentation: null,
    'workout-preset': null,
    faq: null,
    sdk: null,
};

export const OVERVIEW_MENU = {
};

export const PROFILE_MENU = {
    update: {
        title: 'Profile',
        //companyRequired: true,
    },
    companies: {
        title: 'Companies',
        //companyRequired: false,
    },
    licenses: {
        title: 'Licenses',
        //companyRequired: true,
    },
    support: {
        title: 'Support',
        //companyRequired: true,
    },
    // 'team-members': {
    //     title: 'Team Access',
    //     companyRequired: true,
    //     accessDenied: [ MEMBER_ROLES.member ],
    // },
    // licenses: {
    //     title: 'Licenses',
    //     companyRequired: false,
    // },
};

export const MARKETING_GUIDELINES_MENU = {
    overviewrequirements: {
        title: 'Overview and Requirements',
    },
    'logo-usage': {
        title: 'Logo Usage and Guidelines',
    },
    'imagery-usage': {
        title: 'Imagery Usage',
    },
    'language-legal-naming': {
        title: 'Language, Legal and Product Naming',
    },
};

export const COPYRIGHTS_DATE = {
    copyrights_date: new Date().getFullYear(),
};

export const MEMBERS_MENU = {
    'edit-member': {
        title: 'Edit Member',
        //companyRequired: true,
        action: 'update',
    },
    'remove-member': {
        title: 'Remove Member',
        //companyRequired: true,
        action: 'delete',
    },
};

export const MEMBERS_MENU_INVITATION = {
    'resend-invitation': {
        title: 'Resend Invitation',
        //companyRequired: true,
        action: 'resend_invitation',
    },
}

export const LICENSES_MENU = {
    'move-production': {
        title: 'Move to Production',
        forDev: true,
        action: 'move-license',
    },
    'edit-dev-license': {
        title: 'Edit License',
        forDev: true,
        action: 'edit-dev-license',
    },
    'edit-prod-license': {
        title: 'Edit License',
        forDev: false,
        action: 'edit-prod-license',
    },
    'edit-license': {
        title: 'Edit License',
        forDev: true,
        action: 'edit-license',
    },
    'delete-license': {
        title: 'Delete License',
        forDev: true,
        action: 'delete-license',
    },
};

export const GUIDES_MENU = {
    introduction: {
        title: 'Introduction',
    },
    'mms-integration': {
        title: 'MMS Integration',
    },
    'real-time-availability': {
        title: 'Real-Time Availability',
    },
};
