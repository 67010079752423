import { Accordion, Badge } from "react-bootstrap";

import "./accordion.scss";

const AccordionComponent = (props) => {
  const { title, eventKey, children, disabled, activeKey } = props;
  const isOpen = activeKey === eventKey;

  return (
    <>
      <Accordion.Item className={disabled && "disabled"} eventKey={eventKey}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>{children}</Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default AccordionComponent;
