import { Tabs, Tab } from "react-bootstrap";

import {
  OVERVIEW_MENU,
  WEB_MENU,
  IOS_MENU,
  ANDROID_MENU,
} from "../core/MenuComponent/MenuConstants";
import DeveloperSdkComponent from "./developerSdk/DeveloperSdkComponent";
import ScrollToTop from "../../ScrollToTop";
import ScrollButtonComponent from "../core/ScrollButtonComponent/ScrollButtonComponent";

import "./developerApi.scss";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

const DeveloperApiComponent = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { tab } = props;
  const changeApi = (api) => {
    return navigate(`/auth/api/${api}/started`, { replace: true });
  };

  const getMenuItems = (api, menuItems) =>
    Object.keys(menuItems).map((keyItem) => {
      const menuItem = menuItems[keyItem];
      const subMenu = menuItem && menuItem.subMenu;
      return {
        ...menuItem,
        url: `/auth/api/${api}/${keyItem}`,
        active: params.menuItem === keyItem,
        subMenu:
          subMenu &&
          Object.keys(subMenu).map((subMenuItem) => ({
            ...subMenu[subMenuItem],
            url: subMenuItem,
            active: params.subMenuItem === subMenuItem,
          })),
      };
    });

  const renderTab = (api, title, menu) => {
    return (
      <Tab eventKey={api} title={title}>
        <DeveloperSdkComponent
          menuTitle={title}
          menuItems={getMenuItems(api, menu)}
          children={props.children}
        />
      </Tab>
    );
  };

  return (
    <div id="developer-api" className="Developer-api-component">
      <ScrollToTop elementId="developer-api" indentElementId="header">
        <Tabs onSelect={changeApi} defaultActiveKey={tab} id="api-tabs">
          <Tab title="DOCS" eventKey="docs" />
          {renderTab("overview", "Overview", OVERVIEW_MENU)}
          {renderTab("web", "Web API", WEB_MENU)}
          {renderTab("ios", "iOS API", IOS_MENU)}
          {renderTab("android", "Android API", ANDROID_MENU)}
        </Tabs>
      </ScrollToTop>
      <ScrollButtonComponent elementId="developer-api" />
    </div>
  );
};

export default DeveloperApiComponent;
