import React from 'react';
import PropTypes from 'prop-types';
import { Overlay, Tooltip } from 'react-bootstrap';

import './tooltip.scss';

class TooltipComponent extends React.Component {
    constructor(props) {
        super(props);
        this.target = null;
        this.state = {
            show: false,
        }
    }

    render() {
        const { overlayContent, children, placement, className, keepOpen, show, setShow, allowHover } = this.props;

        return (
            <span>
                <span ref={target => { this.target = target }} onMouseEnter={() => setShow !== undefined && allowHover ? setShow(true) : this.setState({ show: true })} onMouseLeave={() => setShow !== undefined ? setShow(false) : this.setState({ show: false })}>
                    {children}
                </span>
                <Overlay target={this.target} show={show !== undefined ? show : this.state.show} placement={placement}>
                    <Tooltip id={className} className={className} onMouseEnter={() => setShow !== undefined ? setShow(keepOpen) : this.setState({ show: (keepOpen) })} onMouseLeave={() => setShow !== undefined ? setShow(false) : this.setState({ show: false })}>
                        {overlayContent}
                    </Tooltip>
                </Overlay>
            </span>
        );
    }
}

TooltipComponent.defaultProps = {
    className: '',
};

TooltipComponent.propTypes = {
    overlayContent: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    placement: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    keepOpen: PropTypes.bool,
    show: PropTypes.bool,
    setShow: PropTypes.func,
    allowHover: PropTypes.bool,
};

export default TooltipComponent;
