import { useState } from "react";
import FieldGroupComponent from "../../components/core/FieldGroupComponent/FieldGroupComponent";
import CONFIG from "../../config/config";

import "./signUpCheckboxLayout.scss";

const SignUpFormCheckboxLayout = () => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptSubscription, setAcceptSubscription] = useState(false);

  const acceptTermsHandler = () => {
    setAcceptTerms((prevState) => !prevState);
  };

  const acceptSubscriptionHandler = () => {
    setAcceptSubscription((prevState) => !prevState);
  };

  let acceptTermsPlaceholder =
    `By clicking this box, I acknowledge and accept the
            <a href="` +
    CONFIG.termsAndConditionsURL +
    `" rel="noopener noreferrer" target="_blank">
            Terms of Use, the Privacy Policy</a> and agree that my information may be transferred to the U.S.`;

  if (navigator.language === "en-US") {
    acceptTermsPlaceholder =
      `By clicking this box, I acknowledge and accept the
                <a href="` +
      CONFIG.termsAndConditionsURL +
      `" rel="noopener noreferrer" target="_blank">
                Terms of Use and the Privacy Policy</a>.`;
  }

  return (
    <>
      <FieldGroupComponent name="acceptTerms" type="checkbox" placeholder={acceptTermsPlaceholder} checked={acceptTerms} />
      <FieldGroupComponent
        name="acceptSubscription"
        type="checkbox"
        placeholder={`Accepting the <a href="https://www.lifefitness.com/en-us/legal/subscriptions/master-subscription-agreement"
                    rel="noopener noreferrer" target="_blank">Subscription Agreement</a>
                    constitutes a binding contract on you and governs your user of and
                    access to the services by you, staff and end-users whether in connection with a paid or free trial
                    subscription to the services.`}
        checked={acceptSubscription}
      />
    </>
  );
};

export default SignUpFormCheckboxLayout;
