import { connect } from "react-redux";

import { getCurrentCompanyId, getCurrentCompanyPlatform, getAllCompanies } from "./selectors/companySelectors";
//import { selectCompany } from "../store/actions/companies";
import ProfileComponent from "../components/profile/ProfileComponent";

const mapStateToProps = (state, { match }) => {
  // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  // console.log("$$$$$$$$$$$$ INSIDE ProfileContainer.mapStateToProps.state: ", state);
  // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  return {
    // currentCompanyId: getCurrentCompanyId(state),
    // currentCompanyPlatform: getCurrentCompanyPlatform(state),
    // companies: getAllCompanies(state),
    match,
  };
};

const mapDispatchToProps = {};

const mergeProps = ({ currentCompanyId, currentCompanyPlatform, isMembersLoading, isLicensesLoading, userRole, ...stateProps }) => ({
  ...stateProps,
  currentCompanyId,
  //isCompanySelected: !!currentCompanyId,
  //availablePlatform: currentCompanyPlatform[0],
  userRole,
  //getMembers: () => userRole === MEMBER_ROLES.admin && getTeamMembers(currentCompanyId),
  //getLicenses: () => getLicenses(currentCompanyId),
  //onCompanySelect: selectCompany,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProfileComponent);
