import { Image, Col } from "react-bootstrap";
import ReactGA from "react-ga";

import { SDK_CAPABILITIES } from "./OverviewConstants";
import CONFIG from "../../../../config/config";
import LinkComponent from "../../../core/LinkComponent/LinkComponent";

import "./overviewComponent.scss";
import { useEffect } from "react";

const OverviewComponent = () => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Overview Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="overview-component">
      <div className="section">
        <p>Developer Connect APIs provide a variety of digital solutions - for developers wishing to create powerful mobile Apple and Android applications using the Mobile APIs, and the Web APIs, allowing developers to utilize the data, such as exercisers' profiles, workout results, and workout libraries. The Developer Connect Web API provides full REST web services intended for developers to utilize this data.</p>
      </div>
      <br />
      <div className="section">
        <h3>DEVELOPER CONNECT WEB SERVICES</h3>
        <p>With Developer Connect Web API services, applications can further extend the functionalities of the Developer Connect platform through the access of an exerciser's Profile, Workout Progress, and Workout Results.</p>
      </div>
      <br />
      <div className="row">
        <Col xs={6} md={4} align="center">
          <Image src={require("../../../../images/API_image_web_services_profile_103x108.png")} />
          <p>
            With Developer Connect, an application can retrieve and update a Developer Connect exerciser's
            <b className="strawberry-text"> profile</b>.
          </p>
        </Col>
        <Col xs={6} md={4} align="center">
          <Image src={require("../../../../images/API_image_web_services_progress_103x108.png")} />
          <p>
            Retrieve an exerciser's <b className="strawberry-text">progress</b> (e.g. number of calories burned this week, etc).
          </p>
        </Col>
        <Col xs={6} md={4} align="center">
          <Image src={require("../../../../images/API_image_web_services_retrieve_103x108.png")} />
          <p>
            Retrieve an exerciser's <b className="strawberry-text">workout list</b> and
            <b className="strawberry-text"> presets</b>, as well as upload GPS and Manual Workout Results.
          </p>
        </Col>
      </div>
      <br />
      <div className="section">
        <p>For example, a developer can create an application that utilizes GPS functionalities to create a GPS-based workout result submission. The application then submits the data to Developer Connect and the exerciser can view his/her results online.</p>
        <p>
          A whole new world of exciting possibilities are in store for developers with these web services. Utilize data, such as averge heart rate, calories burned, and time to create workout result-based solutions and more.{" "}
          <b>
            <LinkComponent className="blue-text" title="Check out" url={CONFIG.restURL} isExternalLink="true" />{" "}
          </b>
          each end point to further understand the usage and purpose of each method.
        </p>
      </div>

      <div className="section">
        <p>
          <i>*Developer Connect provides the Subscription API to get near real time notifications on Developer Connect user's latest data.</i>
        </p>
      </div>
      <br />
      <div className="section">
        <h3>DEVELOPER CONNECT SDK</h3>
        <p>The Developer Connect iOS and Android SDK makes it possible for third-party apps to interact directly with compatible consoles from Life Fitness. Developer Connect API enables third party apps to receive in-workout data stream and workout result summary. Third party apps can also transmit workout presets and control some equipment parameters to supported Life Fitness consoles.</p>
        <p>Developer Connect SDK supports various families of Life Fitness equipment, from cardio to strength and from home to commercial equipment.</p>

        <p>Developer Connect SDK also allows third-party apps to read QR codes from Life Fitness strength equipment to identify and track the workout on the strength equipment.</p>
        <br />
        <div className="image">
          <Image src={require("./images/api-about-sdk.png")} />
        </div>
      </div>

      <div className="section sdk">
        <p>Developer Connect SDK for iOS/Android capability to communicate with compatible Life Fitness consoles can be summarized below:</p>
        <br />
        <div className="row">
          {SDK_CAPABILITIES.map(({ imageUrl, action, desc }, i) => (
            <div className="image" key={i}>
              <Image src={require(`${imageUrl}`)} />
              <p>
                <b>
                  <span className="strawberry-text">{action}</span>
                </b>{" "}
                {desc}
              </p>
            </div>
          ))}
        </div>
        <div className="section">
          <p>
            <i>*Developer Connect SDK and documentation are available for download after registration on this portal.</i>
          </p>
        </div>
      </div>
      <br />
      <div className="section">
        <h3>BUT FIRST... LET'S GET YOU A LICENSE</h3>

        <p>In order to use the web APIs and classes in the SDK library, the developer must supply the license details (OAuth Client ID and secret for Web APIs and License number for Android/iOS SDKs) for using any of the methods or APIs. Calling any methods/APIs without supplying the valid license details will result in an API Error/Exception.</p>
        <br />
        <p>Developer Connect provides two environments for all third-party developers:</p>
        <br />
        <ol>
          <li>
            <b className="strawberry-text">Development Environment:</b> The app should use this environment during mobile app development and testing.
          </li>
          <li>
            <b className="strawberry-text">Production Environment:</b> The app distributed to end-users must use this environment.
          </li>
        </ol>
        <br />
        <p>
          You can obtain the License by registering yourself on the{" "}
          <a href="../../../profile/licenses">
            <b className="blue-text">license page</b>
          </a>
          . The license will first be created for the development environment and then can be “Moved to Production”. <b>The license moved to production environment will continue to exist in the development environment as well</b>.
        </p>
        <br />
        <p>Below is the complete process of obtaining the licenses (the blue boxes show the processes taking place on the Developer Connect portal):</p>
        <br />
        <div className="image">
          <Image src={require("./images/api-license-process.png")} />
        </div>

        <p>Apps under the “Dev” section display development environment Licenses (green rectangle) and Apps under the “Prod” section display Live environment Licenses (blue rectangle). You can edit the development environment license for licenses Moved to Production from the menu in the “Prod” section.</p>
        <br />
        <div className="image">
          <Image src={require("./images/api-license-screen.png")} />
        </div>
      </div>
    </div>
  );
};

export default OverviewComponent;
