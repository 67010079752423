import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

import "./grid.scss";

import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import { useDispatch } from "react-redux";
import { setGridAction, setGridItemsAction } from "../../../redux/slice/gridSlice";

const _ = require("lodash");

const GridComponent = (props) => {
  const dispatch = useDispatch();
  // component did mount
  useEffect(() => {
    const { setGrid, setGridItems, setSorting, sortBy, setGridPagination, items, enablePagination, itemsPerPage } = props;
    let totalPages = 1;

    if (items.length > 0) {
      totalPages = Math.ceil(items.length / itemsPerPage);
    }

    dispatch(setGridAction({ name: props.name, items, sortBy, pagination: { enabled: enablePagination, itemsPerPage, totalPages } }));

    // setGrid();
    // setGridItems(items);
    // setSorting(Array.isArray(sortBy) ? { props: sortBy } : sortBy);
    // setGridPagination({
    //   enabled: enablePagination,
    //   itemsPerPage,
    //   totalPages: totalPages,
    // });

    // component will unmount
    return () => {
      props.deleteGrid();
    };
  }, []);

  useEffect(() => {
    dispatch(setGridItemsAction({ name: props.name, items: props.items }));
  }, [props.items]);

  // const componentWillUpdate = (nextProps) => {
  //   const { items, setGridItems, setGridPagination, forceUpdate } = props;
  //   const {
  //     items: nextItems,
  //     paginationOptions: { enabled: paginationEnabled, itemsPerPage, activePage },
  //     forceUpdate: nextForceUpdate,
  //   } = nextProps;

  //   if (!_.isEqual(items, nextItems)) {
  //     setGridItems(nextItems);

  //     if (paginationEnabled) {
  //       const totalPages = Math.ceil(nextItems.length / itemsPerPage) || 1;

  //       setGridPagination({
  //         totalPages,
  //         activePage: activePage > totalPages ? totalPages : activePage,
  //       });
  //     }
  //   }

  //   if (nextForceUpdate && nextForceUpdate !== forceUpdate) {
  //     this.forceUpdate();
  //   }
  // };

  const handlePageChange = (activePage) => {
    const { setGridPagination, onPageChange } = this.props;

    onPageChange && onPageChange();
    setGridPagination({
      activePage,
    });
  };

  const { gridProps, enableCheckbox, name, selectGridItem, selectedItems, singleSelection, gridItems, paginationOptions } = props;
  // console.log("***** GridComponent.props: ", props);
  // console.log("***** GridComponent.gridItems: ", gridItems);
  // console.log("***** GridComponent.gridProps: ", gridProps);
  //const { enabled, activePage, totalPages } = paginationOptions;

  return (
    <div className="grid-wrapper">
      <Table className="grid" id={name}>
        {name !== "team-members" && name !== "facility-ids" && (
          <thead>
            <tr>
              {enableCheckbox && <th />}
              {gridProps.map(({ title }, index) => (
                <th key={index}>{typeof title === "function" ? title() : title}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {gridItems.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {enableCheckbox && (
                <td key={`${rowIndex}_0`}>
                  <CheckboxComponent name="gridCheckbox" checked={selectedItems[item.id]} onChange={() => selectGridItem(name, item.id, singleSelection)} />
                </td>
              )}
              {gridProps.map((prop, cellIndex) => (
                <td className={prop.className} key={`${rowIndex}_${cellIndex + 1}`}>
                  {typeof prop.getter === "function" ? prop.getter(item, prop) : item[prop.id]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

GridComponent.defaultProps = {
  items: [],
  enableCheckbox: false,
  selectedItems: {},
  singleSelection: false,
  enablePagination: false,
  itemsPerPage: 5,
  sortBy: [],
};

GridComponent.propTypes = {
  name: PropTypes.string.isRequired,
  gridProps: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  gridItems: PropTypes.array.isRequired,
  selectedItems: PropTypes.object.isRequired,
  paginationOptions: PropTypes.object.isRequired,
  enableCheckbox: PropTypes.bool,
  enablePagination: PropTypes.bool.isRequired,
  singleSelection: PropTypes.bool,
  sortBy: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  itemsPerPage: PropTypes.number.isRequired,
  setGrid: PropTypes.func.isRequired,
  setGridItems: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  selectGridItem: PropTypes.func.isRequired,
  deleteGrid: PropTypes.func.isRequired,
  onPageChange: PropTypes.func,
  forceUpdate: PropTypes.bool,
};

export default GridComponent;
