import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";

import CompanyPanelGroupComponent from "../../../components/profile/Companies/CompanyPanelGroupComponent";
import ModalComponent from "../../core/ModalComponent/ModalComponent";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";
import CompanyFormContainer, { validationRules } from "../../../containers/CompanyFormContainer";

import "./companies.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LICENSES_PATH } from "../../../constants/routes";
import { selectCompany } from "../../../redux/actions/companies";
import { Form } from "react-final-form";
import { getValidationRules } from "../../../validation/validation";
import COMPANY_RULES from "../../../validation/validationRules/company";
const _ = require("lodash");

const ACTIONS = {
  add: "add",
  edit: "edit",
};

const CompaniesComponent = (props) => {
  const sessionState = useSelector((__state__) => __state__.session);
  const [companiesModal, setCompaniesModal] = useState({
    modalTitle: "",
    modalMessage: "",
    modalBody: "",
    modalConfirmButtonTitle: "Yes",
    modalCancelButtonTitle: "No",
    modalOnConfirm: props.submitCompanyForm,
    showModal: false,
    activeKey: props.activePanel,
  });

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Companies Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  const navigate = useNavigate();
  const thisShowModal = (action, company) => {
    let __state__ = {};

    ReactGA.event({
      category: "Companies Component",
      action: "Clicked Add Icon",
      label: "Icon",
    });

    if (action === ACTIONS.add) {
      const __company__ = {
        name: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
        phoneNumber: "",
        country: null,
        platform: null,
        companySize: null,
        industry: null,
        vertical: null,
        otherIndustry: null,
        otherVertical: null,
      };
      __state__ = {
        modalTitle: `${_.capitalize(action)} Company`,
        modalBody: <CompanyFormContainer action={ACTIONS.add} company={__company__} initialValues={thisInitialValues(__company__)} close={closeModal} updateInvalid={updateInvalid} cancelButtonTitle="Cancel" confirmButtonTitle="Create" disableConfirmButton={true} />,
        modalConfirmButtonTitle: "Create",
        modalCancelButtonTitle: "Cancel",
        disableConfirmButton: true,
      };
    } else {
      if (!company.companySize) {
        company = {
          ...company,
          companySize: company.size,
        };
      }
      __state__ = {
        action: ACTIONS.edit,
        modalTitle: "Edit Company",
        modalBody: <CompanyFormContainer action={ACTIONS.edit} company={company} initialValues={thisInitialValues(company)} close={closeModal} updateInvalid={updateInvalid} cancelButtonTitle="Cancel" confirmButtonTitle="Update" disableConfirmButton={false} />,
        modalConfirmButtonTitle: "Update",
        modalCancelButtonTitle: "Cancel",
        disableConfirmButton: false,
      };
    }

    setCompaniesModal({
      ...companiesModal,
      ...__state__,
      showModal: true,
    });
  };

  const closeModal = () => {
    ReactGA.event({
      category: "Companies Component",
      action: "Clicked Close Modal Icon",
      label: "Icon",
    });

    setCompaniesModal({
      ...companiesModal,
      showModal: false,
    });
  };

  const updateInvalid = (invalid) => {
    setCompaniesModal({
      ...companiesModal,
      disableConfirmButton: invalid,
    });
  };

  const thisViewLicenses = (__companyId__) => {
    return selectCompany(__companyId__).then(() => navigate(LICENSES_PATH));
  };

  const thisInitialValues = (__company__) => {
    console.log("***** CompaniesComponent.thisInitialValues() __company__: ", __company__);
    return {
      ...__company__,
      companySize: __company__.companySize && { label: __company__.companySize, value: __company__.companySize },
      industry: __company__.industry && { label: __company__.industry, value: __company__.industry },
      platform: __company__.platform && __company__.platform.map((p) => ({ label: p, value: p })),
      vertical: __company__.vertical && { label: __company__.vertical, value: __company__.vertical },
      country: __company__.country && { label: __company__.country, value: __company__.country },
      phoneNumber: __company__.phone,
    };
  };

  const renderCreateForm = (company) => <CompanyFormContainer company={company} initialValues={thisInitialValues(company)} close={closeModal} updateInvalid={updateInvalid} cancelButtonTitle="Cancel" confirmButtonTitle={companiesModal.modalConfirmButtonTitle} disableConfirmButton={companiesModal.disableConfirmButton} />;

  const handleSelect = (activeKey) => {
    setCompaniesModal({ ...companiesModal, activeKey });
  };

  const { companies, user } = sessionState;
  const { activeKey, showModal, modalTitle, modalBody, disableConfirmButton, modalConfirmButtonTitle, modalCancelButtonTitle, modalOnConfirm } = companiesModal;
  return (
    <div>
      <div className="companies-component">
        <h3>Companies</h3>
        <span className="link" onClick={() => thisShowModal(ACTIONS.add)}>
          Add
        </span>
        <div>{Object.keys(user).length > 0 && <CompanyPanelGroupComponent activeKey={activeKey} handleSelect={handleSelect} companies={companies} onEdit={thisShowModal} viewLicenses={thisViewLicenses} user={user} />}</div>
      </div>
      <ModalComponent dialogClassName="companies-component-modal" show={showModal} hideFooter={true} handleClose={closeModal} title={modalTitle} body={modalBody} cancelButtonTitle={modalCancelButtonTitle} confirmButtonTitle={modalConfirmButtonTitle} disableConfirmButton={disableConfirmButton} />
    </div>
  );
};

// CompaniesComponent.propTypes = {
//   activePanel: PropTypes.string,
//   companies: PropTypes.array.isRequired,
//   submitCompanyForm: PropTypes.func.isRequired,
// };

export default CompaniesComponent;
