import { Breadcrumb } from "react-bootstrap";

import { MENU, MARKETING_GUIDELINES_MENU } from "../MenuComponent/MenuConstants";
import { HOME_PATH, WEB_API_PATH, ANDROID_API_PATH, IOS_API_PATH, MARKETING_GUIDELINES_PATH, PROFILE_PATH, LICENSES_PATH, COMPANIES_PATH, SUPPORT_PATH, OVERVIEW_PATH, GUIDES_PATH, INTRODUCTION_GUIDES_PATH, MMS_GUIDES_PATH, REAL_TIME_GUIDES_PATH } from "../../../constants/routes";

import "./breadcrumbs.scss";
import { useEffect, useState } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";

const BREADCRUMBS_SKIP = ["auth"];

const BREADCRUMBS = {
  api: {
    title: "Home",
    url: HOME_PATH,
  },
  android: {
    title: "Android API",
    url: `${ANDROID_API_PATH}/`,
  },
  ios: {
    title: "iOS API",
    url: `${IOS_API_PATH}/`,
  },
  web: {
    title: "Web API",
    url: `${WEB_API_PATH}/`,
  },
  marketing: {
    title: "Marketing Guidelines",
    url: `${MARKETING_GUIDELINES_PATH}/`,
  },
  profile: {
    title: "Home",
    url: HOME_PATH,
  },
  update: {
    title: "Profile",
    url: `${PROFILE_PATH}/`,
  },
  licenses: {
    title: "Licenses",
    url: `${LICENSES_PATH}/`,
  },
  companies: {
    title: "Companies",
    url: `${COMPANIES_PATH}/`,
  },
  support: {
    title: "Support",
    url: `${SUPPORT_PATH}/`,
  },
  overview: {
    title: "Overview",
    url: `${OVERVIEW_PATH}/`,
  },
  guides: {
    title: "Guides",
    url: `${GUIDES_PATH}/introduction`,
  },
  introduction: {
    title: "Introduction",
    url: `${INTRODUCTION_GUIDES_PATH}/`,
  },
  "mms-integration": {
    title: "MMS Integration",
    url: `${MMS_GUIDES_PATH}/`,
  },
  "real-time-availability": {
    title: "Real-Time Availability",
    url: `${REAL_TIME_GUIDES_PATH}/`,
  },
  ...MENU,
  ...MARKETING_GUIDELINES_MENU,
};

const BreadcrumbsComponent = ({ dependency }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  useEffect(() => {
    setup(location);
  }, [location]);

  const setup = (location) => {
    const items = [];
    let prevPath = "";
    location.pathname.split("/").forEach((pathname) => {
      if (pathname && !BREADCRUMBS_SKIP.includes(pathname)) {
        let path = pathname;
        let breadcrumb = BREADCRUMBS[path];

        if (!breadcrumb) {
          breadcrumb = BREADCRUMBS[prevPath].subMenu[path];
          path = `${prevPath}/${path}`;
        }

        const { title, url } = breadcrumb;
        items.push({
          title,
          url: url || path,
        });
        prevPath = path;
      }
    });

    setItems([...items]);
  };

  return (
    <div className="Breadcrumbs-component">
      <Breadcrumb>
        {items.map(({ url, title }, i) => {
          const active = i === items.length - 1;
          return (
            <Breadcrumb.Item key={i} active={active} onClick={() => !active && navigate(url)}>
              <span className={active ? "breadcrumb-title-active" : "breadcrumb-title"}>{title}</span>
              {!active && <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbsComponent;
