import { GetDownloadURL, ref, get, child } from "../config/firebase";

export function getUrl(path) {
  return GetDownloadURL(path);
  // return ref
  //   .child(path)
  //   .once("value")
  //   .then((snapshot) => storageRef.child(snapshot.val().name).getDownloadURL());
}

export function getArchiveFiles(path, version) {
  return get(child(ref, path)).then(async (snapshot) => {
    const archives = snapshot.val()["Archives"];
    let resultArchives = {};
    const latestVersion = snapshot.val()["latest"];
    let versionVisible = latestVersion;
    if (version !== "latest") {
      versionVisible = version;
    }

    for (const key in archives) {
      if (archives.hasOwnProperty(key)) {
        const archive = archives[key];
        const sdk = archive.SDK;
        const version = key.replace(/-/g, ".");
        let sdkDownloadUrl = sdk.name;
        let docDownloadUrl = archive.Documentation.name;
        if (!path.endsWith("Web")) {
          sdkDownloadUrl = await GetDownloadURL(sdk.name);
          docDownloadUrl = await GetDownloadURL(archive.Documentation.name);
        }

        resultArchives[version] = {
          doc: {
            downloadUrl: docDownloadUrl,
          },
          sdk: {
            apiName: sdk.apiName,
            size: sdk.size,
            updated: sdk.updated,
            notes: sdk.notes,
            downloadUrl: sdkDownloadUrl, //storageRef.child(sdk.name).getDownloadURL(),
            compatibility: sdk.compatibility,
            version: key,
            isSelected: key === versionVisible,
          },
        };
      }
    }

    return resultArchives;
  });
}

export function getDataTypes() {
  get(child(ref, "DATA_TYPES"))
    .then(async (snapshot) => {
      console.log("***** snapshot: ", snapshot);
      const DATA_TYPES_ORDER = ["user", "workoutresult", "workoutpreset"];
      const dataTypes = snapshot.val() || {};

      for (const namespaceName of DATA_TYPES_ORDER) {
        console.log("***** namespaceName: ", namespaceName);
        const namespace = dataTypes[namespaceName];

        //namespace.xmlSchemaDownloadUrl = await storageRef.child(namespace.xmlSchemaFilePath).getDownloadURL();
        namespace.xmlSchemaDownloadUrl = GetDownloadURL(namespace.xmlSchemaFilePath);
      }

      return DATA_TYPES_ORDER.map((namespaceName) => ({
        ...dataTypes[namespaceName],
        name: namespaceName,
      }));
    })
    .catch((error) => {
      console.error(error);
    });

  // return ref.child('DATA_TYPES').once('value').then(async (snapshot) => {
  //     const DATA_TYPES_ORDER = ['user', 'workoutresult', 'workoutpreset'];
  //     const dataTypes = snapshot.val() || {};

  //     for (const namespaceName of DATA_TYPES_ORDER) {
  //         const namespace = dataTypes[namespaceName];

  //         namespace.xmlSchemaDownloadUrl = await storageRef.child(namespace.xmlSchemaFilePath).getDownloadURL();
  //     }

  //     return DATA_TYPES_ORDER.map((namespaceName) => ({
  //         ...dataTypes[namespaceName],
  //         name: namespaceName,
  //     }));
  // });
}
