import OverviewComponent from "../../components/developerApi/developerSdk/Overview/OverviewComponent";

import "./overviewLayout.scss";

const OverviewLayout = () => {
  return (
    <div className="overview-layout">
      <h2>Getting Started</h2>
      <OverviewComponent />
    </div>
  );
};

export default OverviewLayout;
