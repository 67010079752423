module.exports = {
  firebase: {
    apiKey: "AIzaSyAg2XP5jCo_witF3H6y__8bg0t0q6WFhf4",
    authDomain: "partnerapi-dev.firebaseapp.com",
    databaseURL: "https://partnerapi-dev.firebaseio.com",
    storageBucket: "partnerapi-dev.appspot.com",
  },
  cloudFunctionsUrl: "https://developers-qa1.halo.fitness",
  captcha: {
    //Can be get after registration at https://www.google.com/recaptcha/admin
    siteKey: "6Lc7GPgpAAAAAIpZS5rOoI_QuW_Y0hUxyshhEUpt",
  },
  apiUrl: "https://api-vtqa.halo.fitness/partnerapi/api",
  qaApiUrl: "https://api-qa1.halo.fitness/partnerapi/api",
  restURL: "https://apiconsole.halo.fitness/apis",
  storageURL: "https://firebasestorage.googleapis.com/v0/b/partnerapi-dev.appspot.com/o/Web%2FDocumentation%2FRealTimeCallback%20documentation_v3.pdf?alt=media&token=a8ad70e6-98f3-442a-98dc-7a6912fa5af0",
  GA_ANALYTICS: "UA-132570787-1",
  termsAndConditionsURL: "https://dev.facility.lifefitness.com/static-apps/legal-pages/en_US?developerMode=true",
};
