import { Outlet } from "react-router-dom";

import { doCredentialsExist } from "../../../storage/localStorage";

const AuthRouteComponent = () => {
  return <Outlet />;
};

export default AuthRouteComponent;

// export const authRouteComponentWillMount = async () => {
//   console.log("***** DELETEME authRouteComponentWillMount() *****");
//   if (doCredentialsExist) {
//     console.log("***** DELETEME credentials exist");
//   }
//   return true;
// };
