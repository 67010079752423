import BaseApi from './BaseApi';

class UpdateProfileApi {
    constructor() {
        this.api = new BaseApi();
        this.headers = {
            'Content-Type': 'application/json',
        };
    }

    update(user) {
        return this.api.post('developer/update_developer', JSON.stringify(user), this.headers);
    }
}

export default new UpdateProfileApi();
