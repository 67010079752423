import { isLoadingHandler } from '../Utils';

export const IS_LOADING = 'TEAM_MEMBERS_IS_LOADING';
export const SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS';
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';
export const UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER';
export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER';

const INITIAL_STATE = {
    isLoading: false,
    items: [],
};

const ACTION_HANDLERS = {
    [IS_LOADING]: isLoadingHandler,
    [SET_TEAM_MEMBERS]: (state, { payload: { items } }) => ({
        ...state,
        items,
    }),
    [ADD_TEAM_MEMBER]: (state, { payload: { member } }) => ({
        ...state,
        items: [...state.items, member],
    }),
    [UPDATE_TEAM_MEMBER]: (state, { payload: { member } }) => ({
        ...state,
        items: state.items.map((currentMember) => currentMember.uid === member.uid ? member : currentMember),
    }),
    [DELETE_TEAM_MEMBER]: (state, { payload: { memberId } }) => ({
        ...state,
        items: state.items.filter((member) => member.uid !== memberId),
    }),
};

export default (state = INITIAL_STATE, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
