import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TEAM_MEMBERS } from "../Constants";
import { createMember, getMembers, removeMember, resendInvitationMemberEmail, updateMember } from "../../firebase/cloud-functions";
import { getUser } from "../../firebase/user";
import { getUserId } from "../../storage/localStorage";
import { toast } from "react-toastify";
import { setGridItemsAction } from "./gridSlice";
import updateProfileApi from "../../api/updateProfileApi";

const prepareUser = ({ developerId, email, firstName, lastName }) => ({
  ...(developerId && { id: developerId }),
  email,
  firstName,
  lastName,
});

// INITIAL STATE
const initialState = TEAM_MEMBERS.INITIAL_STATE;

// CREATE ACTIONS
export const initTeamMembersAction = createAsyncThunk(TEAM_MEMBERS.ACTION.INIT_TEAM_MEMBERS, async (payload, { rejectWithValue, getState, dispatch }) => {
  return;
});

export const getTeamMembersAction = createAsyncThunk(TEAM_MEMBERS.ACTION.GET_TEAM_MEMBERS, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { companyId } = payload;
    const response = await getMembers(companyId);
    let getLoggedInMember = await getUser(getUserId());

    if (response.ok) {
      let members = await response.json();
      console.log("***** BEFORE teamMembersSlice.getTeamMembersAction().members: ", members);
      members[getLoggedInMember.uid] = getLoggedInMember;
      members = Object.keys(members).map((id) => ({
        ...members[id],
      }));
      console.log("***** AFTER teamMembersSlice.getTeamMembersAction().members: ", members);
      return members;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createTeamMemberAction = createAsyncThunk(TEAM_MEMBERS.ACTION.CREATE_TEAM_MEMBER, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { companyId } = payload;

    const currentDate = Date.now();
    const member = {
      ...payload.member,
      companies: {},
      createdAt: currentDate,
      updatedAt: currentDate,
    };

    member.companies[companyId] = { companyId: companyId, isAdmin: member.role.value === "Admin", status: "Pending" };
    delete member.role;

    const response = await createMember(member, companyId);
    if (response.ok) {
      const newMember = await response.json();
      member.uid = newMember.uid;
      member.firstName = newMember.firstName;
      member.lastName = newMember.lastName;

      toast(TEAM_MEMBERS.SESSION.RESPONSE.MESSAGE.SUCCESSFUL_CREATE_TEAM_MEMBER);

      const __items__ = [...payload.members, member];
      // dispatch(setGridItemsAction({ name: "team-members", items: __items__ }));
      return __items__;
    } else if (response.status === TEAM_MEMBERS.SESSION.RESPONSE.STATUS_CODE.UNPROCESSABLE_ENTITY_ERROR_CODE) {
      const __error__ = {
        email: await response.text(),
      };
      throw __error__;
    }
  } catch (error) {
    console.log("***** createTeamMemberAction.error: ", error);
    return rejectWithValue(error);
  }
});

export const editTeamMemberAction = createAsyncThunk(TEAM_MEMBERS.ACTION.EDIT_TEAM_MEMBER, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { members, memberId, member, companyId } = payload;

    //check if the member has a field : developerId, then
    //call the updateProfileApi
    if (member.developerId) {
      let developer = {
        ...prepareUser(member),
      };
      await updateProfileApi.update(developer);
    }

    const response = await updateMember(memberId, member, companyId);

    if (response.ok) {
      toast(`${member.firstName} ${member.lastName} ${TEAM_MEMBERS.SESSION.RESPONSE.MESSAGE.SUCCESSFUL_UPDATE_TEAM_MEMBER}`);
      delete member.role;
      const __items__ = members.map((m) => (m.uid === memberId ? { ...m, ...member } : m));
      // dispatch(setGridItemsAction({ name: "team-members", items: __items__ }));
      return __items__;
    } else if (response.status === TEAM_MEMBERS.SESSION.RESPONSE.STATUS_CODE.UNPROCESSABLE_ENTITY_ERROR_CODE) {
      const __error__ = {
        email: await response.text(),
      };
      throw __error__;
    }
  } catch (error) {
    console.log("***** editTeamMemberAction.error: ", error);
    return rejectWithValue(error);
  }
});

export const removeTeamMemberAction = createAsyncThunk(TEAM_MEMBERS.ACTION.REMOVE_TEAM_MEMBER, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { firstName, lastName, uid, currentCompanyId } = payload.member;
    const response = await removeMember(uid, currentCompanyId);
    if (response.ok) {
      toast(`${firstName} ${lastName} ${TEAM_MEMBERS.SESSION.RESPONSE.MESSAGE.SUCCESSFUL_DELETE_TEAM_MEMBER}`);
      const __items__ = payload.members.filter((m) => m.uid !== uid);
      dispatch(setGridItemsAction({ name: "team-members", items: __items__ }));
      return __items__;
    } else throw new Error(await response.text());
  } catch (error) {
    console.log("***** removeTeamMemberAction.error: ", error);
    return rejectWithValue(error);
  }
});

export const resendInvitationAction = createAsyncThunk(TEAM_MEMBERS.ACTION.RESEND_INVITATION, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { uid, currentCompanyId } = payload;
    const response = await resendInvitationMemberEmail(uid, currentCompanyId);

    if (response.ok) {
      toast("The invitation was successfully resent.");
    } else {
      toast("There was an error while sending the email. Please try to resend later.");
    }
  } catch (error) {
    console.log("***** resendInvitationAction.error: ", error);
    return rejectWithValue(error);
  }
});

// CREATE SLICE
const teamMembersSlice = createSlice({
  name: TEAM_MEMBERS.SLICE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(initTeamMembersAction.pending, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(initTeamMembersAction.fulfilled, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(initTeamMembersAction.rejected, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getTeamMembersAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.items = [];
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getTeamMembersAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
      state.items = action.payload;
    });

    builder.addCase(getTeamMembersAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.items = [];
    });

    builder.addCase(createTeamMemberAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(createTeamMemberAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.items = action.payload;
    });

    builder.addCase(createTeamMemberAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(editTeamMemberAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(editTeamMemberAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.items = action.payload;
    });

    builder.addCase(editTeamMemberAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(removeTeamMemberAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(removeTeamMemberAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.items = [...action.payload];
    });

    builder.addCase(removeTeamMemberAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(resendInvitationAction.pending, (state) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(resendInvitationAction.fulfilled, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(resendInvitationAction.rejected, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });
  },
});

// CREATE REDUCER
const teamMembersReducer = teamMembersSlice.reducer;
export default teamMembersReducer;
