// import React from 'react'
// import PropTypes from 'prop-types'
import { Form } from "react-bootstrap";
import "../fieldGroup.scss";
import { useState } from "react";

const SearchComponent = (props) => {
  const { input, type, formProps, className, readOnly, onChange, value } = props;
  let passwordField = input.name === "confirmPassword" || input.name === "password" || input.name === "currentPassword";
  let searchField = input.name === "search";

  return (
    <>
      {passwordField && (
        <div className="pwdContainer">
          <Form.Control type="password" {...formProps} {...input} readOnly={readOnly} className={className !== "form-group" && className} />
        </div>
      )}
      {!passwordField && (
        <div className={searchField ? "searchContainer" : ""}>
          {searchField && <span className="searchField" />}
          {!onChange && <Form.Control type={type} {...formProps} {...input} readOnly={readOnly} className={className !== "form-group" && className} value={value} />}
          {onChange && <Form.Control type={type} {...formProps} {...input} readOnly={readOnly} className={className !== "form-group" && className} value={value} onChange={onChange} />}
        </div>
      )}
    </>
  );
};

export default SearchComponent;
