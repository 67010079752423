import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { CATEGORIES, DESCRIPTION_LIMIT } from "./LicenseConstants";
import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";
import { ACTIONS, ENVIRONMENTS } from "../../../constants/common";

import "./licenseForm.scss";
import { Field, Form } from "react-final-form";
import { getValidationRules } from "../../../validation/validation";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeLicenseAction, initLicensesAction } from "../../../redux/slice/licensesSlice";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";

const LicenseFormComponent = (props) => {
  const sessionState = useSelector((__state__) => __state__.session);
  const licensesState = useSelector((__state__) => __state__.licenses);
  const dispatch = useDispatch();

  useEffect(() => {
    if (licensesState.fulfilled === true) {
      props.close();
    }
  }, [licensesState.fulfilled]);

  const [thisInitialValues, setThisInitialValues] = useState({ ...props.initialValues, category: { label: props.initialValues?.category, value: props.initialValues?.category } });
  const [inputDescription, setInputDescription] = useState(props.initialValues.description);

  //   componentWillMount() {
  //        !this.props.inProgress && this.props.reset();
  //   }

  const onDescriptionChangeHandler = (e) => {
    setInputDescription(e.target.value);
  };
  // const onInputChangeHandler = (field, value) => {
  //   console.log(value);
  //   setThisInitialValues({
  //     ...thisInitialValues,
  //     [field]: value,
  //   });
  // };

  const { action, cancelButtonTitle, confirmButtonTitle, close, modalTitle, isCreateAction, initialValues, platforms, toolTipFlag, changePlatform, page, pageAmount, nextPage } = props;
  const { consumerKey, consumerSecret, env, license, prodDate, platform: selectedPlatform } = initialValues;

  const handleSubmit = (data) => {
    if (page < pageAmount) {
      nextPage(data, action, cancelButtonTitle, confirmButtonTitle, parseInt(page) + 1, modalTitle);
    } else {
      props.handleSubmit(data);
    }
  };

  return (
    <>
      {licensesState.pending && <LoadingComponent fullScreen />}
      <Form
        initialValues={thisInitialValues}
        validate={getValidationRules(["name", "platform", "category", "description", "redirectUrl"])}
        onSubmit={handleSubmit}
        render={(props) => (
          <form onSubmit={props.handleSubmit}>
            {toolTipFlag && <span className="toolTip">Licenses moved to Production are active and editable in Dev environment.</span>}
            <FieldGroupComponent
              label="App Name"
              id="name"
              name="name"
              type="text"
              formProps={{
                maxLength: 100,
              }}
              // onChange={(e) => onInputChangeHandler("name", e.target.value)}
            />
            {isCreateAction && (
              <FieldGroupComponent
                label="Enrollment Type"
                name="platform"
                placeholder="Select"
                type="select"
                formProps={{
                  id: "platform",
                  options: platforms,
                  onChange: (e) => {
                    changePlatform(e, license, action, cancelButtonTitle, confirmButtonTitle, page, modalTitle);
                  },
                }}
              />
            )}
            {!isCreateAction && (
              <>
                <div className="form-group">
                  <label>Enrollment Type</label>
                  <br />
                  <span>{selectedPlatform}</span>
                </div>
                <div className="form-group">
                  <label>License Number</label>
                  <br />
                  <span>{license}</span>
                </div>
                {consumerKey && (
                  <div className="form-group">
                    <label>OAuth Client ID</label>
                    <br />
                    <span>{consumerKey}</span>
                  </div>
                )}
                {consumerSecret && (
                  <div className="form-group">
                    <label>OAuth Client Secret</label>
                    <br />
                    <span>{consumerSecret}</span>
                  </div>
                )}
              </>
            )}
            <FieldGroupComponent
              label="Category"
              name="category"
              placeholder="Select"
              type="select"
              formProps={{
                id: "category",
                options: CATEGORIES,
                // onChange: (e) => {
                //   console.log(e);
                //   onInputChangeHandler("category", e);
                // },
              }}
            />
            <FieldGroupComponent label="Redirect URL" id="redirectUrl" name="redirectUrl" type="text" />
            {!isCreateAction && env === ENVIRONMENTS.prod && (
              <div className="form-group">
                <label>Production Date</label>
                <br />
                {prodDate && <span>{moment(prodDate).format("MMM DD, YYYY").valueOf()}</span>}
                {!prodDate && <span>{moment().format("MMM DD, YYYY").valueOf()}</span>}
              </div>
            )}
            <FieldGroupComponent
              onChange={onDescriptionChangeHandler}
              className="description"
              label="Description"
              id="description"
              name="description"
              type="textarea"
              formProps={{
                id: "description",
                placeholder: "Enter a description about your app.",
                maxLength: DESCRIPTION_LIMIT,
              }}
            />
            <div className="form-group">
              <span className="description-limit">You have {isNaN(DESCRIPTION_LIMIT - inputDescription?.length) ? DESCRIPTION_LIMIT : DESCRIPTION_LIMIT - inputDescription?.length} character(s) left.</span>
            </div>
            <div className="buttons">
              <Button className="btn btn-default dark" onClick={close}>
                {cancelButtonTitle}
              </Button>
              <Button disabled={props.invalid || props.submitting} type="submit">
                {`${page < pageAmount ? "Next" : confirmButtonTitle}`}
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
};

LicenseFormComponent.defaultProps = {
  description: "",
};

// LicenseFormComponent.propTypes = {
//   isCreateAction: PropTypes.bool.isRequired,
//   description: PropTypes.string,
//   selectedPlatform: PropTypes.string,
//   initialValues: PropTypes.object.isRequired,
//   platforms: PropTypes.array.isRequired,
//   toolTipFlag: PropTypes.bool,
//   reset: PropTypes.func,
//   inProgress: PropTypes.bool,
//   changePlatform: PropTypes.func,
// };

export default LicenseFormComponent;
