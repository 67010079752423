import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children, elementId, indentElementId, isAccordion }) => {
  const location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return <>{children}</>;
};

export default ScrollToTop;
