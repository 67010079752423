import React, { useEffect } from "react";
import PropTypes from "prop-types";
// import { Switch, Route } from "react-router";
import { useParams } from "react-router-dom";

import CompaniesContainer from "../../containers/CompaniesContainer";
import UpdateProfileContainer from "../../containers/UpdateProfileContainer";

import MenuComponent from "../core/MenuComponent/MenuComponent";
import { PROFILE_MENU } from "../core/MenuComponent/MenuConstants";
// import ScrollToTop from "../../ScrollToTop";
import LoadingComponent from "../core/LoadingComponent/LoadingComponent";
import BreadcrumbsComponent from "../core/BreadcrumbsComponent/BreadcrumbsComponent";

import { LICENSES_PATH, COMPANIES_PATH, UPDATE_PROFILE_PATH, SUPPORT_PATH } from "../../constants/routes";

import "./profile.scss";
import { useSelector } from "react-redux";
import { setCompanyId } from "../../storage/localStorage";
import SupportComponent from "./Support/SupportComponent";
import LicensesTeamContainer from "../../containers/LicensesTeamContainer";

const ProfileComponent = (props) => {
  const companiesState = useSelector((__state__) => __state__.companies);
  const sessionState = useSelector((__state__) => __state__.session);

  const params = useParams();

  const companies = sessionState.companies;
  useEffect(() => {
    //select the default company and get members and licenses accordingly
    if (companies && companies[0]) {
      setCompanyId(companies[0].firebaseId);
    }
  }, []);

  const getMenuItems = (menuItems) =>
    Object.keys(menuItems)
      .filter((keyItem) => {
        const { userRole } = props;
        //const { companyRequired, accessDenied } = menuItems[keyItem];
        const { accessDenied } = menuItems[keyItem];

        return !accessDenied || accessDenied.indexOf(userRole) === -1;
        //return (!companyRequired || isCompanySelected) && (!accessDenied || accessDenied.indexOf(userRole) === -1);
      })
      .map((keyItem) => ({
        ...menuItems[keyItem],
        url: "/auth/profile/" + keyItem,
        active: params.menuItem === keyItem,
      }));

  // const MEMBER_HOME_PATH = `/api/${PLATFORM_MAP[availablePlatform]}/started`;
  const { menuItem } = params;

  // EPEDRO
  // if (!menuItem) {
  //   return <Redirect to={LICENSES_PATH} />;
  // }

  if (companiesState.pending) {
    return <LoadingComponent fullScreen />;
  }

  return (
    <>
      {companiesState.pending && <LoadingComponent fullScreen />}
      <div id="profile" className="profile-component container">
        <div className="content">
          <div className="sticky-wrapper">
            <BreadcrumbsComponent />
            <MenuComponent menuTitle="Account" menuItems={getMenuItems(PROFILE_MENU)} />
          </div>
          <div className="content-wrapper">
            {UPDATE_PROFILE_PATH.endsWith(menuItem) && <UpdateProfileContainer />}
            {COMPANIES_PATH.endsWith(menuItem) && <CompaniesContainer />}
            {LICENSES_PATH.endsWith(menuItem) && <LicensesTeamContainer />}
            {SUPPORT_PATH.endsWith(menuItem) && <SupportComponent />}
            {/* <ScrollToTop elementId="profile">
                <Switch>
                  <Route path={LICENSES_PATH} component={LicensesTeamContainer} />
                  <Route path={UPDATE_PROFILE_PATH} component={UpdateProfileContainer} />
                  <Route path={COMPANIES_PATH} component={CompaniesContainer} />
                  <Route path={SUPPORT_PATH} component={SupportContainer} />
                  <RedirectRoute />
                </Switch>
              </ScrollToTop> */}
          </div>
        </div>
      </div>
    </>
  );
};

// ProfileComponent.propTypes = {
//   match: PropTypes.object.isRequired,
//   userRole: PropTypes.string,
//   availablePlatform: PropTypes.string,
// };

export default ProfileComponent;
