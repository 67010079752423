import { redirect, useLocation, useNavigate, useParams } from "react-router-dom";

import { OVERVIEW_MENU, WEB_MENU, IOS_MENU, ANDROID_MENU } from "../components/core/MenuComponent/MenuConstants";
import DeveloperApiComponent from "../components/developerApi/DeveloperApiComponent";
import { useEffect } from "react";
import OverviewLayout from "../layouts/Overview/OverviewLayout";
import GettingStartedLayout from "../layouts/GettingStarted/GettingStartedLayout";
import AuthenticationLayout from "../layouts/Authentication/AuthenticationLayout";
import PubSubLayout from "../layouts/Structure/PubSubLayout";
import SubscriptionLayout from "../layouts/Subscription/SubscriptionLayout";
import DocumentationLayout from "../layouts/Documentation/DocumentationLayout";
import WorkoutPresetLayout from "../layouts/WorkoutPreset/WorkoutPresetLayout";
import WebArchivesContainer from "../containers/WebArchivesContainer";
import DataTypesContainer from "../containers/DataTypesContainer";
import CompatibilityContainer from "../containers/CompatibilityContainer";
import ArchivesContainer from "../containers/ArchivesContainer";

const menuItemsByApi = {
  overview: {
    ...OVERVIEW_MENU,
  },
  web: {
    ...WEB_MENU,
  },
  ios: {
    ...IOS_MENU,
  },
  android: {
    ...ANDROID_MENU,
  },
};

const apis = Object.keys(menuItemsByApi);

const DeveloperApiRoute = (props) => {
  const params = useParams();
  const api = params.api || "overview";
  const location = useLocation();
  const navigate = useNavigate();

  const fixedApi = api && apis.indexOf(api) > -1 ? api : apis[0];
  const pathParts = location.pathname.split("/");
  const menuItem = menuItemsByApi[fixedApi][pathParts[4]];
  const subMenuItemName = pathParts[4];

  useEffect(() => {
    if (api === fixedApi && ((fixedApi === "overview" && pathParts[4] === "started") || (menuItem && (!subMenuItemName || (menuItem?.subMenu && menuItem?.subMenu[subMenuItemName]))))) {
      console.log("***** return fixedApi: ", fixedApi);
      console.log("***** return api: ", api);
      console.log("***** return menuItem: ", menuItem);
      console.log("***** return subMenuItem: ", subMenuItemName);
    } else {
      console.log("***** navigate ", fixedApi);
      navigate(`/auth/api/${fixedApi}/started`, { replace: true });
    }
  }, []);

  return (
    <>
      {/* <div>LOCATION PATHNAME -{location.pathname}</div>
      <div>FIXED API - {fixedApi}</div>
      <div>API - {api}</div>
      <div>SUBMENU ITEM NAME - {subMenuItemName}</div>
      <div>PATH PARTS[4] - {pathParts[4]}</div> */}
      <DeveloperApiComponent tab={api}>
        {fixedApi === "overview" && <OverviewLayout />}
        {api !== "overview" && (pathParts[4] === "started" || !subMenuItemName) && <GettingStartedLayout />}
        {subMenuItemName === "archives" && <WebArchivesContainer api={api} />}
        {subMenuItemName === "authentication" && <AuthenticationLayout />}
        {subMenuItemName === "compatibility" && <CompatibilityContainer />}
        {subMenuItemName === "data-types" && <DataTypesContainer />}
        {subMenuItemName === "documentation" && <DocumentationLayout />}
        {subMenuItemName === "structure" && <PubSubLayout />}
        {subMenuItemName === "subscription" && <SubscriptionLayout />}
        {subMenuItemName === "workout-preset" && <WorkoutPresetLayout />}
        {subMenuItemName === "sdk" && <ArchivesContainer api={api} />}
      </DeveloperApiComponent>
    </>
  );
};

export default DeveloperApiRoute;
