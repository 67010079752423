import { isLoadingHandler } from '../Utils';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const IS_LOADING = 'SESSION_IS_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const COOKIE_POLICY_BANNER = 'COOKIE_POLICY_BANNER';
export const BETA_MESSAGING_BANNER = 'BETA_MESSAGING_BANNER';

const INITIAL_STATE = {
    isLoading: true,
    isAuthenticated: false,
    token: '',
    user: {},
    showCookiePolicyBanner: false,
    showBetaMessagingBanner: true,
    staySignedIn: false,
};

const ACTION_HANDLERS = {
    [IS_LOADING]: isLoadingHandler,
    [SIGN_IN]: (state, { payload: { token, user, staySignedIn } }) => ({
        ...state,
        isAuthenticated: !!token,
        token: token,
        user: user,
        staySignedIn,
    }),
    [SIGN_OUT]: (state) => ({
        ...state,
        ...INITIAL_STATE,
    }),
    [UPDATE_USER]: (state, { payload: { params } }) => ({
        ...state,
        user: { ...state.user, ...params },
    }),
    [COOKIE_POLICY_BANNER]: (state, { payload: showCookiePolicyBanner }) => {
        return {
            ...state,
            showCookiePolicyBanner,
        }
    },
    [BETA_MESSAGING_BANNER]: (state, { payload: showBetaMessagingBanner }) => {
        return {
            ...state,
            showBetaMessagingBanner,
        }
    },
};

export default (state = INITIAL_STATE, action) => {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
};
