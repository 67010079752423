import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";

import "./update-profile.scss";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-final-form";
import { COMMON_RULES, getValidationRules } from "../../../validation/validation";
import SIGN_IN_RULES from "../../../validation/validationRules/signIn";
import { updateEmailAction } from "../../../redux/slice/sessionSlice";

const UpdateEmailComponent = (props) => {
  const sessionState = useSelector((__state__) => __state__.session);
  const dispatch = useDispatch();
  const { initialValues } = props;

  const handleSubmit = (e) => {
    console.log(JSON.stringify(e));
    dispatch(
      updateEmailAction({
        ...e,
        userId: e.uid,
        prevEmail: sessionState.user.email,
      })
    );
  };

  const validateEmail = () => {
    const email = sessionState.user.email;
    return {
      name: "NewEmail",
      func: (values) => values.email.trim() === email,
      message: "Please enter a different email address.",
    };
  };

  return (
    <>
      {sessionState.pending && <LoadingComponent fullscreen />}
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={getValidationRules(["email", "password"], { ...SIGN_IN_RULES, email: [...COMMON_RULES.email, validateEmail()] })}
        render={(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <FieldGroupComponent
                label="Email"
                name="email"
                type="text"
                required={true}
                submissionError={sessionState.rejected && sessionState.error}
                formProps={{
                  maxLength: 50,
                }}
              />
              <FieldGroupComponent
                label="Password"
                name="password"
                type="password"
                required={true}
                submissionError={sessionState.rejected && sessionState.error}
                formProps={{
                  maxLength: 30,
                }}
              />
              <div class="buttons">
                <Button disabled={props.invalid || props.submitting} type="submit" className="tertiary">
                  Update
                </Button>
              </div>
            </form>
          );
        }}
      />
    </>
  );
};

export default UpdateEmailComponent;
