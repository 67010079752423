import { Button } from "react-bootstrap";
import ReactGA from "react-ga";

import "./signUp.scss";
import FormTitleComponent from "../../../components/core/FormTitleComponent/FormTitleComponent";
import SignUpFormFieldsLayout from "../../../layouts/SignUp/SignUpFormFieldsLayout";
import { SIGN_UP_PATH, SIGN_IN_PATH } from "../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import { getValidationRules } from "../../../validation/validation";
import SIGN_UP_RULES from "../../../validation/validationRules/signUp";
import { useSelector } from "react-redux";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";

const SignUpPersonalComponent = (props) => {
  const navigate = useNavigate();
  const sessionState = useSelector((__state__) => __state__.session);

  const handleSubmit = (e) => {
    console.log("***** signuppersonalcomponent handleSubmit e: ", e);
    props.onSubmit(e);
  };

  const initialValues = () => {
    console.log("***** sessionState.user: ", sessionState.user);
    return {
      firstName: sessionState.user.firstName || "",
      lastName: sessionState.user.lastName || "",
      email: sessionState.user.email || "",
      password: sessionState.user.password || "",
      confirmPassword: sessionState.user.confirmPassword || "",
    };
  };

  const goToSignInPage = () => {
    ReactGA.event({
      category: "Sign In Component",
      action: "Clicked Login",
      label: "Button",
    });
    navigate(SIGN_IN_PATH);
  };

  return (
    <>
      {sessionState.pending && <LoadingComponent fullScreen />}
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues()}
        validate={getValidationRules(["email", "password", "confirmPassword", "firstName", "lastName"], Object.assign(SIGN_UP_RULES))}
        render={(props) => (
          <form onSubmit={props.handleSubmit}>
            <FormTitleComponent title="Personal Information" />
            <SignUpFormFieldsLayout />
            <Button disabled={props.invalid || props.submitting} type="submit" className="next">
              Next
            </Button>
            <div className="divider">
              <hr className="left" />
              <span>Or</span>
              <hr className="right" />
            </div>
            <Button onClick={goToSignInPage} className="tertiary">
              Log In
            </Button>
          </form>
        )}
      />
    </>
  );
};

export default SignUpPersonalComponent;
