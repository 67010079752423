import React, { useEffect } from "react";
import ReleaseNotesComponent from "./ReleaseNotesComponent";
import ReactGA from "react-ga";
import { Button } from "react-bootstrap";

import "./webarchives.scss";
import LinkComponent from "../../../core/LinkComponent/LinkComponent";
import { SIGN_IN_PATH } from "../../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { getArchivesAction } from "../../../../redux/slice/storageSlice";
import LoadingComponent from "../../../core/LoadingComponent/LoadingComponent";

const WebArchivesComponent = (props) => {
  const dispatch = useDispatch();
  const sessionState = useSelector((__state__) => __state__.session);
  const storageState = useSelector((__state__) => __state__.storage);
  const user = sessionState.user;

  const { api, title, content, version } = props;

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Web Archives Page" });
    ReactGA.pageview(window.location.pathname);

    if (user.uid) dispatch(getArchivesAction({ api, version }));
  }, []);
  // constructor(props) {
  //     super(props);

  //     // this.showHideArchives = this.showHideArchives.bind(this);

  //     this.state = {
  //         showAllArchives: false,
  //     }
  // }

  let archives = storageState.fulfilled && storageState.files["web.archives"];
  if (archives === undefined || archives === null) archives = {};

  // showHideArchives = () => {
  //     const { getArchives, api } = this.props;
  //     this.setState({
  //         showArchives: !this.state.showArchives,
  //     });

  //     if (this.state.showArchives) {
  //         getArchives(api, 'latest');
  //     }
  // }

  // if (isLoading) {
  //     return <LoadingComponent fullScreen />;
  // }

  return (
    <>
      {storageState.pending && <LoadingComponent fullScreen />}
      <div className="WebArchives-component">
        <h2>{title}</h2>
        <p className="content">{content}</p>
        {!user.uid && (
          <Button>
            <LinkComponent className="redirectToSignIn" url={SIGN_IN_PATH} title="Log In To View" />
          </Button>
        )}
        {storageState.fulfilled &&
          user.uid &&
          Object.keys(archives)
            .reverse()
            .map((version, index) => <ReleaseNotesComponent key={index} version={version} releaseNotes={archives[version].sdk} />)}
        {user.uid && Object.keys(archives).length > 3 && <span>View More Archives</span>}
      </div>
    </>
  );
  // }
};

// WebArchivesComponent.propTypes = {
//     title: PropTypes.string.isRequired,
//     content: PropTypes.string.isRequired,
//     isLoading: PropTypes.bool.isRequired,
//     archives: PropTypes.object.isRequired,
//     getArchives: PropTypes.func.isRequired,
//     user: PropTypes.object.isRequired,
// }

export default WebArchivesComponent;
