import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ArchiveComponent from "./ArchiveComponent";
import ReactGA from "react-ga";
import { Button } from "react-bootstrap";
import { Form } from "react-final-form";
import "./archives.scss";
import LinkComponent from "../../../core/LinkComponent/LinkComponent";
import LoadingComponent from "../../../core/LoadingComponent/LoadingComponent";
import FieldGroupComponent from "../../../core/FieldGroupComponent/FieldGroupComponent";
import { SIGN_IN_PATH } from "../../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { getArchivesAction } from "../../../../redux/slice/storageSlice";

const ArchivesComponent = (props) => {
  const storageState = useSelector((__state__) => __state__.storage);
  const dispatch = useDispatch();
  const [showArchives, setShowArchives] = useState(false);
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Archives Page" });
    ReactGA.pageview(window.location.pathname);
    if (props.user.uid) dispatch(getArchivesAction({ api: props.api, version: "latest" }));
  }, []);
  // constructor(props) {
  //     super(props);

  //     showHideArchives = showHideArchives.bind(this);

  //     state = {
  //         showArchives: false,
  //     }
  // }

  // componentWillMount() {
  //     const { isLoading, getArchives } = props

  //     if (!isLoading) {
  //         getArchives();
  //     }
  // }
  const { api, title, content, archives, user } = props;

  //   const archives = storageState.fulfilled && storageState.files[`${api}.`];
  //   const user = sessionState.user;
  const showHideArchives = () => {
    setShowArchives(!showArchives);

    if (showArchives) {
      dispatch(getArchivesAction({ api: props.api, version: "latest" }));
    }
  };

  if (storageState.pending) {
    return <LoadingComponent fullScreen />;
  }

  const onVersionSelect = (api, version) => {
    dispatch(getArchivesAction({ api, version }));
  };

  let versionList = Object.keys(archives)
    .map((version) => {
      return archives[version].sdk.apiName;
    })
    .reverse();

  let versionMapping = Object.keys(archives).map((version) => {
    return {
      version: version,
      versionName: archives[version].sdk.apiName,
      isSelected: archives[version].sdk.isSelected,
    };
  });

  return (
    <>
      {storageState.pending && <LoadingComponent fullScreen />}
      {!storageState.rejected && !storageState.pending && (
        <div className="Archives-component">
          <h2>{title}</h2>
          <p className="content">
            {content} &nbsp;
            {user.uid && (
              <span className="blue-text" onClick={() => showHideArchives()}>
                <b>{showArchives ? "Hide" : "View"} Archives</b>
              </span>
            )}
          </p>
          {!user.uid && (
            <Button className="redirectToSignInButton">
              <LinkComponent className="redirectToSignIn" url={SIGN_IN_PATH} title="Log In To Download" />
            </Button>
          )}
          {showArchives && user.uid && (
            <Form
              onSubmit={() => true}
              render={(props) => (
                <div>
                  <form onSubmit={props.handleSubmit}>
                    <FieldGroupComponent required="true" name="sdkVersion" type="select" label="SDK Version" formProps={{ id: "sdkVersion", options: versionList, isLoading: storageState.pending, api: api, onVersionSelect: onVersionSelect, versionMapping: versionMapping }} className="version-select" />
                  </form>
                </div>
              )}
            />
          )}
          <div id="archiveComponent">
            {user.uid &&
              Object.keys(archives)
                .reverse()
                .map((version, index) => archives[version]["sdk"]["isSelected"] && <ArchiveComponent key={index} version={version} archive={archives[version]} />)}
          </div>
        </div>
      )}
    </>
  );
};

ArchivesComponent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  archives: PropTypes.object.isRequired,
  getArchives: PropTypes.func.isRequired,
  api: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default ArchivesComponent;
