import { Form } from "react-bootstrap";
import "./checkbox.scss";

const CheckboxComponent = (props) => {
  const { input, formProps, label } = props;

  return (
    <label className="custom-checkbox">
      <input className="checkbox-input" {...input} {...formProps} />
      <div className="checkbox-icon" />
      {label && <div className="checkbox-placeholder" dangerouslySetInnerHTML={{ __html: label }} />}
    </label>
  );
};

// CheckboxComponent.defaultProps = {
//     checked: false,
// };

// CheckboxComponent.propTypes = {
//     name: PropTypes.string.isRequired,
//     onChange: PropTypes.func.isRequired,
//     checked: PropTypes.bool.isRequired,
//     placeholder: PropTypes.string,
// };

export default CheckboxComponent;
