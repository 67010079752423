import React from "react";
import PropTypes from "prop-types";

// import CONFIG from '../../../config/config';
import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";

import { Button } from "react-bootstrap";
import { Form } from "react-final-form";

import "./update-profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { updatePersonalInfoAction } from "../../../redux/slice/sessionSlice";

const UpdateInfoComponent = (props) => {
  const dispatch = useDispatch();
  const sessionState = useSelector((__state__) => __state__.session);
  const { initialValues, validationRules } = props;

  const handleSubmit = (e) => {
    console.log("handleSubmit e: ", e);
    console.log("handleSubmit sessionState.user: ", sessionState.user);

    dispatch(updatePersonalInfoAction({ user: e }));
  };

  return (
    <>
      {sessionState.pending && <LoadingComponent fullScreen />}
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validationRules}
        render={(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <FieldGroupComponent
                label="First Name"
                name="firstName"
                type="text"
                required={true}
                formProps={{
                  maxLength: 30,
                }}
              />
              <FieldGroupComponent
                label="Last Name"
                name="lastName"
                type="text"
                required={true}
                formProps={{
                  maxLength: 30,
                }}
              />
              <div class="buttons">
                <Button type="submit" disabled={props.invalid || props.submitting} className="tertiary">
                  Update
                </Button>
              </div>
            </form>
          );
        }}
      />
    </>
  );
};

// UpdateInfoComponent.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   submitting: PropTypes.bool,
//   invalid: PropTypes.bool.isRequired,
// };

export default UpdateInfoComponent;
