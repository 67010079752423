import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactGA from "react-ga";
import { Button } from "react-bootstrap";

import LoadingComponent from "../../../core/LoadingComponent/LoadingComponent";
import LinkComponent from "../../../core/LinkComponent/LinkComponent";
//import DataComponent from "./Data/DataComponent";
import NamespaceGridComponent from "./NamespaceGridComponent";
import { DATA_NAMES } from "./DataTypesConstants";
import { SIGN_IN_PATH } from "../../../../constants/routes";

import "./dataTypes.scss";
import { useDispatch, useSelector } from "react-redux";

import { getDataTypesAction } from "../../../../redux/slice/dataTypesSlice";
import DataComponent from "./Data/DataComponent";

const links = {
  xmlSchema: <LinkComponent title="XML Schema" url="https://www.w3.org/XML/Schema" isExternalLink className="blue-text" />,
  json: <LinkComponent title="JSON" url="http://json.org/" isExternalLink className="blue-text" />,
};

const DataTypesComponent = (props) => {
  const dispatch = useDispatch();
  const dataTypesState = useSelector((__state__) => __state__.dataTypes);
  const sessionState = useSelector((__state__) => __state__.session);

  useEffect(() => {
    console.log("****** DataTypesComponent useEffect() *****");
    if (sessionState.user && sessionState.user.uid) dispatch(getDataTypesAction());
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Data Types Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);
  // useEffect(() => {
  //   const { namespaces: newNamespaces } = props;
  //   if (!Object.keys(initialState).length && newNamespaces.length) {
  //     newNamespaces.forEach(({ name }) => {
  //       setInitialState((prevState) => ({ ...prevState, [name]: initialDataState }));
  //     });
  //   }
  // }, [props.namespaces]);

  // componentWillReceiveProps(nextProps) {
  //     const { namespaces: newNamespaces } = nextProps;

  //     if (props.namespaces.length === 0 && newNamespaces.length > 0) {
  //         newNamespaces.forEach(({ name }) => {
  //             initialState[name] = initialDataState;
  //         });

  //         setState(initialState);
  //     }
  // }

  const changeActiveKey = (namespaceName, dataName, activeKey) => {
    console.log("**** changeActiveKey START *****");
    console.log("***** changeActiveKey.namespaceName: ", namespaceName);
    console.log("***** changeActiveKey.dataName: ", dataName);
    console.log("***** changeActiveKey.activeKey: ", activeKey);
    console.log("**** changeActiveKey END *****");
  };

  return (
    <>
      {dataTypesState.pending && !dataTypesState.rejected && <LoadingComponent fullScreen />}
      {!dataTypesState.pending && !dataTypesState.rejected && (
        <div className="data-types">
          <h2>Data Types</h2>
          <p>
            All endpoints act on a common set of data. The data can be represented with difference media (i.e. "MIME") types, depending on the endpoint that consumes and/or produces the data. The data can described by {links.xmlSchema}, which definitively describes the XML representation of the data, but is also useful for describing the other formats of the data, such as {links.json}.
          </p>
          <p>This document will describe the data using terms based on {links.xmlSchema}. Data can be grouped by namespace, with a schema document describing the elements and types of the namespace. Generally speaking, types define the structure of the data and elements are instances of a type. For example, elements are usually produced by (or consumed by) a REST endpoint, and the structure of each element is described by its type.</p>
          {!sessionState.user.uid && (
            <Button className="redirectToSignInButton">
              <LinkComponent className="redirectToSignIn" url={SIGN_IN_PATH} title="Log In To Download" />
            </Button>
          )}
          {sessionState.user.uid &&
            dataTypesState.items.map((namespace, namespaceIndex) => (
              <div className="data-type" key={namespaceIndex}>
                <h4>
                  Namespace <span>"{namespace.name}"</span>
                </h4>
                <NamespaceGridComponent namespace={namespace} name={namespace.name} />
                <DataComponent namespace={namespace} namespaceName={namespace.name} dataNames={[DATA_NAMES.elements, DATA_NAMES.types]} activeKeys={dataTypesState.activeKeys[namespace.name]} setActiveKey={changeActiveKey} />
              </div>
            ))}
        </div>
      )}
    </>
  );
};

// DataTypesComponent.propTypes = {
//   isLoading: PropTypes.bool.isRequired,
//   namespaces: PropTypes.array.isRequired,
//   getDataTypes: PropTypes.func.isRequired,
//   user: PropTypes.object.isRequired,
// };

export default DataTypesComponent;
