import { initializeApp } from "firebase/app";
import SERVICE_ACCOUNT from "./serviceAccountKey.json";
import { child as dbChild, get as dbGet, getDatabase, ref as dbRef, remove as dbRemove, update as dbUpdate, set as dbSet } from "firebase/database";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import CONFIG from "./config";

const firebase_config = {
  ...CONFIG.firebase,
  credential: SERVICE_ACCOUNT,
};

const app = initializeApp(firebase_config, "Halo5_Developers_Portal");
export const db = getDatabase(app);
export const child = dbChild;
export const firebaseAuth = getAuth(app);
export const get = dbGet;
export const remove = dbRemove;
export const update = dbUpdate;
export const ref = dbRef(db);
export const set = dbSet;

export const GetDownloadURL = async (path) => {
  const __storage__ = getStorage(app);
  return getDownloadURL(storageRef(__storage__, path))
    .then((url) => url)
    .catch((error) => {
      throw error;
    });
};
