import React, { useEffect, useRef, useState } from "react";
import { Image, Col } from "react-bootstrap";
import ReactGA from "react-ga";
import "./usecases.scss";
import { ref, getDatabase, child, get } from "firebase/database";
import { db } from "../../config/firebase";

const world = require("./use_cases_for_the_world_v3_660_376.gif");
const useOneImage = require("./Image_UC1_Exerciser_Management_331_227@2X.png");
const useOneExtra = require("./Image_UC1_Exerciser_Facility@3x.png");
const useTwoImage = require("./Image_UC2_Personalized_Experience_331_227@2X.png");
const useTwoExtra = require("./Image_UC2_Trainer_Exerciser@3x.png");
const useThreeImage = require("./Image_UC3_Wokout_Results_331_227@2X.png");
const useThreeExtra = require("./Image_UC3_Exerciser_Trainer@3x.png");
const useFourImage = require("./Image_UC4_SSO_331_227@2X.png");
const useFourExtra = require("./Image_UC4_Exerciser_Console@3x.png");
const dots = require("./dots_texture_768_109@3x.png");

const Usecases = (props) => {
  const [scroll, setScroll] = useState({
    scroll1: false,
    scroll2: false,
    scroll3: false,
    scroll4: false,
  });

  const scrollRef1 = useRef();
  const scrollRef2 = useRef();
  const scrollRef3 = useRef();
  const scrollRef4 = useRef();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Usecases Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  const handleScroll = () => {
    setScroll({
      scroll1: checkVisible(document.getElementsByClassName("use-one")[0]) || scroll["scroll1"],
      scroll2: checkVisible(document.getElementsByClassName("use-two")[0]) || scroll["scroll2"],
      scroll3: checkVisible(document.getElementsByClassName("use-three")[0]) || scroll["scroll3"],
      scroll4: checkVisible(document.getElementsByClassName("use-four")[0]) || scroll["scroll4"],
    });
  };

  const checkVisible = (elm) => {
    if (elm) {
      var rect = elm.getBoundingClientRect();
      var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }
  };

  const onClickHandler = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="Usecases-component">
      <section className="welcome">
        <div className="content">
          <h2 className="strawberry">USE CASES</h2>
          <h2>
            Connect with millions of exercisers <b>worldwide</b>
          </h2>
          <p>Unleash your business potential and extract added value through consumer app engagement or equipment management data. See how you can benefit from APIs offered by the world’s largest fitness equipment manufacturer, Life Fitness.</p>
          <Image className="img-responsive" src={world} />
          <br />
          <br />
        </div>
      </section>
      <section className="icons">
        <Image className="dots" responsive src={dots} />
        <div className="title">
          World-Class integration powered by <br />
          the Developer Connect platform
        </div>
        <div className="row">
          <Col xs={6} md={3} align="center">
            <a onClick={() => onClickHandler(scrollRef1)}>
              <div className="description">
                <h2 className="one">
                  <br />
                  Real-Time Exerciser Management
                </h2>
              </div>
              <span className="carat" />
            </a>
          </Col>
          <Col xs={6} md={3} align="center">
            <a onClick={() => onClickHandler(scrollRef2)}>
              <div className="description">
                <h2 className="two">
                  <br />
                  Personalize the Exerciser Experience
                </h2>
              </div>
              <span className="carat" />
            </a>
          </Col>
          <Col xs={6} md={3} align="center">
            <a onClick={() => onClickHandler(scrollRef3)}>
              <div className="description">
                <h2 className="three">
                  <br />
                  Workout Results
                </h2>
              </div>
              <span className="carat" />
            </a>
          </Col>
          <Col xs={6} md={3} align="center">
            <a onClick={() => onClickHandler(scrollRef4)}>
              <div className="description">
                <h2 className="four">
                  <br />
                  Seamless Sign On
                </h2>
              </div>
              <span className="carat" />
            </a>
          </Col>
        </div>
      </section>
      <div ref={scrollRef1} />
      <section className={"use-one " + (scroll.scroll1 ? "slide" : "")}>
        <div className="content">
          <Image className="img" responsive src={useOneImage} />
          <span className="num">1</span>
          <h2 className="red">USE CASE</h2>
          <h2>Real-Time Exerciser Management</h2>
          <Image className="extra" responsive src={useOneExtra} />
          <ul>
            <li>Real-time notification of fitness equipment occupancy</li>
            <li>Equipment usage statistics for all equipment in fitness facility</li>
            <li>Create dynamic leaderboards</li>
            <li>Cardio equipment reservation</li>
          </ul>
        </div>
      </section>
      <hr />
      <div ref={scrollRef2} />
      <section className={"use-two " + (scroll.scroll2 ? "slide" : "")}>
        <div className="content">
          <Image className="img" responsive src={useTwoImage} />
          <span className="num">2</span>
          <h2 className="red">USE CASE</h2>
          <h2>Personalize the Exerciser Experience</h2>
          <Image className="extra" responsive src={useTwoExtra} />
          <ul>
            <li>Send personalized workout recommended by personal trainers</li>
            <li>Load personalized workouts every time an exerciser logs in</li>
            <li>Send personalized messages to exercisers on the cardio console display</li>
            <li>Collect exerciser feedback triggered by workout events</li>
            <li>Set custom quick speeds according to exerciser preferences</li>
            <li>Video tutorials for strength equipment along with recommended settings</li>
          </ul>
        </div>
      </section>
      <hr />
      <div ref={scrollRef3} />
      <section className={"use-three " + (scroll.scroll3 ? "slide" : "")}>
        <div className="content">
          <Image className="img" responsive src={useThreeImage} />
          <span className="num">3</span>
          <h2 className="red">USE CASE</h2>
          <h2>Workout Results</h2>
          <Image className="extra" responsive src={useThreeExtra} />
          <ul>
            <li>Empowers app/web developers in delivering customized workout results statistics views for exercisers and personal trainers</li>
            <li>Update workout results by adding manual workouts performed by exercisers</li>
            <li>Capability to add GPS workouts manually to track workout progress</li>
          </ul>
        </div>
      </section>
      <hr />
      <div ref={scrollRef4} />
      <section className={"use-four " + (scroll.scroll4 ? "slide" : "")}>
        <div className="content">
          <Image className="img" responsive src={useFourImage} />
          <span className="num">4</span>
          <h2 className="red">USE CASE</h2>
          <h2>Seamless Sign On</h2>
          <Image className="extra" responsive src={useFourExtra} />
          <ul>
            <li>Single sign-on capabilities for any client</li>
            <li>Multiple login approaches available including RFID, QR scan and more</li>
            <li>Manage exercisers’ login and workout experience on compatible Life Fitness equipment</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Usecases;
