import { configureStore } from "@reduxjs/toolkit";
import companiesReducer from "../slice/companiesSlice";
import dataTypesReducer from "../slice/dataTypesSlice";
import sessionReducer from "../slice/sessionSlice";
import compatibilityReducer from "../slice/compatibilitySlice";
import tosReducer from "../slice/tosSlice";
import storageReducer from "../slice/storageSlice";
import gridStoreReducer from "../slice/gridSlice";
import licensesReducer from "../slice/licensesSlice";
import teamMembersReducer from "../slice/teamMembersSlice";

const store = configureStore({
  reducer: {
    companies: companiesReducer,
    compatibility: compatibilityReducer,
    dataTypes: dataTypesReducer,
    gridStore: gridStoreReducer,
    licenses: licensesReducer,
    session: sessionReducer,
    storage: storageReducer,
    teamMembers: teamMembersReducer,
    tos: tosReducer,
  },
});

export default store;
