import BaseApi from './BaseApi';
import { ENVIRONMENTS } from '../constants/common'
import CONFIG from '../config/config';

class FacilityConfigurationApi {
    constructor() {
        this.headers = {
            'Content-Type': 'application/json',
        };
    }

    enableForcedLogin(facilities, licenseEnv) {
        this.api = licenseEnv === ENVIRONMENTS.dev ? new BaseApi(CONFIG.qaApiUrl) : new BaseApi();

        return this.api.post('asset_facilities/enableForcedLogin', JSON.stringify(facilities), this.headers);
    }

    disableForcedLogin(facilities, licenseEnv) {
        this.api = licenseEnv === ENVIRONMENTS.dev ? new BaseApi(CONFIG.qaApiUrl) : new BaseApi();

        return this.api.post('asset_facilities/disableForcedLogin', JSON.stringify(facilities), this.headers);
    }
}

export default new FacilityConfigurationApi();
