import { connect } from "react-redux";
// import { submit } from 'redux-form';
// import { withRouter } from 'react-router';

import { getTeamMembers, removeTeamMember, resendInvitation } from "../store/actions/teamMembers";
import TeamMembersComponent from "../components/profile/TeamMembers/TeamMembersComponent";
import { isUserAdmin } from "./selectors/userSelectors";
import { getCurrentCompanyId } from "./selectors/companySelectors";

const TEAM_MEMBER_FORM = "team-member";

const mapStateToProps = (state, {}) => ({
  members: state.teamMembers.items,
  // isLoading: state.teamMembers.isLoading,
  currentCompanyId: getCurrentCompanyId(state),
  user: state.session.user,
  isUserAdmin: isUserAdmin(state),
});

const mapDispatchToProps = {
  getTeamMembers,
  removeTeamMember,
  resendInvitation,
  // submitMemberForm: () => submit(TEAM_MEMBER_FORM),
};

const mergeProps = ({ currentCompanyId, members, ...stateProps }, { getTeamMembers, removeTeamMember, submitMemberForm, resendInvitation }) => ({
  ...stateProps,
  currentCompanyId,
  members,
  // submitMemberForm,
  getMembers: () => getTeamMembers(currentCompanyId),
  removeMember: removeTeamMember,
  resendInvitation,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TeamMembersComponent);
