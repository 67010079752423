import { Button, FormControl, FormGroup } from "react-bootstrap";
import LoadingComponent from "../core/LoadingComponent/LoadingComponent";
import LinkComponent from "../core/LinkComponent/LinkComponent";
import { COPYRIGHTS_DATE } from "../core/MenuComponent/MenuConstants";
import "./footer.scss";
import CONFIG from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { isTOSUpdatedAction } from "../../redux/slice/tosSlice";

const RESOURCES = [
  {
    title: "Marketing Guidelines",
    url: "/marketing",
  },
];

const OUR_BRANDS = [
  {
    title: "Life Fitness",
    url: "https://lifefitness.com/",
  },
  {
    title: "Hammer Strength",
    url: "https://lifefitness.com/hammer-strength/",
  },
  {
    title: "Cybex",
    url: "http://www.cybexintl.com/",
  },
  {
    title: "Indoor Cycling Group",
    url: "https://www.teamicg.com/",
  },
  {
    title: "SCIFIT",
    url: "http://www.scifit.com/",
  },
];

const FooterComponent = (props) => {
  const dispatch = useDispatch();
  const tosState = useSelector((__state__) => __state__.tos);
  useEffect(() => {
    dispatch(isTOSUpdatedAction());
  }, []);

  const { isLandingOrEntryPage, tosTitle } = props;

  return (
    <>
      {tosState.pending && <LoadingComponent fullScreen />}
      {tosState.fulfilled && (
        <div className={`Footer-component${isLandingOrEntryPage ? " landing" : ""}`}>
          <div className="container">
            {/*Temporarily disable for now*/}
            {/* <div className="signupFooter">
          <form>
            <FormGroup>
              <FormControl
                type="text"
                placeholder="Get Developer Connect API updates"
              />
            </FormGroup>
            <Button>Sign Up</Button>
          </form>
        </div> */}
            <div className="info-wrapper">
              <div className="resources">
                <h4>RESOURCES:</h4>
                {RESOURCES.map((props, i) => (
                  <LinkComponent key={i} {...props} />
                ))}
              </div>
              <div className="brands">
                <h4>OUR BRANDS:</h4>
                {OUR_BRANDS.map((props, i) => (
                  <LinkComponent key={i} {...props} isExternalLink />
                ))}
              </div>
            </div>
          </div>
          <hr />
          <div className="container bottom">
            <div>
              <p>
                ©{COPYRIGHTS_DATE.copyrights_date} Life Fitness.&nbsp;
                <LinkComponent url={CONFIG.termsAndConditionsURL} title={`${tosState.isUpdated ? "UPDATED " : ""}${tosTitle}`} isExternalLink />. All Rights Reserved.
              </p>
            </div>
            <div>
              <LinkComponent className="about" url="https://www.lifefitness.com/en-us/catalog/digital-solutions/facility-connect" title="About Facility Connect" isExternalLink />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// FooterComponent.propTypes = {
//     isTosUpdated: PropTypes.func.isRequired,
//     tosTitle: PropTypes.string.isRequired,
//     isLoading: PropTypes.bool.isRequired,
//     isLandingOrEntryPage: PropTypes.bool,
// };

export default FooterComponent;
