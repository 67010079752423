import { Link } from "react-router-dom";
import LinkComponent from "../LinkComponent/LinkComponent";
import "./menu.scss";

const MenuComponent = (props) => {
  const { menuTitle, menuItems } = props;
  return (
    <div className="Menu-component">
      <h3 className="title">{menuTitle}</h3>
      {menuItems.map(({ url, title, active, subMenu, extLink }, menuIndex) =>
        subMenu ? (
          <div className="sub-menu" key={menuIndex}>
            <Link
              to={`${url}/${subMenu[0].url}`}
              onClick={(e) => active && e.preventDefault()}
            >
              {title}
            </Link>
            {subMenu.map((subMenuItem, subMenuIndex) => (
              <Link
                className={`sub-menu-component${
                  subMenuItem.active ? " active" : ""
                }`}
                key={subMenuIndex}
                to={`${url}/${subMenuItem.url}`}
                onClick={(e) => subMenuItem.active && e.preventDefault()}
              >
                {subMenuItem.title}
              </Link>
            ))}
          </div>
        ) : (
          [
            !extLink ? (
              <Link
                className={active ? "active" : ""}
                key={menuIndex}
                to={url}
                onClick={(e) => active && e.preventDefault()}
              >
                {title}
              </Link>
            ) : (
              <LinkComponent
                title={title}
                url={extLink}
                key={menuIndex}
                isExternalLink
              />
            ),
          ]
        )
      )}
    </div>
  );
};

export default MenuComponent;
