import SubscriptionComponent from "../../components/developerApi/developerSdk/Subscription/SubscriptionComponent";

import "./subscriptionLayout.scss";

const SubscriptionLayout = (props) => {
  return (
    <div className="subscription-layout">
      <h2>Subscriptions</h2>
      <h5>Below is the documentation for the Realtime API Callbacks.</h5>
      <SubscriptionComponent api={props} />
    </div>
  );
};

export default SubscriptionLayout;
