import React from "react";
import AuthenticationComponent from "../../components/developerApi/developerSdk/Authentication/AuthenticationComponent";

import "./authenticationLayout.scss";

const AuthenticationLayout = () => {
  return (
    <div className="authentication-layout">
      <h2>Authentication</h2>
      <br />
      <AuthenticationComponent />
    </div>
  );
};

export default AuthenticationLayout;
