//import { ref, child, get } from "firebase/database";
import { child, get, ref, update } from "../config/firebase";

export function getUser(userId) {
  return get(child(ref, `USERS/${userId}`)).then(async (snapshot) => snapshot.val());
  // return ref.child(`USERS/${userId}`).once('value')
  //     .then((userSnapshot) => userSnapshot.val())
}

export async function updateUser(userId, params) {
  //return get(child(ref, `USERS/${userId}`).update(params));
  // return ref.child(`USERS/${userId}`).update(params);
  const user = await getUser(userId);
  console.log("*************** updateUser user: ", user);
  console.log("*************** updateUser params: ", params);
  return update(ref, { [`USERS/${userId}`]: { ...user, ...params } });
}
