import { isLoadingHandler, areIntEqual } from '../Utils';

export const SET_LICENSES = 'SET_LICENSES';
export const SET_LICENSE = 'SET_LICENSE';
export const CREATE_LICENSE = 'CREATE_LICENSE';
export const UPDATE_LICENSE = 'UPDATE_LICENSE';
export const REMOVE_LICENSE = 'REMOVE_LICENSE';
export const IS_LOADING = 'IS_LOADING';

const INITIAL_STATE = {
    isLoading: false,
    items: [],
    currentLicense: {},
};

const ACTION_HANDLERS = {
    [IS_LOADING]: isLoadingHandler,
    [SET_LICENSES]: (state, { payload: { licenses } }) => ({
        ...state,
        items: licenses,
    }),
    [SET_LICENSE]: (state, { payload: { licenseId } }) => ({
        ...state,
        currentLicense: state.items.filter(({ id }) => areIntEqual(id, licenseId))[0] || {},
    }),
    [CREATE_LICENSE]: (state, { payload: { license } }) => ({
        ...state,
        items: [ ...state.items, license ],
    }),
    [UPDATE_LICENSE]: (state, { payload: { license, env } }) => ({
        ...state,
        items: state.items.map((currentLicense) => areIntEqual(currentLicense.id, license.id) ? {
            ...currentLicense,
            [env]: license,
        } : currentLicense),
    }),
    [REMOVE_LICENSE]: (state, { payload: { licenseId } }) => ({
        ...state,
        items: state.items.filter((license) => license.id !== licenseId),
    }),
}

export default (state = INITIAL_STATE, action) => {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
};
