import { connect } from "react-redux";
import UpdateEmailComponent from "../components/profile/Profile/UpdateEmailComponent";
import { isUserAdmin, getCurrentUser } from "./selectors/userSelectors";

const UPDATE_EMAIL_FORM = "update-email";

const mapStateToProps = (state) => ({
  canEdit: isUserAdmin(state),
  currentUserInfo: getCurrentUser(state),
});

const mapDispatchToProps = {};

const mergeProps = ({ history, currentUserInfo, ...stateProps }) => {
  return {
    ...stateProps,
    initialValues: currentUserInfo,
    // validationRules: getValidationRules(["email", "password"], SIGN_IN_RULES),
    // onSubmit: ({ email, password, firstName, lastName, developerId, uid }) => {
    //   const prevEmail = currentUserInfo.email;
    //   const action = updateEmailAction;
    //   const userId = uid;
    //   if (email.toLowerCase() !== prevEmail.toLowerCase()) {
    //     return action({ userId, prevEmail, email, password, firstName, lastName, developerId });
    //   } else {
    //     throw new SubmissionError({
    //       email: "Please enter a different email address.",
    //     });
    //   }
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UpdateEmailComponent);
// export default connect( mapStateToProps, mapDispatchToProps, mergeProps)(
//   reduxForm({
//     form: UPDATE_EMAIL_FORM,
//     validate: getValidationRules(["email", "password"], SIGN_IN_RULES),
//   })(UpdateEmailComponent)
// );
