import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class DateComponent extends React.Component {
    render() {
        const { date, format, className } = this.props
        return <span className={className}>{moment(date).format(format)}</span>
    }
}

DateComponent.defaultProps = {
    format: 'MMM DD, YYYY',
    className: '',
}

DateComponent.propTypes = {
    date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    format: PropTypes.string,
    className: PropTypes.string.isRequired,
}

export default DateComponent
