import { Button } from "react-bootstrap";
import CONFIG from "../../../../config/config";
import ReactGA from "react-ga";

import "./subscriptionComponent.scss";
import { useEffect } from "react";

const SubscriptionComponent = () => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Subscription Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  const url = CONFIG.storageURL;
  return (
    <div className="subscription-component">
      <div className="buttons">
        <Button className="docs" target="_blank" href={url}>
          Docs
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionComponent;
