import ReactGA from "react-ga";

import { DOCUMENTATION_HTML_EXAMPLES, GRID_PROPS, CALLBACKS } from "./DocumentationConstants";
//import GridContainer from "../../../../../containers/GridContainer";
import CodeComponent from "../../../../core/CodeComponent/CodeComponent";

import "../documentation.scss";
import { useEffect } from "react";
import GridContainer from "../../../../../containers/GridContainer";

const DocumentationComponent = () => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Documentation Page" });
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <div className="documentation">
      <div className="section about">
        <h3>Using Developer Connect SDK for Android</h3>
        <p>In this section, we provide step-by-step instructions to use Developer Connect SDK on your Android app.</p>
        <p>The code snippets are taken from the Developer Connect Demo App to initiate the connection to a Life Fitness console, getting the live workout data, and the workout summary.</p>
      </div>

      <div className="section setup">
        <h4>Step 1: Setup</h4>
        <p className="project-setup-title">Project Setup:</p>
        <ol>
          <li>
            Make sure <code>lfopen2.aar</code> is imported as a module (lfopen2) into your Android project
          </li>
          <li>Add lfopen2 as a dependency of your app’s build grade</li>
          <CodeComponent code={DOCUMENTATION_HTML_EXAMPLES.setupCompile} />
          <li>Select SDK level 22 Google API or higher for the target platform</li>
          <li>Modify project manifest to support USB cable communication</li>
          <CodeComponent code={DOCUMENTATION_HTML_EXAMPLES.setupModify} />
        </ol>
        <p>And required resource file res/xml/accessory_data.xml:</p>
        <CodeComponent code={DOCUMENTATION_HTML_EXAMPLES.setupResourceFile} />
      </div>

      <div className="section init">
        <h4>Step 2: Initializing, Choosing environment, and getting a license</h4>
        <CodeComponent title="To set the environment and supply the license given by Developer Connect for developer website:" code={DOCUMENTATION_HTML_EXAMPLES.initSetEnv} />
        <p>
          The above code snippet set the environment to Development (set <code>isLiveEnv</code> to false) and it supplies the license obtained from the Developer Connect website.
        </p>

        <p>For complete reference about License and developer website, please proceed to "Developer Connect License" section.</p>
      </div>

      <div className="section equipment-observer">
        <h4>Step 3: Setup equipment observer</h4>
        <p>
          In order to receive the live workout data from the Life Fitness console,&nbsp;
          <code>EquipmentConnectivityService</code> implements&nbsp;
          <code>EquipmentObserver</code>. We want the app to keep receiving the live workout data even though the user can switch to another app (e.g. music player), so it extends&nbsp;
          <code>Service</code> class.
        </p>

        <CodeComponent code={DOCUMENTATION_HTML_EXAMPLES.setupEquipmentObserver} />
        <CodeComponent title="Register the observer to Developer Connect:" code={DOCUMENTATION_HTML_EXAMPLES.registerObserver} />
      </div>

      <div className="section workout">
        <h4>Step 4: Getting in-workout data</h4>
        <p>
          Once registered, the data will be sent to the observer methods. We want to display the stream data during a workout, therefore we have to implement <code>onStreamReceived</code> callback:
        </p>

        <CodeComponent code={DOCUMENTATION_HTML_EXAMPLES.gettingWorkoutData} />
      </div>

      <div className="section workout-summary">
        <h4>Step 5: Display workout summary</h4>
        <p>
          Similarly, we want to display the workout summary data at the end of a workout. We have to implement <code>onWorkoutResultReceived</code> callback:
        </p>

        <CodeComponent code={DOCUMENTATION_HTML_EXAMPLES.displayWorkoutSummary} />
      </div>

      <div className="section bluetooth">
        <h4>Step 6: Connect to equipment via bluetooth</h4>
        <p>
          To directly connect to Life Fitness console via Bluetooth LE, the app needs to obtain the console’s mac address. The mac address is encoded in the QR code displayed on the login button of the Discover console. How to read the QR code and parse the mac address is beyond the scope of this Getting Started tutorial. You are encouraged to read the Developer Connect Demo App full source code provided. See <code>startScanner</code> and <code>onActivityResult</code> methods in <code>MainActivity</code> class to see how to use <code>ZXing</code> library to scan & parse the QR code.
        </p>

        <CodeComponent
          title="The sample code below tells the app to connect to a Discover console whose mac address is
                        <code>macAddress</code>"
          code={DOCUMENTATION_HTML_EXAMPLES.connectToBluetoothMacAddress}
        />
        <CodeComponent title="Sample Manifest permissions required by bluetooth connectivity:" code={DOCUMENTATION_HTML_EXAMPLES.sampleManifestBluetoothConnectivity} />
        <CodeComponent title="Features required:" code={DOCUMENTATION_HTML_EXAMPLES.connectToBluetoothFeatures} />
        <CodeComponent title="For Android 6 and above. You need to request those permissions in runtime:" code={DOCUMENTATION_HTML_EXAMPLES.connectToBluetoothPermissions} />
      </div>

      <div className="section life-cycle">
        <h3>Life cycle of Developer Connect API connection to Life Fitness console</h3>
        <h4>Establishing connection to console</h4>
        <p>There are two ways to establishing connection to a Life Fitness console:</p>

        <ol>
          <li className="usb-cable">USB Cable</li>
          <p>The app will automatically receive some call backs after the user has connected the cable to the mobile device. As long as the manifest and initialization are in place, there are no additional steps in getting the callbacks.</p>

          <li className="bluetooth">Bluetooth LE</li>
          <p>
            a. Scanning Bluetooth LE devices using Android BLE API and pass the discovered device to&nbsp;
            <code>EquipmentManager.connectBLE()</code>. The app must make sure that the discovered device is a Life Fitness console using <code>EquipmentManager.isLFBLE()</code>
          </p>
          <p>
            b. Get the console address from QR code and pass the address to <code>EquipmentManager.startScanningBLE()</code>
          </p>
        </ol>

        <h4 className="callback">Callbacks</h4>
        <p>In a typical workout session, these are the callback events in chronological order:</p>
        <GridContainer name="callBacks" gridProps={GRID_PROPS} items={CALLBACKS} />

        <p>
          During a workout, the app can control some equipment parameters by calling <code>EquipmentManager</code> methods:&nbsp;
          <code>sendSetWorkoutThr(), sendShowConsoleMessage(), sendSetWorkoutLevel(), sendSetWorkoutWatts()</code>
          &nbsp; and <code>sendSetWorkoutInclide()</code>.
        </p>
      </div>
    </div>
  );
};

export default DocumentationComponent;
