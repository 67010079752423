//import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Form } from "react-final-form";
import LinkComponent from "../core/LinkComponent/LinkComponent";
import ReactGA from "react-ga";

import FieldGroupComponent from "../core/FieldGroupComponent/FieldGroupComponent";
import LoadingComponent from "../core/LoadingComponent/LoadingComponent";

import "./login.scss";
import { useEffect, useState } from "react";
import { HOME_PATH, SIGN_UP_PATH } from "../../constants/routes";
import { Navigate, useNavigate } from "react-router-dom";
import { getValidationRules } from "../../validation/validation";

import SIGN_IN_RULES from "../../validation/validationRules/signIn";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../redux/slice/sessionSlice";

const SignInComponent = (props) => {
  const sessionState = useSelector((__state__) => __state__.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [staySignedIn, setStaySignedIn] = useState(false);
  useEffect(() => {
    window.location.search && window.history.replaceState(null, "", window.location.pathname);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Sign In Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  const changeStay = () => {
    setStaySignedIn(!staySignedIn);
  };

  const handleSubmit = (login_input) => {
    ReactGA.event({
      category: "Sign In Component",
      action: "Clicked Login",
      label: "Button",
    });

    dispatch(loginAction(login_input));
  };

  const onGoToSignUp = () => {
    ReactGA.event({
      category: "Sign In Component",
      action: "Clicked Sign Up Now",
      label: "Button",
    });
    navigate(SIGN_UP_PATH);
  };

  return (
    <>
      {sessionState.fulfilled && sessionState.token && <Navigate to={HOME_PATH} />}
      {!sessionState.token && (
        <div className="login container">
          {sessionState.pending && <LoadingComponent fullScreen />}
          <h2>Log In</h2>
          <Form
            onSubmit={handleSubmit}
            validate={getValidationRules(["email", "password"], SIGN_IN_RULES)}
            render={(props) => (
              <div>
                <form onSubmit={props.handleSubmit}>
                  <FieldGroupComponent label="Email" name="email" type="text" submissionError={sessionState.rejected && sessionState.error} />
                  <FieldGroupComponent label="Password" name="password" type="password" className="password-field" />
                  <LinkComponent url="/reset-password" title="Forgot Password?" className="forgot-pass-text" />
                  <FieldGroupComponent name="staySignedIn" type="checkbox" placeholder="Stay Signed In" onChange={changeStay} checked={staySignedIn} />
                  <div className="buttons">
                    <Button disabled={props.invalid || props.submitting} type="submit" className="next">
                      Log In
                    </Button>
                  </div>
                  <div className="divider">
                    <hr className="left" />
                    <span>Or</span>
                    <hr className="right" />
                  </div>
                  <div className="buttons">
                    <Button className="tertiary" onClick={onGoToSignUp}>
                      Sign Up Now
                    </Button>
                  </div>
                </form>
              </div>
            )}
          />
        </div>
      )}
    </>
  );
};

// SignInComponent.propTypes = {
//     handleSubmit: PropTypes.func.isRequired,
//     onGoToSignUp: PropTypes.func.isRequired,
//     submitting: PropTypes.bool.isRequired,
// };

export default SignInComponent;
