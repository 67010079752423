import { connect } from "react-redux";
//import { reduxForm } from "redux-form";

import { getValidationRules } from "../validation/validation";
import SIGN_UP_RULES from "../validation/validationRules/signUp";
import UpdateProfileComponent from "../components/profile/Profile/UpdateProfileComponent";
import { validationRules } from "./CompanyFormContainer";
import { getCurrentUser } from "./selectors/userSelectors";

const mapStateToProps = (state, {}) => ({
  currentUserInfo: getCurrentUser(state),
});

const mergeProps = ({ currentUserInfo, ...stateProps }) => {
  return {
    ...stateProps,
    initialValues: currentUserInfo,
    activePanel: null,
    validationRules: getValidationRules(["email", "firstName", "lastName"].concat(validationRules), Object.assign(SIGN_UP_RULES)),
  };
};

export default connect(mapStateToProps, null, mergeProps)(UpdateProfileComponent);
// export default connect(
//   mapStateToProps,
//   null,
//   mergeProps
// )(
//   reduxForm({
//     form: "update-profile",
//     validate: getValidationRules(["email", "firstName", "lastName"].concat(validationRules), Object.assign(SIGN_UP_RULES)),
//   })(UpdateProfileComponent)
// );
