import { Button, Glyphicon } from "react-bootstrap";
import "./scrollButton.scss";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

const ScrollButtonComponent = (props) => {
  const [scrollButtonClass, setScrollButtonClass] = useState("");
  useEffect(() => {
    const onScroll = () => {
      let indent =
        document.getElementById(props.elementId).offsetTop -
        document.getElementById("header").offsetHeight;
      let _scrollButtonClass_ = window.scrollY > indent ? "show" : "";
      setScrollButtonClass(_scrollButtonClass_);
    };
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <Button
      className={`scroll-button dark ${scrollButtonClass}`}
      onClick={() => window.scrollTo(0, 0)}
    >
      <FontAwesomeIcon
        icon={faChevronUp}
        style={{ fontWeight: "bold", fontSize: "1.2em" }}
      />
    </Button>
  );
};

export default ScrollButtonComponent;
