import { ToastContainer } from "react-toastify";
import MainLayout from "./layouts/Main/MainLayout";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LandingComponent from "./components/landing/LandingComponent";
import AuthRouteComponent from "./components/core/AuthRouteComponent/AuthRouteComponent";

import ReactGA from "react-ga";
import CONFIG from "./config/config";

import { useEffect } from "react";
import { DEVELOPER_API_PATH, GUIDES_PATH, HOME_PATH, PROFILE_PATH, RESET_PASSWORD_PATH, SIGN_IN_PATH, SIGN_UP_PATH, USECASES_PATH } from "./constants/routes";
import Usecases from "./components/usecases/UsecasesComponent";
import Welcome from "./components/welcome/WelcomeComponent";
import GuidesComponent from "./components/guides/GuidesComponent";
import DeveloperApiRoute from "./routes/DeveloperApiRoute";
// import OverviewLayout from "./layouts/Overview/OverviewLayout";
// import GettingStartedLayout from "./layouts/GettingStarted/GettingStartedLayout";
// import AuthenticationLayout from "./layouts/Authentication/AuthenticationLayout";
import SignUpComponent from "./components/login/SignUp/SignUpComponent";
import ResetPasswordComponent from "./components/login/resetPassword/ResetPasswordComponent";

import "react-toastify/dist/ReactToastify.css";
import SignInComponent from "./components/login/SignInComponent";
import ProfileContainer from "./containers/ProfileContainer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <LandingComponent />,
      },
      {
        path: RESET_PASSWORD_PATH,
        element: <ResetPasswordComponent />,
      },
      {
        path: SIGN_IN_PATH,
        element: <SignInComponent />,
      },
      {
        path: SIGN_UP_PATH,
        element: <SignUpComponent />,
      },
      {
        path: "/auth",
        element: <AuthRouteComponent />,
        children: [
          {
            path: `${DEVELOPER_API_PATH}/:api/:menuItem?/:subMenuItem?`,
            element: <DeveloperApiRoute />,
          },
          {
            path: `${GUIDES_PATH}/:menuItem`,
            element: <GuidesComponent />,
          },
          {
            path: HOME_PATH,
            element: <Welcome />,
          },
          {
            path: USECASES_PATH,
            element: <Usecases />,
          },
          {
            path: `${PROFILE_PATH}/:menuItem`,
            element: <ProfileContainer />,
          },
        ],
      },
      {
        path: "*",
        element: <Welcome />,
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    ReactGA.initialize(CONFIG.GA_ANALYTICS);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="App">
      <ToastContainer position="top-center" autoClose={8000} closeOnClick={false} hideProgressBar pauseOnHover={false} className="custom-toast-container" toastClassName="custom-toast" bodyClassName="custom-toast-body" />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
