import React from "react";
import PropTypes from "prop-types";

import TooltipComponent from "../../core/TooltipComponent/TooltipComponent";
import { Image } from "react-bootstrap";

class LicensePlatformIconComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: {},
    };
  }

  handleView = (action, item) => {
    let temp = this.state.showTooltip;
    temp[item.id] = false;
    this.setState({
      showTooltip: temp,
    });
    this.props.handleView(action, item);
  };

  render() {
    const { item } = this.props;

    let comp =
      (item.facilitiesSubscriptionPending && item.facilitiesSubscriptionPending.split("-").join(",").split(",").length > 0) || (item.facilitiesSubscriptionRejected && item.facilitiesSubscriptionRejected.split("-").join(",").split(",").length > 0) ? (
        <TooltipComponent
          overlayContent={
            <div className={"tooltip-content"}>
              {item.facilitiesSubscriptionRejected && item.facilitiesSubscriptionRejected.split("-").join(",").split(",").length > 0 && (
                <div>
                  <Image className={"indicator-icon-img"} src={require("./img/icon_license_alert@2x.png")} />
                  <span className={"indicator-text"}>{item.facilitiesSubscriptionRejected.split("-").join(",").split(",").length - (item.facilitiesSubscriptionRejected.startsWith("-") ? 1 : 0)} Facility ID's had errors.</span>
                </div>
              )}
              {item.facilitiesSubscriptionPending && item.facilitiesSubscriptionPending.split("-").join(",").split(",").length > 0 && (
                <div>
                  <Image className={"indicator-icon-img"} src={require("./img/icon_license_pending@2x.png")} />
                  <span className={"indicator-text"}>{item.facilitiesSubscriptionPending.split("-").join(",").split(",").length - (item.facilitiesSubscriptionPending.startsWith("-") ? 1 : 0)} Subscription Events are pending approval.</span>
                </div>
              )}
              <span onClick={() => this.handleView("edit-" + item.env + "-license", item)} className={"viewButton"}>
                View
              </span>
            </div>
          }
          className={"license-icon-tooltip"}
          placement={"bottom"}
          keepOpen
          allowHover
          show={this.state.showTooltip[item.id]}
          setShow={(val) => {
            let temp = this.state.showTooltip;
            temp[item.id] = val;
            this.setState({ showTooltip: temp });
          }}
          children={
            <div className={"platform-icon"}>
              <Image className="platform-icon-img" src={require("./img/" + (item.env === "prod" ? "prod" : "dev") + (item.platform.toLowerCase() === "ios" ? "_ios" : item.platform === "Android" ? "_android" : "_web") + (item.inProd ? "_prod" : "") + (item.facilitiesSubscriptionRejected && item.facilitiesSubscriptionRejected.split("-").join(",").split(",").length > 0 ? "_alert" : "_pending") + "@2x.png")} />
            </div>
          }
        />
      ) : (
        <div className={"platform-icon"}>
          <Image className="platform-icon-img" src={require("./img/" + (item.env === "prod" ? "prod" : "dev") + (item.platform.toLowerCase() === "ios" ? "_ios" : item.platform === "Android" ? "_android" : "_web") + (item.inProd ? "_prod" : "") + "@2x.png")} />
        </div>
      );
    return <div className="platform-icon">{comp}</div>;
  }
}

LicensePlatformIconComponent.propTypes = {
  item: PropTypes.object.isRequired,
  handleView: PropTypes.func.isRequired,
};

export default LicensePlatformIconComponent;
