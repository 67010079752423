export const LANDING_PATH = "/";
export const SIGN_IN_PATH = "/login";
export const SIGN_UP_PATH = "/sign-up";
export const HOME_PATH = "/auth/home";
export const RESET_PASSWORD_PATH = "/reset-password";
export const CHANGE_PASSWORD_PATH = "/change-password";
export const INVITE_PATH = "/invite";
export const OLD_INVITE_PATH = "/old/invite";
export const ADD_COMPANY_PATH = "/add-company";
export const PROFILE_PATH = "/auth/profile";
export const ADD_LICENSE_PATH = "/profile/license/create";
export const TEAM_MEMBERS_PATH = "/profile/team-members";
export const LICENSES_PATH = "/auth/profile/licenses";
export const COMPANIES_PATH = "/auth/profile/companies";
export const UPDATE_PROFILE_PATH = "/profile/update";
export const LICENSES_TEAM_PATH = "/profile/licensesteam";
export const SUPPORT_PATH = "/auth/profile/support";
export const DOCUMENTATION_PATH = "/auth/api";
export const WEB_API_PATH = "/auth/api/web";
export const ANDROID_API_PATH = "/auth/api/android";
export const IOS_API_PATH = "/auth/api/ios";
export const MARKETING_GUIDELINES_PATH = "/marketing";
export const USECASES_PATH = "/auth/usecases";
export const WELCOME_PATH = "/home";
export const SUPPORT_AUTH_PATH = "/support-login";
export const OVERVIEW_PATH = "/auth/api/overview/started";
export const ADMIN_PATH = "/admin";
export const GUIDES_PATH = "/auth/guides";
export const INTRODUCTION_GUIDES_PATH = "/guides/introduction";
export const MMS_GUIDES_PATH = "/guides/mms-integration";
export const REAL_TIME_GUIDES_PATH = "/guides/real-time-availability";
export const DEVELOPER_API_PATH = "/auth/api";
