import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Image } from "react-bootstrap";

import LoadingComponent from "../../../core/LoadingComponent/LoadingComponent";
import CompatibilityPanelGroupComponent from "./CompatibilityPanelGroupComponent";

import "./compatibility.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCompatibilityMapping } from "../../../../redux/slice/compatibilitySlice";

const CompatibilityComponent = (props) => {
  const dispatch = useDispatch();
  const compatibilityState = useSelector((__state__) => __state__.compatibility);
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    dispatch(getCompatibilityMapping());

    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Compatibility Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  const handleSelect = (activeKey) => {
    setActiveKey(activeKey);
  };

  const thumbnails = {
    "Discover SE and Discover SI Only": ["discover-se-console.png", "discover-si-console.png"],
    "Discover SE3 and Discover SE3HD Only": ["discover-se3-console.png", "discover-se3-hd-console.png"],
  };

  const titles = {
    "Discover SE and Discover SI Only": "Discover SE / SI Only",
    "Discover SE3 and Discover SE3HD Only": "Discover SE3 / SE3 HD Only",
  };

  return (
    <>
      {compatibilityState.pending && !compatibilityState.rejected && <LoadingComponent fullScreen />}
      {compatibilityState.fulfilled && !compatibilityState.rejected && (
        <div className="compatibility-component">
          <div className="compatibility-component">
            <h3>API Compatibility</h3>
            <p>View APIs that are universally compatible with, or specific to, our equipment consoles.</p>

            <h4>Compatible with all consoles</h4>
            {compatibilityState.apis && (
              <CompatibilityPanelGroupComponent
                allApis={compatibilityState.apis}
                apisToShow={compatibilityState.commonApis}
                activeKey={activeKey}
                handleSelect={handleSelect}
                panelKey={"allApis"}
              />
            )}

            {compatibilityState.apis &&
              Object.keys(compatibilityState.consoleSpecificApis).map((key) => {
                return (
                  <div key={key}>
                    <h4>
                      {thumbnails[key].map((imageurl) => {
                        return <Image key={imageurl} className="console-thumbails" src={require(`./images/${imageurl}`)} />;
                      })}
                      {titles[key]}
                    </h4>
                    <CompatibilityPanelGroupComponent
                      allApis={compatibilityState.apis}
                      apisToShow={compatibilityState.consoleSpecificApis[key]}
                      activeKey={activeKey}
                      handleSelect={handleSelect}
                      panelKey={key}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default CompatibilityComponent;
