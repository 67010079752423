const SETUP_HTML = `<key>UISupportedExternalAccessoryProtocols</key>
<array>
    <string>com.lifefitness.vtapp.protocol</string>
</array>`

const LICENSE_HTML = `<key>LFLicenseKey</key>
<array>
<string>123-4567890</string>

<key>LFConnectivity</key>
<string>Production</string>`

const LICENSE_SWIFT_HTML = `var connectionMgr:LFEquipmentConnectionManager =
LFEquipmentConnectionManager.shared();

connectionMgr?.register
forWorkoutSession: self, sessionType: LFRealWorkoutSession
)`

const WORKOUT_SWIFT_HTML = `func didReceivedWorkoutStream(_ streamData: LFWorkoutStream!) {
    self.stream = streamData as LFWorkoutStream!
    self.workoutTable?.reloadData()
}`

const WORKOUT_SUMMARY_SWIFT_HTML = `func didReceivedResult(_ equipmentResult: LFCardioEquipmentWorkoutResult!) {
    let alert = UIAlertController(
        title: "Result", message: equipmentResult.workoutResultDetails,
        preferredStyle: UIAlertControllerStyle.alert 
    )

    alert.addAction(
        UIAlertAction(
            title: "Ok",
            style: UIAlertActionStyle.default,
            handler: nil
        )
    )
    self.present(alert, animated: true) {}

    scanButton?.setBackgroundImage(
        UIImage.init(named: "Scan"), for: UIControlState.normal
    )
    self.messageView!.text =
        self.messageView!.text +
        equipmentResult.workoutResultDetails

    scanButton?.setBackgroundImage(
        UIImage.init(named: "Scan"),
        for: UIControlState.normal
    )

    self.resetButton?.isHidden = false;
}`

const MAC_ADDRESS_HTML = `LFEquipmentConnectionManager.shared().connectThroughBluetooth(
    usingEquipmentName: self.macAddress
)`

const QR_URL_HTML = `- (void)connectThroughBluetoothUsingQRvalue:(NSString*)qr_url response:(void (^)(BOOL
success,NSError* error))response;`

const IS_LIFE_FITNESS_EQUIPMENT_HTML = `LFEquipmentConnectionManager.shared().isLifefitnessEquipment(
    advertisementData
)`

const CONNECT_BLUETOOTH_HTML = `LFEquipmentConnectionManager.shared().connectThroughBluetooth(
    usingEquipmentName: self.selected_name
)`

const INCLINE_HTML = `- (void)setIncline:(double)inclineValue responseStatus:
(void (^)(LFWriteAcknowledgeStatus ack , NSError* error))responseStatus;`

const WATTS_HTML = `- (void)setWatts:(int)wattsValue responseStatus:
(void (^)(LFWriteAcknowledgeStatus ack , NSError error))responseStatus;`

const HEART_RATE_HTML = `- (void)setWorkoutTargetHeartRate:(int)targetHeartrateValue responseStatus:
(void (^)(LFWriteAcknowledgeStatus ack , NSError* error))responseStatus;`

const MESSAGE_TO_DISPLAY_HTML = `- (void)setMessageToDispalyOnEquipmentScreen:(NSString*)message responseStatus:
(void(^)(LFWriteAcknowledgeStatus ack , NSError* error))responseStatus;`

const LEVEL_HTML = `- (void)setWorkoutLevel:(int)level responseStatus:
(void (^)(LFWriteAcknowledgeStatus ack, NSError* error))responseStatus;`

const WORKOUT_PRESET_HTML = `- (void)sendWorkoutPreset:(LFWorkoutPreset *)workoutPreset responseStatus:
(void (^)(LFWriteAcknowledgeStatus ack , NSError* error))responseStatus;`

export const DOCUMENTATION_HTML_EXAMPLES = {
    setup: SETUP_HTML,
    license: LICENSE_HTML,
    licenseSwift: LICENSE_SWIFT_HTML,
    workoutSwift: WORKOUT_SWIFT_HTML,
    workoutSummarySwift: WORKOUT_SUMMARY_SWIFT_HTML,
    macAddress: MAC_ADDRESS_HTML,
    qrUrl: QR_URL_HTML,
    isLifeFitnessEquipment: IS_LIFE_FITNESS_EQUIPMENT_HTML,
    connectBluetooth: CONNECT_BLUETOOTH_HTML,
    incline: INCLINE_HTML,
    watts: WATTS_HTML,
    heartRate: HEART_RATE_HTML,
    messageToDisplay: MESSAGE_TO_DISPLAY_HTML,
    level: LEVEL_HTML,
    workoutPreset: WORKOUT_PRESET_HTML,
}

export const GRID_PROPS = {
    classes: [
        {
            id: 'name',
            title: 'Header Name',
        },
        {
            id: 'description',
            title: 'Description',
        },
    ],
    delegates: [
        {
            id: 'order',
            title: 'Order',
        },
        {
            id: 'method',
            title: 'Observer Method',
            className: 'observer-method',
        },
        {
            id: 'description',
            title: 'Description',
        },
        {
            id: 'frequency',
            title: 'Frequency',
        },
    ],
}

export const MAIN_CLASSES = [
    {
        name: 'rLFEquipmentConnectionManager.h',
        description: 'The shared class which manages equipment connectivity',
    },
    {
        name: 'LFconnectAPIService.h',
        description: 'Contains code for connect backend service',
    },
    {
        name: 'LFopenBluetoothService.h',
        description: 'Contains bluetooth connectivity related functions',
    },
    {
        name: 'LFEquipmentCapability.java',
        description: 'Contains equipment capability functions',
    },
    {
        name: 'LFWorkoutStream.h',
        description: 'Manage workout stream data',
    },
    {
        name: 'LFWorkoutSessionDelegate.h',
        description: 'Manage workout delegates, work as interface for client app',
    },
    {
        name: 'LFEquipmentWorkoutBuilder.h',
        description: 'Contains workout xml creation functions',
    },
    {
        name: 'LFPresetDataSource.h',
        description: 'Work as interface between client app and console. ' +
            'Main task is to provide user workout information to console.',
    },
]

export const DELEGATES = [
    {
        order: 1,
        method: '- (void) didDeviceConnect;',
        description: 'TConnection is established',
        frequency: 'Once per session',
    },
    {
        order: 2,
        method: '- (void) didReceivedEquipmentInfo: (LFEquipment*) equipment;',
        description: 'Console sends equipment information to the mobile app',
        frequency: 'Once per session',
    },
    {
        order: 3,
        method: '- (NSArray*) didRequestPresetListFor: (enumLFEquipmentId) equipmentId;',
        description: 'Called when the console is ready to receive workout presets from the app',
        frequency: 'Once per session',
    },
    {
        order: 4,
        method: '- (void) didReceivedWorkoutStream: (LFWorkoutStream*) streamData;',
        description: 'After a workout is started, the app receives current workout information: calorie, distance, duration, etc',
        frequency: 'Multiple times, every second during a workout',
    },
    {
        order: 5,
        method: '- (void) didReceivedResult: (LFCardioEquipmentWorkoutResult*) equipmentResult;',
        description: 'Called when the app receives workout',
        frequency: 'Once per session',
    },
    {
        order: 6,
        method: '- (void) didDeviceDisconnect;',
        description: 'App is disconnected from console',
        frequency: 'Once per session',
    },
]
