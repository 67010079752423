import { createSelector } from 'reselect';

const licensesSelector = (state) => state.licenses;

export const filterLicenses = createSelector(
    licensesSelector,
    ({ items }) => ({
        dev: items.filter((license) => license.dev && !license.prod).map((license) => ({
            firebaseId: license.id,
            ...license.dev,
        })),
        prod: items.filter((license) => license.prod).map((license) => ({
            firebaseId: license.id,
            ...license.prod,
        })),
        devInProd: items.filter((license) => license.prod).map((license) => ({
            firebaseId: license.id,
            ...license.dev,
        })),
    })
);

export const getLoadingStatus = createSelector(
    licensesSelector,
    (licenses) => licenses.isLoading
);

export const getCurrentLicense = createSelector(
    licensesSelector,
    (licenses) => licenses.currentLicense,
)
