import { Image } from "react-bootstrap";
import ReactGA from "react-ga";

import { DOCUMENTATION_HTML_EXAMPLES, GRID_PROPS, MAIN_CLASSES, DELEGATES } from "./DocumentationConstants";
import GridContainer from "../../../../../containers/GridContainer";
import CodeComponent from "../../../../core/CodeComponent/CodeComponent";

import "../documentation.scss";
import { useEffect } from "react";

const DocumentationComponent = () => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Documentation Page" });
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <div className="documentation">
      <div className="section about">
        <h3>Using Developer Connect SDK for iOS</h3>
        <p>In this section, we provide step-by-step instructions to use Developer Connect SDK on your iOS app.</p>
        <p>The code snippets are taken from the Developer Connect Demo App to initiate the connection to Life Fitness console, getting the live workout data, and workout summary.</p>
        <p>Developer Connect SDK supports communication to Life Fitness consoles via Apple 31-pin cable and Bluetooth LE. Unfortunately, releasing an app that supports 31-pin cable requires MFi license from Apple and the latest Life Fitness consoles no longer provide a 31-pin connector. It is recommended to use Bluetooth LE which does not require any special licensing. Most newer Life Fitness consoles support Bluetooth LE.</p>
      </div>

      <div className="section setup">
        <h4>Step 1: Setup</h4>
        <p className="project-setup-title">Project Setup:</p>
        <ol>
          <li>
            Make sure <code>Lfopen.framework</code> is added to your project: Project target -&gt; Build Phases -&gt; Link binary -&gt; Add framework
          </li>
          <li>Modify the project plist to set Life Fitness external accessory protocol:</li>
        </ol>
        <CodeComponent code={DOCUMENTATION_HTML_EXAMPLES.setup} />
      </div>

      <div className="section init">
        <h4>Step 2: Initializing, Choosing the environment, and getting a license</h4>
        <CodeComponent
          title="To set the environment and supply the license given by the Life Fitness for Developer website,
                        add below properties to the project plist:"
          code={DOCUMENTATION_HTML_EXAMPLES.license}
        />
        <CodeComponent title="Swift:" titleClassName="bold" code={DOCUMENTATION_HTML_EXAMPLES.licenseSwift} />
        <p>
          The above code snippet set the environment to Production (set <code>LFConnectivity</code> property value as Production) and it supplies the license obtained from the Developer Connect website.
        </p>

        <p>For complete reference about License and developer website, please proceed to the “Developer Connect License” section.</p>
      </div>

      <div className="section main-classes">
        <h4>Step 3: Main classes</h4>
        <p>The table below are the main classes created to implement Workout tracking function:</p>
        <GridContainer name="main-classes-grid" gridProps={GRID_PROPS.classes} items={MAIN_CLASSES} />
      </div>

      <div className="section equipment-observer">
        <h4>Step 4: Setup equipment observer</h4>
        <p>
          In order to receive the workout data from Life Fitness console,
          <code> LFEquipmentConnectionManager</code> implements
          <code> LFWorkoutSessionDelegate</code>. We want the app to keep receiving the stream data even though the user can switch to another app (e.g. music player), in order to do that the app should enable following background mode.
        </p>

        <div className="image">
          <Image src={require("./images/API_observer.jpg")} />
        </div>
      </div>

      <div className="section workout">
        <h4>Step 5: Getting in-workout data</h4>
        <p>
          Once registered, the data will be sent to the observer methods. We want to display the stream data during a workout, therefore we have to implement <code>didReceivedWorkoutStream</code> delegate:
        </p>

        <CodeComponent title="Swift:" titleClassName="bold" code={DOCUMENTATION_HTML_EXAMPLES.workoutSwift} />
      </div>

      <div className="section workout-summary">
        <h4>Step 6: Display workout summary</h4>
        <p>
          Similarly, we want to display the workout summary data at the end of a workout. We have to implement
          <code> didReceivedResult</code> delegate:
        </p>

        <CodeComponent title="Swift:" titleClassName="bold" code={DOCUMENTATION_HTML_EXAMPLES.workoutSummarySwift} />
      </div>

      <div className="section bluetooth">
        <h4>Step 7: Support bluetooth</h4>
        <p>To directly connect to a Discover console, the app needs to obtain the console’s mac address. The mac address is encoded in the QR code displayed on the login button of the Discover console. How to read the QR code and parse the mac address is beyond the scope of this Getting Started tutorial. You are encouraged to study the sample project.</p>

        <CodeComponent
          title="The sample code below tells the app to connect to a Discover console whose mac address is
                        <code>macAddress</code>"
          code={DOCUMENTATION_HTML_EXAMPLES.macAddress}
        />
        <CodeComponent
          title="The sample code below tells the app to connect to a Discover console whose qr code value is
                        <code>qr_url</code>."
          code={DOCUMENTATION_HTML_EXAMPLES.qrUrl}
        />
      </div>

      <div className="section test">
        <h4>Step 8: Test the app</h4>
        <p>You can execute and test the app:</p>
        <ol>
          <li>Build the Xcode project and install it on a device (iOS 4.0 and above is recommended for Bluetooth LE)</li>
          <li>Connect the device to a Discover console. If you are using a cable, plug the cable into your device. If you are using Bluetooth, scan the QR code with the scanner</li>
          <li>Once connected, you should see a green dot on the “Mobile” button on the Discover console</li>
          <li>Start a workout</li>
          <li>You can view the in-workout display by table view (Demo app). At the end of the workout, Developer Connect will provide the result summary data to the client app</li>
        </ol>
      </div>

      <div className="section life-cycle">
        <h3>Life cycle of Developer Connect API connection to Life Fitness console</h3>
        <h4>Establishing connection to console</h4>
        <p>There are two ways to establishing connection to a Life Fitness console:</p>

        <ol>
          <li className="bold">USB Cable</li>
          <p>
            The app will automatically fire <code>didDeviceConnect</code> delegate after the user has connected the cable to the mobile device. As long as the proper initialization (set External accessory protocol and Developer Connect license) are in place, there are no additional steps in getting the callbacks.
          </p>

          <li className="bold">Bluetooth LE</li>
          <p>As mentioned in Getting Started step 6, the app may initiate the connection in two options:</p>

          <CodeComponent
            title="a. Scanning Bluetooth LE devices using Core Bluetooth framework and
                            use the discovered advertisement data to LFEquipmentConnectionManager.
                            The app must make sure that the discovered device is a Life Fitness console by using below code snippet:"
            code={DOCUMENTATION_HTML_EXAMPLES.isLifeFitnessEquipment}
          />
          <CodeComponent title="b. Get the console address from QR code and pass the address to:" code={DOCUMENTATION_HTML_EXAMPLES.connectBluetooth} />
        </ol>
      </div>

      <div className="section delegates">
        <h4>Delegates</h4>
        <p>In a typical workout session, these are the call back events in chronological order:</p>
        <GridContainer name="delegates" gridProps={GRID_PROPS.delegates} items={DELEGATES} />

        <p>
          During a workout, the app can control some equipment parameters by calling workout control functions of <code>LFEquipmentConnectionManager</code> methods:
        </p>

        <CodeComponent title="1. Set Incline:" code={DOCUMENTATION_HTML_EXAMPLES.incline} />
        <CodeComponent title="2. Set Watts:" code={DOCUMENTATION_HTML_EXAMPLES.watts} />
        <CodeComponent title="3. Set Heart Rate Value:" code={DOCUMENTATION_HTML_EXAMPLES.heartRate} />
        <CodeComponent title="4. Set Message to Display on Console:" code={DOCUMENTATION_HTML_EXAMPLES.messageToDisplay} />
        <CodeComponent title="5. Set Level:" code={DOCUMENTATION_HTML_EXAMPLES.level} />
        <CodeComponent title="6. Send workout preset" code={DOCUMENTATION_HTML_EXAMPLES.workoutPreset} />
      </div>
    </div>
  );
};

export default DocumentationComponent;
