import "./loading.scss";

const LoadingComponent = (props) => {
  return (
    <div className={`spinner-wrapper ${props.fullScreen ? "full-screen" : ""}`}>
      <div className="spinner" />
    </div>
  );
};
export default LoadingComponent;
