import { ref, get, child, update, remove } from "../config/firebase";
import moment from "moment/moment";

import { createCompany as createCompanyRequest, updateCompany as updateCompanyRequest } from "./cloud-functions";
import { PARTNERAPI_DB_KEYS } from "../redux/Constants";

export async function createCompany(company) {
  const currentDate = moment().valueOf();
  return createCompanyRequest({
    name: company.name.trim(),
    platform: company.platform.split(","),
    phone: company.phoneNumber || null,
    size: company.companySize,
    industry: company.industry,
    otherIndustry: company.otherIndustry || "",
    vertical: company.vertical,
    otherVertical: company.otherVertical || "",
    country: company.country,
    address: company.address,
    city: company.city,
    state: company.state,
    postalCode: company.postalCode,
    createdAt: currentDate,
    updatedAt: currentDate,
  });
}

export function getCompany(companyId) {
  return get(child(ref, `${PARTNERAPI_DB_KEYS.COMPANIES}/${companyId}`)).then(async (companySnapshot) => {
    // return ref.child(`COMPANIES/${companyId}`).once('value')
    //     .then((companySnapshot) => {
    let company = {};

    if (companySnapshot.exists()) {
      company = Object.assign(companySnapshot.val(), { firebaseId: companyId });
    }

    return company;
  });
}

export function updateCompany(companyId, params) {
  //return ref.child(`${PARTNERAPI_DB_KEYS.COMPANIES}/${companyId}`).update(params);
  return update(ref, {
    [`${PARTNERAPI_DB_KEYS.COMPANIES}/${companyId}`]: params,
  });
}

export async function changeCompany(company) {
  const currentDate = moment().valueOf();
  return updateCompanyRequest({
    name: company.name.trim(),
    platform: company.platform,
    phone: company.phoneNumber || null,
    size: company.companySize,
    companySize: company.companySize,
    industry: company.industry,
    otherIndustry: company.otherIndustry || "",
    vertical: company.vertical,
    otherVertical: company.otherVertical || "",
    country: company.country,
    address: company.address,
    city: company.city,
    state: company.state,
    postalCode: company.postalCode,
    updatedAt: currentDate,
    firebaseId: company.firebaseId,
  });
}

export function getPendingFacilitiesAdmin() {
  return get(child(ref, PARTNERAPI_DB_KEYS.PENDING_FACILITIES)).then(async (companiesSnapshot) => {
    // return ref
    //   .child(PARTNERAPI_DB_KEYS.PENDING_FACILITIES)
    //   .once("value")
    //   .then((companiesSnapshot) => {
    return companiesSnapshot.val();
  });
}

export function getPendingFacilities(companyId) {
  console.log("***** getPendingFacilities().companyId: ", companyId);
  return get(child(ref, `${PARTNERAPI_DB_KEYS.PENDING_FACILITIES}/${companyId}`)).then(async (companySnapshot) => {
    return companySnapshot.val();
  });
}

export function updatePendingFacilities(companyId, params) {
  if (!params) {
    //return ref.child(`${PARTNERAPI_DB_KEYS.PENDING_FACILITIES}/${companyId}`).remove();
    return remove(ref, `${PARTNERAPI_DB_KEYS.PENDING_FACILITIES}/${companyId}`);
  } else {
    //return ref.child(`${PARTNERAPI_DB_KEYS.PENDING_FACILITIES}/${companyId}`).update(params);
    return update(ref, {
      [`${PARTNERAPI_DB_KEYS.PENDING_FACILITIES}/${companyId}`]: params,
    });
  }
}
