const text = `3. Make sure equipment in the facility are connected to the Internet and the Push Notification Server (Port 1883/8883)

&nbsp;

### WORKFLOW

1. User logs in to equipment

    The developer application receives a callback notification when the user is logged in to an equipment from a subscribed facility.

    &nbsp;

    Callback Event: User Logged In
    ~~~
    POST https://www.example.com/notifications?subType=LFUserLoggedIn&timestamp=2020-02-24T20%3A20%3A15.895Z&access_token=1eowepw216dfdbe8df5a15cfa32cbn%7C5cd655cbc4a &device=HHT-902902&facilityId=20&activityId=1
    ~~~

2. User begins workout

    The developer application receives a callback notification when a user starts a workout on an equipment from a subscribed facility.

    &nbsp;

    Callback Event: User Workout Started
    ~~~
    POST https://www.example.com/notifications?subType=LFUserWorkoutStarted&timestamp=2020-02-24T20%3A20%3A15.895Z&access_token=1eowepw216dfdbe8df5a15cfa32cbn%7C5cd655cbc4a &device=HHT-902902&facilityId=20&activityId=1
    ~~~

3. User workout data is sent every second

    The developer application receives a callback notification every second with the workout data details when the user is performing the workout on a cardio equipment.

    &nbsp;

    **Note** - The workout data is encoded JSON object. It sends the Developer Connect UserId as the parameter instead of the user’s access-token.

    &nbsp;

    Callback Event: User Workout Data
    ~~~
    POST https://www.example.com/notification?subType=LFUserWorkoutData&userId=2112122&timestamp=2020-02-24T20%3A20%3A15.895Z&device=HHT-902902&facilityId=20&activityId=1 &workoutData=%7B%22datetime%22%3A%222020-02-24T10%3A16%3A53.512Z%22%2C%22userId%22%3A%222112122%22%2C%22workout_timeInZoneString%22%3A%220%3A00%22%2C%22workout_timeElapsedString%22%3A%220%3A14%22%2C%22workout_avgIncline%22%3A0.0%2C%22workout_avgLevel%22%3A3.0%2C%22workout_avgMets%22%3A58.0%2C%22workout_avgRpm%22%3A0.0%2C%22workout_avgSpeed%22%3A75.0933%2C%22workout_avgStride%22%3A0.0%2C%22workout_avgStridesPerMin%22%3A0.0%2C%22workout_totalWorkoutTime%22%3A0.0%2C%22workout_timeInCoolDown%22%3A-1.0%2C%22workout_timeGoalInCoolDown%22%3A0.0%2C%22workout_completeWorkoutTime%22%3A14.0%2C%22workout_cooldownTimeRemaining%22%3A3586.0%2C%22workout_currentIncline%22%3A0.0%2C%22workout_timeElapsed%22%3A14.0%2C%22workout_currentSpeed%22%3A4.6667%2C%22workout_distance%22%3A0.0%2C%22workout_distanceClimbed%22%3A8.5333%2C%22workout_stride%22%3A0.0%2C%22workout_maxStride%22%3A0.0%2C%22workout_maxHeartRate%22%3A75%2C%22workout_mets%22%3A85%2C%22workout_rank%22%3A0%2C%22workout_recoveryHR%22%3A0%2C%22workout_rpm%22%3A0%2C%22workout_score%22%3A0%2C%22workout_heartRate%22%3A75%2C%22workout_summaryTotalWorkoutTime%22%3A0%2C%22workout_targetHeartRate%22%3A0%2C%22workout_currentLevel%22%3A6%2C%22workout_timeElapsedSeconds%22%3A14%2C%22workout_avgHeartRate%22%3A75%2C%22workout_caloriesPerHour%22%3A365%2C%22workout_calories%22%3A1%2C%22workout_airForcePRTScore%22%3A0%2C%22workout_totalWattSecondsSaved%22%3A0%2C%22workout_avgWatts%22%3A44%2C%22workout_watts%22%3A76%7D
    ~~~

4. User ends workout

    The developer application receives a callback notification when the user ends a workout on an equipment from a subscribed facility.

    &nbsp;

    Callback Event: User Workout Ended
    ~~~
    POST https://www.example.com/notifications?subType=LFUserWorkoutEnded&timestamp=2020-02-24T20%3A20%3A15.895Z&access_token=1eowepw216dfdbe8df5a15cfa32cbn%7C5cd655cbc4a&device=HHT-902902&facilityId=20&activityId=1
    ~~~

5. User logs out

    The developer application receives a callback notification when the user logs out from an equipment from a subscribed facility.

    &nbsp;

    Callback Event: User Logged Out
    ~~~
    POST https://www.example.com/notifications?subType=LFUserLoggedOut&timestamp=2020-02-24T20%3A20%3A15.895Z&access_token=1eowepw216dfdbe8df5a15cfa32cbn%7C5cd655cbc4a &device=HHT-902902&facilityId=20&activityId=1
    ~~~

&nbsp;

### CARDIO CALLBACK URL PARAMETERS

The developer sets the callback URL for the 'Cardio' callback events and then receives a POST callback notification to the URL specified for the events with the following parameters -
`;

export default text;
