import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

import "./modal.scss";

const ModalComponent = (props) => {
  const submitButton = (value) => {
    props.setSubmitting && props.setSubmitting(value);
  };

  const nextPage = () => {
    props.nextPage();
  };

  const closeFunction = () => {
    props.handleClose();
  };

  const { title, body, cancelButtonTitle, confirmButtonTitle, onConfirm, show, dialogClassName, backdrop, disableConfirmButton, submitting, pages, page, noCloseButton, hideFooter } = props;

  return (
    <>
      <Modal backdrop={backdrop} show={show} onHide={closeFunction} onEnter={() => submitButton(false)} dialogClassName={`custom-modal ${dialogClassName}`}>
        <Modal.Header closeButton={!noCloseButton}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{body}</Modal.Body>

        {!hideFooter && (
          <Modal.Footer>
            <Button className="btn btn-default dark" onClick={closeFunction}>
              {cancelButtonTitle}
            </Button>
            <Button
              disabled={disableConfirmButton || submitting}
              className="btn btn-default"
              onClick={(data) => {
                onConfirm(data);
                if (page === pages) {
                  submitButton(true);
                }
              }}
            >
              {page < pages ? "Next" : confirmButtonTitle}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

ModalComponent.defaultProps = {
  show: false,
  dialogClassName: "",
  disableConfirmButton: false,
  backdrop: "static",
  submitting: false,
};

// ModalComponent.propTypes = {
//   show: PropTypes.bool.isRequired,
//   handleClose: PropTypes.func.isRequired,
//   title: PropTypes.string.isRequired,
//   body: PropTypes.node.isRequired,
//   cancelButtonTitle: PropTypes.string.isRequired,
//   confirmButtonTitle: PropTypes.string.isRequired,
//   onConfirm: PropTypes.func.isRequired,
//   dialogClassName: PropTypes.string.isRequired,
//   backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
//   disableConfirmButton: PropTypes.bool.isRequired,
//   pages: PropTypes.number,
//   nextPage: PropTypes.func,
//   page: PropTypes.number,
//   noCloseButton: PropTypes.bool,
//   setSubmitting: PropTypes.func,
//   submitting: PropTypes.bool,
// };

export default ModalComponent;
