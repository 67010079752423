import { Link, useLocation } from "react-router-dom";

const LinkComponent = (props) => {
  const { title, url, className, isExternalLink } = props;

  const { pathname } = useLocation();

  return (
    <>
      {isExternalLink && (
        <a className={`${className || ""}`} target="_blank" rel="noopener noreferrer" href={url}>
          {title}
        </a>
      )}
      {!isExternalLink && (
        <Link className={`${className || ""}`} state={{ prevPath: pathname }} to={{ pathname: url, state: { prevPath: pathname } }}>
          {title}
        </Link>
      )}
    </>
  );
};

export default LinkComponent;
