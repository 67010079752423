import { GUIDES_MENU } from "../core/MenuComponent/MenuConstants";
import BreadcrumbsComponent from "../core/BreadcrumbsComponent/BreadcrumbsComponent";
import MenuComponent from "../core/MenuComponent/MenuComponent";
import IntroductionComponent from "./Introduction/IntroductionComponent";
import MMSIntegrationComponent from "./MMSIntegration/MMSIntegrationComponent";
import RealTimeAvailabilityComponent from "./RealTimeAvailability/RealTimeAvailabilityComponent";

import "./guides.scss";
import { useParams } from "react-router-dom";
import { GUIDES_PATH } from "../../constants/routes";

const GuidesComponent = (props) => {
  const params = useParams();
  const { menuItem } = params;
  const guidesIntroduction = menuItem === "introduction";
  const guidesMMSIntegration = menuItem === "mms-integration";
  const guidesRealTimeAvailability = menuItem === "real-time-availability";

  const getMenuItems = (menuItems) =>
    Object.keys(menuItems).map((keyItem) => {
      const menuItem = menuItems[keyItem];
      const subMenu = menuItem && menuItem.subMenu;

      return {
        ...menuItem,
        url: `${GUIDES_PATH}/${keyItem}`,
        active:
          params.menuItem === keyItem ||
          (keyItem === "introduction" &&
            !guidesIntroduction &&
            !guidesMMSIntegration &&
            !guidesRealTimeAvailability),
        subMenu:
          subMenu &&
          Object.keys(subMenu).map((subMenuItem) => ({
            ...subMenu[subMenuItem],
            url: subMenuItem,
            active: params.subMenuItem === subMenuItem,
          })),
      };
    });

  return (
    <div className="guides-component container">
      <div className="content">
        <div className="sticky-wrapper">
          <BreadcrumbsComponent dependency={menuItem} class="breadcrumbs" />
          <MenuComponent
            {...props}
            menuTitle={"Guides"}
            menuItems={getMenuItems(GUIDES_MENU)}
          />
        </div>
        <div className="content-wrapper">
          {guidesIntroduction && <IntroductionComponent />}
          {guidesMMSIntegration && <MMSIntegrationComponent />}
          {guidesRealTimeAvailability && <RealTimeAvailabilityComponent />}
          {!guidesIntroduction &&
            !guidesMMSIntegration &&
            !guidesRealTimeAvailability && <IntroductionComponent />}
        </div>
      </div>
    </div>
  );
};

export default GuidesComponent;
