import React, { useEffect } from "react";
import ReactGA from "react-ga";

import { WORKOUT_PRESET_CODES } from "../../WorkoutPreset/Android/WorkoutPresetConstants";

import CodeComponent from "../../../../core/CodeComponent/CodeComponent";

import "./workoutPreset.scss";

const WorkoutPresetComponent = () => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Workout Preset Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="workout-preset">
      <div>
        <p>
          The mobile app can pass a workout to the console and the user can use
          the workout presets to start a workout on the equipment. This can be
          done by implementing<code> onSendingWorkoutPreset() </code>
          in<code> EquipmentObserver </code>interface:
        </p>

        <CodeComponent code={WORKOUT_PRESET_CODES.onSendingWorkOutPreset} />

        <p className="without-indent">
          The list of workout presets can also be generated using{" "}
          <code>WorkoutHelper</code> class. The sample code below generates a
          workout preset for a connected equipment with manual program and 10
          minute time goal. Other parameters (target hr, initial speed, level,
          etc..) may be vary based on connected equipment family
          (Treadmill/Bike/Crosstrainer/Powermill).
        </p>

        <br />

        <CodeComponent code={WORKOUT_PRESET_CODES.sampleWorkOutPreset} />

        <p>
          Last but not least, in order to display list of presets to be chosen
          on console, we need to send the list of workout names to the console.
          This can be done by implementing <code>onDisplaySettingsRequest</code>{" "}
          method
        </p>

        <br />

        <CodeComponent code={WORKOUT_PRESET_CODES.onDisplaySettingsRequest} />

        <p>
          The output of <code>buildDisplaySettings</code> should have the below
          format:
        </p>

        <CodeComponent code={WORKOUT_PRESET_CODES.settingsFormat} />

        <p>
          The workoutSummaries json array stands for the preset list defined
          above.
        </p>

        <h3>Getting information about the console and equipment</h3>
        <p>
          After the connection between the mobile device and console is
          established, the app can obtain the equipment type of the connected
          console:
        </p>

        <CodeComponent code={WORKOUT_PRESET_CODES.equipmentType} />

        <p>
          The device family value can be one of the Life Fitness equipment types
          below:
        </p>
        <ul>
          {WORKOUT_PRESET_CODES.workOutHelpers.map((workOutHelper, i) => (
            <li key={i}>
              <code>WorkoutHelper.${workOutHelper}</code>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WorkoutPresetComponent;
