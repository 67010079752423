import React from 'react'
import PropTypes from 'prop-types'

class SizeComponent extends React.Component {
    render() {
        const { bytes, decimals } = this.props
        const BYTES_IN_KB = 1024
        const UNITS = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        const index = Math.floor(Math.log(bytes) / Math.log(BYTES_IN_KB))

        return <span>{bytes === 0
            ? '0 Bytes'
            : parseFloat((bytes / Math.pow(BYTES_IN_KB, index)).toFixed(decimals)) + ' ' + UNITS[index]}</span>
    }
}

SizeComponent.defaultProps = {
    decimals: 2,
}

SizeComponent.propTypes = {
    bytes: PropTypes.number.isRequired,
    decimals: PropTypes.number,
}

export default SizeComponent
