export default {
    member: [
        'View company APIs',
        'View licenses',
    ],
    admin: [
        'Create/edit/delete users',
        'Update company information',
        'Apply for licenses',
    ],
};
