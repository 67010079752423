import React, { useEffect } from "react";
import ReactGA from "react-ga";

import { LFCONNECT_URLS } from "./GettingStartedConstants";
import CodeComponent from "../../../../core/CodeComponent/CodeComponent";

import "./gettingStarted.scss";

const GettingStartedComponent = () => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Getting Started Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="getting-started">
      <div className="section">
        <p>
          Developers wishing to access <b>Developer Connect API</b> web services must be registered in <b>Developer Connect</b> as a third-party accessor before they will be able to access the web services
        </p>
        <p className="bold">
          1. <span className="strawberry-text">Create a New App License</span>
        </p>
        <p>
          Once registered, the App will issue an <span className="bold">OAuth Client ID</span> and an
          <span className="bold"> OAuth Client secret</span> which are required for authentication with Developer Connect and to access the services.
        </p>

        <p className="bold">
          2. <span className="strawberry-text">Select Web API as the Enrollment Type</span>
        </p>
        <p>
          The Redirect URL has to be provided. This is the URL of the application where the OAuth2 will redirect after authentication. For more information about this, refer to{" "}
          <a href="authentication" className="blue-text">
            Authentication
          </a>
          .
        </p>
        <p className="bold">
          3. <span className="strawberry-text">Authentication</span>
        </p>

        <p>Once an app is created in the Developer Connect API portal, the OAuth keys provide access to the sandbox environment for the third-party users to test their application. For this, Developer Connect users must be created in the sandbox environment.</p>
        <p className="bold">The URL for the sandbox environment is:</p>
        <CodeComponent type="url" code={LFCONNECT_URLS.sandbox} />
        <p className="bold">The base URL for the sandbox API environment is:</p>
        <CodeComponent type="url" code={LFCONNECT_URLS.sandbox_api} />

        <p>Once the app is moved to production and the production OAuth keys are received, then the live environment and data can be accessed.</p>
        <p className="bold">The URL for the live environment is:</p>
        <CodeComponent type="url" code={LFCONNECT_URLS.production} />
        <p className="bold">The base URL for the production API environment is:</p>
        <CodeComponent type="url" code={LFCONNECT_URLS.production_api} />

        <p>The OAuth keys are not interchangeable across the environments, as are the Developer Connect users created.</p>

        <p>
          Service endpoints starting with <b>/user/</b> pertains to all exerciser-related information. Updating the profile, retrieving the profile, and updating and getting the profile photo are some of the functionalities available.
        </p>
        <p>
          To retrieve information on the exerciser's workout library (presets, goals, etc), access the
          <b> /workout_preset/*</b> services.
        </p>
        <p>
          Submitting and retrieving workout results (Manual Cardio, Strength, GPS, and Life Fitness workouts) and the aggregated progress information can be done by accessing the <b>/workout_results/* </b>
          services.
        </p>
      </div>
    </div>
  );
};

export default GettingStartedComponent;
