const text = `## MMS Integration
Sync a gym's member list with Developer Connect to associate workout data with members.

&nbsp;

**KEY BENEFITS**

The key benefits of using MMS integration can be summarized below:
`;

export default text;
