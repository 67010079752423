import { createAction } from 'redux-actions';

import { INIT_GRID, SET_ITEMS, SET_SORT_BY, SET_PAGINATION, SELECT_ITEM, REMOVE_GRID } from '../reducers/gridStore';

const initGrid = createAction(INIT_GRID);
const setItems = createAction(SET_ITEMS);
const setSortBy = createAction(SET_SORT_BY);
const setPagination = createAction(SET_PAGINATION)
const selectItem = createAction(SELECT_ITEM);
const removeGrid = createAction(REMOVE_GRID);

export const setGrid = (name) => (dispatch) => {
    // Name must be unique
    dispatch(initGrid({ name }));
};

export const setGridItems = (name, items) => (dispatch) => {
    dispatch(setItems({ name, items }));
};

export const setSorting = (name, sortBy) => (dispatch) => {
    dispatch(setSortBy({ name, sortBy }));
};

export const setGridPagination = (name, options) => (dispatch) => {
    dispatch(setPagination({ name, options }));
};

export const selectGridItem = (name, index, singleSelection, isChecked) => (dispatch) => {
    dispatch(selectItem({ name, index, singleSelection, isChecked }));
};

export const deleteGrid = (name) => (dispatch) => {
    dispatch(removeGrid({ name }));
};
