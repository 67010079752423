import { connect } from "react-redux";
import { getValidationRules } from "../validation/validation";
import SIGN_UP_RULES from "../validation/validationRules/signUp";
import UpdateInfoComponent from "../components/profile/Profile/UpdateInfoComponent";
import { validationRules } from "./CompanyFormContainer";
import { isUserAdmin, getCurrentUser } from "./selectors/userSelectors";

const _ = require("lodash");

const UPDATE_PERSONAL_INFO_FORM = "update-personal-info";

const mapStateToProps = (state) => ({
  canEdit: isUserAdmin(state),
  currentUserInfo: getCurrentUser(state),
});

const mapDispatchToProps = {};

const mergeProps = ({ history, company, currentUserInfo, ...stateProps }, { updatePersonalInfo }) => {
  return {
    ...stateProps,
    initialValues: currentUserInfo,
    validationRules: getValidationRules(["firstName", "lastName"].concat(validationRules), Object.assign(SIGN_UP_RULES)),
    onSubmit: (data) => {
      let prevData = { ...currentUserInfo };
      if (!_.isEqual(data, prevData)) {
        prevData = data;

        let promise;
        if (data) {
          promise = updatePersonalInfo(data.uid, data);
        }

        return promise.then().finally(() => {
          prevData = {};
        });
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UpdateInfoComponent);
