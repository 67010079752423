import { Button, Image } from "react-bootstrap";
import ReactGA from "react-ga";
import "./landing.scss";
import { useNavigate } from "react-router-dom";
import { LANDING_PATH, SIGN_IN_PATH, SIGN_UP_PATH } from "../../constants/routes";
import LinkComponent from "../core/LinkComponent/LinkComponent";

const featureOne = require("./feature_1.png");
const featureTwo = require("./feature_2.png");
const featureThree = require("./feature_3.png");
const featureFour = require("./feature_4.png");
const featureFive = require("./feature_5.png");
const featureSix = require("./feature_6.png");
const featureSeven = require("./feature_7.png");
const featureEight = require("./feature_8.png");
const quoteicon = require("./quoteicon.png");
const applehealth = require("./Icon_App_AppleHealth.png");
const fitbit = require("./Icon_App_Fitbit.jpg");
const googlefit = require("./Icon_App_GoogleFit.jpg");
const jawbone = require("./Icon_App_Jawbone.jpg");
const myfitnesspal = require("./Icon_App_MyFitnessPal.jpg");
const samsunghealth = require("./Icon_App_SamsungHealth.jpg");

const LandingComponent = (props) => {
  const navigate = useNavigate();

  const goToSignUpPage = () => {
    ReactGA.event({
      category: "Landing Component",
      action: "Clicked Sign Up",
      label: "Button",
    });
    navigate(SIGN_UP_PATH);
  };

  const goToSignInPage = () => {
    ReactGA.event({
      category: "Landing Component",
      action: "Clicked Log In",
      label: "Button",
    });
    navigate(SIGN_IN_PATH);
  };

  return (
    <div className="Landing-component">
      <section className="welcome">
        <div className="content">
          <h1>Welcome to Developer Connect</h1>
          <p>
            <b>We are the world's leading developer portal for fitness equipment, gyms and exercisers. Be part of the platform that connects the world to fitness.</b>
          </p>
          <p>
            <b>Our APIs are associated with the Developer Connect, as well as select connected Life Fitness and Cybex exercise equipment.</b>
          </p>
          <p>
            <b>Our digital experience is available in more than 24 languages and in 160 countries. We work with developers around the world to create innovative technologies that are changing the fitness industry. From start-ups to international corporate facilities, we have a solution.</b>
          </p>
          <Button onClick={goToSignUpPage}>Sign Up Now</Button>
          &nbsp;&nbsp;&nbsp;
          <Button onClick={goToSignInPage} className="login-button">
            Log In
          </Button>
          <br />
          <br />
        </div>
      </section>
      <hr />
      <section className="feature-one">
        <h2>Integrations We Offer:</h2>
        <Image className="img-responsive" src={featureOne} />
        <div className="description">
          <h3>Easily allow your exercisers to log in with your experience using:</h3>
          <ul>
            <b>
              <li>RFID</li>
              <li>Remote Login</li>
              <li>Single Sign On</li>
              <li>QR Codes</li>
            </b>
          </ul>
        </div>
      </section>
      <section className="feature-two">
        <Image className="img-responsive" src={featureTwo} />
        <div className="description">
          <h3>See exerciser result data from:</h3>
          <ul>
            <b>
              <li>Workout Results</li>
              <li>Notifications</li>
              <li>Subscriptions</li>
            </b>
          </ul>
        </div>
      </section>
      <section className="feature-three">
        <Image className="img-responsive" src={featureThree} />
        <div className="description">
          <h3>Connect your apps:</h3>
          <ul>
            <b>
              <li>iOS SDK</li>
              <li>Android SDK</li>
            </b>
          </ul>
        </div>
      </section>
      <section className="feature-four">
        <Image className="img-responsive" src={featureFour} />
        <div className="description">
          <h3>Elevate your exercisers&rsquo; workout experience:</h3>
          <ul>
            <b>
              <li>Create Workouts for Exercisers</li>
              <li>Real-Time Leaderboard</li>
              <li>On-Console Messaging</li>
            </b>
          </ul>
        </div>
      </section>
      <section className="feature-five">
        <Image className="img-responsive" src={featureFive} />
        <div className="description">
          <h3>Fitness equipment asset management* with:</h3>
          <ul>
            <b>
              <li>Real-Time Usage</li>
              <li>Equipment Stats</li>
              <li>Equipment Monitoring</li>
            </b>
          </ul>
        </div>
      </section>
      <section className="feature-six">
        <h2>Grow Your Business with Developer Connect</h2>
        <div className="separateContent">
          <Image className="img-responsive" src={featureSix} />
          <div className="description">
            <h3>Integrate with the worlds largest fitness product manufacturer</h3>
            <p>160 countries, 22 languages, a global fitness market at your disposal to create global solutions.</p>
          </div>
        </div>
      </section>
      <section className="feature-seven">
        <div className="separateContent">
          <Image className="img-responsive" src={featureSeven} />
          <div className="description">
            <h3>Create solutions that put you ahead of the competition</h3>
            <p>Have the access to incredible software solutions that give your product the ability to interact and automate Life Fitness and Cybex equipment.</p>
          </div>
        </div>
      </section>
      <section className="feature-eight">
        <div className="separateContent">
          <Image className="img-responsive" src={featureEight} />
          <div className="description">
            <h3>Be part of the platform that connects the world to fitness</h3>
            <p>Automate equipment check-ins, send custom workouts to exercisers, and manage Leaderboards to attract and retain clients.</p>
          </div>
        </div>
      </section>
      <section className="quotesection">
        <div className="content">
          <Image className="img-responsive" src={quoteicon} />
          <h3>Working with Developer Connect' APIs is super simple - there is plenty of flexibility to create slick, user-friendly experiences that can please exercisers.</h3>
          <br />
          <p className="designation">
            <b>
              Jamie Cole
              <br />
              FitConnect, Founder
            </b>
          </p>
        </div>
      </section>
      <section className="compatible-apps">
        <div className="content">
          <h3>COMPATIBLE APP APIs</h3>
          <br />
          <div className="image-container">
            <Image className="img-responsive" src={applehealth} />
            <div className="caption">Apple Health</div>
          </div>
          <div className="image-container">
            <Image className="img-responsive" src={fitbit} />
            <div className="caption">Fitbit</div>
          </div>
          <div className="image-container">
            <Image className="img-responsive" src={googlefit} />
            <div className="caption">Google Fit</div>
          </div>
          <div className="image-container">
            <Image className="img-responsive" src={jawbone} />
            <div className="caption">Jawbone</div>
          </div>
          <div className="image-container">
            <Image className="img-responsive" src={myfitnesspal} />
            <div className="caption">MyFitnessPal</div>
          </div>
          <div className="image-container">
            <Image className="img-responsive" src={samsunghealth} />
            <div className="caption">Samsung Health</div>
          </div>
        </div>
      </section>
      <section className="relaunch">
        <div className="content">
          <h3>Be a part of the digital fitness transformation</h3>
          <br />
          <Button onClick={goToSignUpPage}>Sign Up Now</Button>
          <p>
            Contact us for pricing:&nbsp;
            <LinkComponent url="mailto:partner-support@halo.fitness" title="partner-support@halo.fitness" isExternalLink />
          </p>
        </div>
      </section>
    </div>
  );
};

export default LandingComponent;
