import { Button } from "react-bootstrap";
import "./contact.scss";
import { useState } from "react";

const ContactComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="Contact-component">
      <Button onClick={onClickHandler}>
        Contact Us
        <span className={`arrow-icon ${isOpen && "reverse"}`} />
      </Button>
    </div>
  );
};

export default ContactComponent;
