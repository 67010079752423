import { Button } from "react-bootstrap";

import "./endpointgrid.scss";

const EndpointGridComponent = (props) => {
  const ENDPOINT_GRID_PROPS = {
    method: ({ method }) => <Button className={`${method}-button no-click`}>{method}</Button>,
    description: ({ url, description }) => (
      <a href="#">
        <b>{url}</b>&emsp;{description}
      </a>
    ),
  };

  const { apiObject } = props;
  console.log("apiObject: ", JSON.stringify(apiObject));
  let gridProps = ENDPOINT_GRID_PROPS;

  return (
    <>
      <div className="endpoint-container">
        <div className="endpoint-method">
          {gridProps.method(apiObject)}
          <div className="endpoint-description">{gridProps.description(apiObject)}</div>
        </div>
      </div>
    </>
  );
  // <GridContainer
  //   className="endpoint-grid"
  //   name={`${apiObject.description}-endpoint-grid`}
  //   gridProps={gridProps}
  //   items={[
  //     {
  //       method: apiObject.method,
  //       url: apiObject.url,
  //       description: apiObject.description,
  //     },
  //   ]}
  // />
};

export default EndpointGridComponent;
