import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";

import "./update-profile.scss";
import UpdateProfilePanelComponent from "../../../components/profile/Profile/UpdateProfilePanelComponent";

const UpdateProfileComponent = (props) => {
  const [activeKey, setActiveKey] = useState(props.activePanel);
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Update Profile Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  const { initialValues: currentUserInfo } = props;

  const handleSelect = (activeKey) => {
    setActiveKey({ activeKey });
  };

  return (
    <div>
      <div className="update-profile-component">
        <h3>Profile</h3>
        <div>
          <UpdateProfilePanelComponent activeKey={activeKey} handleSelect={handleSelect} currentUserInfo={currentUserInfo} />
        </div>
      </div>
    </div>
  );
};

UpdateProfileComponent.propTypes = {
  activePanel: PropTypes.string,
  currentUserInfo: PropTypes.object,
};

export default UpdateProfileComponent;
