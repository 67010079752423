import { getArchives } from "../store/actions/storage";
import WebArchivesComponent from "../components/developerApi/developerSdk/Archives/WebArchivesComponent";
import { ARCHIVES } from "../constants/storage";

const WebArchivesContainer = ({ api }) => {
  const params = {
    title: "Archives",
    content: "Below is a collection of release notes of legacy API releases for the Developer Connect platform.",
    version: "latest",
  };
  return <WebArchivesComponent api={api} {...params} />;
};

// const mapStateToProps = (
//   { session: { user }, storage: { files, isLoading } },
//   { api }
// ) => ({
//   api,
//   archives: files[`${api}.${ARCHIVES}`] || {},
//   isLoading,
//   user,
// });

// const mapDispatchToProps = {
//   getArchives,
// };

// const mergeProps = ({ api, version, user, ...stateProps }, { getArchives }) => {
//   return {
//     ...stateProps,
//     user,
//     getArchives: () => getArchives(api, "latest"),
//     title: `Archives`,
//     content: `Below is a collection of release notes of legacy API releases for the Developer Connect platform.`,
//   };
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
//   mergeProps
// )(WebArchivesComponent);

export default WebArchivesContainer;
