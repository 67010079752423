import "./fieldGroup.scss";
import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import InputComponent from "./FieldComponents/InputComponent";
import { Form } from "react-bootstrap";
import { Field } from "react-final-form";
import SelectComponent from "./FieldComponents/SelectComponent";
import CaptchaComponent from "../CaptchaComponent/CaptchaComponent";
import SearchComponent from "./FieldComponents/SearchComponent";
// import DatePickerComponent from "../DatePickerComponent/DatePickerComponent";

const FieldGroupComponent = (props) => {
  const { label, name, className, disabled, type, required, placeholder, formProps, checked, onChange, value } = props;
  const submissionError = props.submissionError || {};
  const renderInputWrapper = (label, required, touched, error, invalid, inputField) => {
    const isInvalid = touched && invalid && !submissionError[name];
    let _className = isInvalid ? "invalid" : className;
    let _error = error;
    if (Array.isArray(error)) {
      _error = (
        <span>
          {error.map((e, i) => {
            return (
              <span key={e.message} className={e.value ? "gray" : "green"} style={{ color: "black" }}>
                {e.message}
                {i < error.length && <br />}
              </span>
            );
          })}
        </span>
      );
    }

    return (
      <Form.Group className="form-group">
        {label && (
          <Form.Label>
            {required && <span className="required">*</span>} {label}
          </Form.Label>
        )}
        {inputField(_className)}
        {submissionError[name] && <div className="help-block">{submissionError[name]}</div>}
        {_error && isInvalid && <div className="help-block">{_error}</div>}
      </Form.Group>
    );
  };

  const renderFieldComponent = ({ input, meta }) => {
    const { touched, error, invalid } = meta;
    return renderInputWrapper(label, required, touched, error, invalid, (_className) => {
      let component;
      switch (type) {
        case "select":
          component = <SelectComponent input={input} formProps={formProps} label={label} className={className} />;
          break;
        case "checkbox":
          component = <CheckboxComponent input={input} formProps={formProps} label={placeholder} />;
          break;
        case "date":
          break;
        case "captcha":
          component = <CaptchaComponent input={input} formProps={formProps} />;
          break;
        case "search":
          component = <SearchComponent input={input} type={type} formProps={formProps} readOnly={disabled} className={_className} onChange={onChange} />;
          break;
        default:
          component = <InputComponent input={input} type={type} formProps={formProps} readOnly={disabled} className={_className} onChange={onChange} />;
          break;
      }

      return component;
    });
  };

  return (
    <>
      <Field name={name} type={type} value={value}>
        {renderFieldComponent}
      </Field>
    </>
  );
};

export default FieldGroupComponent;
