import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STORAGE } from "../Constants";
import { API_NAMES } from "../../constants/common";
import { ARCHIVES } from "../../constants/storage";
import { getArchiveFiles } from "../../firebase/storage";

// INITIAL STATE
const initialState = STORAGE.INITIAL_STATE;

// CREATE ACTIONS
export const getArchivesAction = createAsyncThunk(STORAGE.ACTION.GET_ARCHIVES, async (payload, { rejectWithValue, getState, dispatch }) => {
  const { api, version } = payload;
  const path = `metadata/${API_NAMES[api]}`;
  try {
    const archives = await getArchiveFiles(path, version);
    return { path: `${api}.${ARCHIVES}`, file: archives, version, api };
  } catch (error) {
    //TODO handle storage errors
    return rejectWithValue(error);
  }
});

// CREATE SLICE
const storageSlice = createSlice({
  name: STORAGE.SLICE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getArchivesAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getArchivesAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.path = action.payload.path;
      state.files[action.payload.path] = action.payload.file;
      state.sdkVersion[action.payload.api] = action.payload.version;
    });

    builder.addCase(getArchivesAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });
  },
});

// CREATE REDUCER
const storageReducer = storageSlice.reducer;
export default storageReducer;
