import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LICENSES } from "../Constants";
import { createLicense, getLicenses, removeLicense, updateLicense } from "../../firebase/licenses";
import { ENVIRONMENTS, LICENSE_CATEGORY_MAP, PLATFORMS, PLATFORM_NAMES } from "../../constants/common";
import licenseApi from "../../api/licenseApi";
import facilityConfigurationApi from "../../api/facilityConfigurationApi";
import moment from "moment";
import { getAuthInfoAction } from "./sessionSlice";
import { updateUser } from "../../firebase/user";
import { getPendingFacilities, getPendingFacilitiesAdmin, updateCompany, updatePendingFacilities } from "../../firebase/company";
import { createOrganizations, createTicket, createZendeskUser, resolveTicket, updateTicket } from "../../firebase/cloud-functions";
import { toast } from "react-toastify";
import { ZENDESK_MESSAGE, ZENDESK_STATUS } from "../../constants/zendesk";
import { setGridItemsAction } from "./gridSlice";

const _ = require("lodash");

const prepareLicenseItemsForGrid = (licensesItems) => {
  let licenses = { dev: [], prod: [], devInProd: [] };
  if (licensesItems && licensesItems.length)
    licenses = {
      dev: [
        ...licensesItems
          .filter((license) => license.dev && !license.prod)
          .map((license) => ({
            firebaseId: license.id,
            ...license.dev,
          })),
        ...licensesItems
          .filter((license) => license.prod)
          .map((license) => ({
            firebaseId: license.id,
            inProd: true,
            ...license.dev,
          })),
      ],
      prod: licensesItems
        .filter((license) => license.prod)
        .map((license) => ({
          firebaseId: license.id,
          ...license.prod,
        })),
    };

  return licenses;
};
const prepareUser = ({ developerId, userName, email, firstName, lastName }) => ({
  ...(developerId && { id: developerId }),
  userId: userName,
  email,
  firstName,
  lastName,
  termsAndConditionAccepted: true,
  subscriptionAgreementAccepted: true,
});

const prepareCompany = ({ id, name, phone, platform, size, companySize, industry, vertical, country, address, city, state, postalCode, otherIndustry, otherVertical }) => ({
  ...(id && { id }),
  name,
  enrollmentType: platform.map((type) => PLATFORMS[type.trim()]).join(","),
  phoneNo: phone,
  companySize: size || companySize,
  industry,
  vertical: industry === "Fitness" ? vertical : null,
  country,
  address,
  city,
  state,
  postalCode,
  otherIndustry,
  otherVertical,
});

const prepareLicense = ({ id, name, description, category, platform, license, consumerSecret, consumerKey, redirectUrl, subEnabled, facilitiesSubscribedFor, accountCallback, manualCallback, lfConsoleCallback, gpsCallback, lifefitnessCallback, lfUserLoginCallback, lfUserLogoutCallback, lfUserWorkoutStartCallback, lfUserWorkoutEndCallback, workoutdataCallback, strengthAssetLoginCallback, strengthAssetLogoutCallback, strengthResultsCallback, assetStatsCallback, apigeeAppId }) => ({
  id,
  name,
  description,
  appCategoryId: LICENSE_CATEGORY_MAP[category],
  platform: PLATFORMS[platform],
  license,
  redirectUrl,
  subEnabled: subEnabled || false,
  ...(consumerSecret && { consumerSecret }),
  ...(consumerKey && { consumerKey }),
  ...(facilitiesSubscribedFor && { facilitiesSubscribedFor }),
  ...(subEnabled && accountCallback && { accountCallback }),
  ...(subEnabled && manualCallback && { manualCallback }),
  ...(subEnabled && lfConsoleCallback && { lfConsoleCallback }),
  ...(subEnabled && gpsCallback && { gpsCallback }),
  ...(subEnabled && lifefitnessCallback && { lifefitnessCallback }),
  ...(subEnabled && lfUserLoginCallback && { lfUserLoginCallback }),
  ...(subEnabled && lfUserLogoutCallback && { lfUserLogoutCallback }),
  ...(subEnabled && lfUserWorkoutStartCallback && { lfUserWorkoutStartCallback }),
  ...(subEnabled && lfUserWorkoutEndCallback && { lfUserWorkoutEndCallback }),
  ...(subEnabled && workoutdataCallback && { workoutdataCallback }),
  ...(subEnabled && strengthAssetLoginCallback && { strengthAssetLoginCallback }),
  ...(subEnabled && strengthAssetLogoutCallback && { strengthAssetLogoutCallback }),
  ...(subEnabled && strengthResultsCallback && { strengthResultsCallback }),
  ...(subEnabled && assetStatsCallback && { assetStatsCallback }),
  ...(apigeeAppId && { apigeeAppId }),
});

const prepareLicenseFirebase = ({ id, name, description, appCategoryId, platform, license, consumerSecret, consumerKey, redirectUrl, subEnabled, facilitiesSubscribedFor, facilitiesSubscriptionPending, facilitiesSubscriptionRejected, facilitiesAdded, accountCallback, manualCallback, lfConsoleCallback, gpsCallback, lifefitnessCallback, lfUserLoginCallback, lfUserLogoutCallback, lfUserWorkoutStartCallback, lfUserWorkoutEndCallback, workoutdataCallback, strengthAssetLoginCallback, strengthAssetLogoutCallback, strengthResultsCallback, assetStatsCallback, apigeeAppId }) => ({
  id,
  name,
  description,
  appCategoryId,
  platform,
  license,
  redirectUrl,
  subEnabled: subEnabled || false,
  ...(consumerSecret && { consumerSecret }),
  ...(consumerKey && { consumerKey }),
  ...(facilitiesSubscribedFor && { facilitiesSubscribedFor }),
  ...(facilitiesSubscriptionPending && { facilitiesSubscriptionPending }),
  ...(facilitiesSubscriptionRejected && { facilitiesSubscriptionRejected }),
  ...(facilitiesAdded && { facilitiesAdded }),
  accountCallback: subEnabled && accountCallback ? accountCallback : null,
  manualCallback: subEnabled && manualCallback ? manualCallback : null,
  lfConsoleCallback: subEnabled && lfConsoleCallback ? lfConsoleCallback : null,
  gpsCallback: subEnabled && gpsCallback ? gpsCallback : null,
  lifefitnessCallback: subEnabled && lifefitnessCallback ? lifefitnessCallback : null,
  lfUserLoginCallback: subEnabled && lfUserLoginCallback ? lfUserLoginCallback : null,
  lfUserLogoutCallback: subEnabled && lfUserLogoutCallback ? lfUserLogoutCallback : null,
  lfUserWorkoutStartCallback: subEnabled && lfUserWorkoutStartCallback ? lfUserWorkoutStartCallback : null,
  lfUserWorkoutEndCallback: subEnabled && lfUserWorkoutEndCallback ? lfUserWorkoutEndCallback : null,
  workoutdataCallback: subEnabled && workoutdataCallback ? workoutdataCallback : null,
  strengthAssetLoginCallback: subEnabled && strengthAssetLoginCallback ? strengthAssetLoginCallback : null,
  strengthAssetLogoutCallback: subEnabled && strengthAssetLogoutCallback ? strengthAssetLogoutCallback : null,
  strengthResultsCallback: subEnabled && strengthResultsCallback ? strengthResultsCallback : null,
  assetStatsCallback: subEnabled && assetStatsCallback ? assetStatsCallback : null,
  ...(apigeeAppId && { apigeeAppId }),
});

const formatLicense = (license, env) => ({
  ...license,
  platform: _.findKey(PLATFORMS, (type) => type === license.platform),
  category: _.findKey(LICENSE_CATEGORY_MAP, (categoryId) => categoryId === license.appCategoryId),
  env,
});

// INITIAL STATE
const initialState = LICENSES.INITIAL_STATE;

// CREATE ACTIONS
export const getLicensesAction = createAsyncThunk(LICENSES.ACTION.GET_LICENSES, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { companyId } = payload;
    const licenses = await getLicenses(companyId);
    return Object.keys(licenses).map((licenseKey) => {
      const { dev, prod } = licenses[licenseKey];
      const license = {
        [ENVIRONMENTS.dev]: formatLicense(dev, ENVIRONMENTS.dev),
        ...(prod && { [ENVIRONMENTS.prod]: formatLicense(prod, ENVIRONMENTS.prod) }),
      };

      return {
        ...license,
        id: licenseKey,
      };
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getLicenseAction = createAsyncThunk(LICENSES.ACTION.GET_LICENSE, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { licenseId } = payload;
    return licenseId;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addLicenseAction = createAsyncThunk(LICENSES.ACTION.ADD_LICENSE, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { user, company, licenseData: data } = payload;
    data.facilitiesSubscribedFor = data.facilitiesAdded || "";
    data.subEnabled = ((data.facilitiesSubscribedFor && data.facilitiesSubscribedFor.length > 0) || (data.facilitiesSubscriptionPending && data.facilitiesSubscriptionPending.length > 0) || (data.facilitiesSubscriptionRejected && data.facilitiesSubscriptionRejected.length > 0) || (data.facilitiesAdded && data.facilitiesAdded.length > 0)) && data.platform === PLATFORM_NAMES.web;

    console.log("%%%%%%%%%%%%%%%%% addLicenseAction.payload: ", payload);
    console.log("%%%%%%%%%%%%%%%%% addLicenseAction.user: ", user);
    console.log("%%%%%%%%%%%%%%%%% addLicenseAction.company: ", company);
    console.log("%%%%%%%%%%%%%%%%% addLicenseAction.data: ", data);
    let apiResult = await licenseApi.create(prepareUser(user), prepareCompany(company), prepareLicense(data));
    apiResult = apiResult.data;
    console.log("%%%%%%%%%%%%%%%%% BEFORE addLicenseAction.apiResult: ", apiResult);
    if (data.platform === PLATFORM_NAMES.web) {
      apiResult.facilitiesSubscriptionPending = data.facilitiesSubscribedFor;
      apiResult.facilitiesSubscribedFor = "";
      apiResult.facilitiesSubscriptionRejected = "";
      apiResult.facilitiesAdded = "";
      data.assetApi && data.assetApi !== "" ? (apiResult.assetApi = true) : (apiResult.assetApi = null);
      data.forcedLogIn && data.forcedLogIn !== "" ? (apiResult.forcedLogIn = true) : (apiResult.forcedLogIn = null);
    } else {
      delete apiResult.facilitiesSubscribedFor;
      delete apiResult.facilitiesSubscriptionPending;
      delete apiResult.facilitiesSubscriptionRejected;
      delete apiResult.facilitiesAdded;
    }
    console.log("%%%%%%%%%%%%%%%%% AFTER addLicenseAction.apiResult: ", apiResult);
    const license = { ..._.omit(apiResult, ["developerId", "companyId"]), lastUpdated: moment().valueOf() };
    console.log("%%%%%%%%%%%%%%%%% addLicenseAction.license: ", license);
    let licenseObjectForFirebase = prepareLicenseFirebase(license);
    console.log("%%%%%%%%%%%%%%%%% BEFORE addLicenseAction.licenseObjectForFirebase: ", licenseObjectForFirebase);
    licenseObjectForFirebase = { ...licenseObjectForFirebase, lastUpdated: moment().valueOf() };
    console.log("%%%%%%%%%%%%%%%%% AFTER addLicenseAction.licenseObjectForFirebase: ", licenseObjectForFirebase);

    if (!user.id) {
      const params = { developerId: apiResult.developerId };
      await updateUser(user.uid, params);
      dispatch(getAuthInfoAction());
    }
    console.log("%%%%%%%%%%%%%%%%% AFTER addLicenseAction.updateUser");
    if (!company.id) {
      const params = { id: apiResult.companyId };
      await updateCompany(company.firebaseId, params);

      // need to set "current company"??? EPEDRO
    }
    console.log("%%%%%%%%%%%%%%%%% AFTER addLicenseAction.updateCompany");
    // create license in firebase
    await createLicense(company.firebaseId, licenseObjectForFirebase);
    console.log("%%%%%%%%%%%%%%%%% AFTER addLicenseAction.createLicense");
    const return_license = {
      [ENVIRONMENTS.dev]: formatLicense(license, ENVIRONMENTS.dev),
      id: license.id,
    };
    console.log("%%%%%%%%%%%%%%%%% addLicenseAction.return_license: ", return_license);
    console.log("%%%%%%%%%%%%%%%%% addLicenseAction.license: ", license);
    let callbacksRequested =
      Object.keys(license)
        .filter((el) => el.includes("Callback"))
        .join(",") || "";
    let additionalRequestedArr = [];
    data.assetApi && additionalRequestedArr.push("assetApi");
    data.forcedLogIn && additionalRequestedArr.push("forcedLogIn");
    let additionalRequested = additionalRequestedArr.join(",");
    console.log("%%%%%%%%%%%%%%%%% addLicenseAction.data: ", data);
    if (data.platform === PLATFORM_NAMES.web) {
      // check if user exists in zendesk
      if (!user.zendeskId) {
        let response = await createOrganizations(user.uid);
        let orgZendeskObj = await response.json();
        let userObj = {
          user,
          organizationId: orgZendeskObj.zendeskId,
        };
        response = await createZendeskUser(userObj);
        if (response.ok) dispatch(getAuthInfoAction());
      }

      let zendeskTicketId = 0;
      let pendingObj = await getPendingFacilities(company.firebaseId);
      pendingObj = {
        ...pendingObj,
        backendId: company.id,
        companyName: company.name,
        userEmail: user.email,
        userName: `${user.firstName} ${user.lastName}`,
      };
      if (!pendingObj.dev) pendingObj.dev = {};

      if (data.facilitiesAdded && license.facilitiesSubscriptionPending !== "") {
        let ticketObj = {
          facilitiesAdded: data.facilitiesAdded,
          companyName: company.name,
          userName: `${user.firstName} ${user.lastName}`,
          userEmail: user.email,
          licenseName: license.name,
          callbacksRequested,
          additionalRequested,
          licenseEnv: ENVIRONMENTS.dev,
        };

        let callSuccess = false;
        await createTicket(ticketObj)
          .then((response) => {
            if (response.ok) callSuccess = true;
            return response.json();
          })
          .then((ticketId) => {
            if (callSuccess) zendeskTicketId = ticketId;
          });
      }

      pendingObj.dev[license.id] = {
        callbacks: callbacksRequested,
        additional: additionalRequested,
        consumerKey: license.consumerKey,
        facilities: license.facilitiesSubscriptionPending,
        licenseName: license.name,
        zendeskTicketId: zendeskTicketId > 0 ? zendeskTicketId : null,
      };

      if (!pendingObj.dev[license.id].facilities) {
        delete pendingObj.dev[license.id];
        if (!Object.keys(pendingObj.dev).length) delete pendingObj.dev;
        if (!pendingObj.dev && !pendingObj.prod) pendingObj = null;
      }

      await updatePendingFacilities(company.firebaseId, pendingObj);
    }

    toast(LICENSES.RESPONSE.MESSAGE.CREATED(data.name, license.license));
    return return_license;
  } catch (error) {
    console.log("*************** addLicenseAction.error: ", error);
    if (error.response && error.response.data.split("\n")[0].split(": ")[1] === "111") return rejectWithValue({ incorrectIds: error.response.data.split("\n")[1].split(":")[2].replace("[", "").replace("]", "") });
    else return rejectWithValue(error);
  }
});

export const changeLicenseAction = createAsyncThunk(LICENSES.ACTION.CHANGE_LICENSE, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { user, company, licenseData } = payload;
    let data = { ...licenseData };
    let facilitiesAdded = data.facilitiesAdded;

    let zendeskUpdateRequired = false;
    let facilitiesRemoved = data.facilitiesSubscribedFor ? data.facilitiesSubscribedFor.split("-")[1] : "";
    data.facilitiesSubscribedFor = data.facilitiesSubscribedFor ? data.facilitiesSubscribedFor.split("-")[0] : "";
    data.facilitiesSubscriptionPending = data.facilitiesSubscriptionPending && data.facilitiesSubscriptionPending.split("-")[0].length > 0 ? data.facilitiesSubscriptionPending.split("-")[0] + (facilitiesAdded ? "," + facilitiesAdded : "") : facilitiesAdded ? "" + facilitiesAdded : "";
    data.facilitiesSubscriptionRejected = data.facilitiesSubscriptionRejected ? data.facilitiesSubscriptionRejected.split("-")[0] : "";

    if ((data["assetApi-active"] && data.assetApi) || (data["forcedLogIn-active"] && data.forcedLogIn)) {
      data.facilitiesSubscriptionPending = data.facilitiesSubscriptionPending && data.facilitiesSubscriptionPending.split("-")[0].length > 0 ? data.facilitiesSubscriptionPending + (data.facilitiesSubscribedFor && data.facilitiesSubscribedFor.split("-")[0].length > 0 ? "," + data.facilitiesSubscribedFor : "") : data.facilitiesSubscribedFor;
      data.facilitiesSubscribedFor = "";
      zendeskUpdateRequired = true;
    }

    data.subEnabled = (data.facilitiesSubscribedFor && data.facilitiesSubscribedFor.length > 0) || (data.facilitiesSubscriptionPending && data.facilitiesSubscriptionPending.length > 0) || (data.facilitiesSubscriptionRejected && data.facilitiesSubscriptionRejected.length > 0) || (facilitiesAdded && facilitiesAdded.length > 0) || false;

    let tempSubscribed = data.facilitiesSubscribedFor;
    data.facilitiesSubscribedFor += (data.facilitiesSubscribedFor.length > 0 ? "," : "") + data.facilitiesSubscriptionPending;

    const licenseEnv = data.env;
    const prodDate = data.prodDate;
    let license = prepareLicense(data);
    let licenseObjectForApi = _.cloneDeep(license);
    await licenseApi.update(licenseObjectForApi, licenseEnv);
    if (data.platform === PLATFORM_NAMES.web) {
      licenseObjectForApi.facilitiesSubscribedFor = tempSubscribed;
      licenseObjectForApi.facilitiesSubscriptionPending = data.facilitiesSubscriptionPending;
      licenseObjectForApi.facilitiesSubscriptionRejected = data.facilitiesSubscriptionRejected;
      licenseObjectForApi.facilitiesAdded = "";
      data.assetApi && data.assetApi !== "" ? (licenseObjectForApi.assetApi = true) : (licenseObjectForApi.assetApi = null);
      data.forcedLogIn && data.forcedLogIn !== "" ? (licenseObjectForApi.forcedLogIn = true) : (licenseObjectForApi.forcedLogIn = null);
      licenseData.facilitiesSubscribedFor = tempSubscribed;
      licenseData.facilitiesSubscriptionPending = data.facilitiesSubscriptionPending;
      licenseData.facilitiesSubscriptionRejected = data.facilitiesSubscriptionRejected;
      licenseData.facilitiesAdded = "";
      data.assetApi && data.assetApi !== "" ? (licenseData.assetApi = true) : (licenseData.assetApi = null);
      data.forcedLogIn && data.forcedLogIn !== "" ? (licenseData.forcedLogIn = true) : (licenseData.forcedLogIn = null);
      if (!data.subEnabled) {
        const __callbacks__ = ["accountCallback", "manualCallback", "lfConsoleCallback", "gpsCallback", "lifefitnessCallback", "lfUserLoginCallback", "lfUserLogoutCallback", "lfUserWorkoutStartCallback", "lfUserWorkoutEndCallback", "workoutdataCallback", "strengthAssetLoginCallback", "strengthAssetLogoutCallback", "strengthResultsCallback", "assetStatsCallback"];
        licenseData.subEnabled = false;
        __callbacks__.forEach((c) => delete licenseData[c]);
      }
    } else {
      let __facilitiesSubscription__ = ["facilitiesSubscribedFor", "facilitiesSubscriptionPending", "facilitiesSubscriptionRejected", "facilitiesAdded"];
      __facilitiesSubscription__.forEach((f) => {
        delete licenseObjectForApi[f];
        delete licenseData[f];
      });
    }

    let licenseObjectForFirebase = prepareLicenseFirebase(licenseObjectForApi);
    const __tempLicenseKeys__ = ["license", "consumerKey", "consumerSecret"];
    licenseObjectForApi = {
      ...licenseObjectForApi,
      ..._.pick(licenseObjectForApi, __tempLicenseKeys__),
      ...(prodDate && { prodDate: prodDate.valueOf() }),
      lastUpdated: moment().valueOf(),
    };

    licenseObjectForFirebase = {
      ...licenseObjectForFirebase,
      ..._.pick(licenseObjectForFirebase, __tempLicenseKeys__),
      ...(prodDate && { prodDate: prodDate.valueOf() }),
      lastUpdated: moment().valueOf(),
    };

    await updateLicense(company.firebaseId, licenseObjectForFirebase, licenseEnv);
    const updated_license = {
      license: formatLicense(licenseObjectForApi, licenseEnv),
      env: licenseEnv,
    };

    let callbacksRequested =
      Object.keys(licenseObjectForApi)
        .filter((el) => el.includes("Callback"))
        .join(",") || "";
    let additionalRequestedArr = [];
    data.assetApi && additionalRequestedArr.push("assetApi");
    data.forcedLogIn && additionalRequestedArr.push("forcedLogIn");
    let additionalRequested = additionalRequestedArr.join(",");

    if (data.platform === PLATFORM_NAMES.web) {
      // Check if user exists in Zendesk
      if (!user.zendeskId) {
        let response = await createOrganizations(user.uid);
        let orgZendeskObj = await response.json();
        let userObj = {
          user: user,
          organizationId: orgZendeskObj.zendeskId,
        };
        response = await createZendeskUser(userObj);
        if (response.ok) dispatch(getAuthInfoAction());
      }

      // Create ticket for the user's facility request and add to pending facilities table
      let zendeskTicketId = 0;
      // if (data["assetApi-active"] && !data.assetApi) {
      //   let apigeeUpdateObj = {};
      //   apigeeUpdateObj.apigeeAppId = data.apigeeAppId;
      //   apigeeUpdateObj.environment = licenseEnv;
      //   await updateApigeeCustomAttribute({ apigeeUpdateObj });
      // } else if (facilitiesRemoved && facilitiesRemoved.length > 0 && data.assetApi) {
      //   let apigeeUpdateObj = {};
      //   apigeeUpdateObj.apigeeAppId = data.apigeeAppId;
      //   apigeeUpdateObj.environment = licenseEnv;
      //   if (licenseObjectForApi.facilitiesSubscribedFor && licenseObjectForApi.facilitiesSubscribedFor.length > 0) {
      //     apigeeUpdateObj.facilities = licenseObjectForApi.facilitiesSubscribedFor;
      //   }
      //   await updateApigeeCustomAttribute({ apigeeUpdateObj });
      // }
      if (data["forcedLogIn-active"] && !data.forcedLogIn) {
        let facilitiesObj = {
          facilities: data.facilitiesSubscribedFor
            .replace(/(^,)|(,$)/g, "")
            .split(",")
            .map((fId) => parseInt(fId, 10)),
        };
        await facilityConfigurationApi.disableForcedLogin(facilitiesObj, licenseEnv);
      } else if (facilitiesRemoved && facilitiesRemoved.length > 0 && data.forcedLogIn) {
        let facilitiesObj = {
          facilities: facilitiesRemoved
            .replace(/(^,)|(,$)/g, "")
            .split(",")
            .map((fId) => parseInt(fId, 10)),
        };
        await facilityConfigurationApi.disableForcedLogin(facilitiesObj, licenseEnv);
      }

      let pendingObj = await getPendingFacilities(company.firebaseId);
      pendingObj = {
        ...pendingObj,
        backendId: company.id,
        companyName: company.name,
        userEmail: user.email,
        userName: user.firstName + " " + user.lastName,
      };

      if (!pendingObj[licenseEnv]) {
        pendingObj[licenseEnv] = {};
      }

      if (pendingObj[licenseEnv][licenseObjectForApi.id] && pendingObj[licenseEnv][licenseObjectForApi.id].zendeskTicketId) {
        zendeskTicketId = pendingObj[licenseEnv][licenseObjectForApi.id].zendeskTicketId;

        let pendingObjFacilities = pendingObj[licenseEnv][licenseObjectForApi.id].facilities.split(",").sort();
        let licenseObjFacilities = licenseObjectForApi.facilitiesSubscriptionPending.split(",").sort();

        if ((pendingObj[licenseEnv][licenseObjectForApi.id].callbacks !== callbacksRequested || pendingObj[licenseEnv][licenseObjectForApi.id].additionalRequested !== additionalRequested) && licenseObjectForApi.facilitiesSubscriptionPending !== "") {
          zendeskUpdateRequired = true;
        } else if (licenseObjectForApi.facilitiesSubscriptionPending !== "") {
          if (pendingObjFacilities.length !== licenseObjFacilities.length) {
            zendeskUpdateRequired = true;
          } else {
            for (var i in pendingObjFacilities) {
              if (pendingObjFacilities[i] !== licenseObjFacilities[i]) {
                zendeskUpdateRequired = true;
                break;
              }
            }
          }
        }
      } else if (facilitiesAdded) {
        zendeskUpdateRequired = true;
      }

      if (zendeskUpdateRequired) {
        let ticketObj = {
          facilitiesAdded: licenseObjectForApi.facilitiesSubscriptionPending,
          companyName: company.name,
          userName: user.firstName + " " + user.lastName,
          userEmail: user.email,
          licenseName: licenseObjectForApi.name,
          callbacksRequested,
          additionalRequested,
          licenseEnv,
          organizationId: company.zendeskId,
        };
        let callSuccess = false;

        if (zendeskTicketId > 0) {
          ticketObj.zendeskTicketId = zendeskTicketId;
          await updateTicket(ticketObj)
            .then((response) => {
              if (response.ok) {
                callSuccess = true;
              }
              return response.json();
            })
            .then((ticketId) => {
              if (callSuccess) {
                zendeskTicketId = ticketId;
              }
            });
        } else {
          await createTicket(ticketObj)
            .then((response) => {
              if (response.ok) {
                callSuccess = true;
              }
              return response.json();
            })
            .then((ticketId) => {
              if (callSuccess) {
                zendeskTicketId = ticketId;
              }
            });
        }
      }
      pendingObj[licenseEnv][licenseObjectForApi.id] = {
        callbacks: callbacksRequested,
        additional: additionalRequested,
        consumerKey: licenseObjectForApi.consumerKey,
        facilities: licenseObjectForApi.facilitiesSubscriptionPending,
        licenseName: licenseObjectForApi.name,
        zendeskTicketId: zendeskTicketId > 0 ? zendeskTicketId : null,
      };
      if (pendingObj[licenseEnv][licenseObjectForApi.id].facilities === "") {
        if (pendingObj[licenseEnv][licenseObjectForApi.id].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: ZENDESK_MESSAGE.noFacilities,
            userName: user.firstName + " " + user.lastName,
            zendeskTicketId: pendingObj[licenseEnv][licenseObjectForApi.id].zendeskTicketId,
            status: ZENDESK_STATUS.closed,
          };
          await resolveTicket(resolveTicketObj);
        }

        delete pendingObj[licenseEnv][licenseObjectForApi.id];
        if (!Object.keys(pendingObj[licenseEnv]).length) {
          delete pendingObj[licenseEnv];
          if (!pendingObj.dev && !pendingObj.prod) pendingObj = null;
        }
      }
      await updatePendingFacilities(company.firebaseId, pendingObj);
    } // endif Web API platform

    toast(LICENSES.RESPONSE.MESSAGE.EDITED(data.name));
    const __state__ = getState();
    let __items__ = __state__.licenses.items;
    __items__ = __items__.map((l) => {
      if (parseInt(l.id) === parseInt(updated_license.license.id)) {
        return {
          ...l,
          [updated_license.env]: updated_license.license,
        };
      } else return l;
    });
    //dispatch(setGridItemsAction({ name: updated_license.env, items: prepareLicenseItemsForGrid(__items__)[updated_license.env] }));
    return __items__;
  } catch (error) {
    if (error.response && error.response.data.split("\n")[0].split(": ")[1] === "111") {
      return rejectWithValue({ incorrectIds: error.response.data.split("\n")[1].split(":")[2].replace("[", "").replace("]", "") });
    } else {
      return rejectWithValue(error);
    }
  }
});

export const moveLicenseAction = createAsyncThunk(LICENSES.ACTION.MOVE_LICENSE, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { user, company, licenseData } = payload;
    let data = { ...licenseData };
    let facilitiesAdded = data.facilitiesAdded;
    data.facilitiesSubscribedFor = facilitiesAdded || "";
    data.subEnabled = facilitiesAdded && facilitiesAdded.length > 0;

    let license = { ...prepareLicense(data) };
    await licenseApi.move(license);

    if (data.platform === PLATFORM_NAMES.web) {
      license.facilitiesSubscriptionPending = data.facilitiesSubscribedFor;
      license.facilitiesSubscribedFor = "";
      license.facilitiesSubscriptionRejected = "";
      license.facilitiesAdded = "";
      data.assetApi && data.assetApi !== "" ? (license.assetApi = true) : (license.assetApi = null);
      data.forcedLogIn && data.forcedLogIn !== "" ? (license.forcedLogIn = true) : (license.forcedLogIn = null);
    } else {
      const __facilitiesSubscription__ = ["facilitiesSubscribedFor", "facilitiesSubscriptionPending", "facilitiesSubscriptionRejected", "facilitiesAdded"];
      __facilitiesSubscription__.forEach((f) => delete license[f]);
    }

    let licenseObjectForFirebase = prepareLicenseFirebase(license);
    license = {
      ...license,
      prodDate: moment().valueOf(),
      lastUpdated: moment().valueOf(),
    };

    licenseObjectForFirebase = {
      ...licenseObjectForFirebase,
      prodDate: moment().valueOf(),
      lastUpdated: moment().valueOf(),
    };

    await updateLicense(company.firebaseId, licenseObjectForFirebase, ENVIRONMENTS.prod);
    const moved_license = {
      license: formatLicense(license, ENVIRONMENTS.prod),
      env: ENVIRONMENTS.prod,
    };

    let callbacksRequested =
      Object.keys(license)
        .filter((el) => el.includes("Callback"))
        .join(",") || "";
    let additionalRequestedArr = [];
    data.assetApi && additionalRequestedArr.push("assetApi");
    data.forcedLogIn && additionalRequestedArr.push("forcedLogIn");
    let additionalRequested = additionalRequestedArr.join(",");

    if (data.platform === PLATFORM_NAMES.web) {
      // Check if user exists in Zendesk
      if (!user.zendeskId) {
        let response = await createOrganizations(user.uid);
        let orgZendeskObj = await response.json();
        let userObj = {
          user: user,
          organizationId: orgZendeskObj.zendeskId,
        };
        response = await createZendeskUser(userObj);
        if (response.ok) dispatch(getAuthInfoAction());
      }

      let zendeskTicketId = 0;

      let pendingObj = await getPendingFacilities(company.firebaseId);
      pendingObj = {
        ...pendingObj,
        backendId: company.id,
        companyName: company.name,
        userEmail: user.email,
        userName: user.firstName + " " + user.lastName,
      };
      if (!pendingObj.prod) pendingObj.prod = {};

      if (facilitiesAdded && license.facilitiesSubscriptionPending !== "") {
        let ticketObj = {
          facilitiesAdded: facilitiesAdded,
          companyName: company.name,
          userName: user.firstName + " " + user.lastName,
          userEmail: user.email,
          licenseName: license.name,
          callbacksRequested: callbacksRequested,
          additionalRequested: additionalRequested,
          licenseEnv: ENVIRONMENTS.prod,
        };
        let callSuccess = false;
        await createTicket(ticketObj)
          .then((response) => {
            if (response.ok) {
              callSuccess = true;
            }
            return response.json();
          })
          .then((ticketId) => {
            if (callSuccess) {
              zendeskTicketId = ticketId;
            }
          });
      }

      pendingObj.prod[license.id] = {
        callbacks: callbacksRequested,
        additional: additionalRequested,
        consumerKey: license.consumerKey,
        facilities: license.facilitiesSubscriptionPending,
        licenseName: license.name,
        zendeskTicketId: zendeskTicketId > 0 ? zendeskTicketId : null,
      };

      if (!pendingObj.prod[license.id].facilities) {
        delete pendingObj.prod[license.id];
        if (Object.keys(pendingObj.prod).length === 0) {
          delete pendingObj.prod;
          if (!pendingObj.dev) pendingObj = null;
        }
      }
      await updatePendingFacilities(company.firebaseId, pendingObj);
    }

    toast(LICENSES.RESPONSE.MESSAGE.MOVED(data.name));
    return moved_license;
  } catch (error) {
    if (error.response && error.response.data.split("\n")[0].split(": ")[1] === "111") return rejectWithValue({ incorrectIds: error.response.data.split("\n")[1].split(":")[2].replace("[", "").replace("]", "") });
    else return rejectWithValue(error);
  }
});

export const deleteLicenseAction = createAsyncThunk(LICENSES.ACTION.DELETE_LICENSE, async (payload, { rejectWithValue, getState, dispatch }) => {
  const { company, licenseId, licenseName, user, apigeeAppId } = payload;
  try {
    await licenseApi.delete(licenseId, apigeeAppId);
  } catch (error) {
    console.log(`License API Delete ${licenseId} Failed: ${error}`);
  } finally {
    try {
      await removeLicense(company.firebaseId, licenseId);

      let pendingObj = await getPendingFacilities(company.firebaseId);
      if (pendingObj.dev && pendingObj.dev[licenseId]) {
        if (pendingObj.dev[licenseId].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: ZENDESK_MESSAGE.deletingLicense,
            userName: user.firstName + " " + user.lastName,
            zendeskTicketId: pendingObj.dev[licenseId].zendeskTicketId,
            status: ZENDESK_STATUS.closed,
          };
          await resolveTicket(resolveTicketObj);
        }

        delete pendingObj.dev[licenseId];
        await updatePendingFacilities(company.firebaseId, pendingObj);
      }

      toast(LICENSES.RESPONSE.MESSAGE.DELETED(licenseName));
      return { licenseId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
});

const validateAdd = (id, licenseData, attribute, move) => {
  console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^");
  console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^ valdateAdd.id: ", id);
  console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^ valdateAdd.licenseData: ", licenseData);
  console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^ valdateAdd.attribute: ", attribute);
  console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^ valdateAdd.move: ", move);
  const data = licenseData[attribute]?.split(",") || [];
  console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^ valdateAdd.data: ", data);

  let removedIndex = -1;
  if (!move && data.length > 0) {
    removedIndex = data.findIndex((el) => {
      return `-${id}` === el || `${id}` === el;
    });
  }

  console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^ valdateAdd.removedIndex: ", removedIndex);
  if (removedIndex >= 0) {
    // undelete the id
    data[removedIndex] = id;
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^ valdateAdd.data[removedIndex]: ", data[removedIndex]);
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^ return data.join(): ", data.join());
    return data.join();
  } else {
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^ return true");
    return true;
  }
};

export const addFacilitiesAction = createAsyncThunk(LICENSES.ACTION.ADD_FACILITIES, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { facilities, licenseData, moveAction } = payload;
    const licenseEnv = licenseData.env;
    let str = licenseData.facilitiesAdded || "";
    if (facilities === null) {
      licenseData.facilitiesSubscriptionPending = licenseData.facilitiesSubscriptionPending ? licenseData.facilitiesSubscriptionPending.split("-").join(",") : "";
    } else {
      let facilitiesSplit = facilities.split(",");
      facilitiesSplit.forEach((id) => {
        const notDeletedFacilitiesSubscribedFor = validateAdd(id, licenseData, "facilitiesSubscribedFor", moveAction);
        if (notDeletedFacilitiesSubscribedFor !== true) licenseData.facilitiesSubscribedFor = notDeletedFacilitiesSubscribedFor;

        const notDeletedFacilitiesSubscriptionPending = validateAdd(id, licenseData, "facilitiesSubscriptionPending", moveAction);
        if (notDeletedFacilitiesSubscriptionPending !== true) licenseData.facilitiesSubscriptionPending = notDeletedFacilitiesSubscriptionPending;

        const notDeletedFacilitiesSubscriptionRejected = validateAdd(id, licenseData, "facilitiesSubscriptionRejected", moveAction);
        if (notDeletedFacilitiesSubscriptionRejected !== true) licenseData.facilitiesSubscriptionRejected = notDeletedFacilitiesSubscriptionRejected;

        if (notDeletedFacilitiesSubscribedFor === true && notDeletedFacilitiesSubscriptionPending === true && notDeletedFacilitiesSubscriptionRejected === true) {
          if (str !== "") {
            str += ",";
          }
          str += parseInt(id, 10).toString();
        }
      });
      licenseData.facilitiesAdded = [...new Set(str.split(","))].join();
    }

    let license = prepareLicense(licenseData);

    license.facilitiesAdded = licenseData.facilitiesAdded;
    license.facilitiesSubscribedFor = licenseData.facilitiesSubscribedFor;
    license.facilitiesSubscriptionPending = licenseData.facilitiesSubscriptionPending;
    license.facilitiesSubscriptionRejected = licenseData.facilitiesSubscriptionRejected;
    licenseData.assetApi && licenseData.assetApi !== "" ? (license.assetApi = true) : (license.assetApi = null);
    licenseData.forcedLogIn && licenseData.forcedLogIn !== "" ? (license.forcedLogIn = true) : (license.forcedLogIn = null);

    return {
      license: formatLicense(license, licenseEnv),
      env: licenseEnv,
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeFacilitiesAction = createAsyncThunk(LICENSES.ACTION.REMOVE_FACILITIES, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { facilities, licenseData } = payload;
    console.log("********************** removeFacilitiesAction.facilities: ", facilities);
    console.log("********************** removeFacilitiesAction.licenseData: ", licenseData);
    const licenseEnv = licenseData.env;
    let subscribedSplit = licenseData.facilitiesSubscribedFor ? licenseData.facilitiesSubscribedFor.split(",") : [];
    let pendingSplit = licenseData.facilitiesSubscriptionPending ? licenseData.facilitiesSubscriptionPending.split(",") : [];
    let rejectedSplit = licenseData.facilitiesSubscriptionRejected ? licenseData.facilitiesSubscriptionRejected.split(",") : [];
    let addedSplit = licenseData.facilitiesAdded ? licenseData.facilitiesAdded.split(",") : [];
    let removedSubscribedSplit = [];
    let removedPendingSplit = [];
    let removedRejectedSplit = [];

    if (facilities === null) {
      subscribedSplit.join(",").startsWith("-") ? (subscribedSplit = subscribedSplit.join(",").slice(1)) : (subscribedSplit = subscribedSplit.join(","));
      pendingSplit.join(",").startsWith("-") ? (pendingSplit = pendingSplit.join(",").slice(1)) : (pendingSplit = pendingSplit.join(","));
      rejectedSplit.join(",").startsWith("-") ? (rejectedSplit = rejectedSplit.join(",").slice(1)) : (rejectedSplit = rejectedSplit.join(","));

      subscribedSplit = subscribedSplit.split("-").join(",").split(",");
      pendingSplit = pendingSplit.split("-").join(",").split(",");
      rejectedSplit = rejectedSplit.split("-").join(",").split(",");
      addedSplit = [];
    } else {
      let facilitiesSplit = facilities.split(",");

      facilitiesSplit.forEach((id) => {
        if (addedSplit.find((el) => el === parseInt(id, 10).toString()) !== undefined) {
          addedSplit.splice(addedSplit.indexOf(parseInt(id, 10).toString()), 1);
        } else if (subscribedSplit.find((el) => el.split("-")[0] === parseInt(id, 10).toString()) !== undefined) {
          removedSubscribedSplit.push(subscribedSplit.splice(subscribedSplit.indexOf(parseInt(id, 10).toString()), 1));
        } else if (pendingSplit.find((el) => el.split("-")[0] === parseInt(id, 10).toString()) !== undefined) {
          removedPendingSplit.push(pendingSplit.splice(pendingSplit.indexOf(parseInt(id, 10).toString()), 1));
        } else if (rejectedSplit.find((el) => el.split("-")[0] === parseInt(id, 10).toString()) !== undefined) {
          removedRejectedSplit.push(rejectedSplit.splice(rejectedSplit.indexOf(parseInt(id, 10).toString()), 1));
        }
      });
    }

    licenseData.facilitiesSubscribedFor = subscribedSplit.join(",") + (removedSubscribedSplit.length > 0 ? "-" + removedSubscribedSplit.join("-") : "");
    licenseData.facilitiesSubscriptionPending = pendingSplit.join(",") + (removedPendingSplit.length > 0 ? "-" + removedPendingSplit.join("-") : "");
    licenseData.facilitiesSubscriptionRejected = rejectedSplit.join(",") + (removedRejectedSplit.length > 0 ? "-" + removedRejectedSplit.join("-") : "");
    licenseData.facilitiesAdded = addedSplit.join(",");

    licenseData.subEnabled = (licenseData.facilitiesSubscribedFor && licenseData.facilitiesSubscribedFor.length > 0) || (licenseData.facilitiesSubscriptionPending && licenseData.facilitiesSubscriptionPending.length > 0) || (licenseData.facilitiesSubscriptionRejected && licenseData.facilitiesSubscriptionRejected.length > 0) || (licenseData.facilitiesAdded && licenseData.facilitiesAdded.length > 0) || false;

    let license = prepareLicense(licenseData);

    license.facilitiesAdded = licenseData.facilitiesAdded;
    license.facilitiesSubscribedFor = licenseData.facilitiesSubscribedFor;
    license.facilitiesSubscriptionPending = licenseData.facilitiesSubscriptionPending;
    license.facilitiesSubscriptionRejected = licenseData.facilitiesSubscriptionRejected;
    licenseData.assetApi && licenseData.assetApi !== "" ? (license.assetApi = true) : (license.assetApi = null);
    licenseData.forcedLogIn && licenseData.forcedLogIn !== "" ? (license.forcedLogIn = true) : (license.forcedLogIn = null);

    return {
      license: formatLicense(license, licenseEnv),
      env: licenseEnv,
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPendingFacilitiesAdminAction = createAsyncThunk(LICENSES.ACTION.GET_PENDING_FACILITIES_ADMIN, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const pendingFacilities = await Promise.all([getPendingFacilitiesAdmin()]);
    return pendingFacilities[0];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePendingFacilitiesAction = createAsyncThunk(LICENSES.ACTION.UPDATE_PENDING_FACILITIES, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { companyId, licenseId, env, company, facilities, action, consumerKey, additional } = payload;
    let status = "OK";
    let temp = JSON.parse(JSON.stringify(company));

    let licenses = await getLicenses(companyId);
    let licenseObj = licenses ? licenses[licenseId] : null;
    let license = licenseObj ? licenseObj[env.toLowerCase()] : null;
    let additionalSplit = additional ? additional.split(",") : [];
    if (license) {
      let filteredFacilities = facilities.split(",").filter((facility) => license.facilitiesSubscriptionPending.split(",").includes(facility));
      license.facilitiesSubscriptionPending = license.facilitiesSubscriptionPending
        .split(",")
        .filter((facility) => !filteredFacilities.includes(facility))
        .join(",");
      if (filteredFacilities && filteredFacilities.length > 0) {
        if (action === "approve") {
          license.facilitiesSubscribedFor = license.facilitiesSubscribedFor && license.facilitiesSubscribedFor.length > 0 ? license.facilitiesSubscribedFor + "," + filteredFacilities.join(",") : filteredFacilities.join(",");
        } else {
          license.facilitiesSubscriptionRejected = license.facilitiesSubscriptionRejected && license.facilitiesSubscriptionRejected.length > 0 ? license.facilitiesSubscriptionRejected + "," + filteredFacilities.join(",") : filteredFacilities.join(",");
        }
      }

      if (action === "approve") {
        let assetApiRequested = false;
        if (additionalSplit.find((el) => el === "assetApi")) {
          assetApiRequested = true;
        }
        await licenseApi.approve(licenseId, facilities, env, consumerKey, assetApiRequested);
      } else {
        await licenseApi.deny(licenseId, facilities, env, consumerKey, false);
      }

      /*
      if (additionalSplit.find((el) => el === "assetApi")) {
        let apigeeUpdateObj = {};
        apigeeUpdateObj.apigeeAppId = license.apigeeAppId;
        apigeeUpdateObj.environment = env;
        if (license.facilitiesSubscribedFor && license.facilitiesSubscribedFor.length > 0) {
          apigeeUpdateObj.facilities = license.facilitiesSubscribedFor;
        }
        await updateApigeeCustomAttribute({ apigeeUpdateObj });
      }
      */

      if (additionalSplit.find((el) => el === "forcedLogIn")) {
        if (action === "approve") {
          let facilitiesObj = {
            facilities: facilities
              .replace(/(^,)|(,$)/g, "")
              .split(",")
              .map((fId) => parseInt(fId, 10)),
          };
          await facilityConfigurationApi.enableForcedLogin(facilitiesObj, env.toLowerCase());
        } else {
          let facilitiesObj = {
            facilities: facilities
              .replace(/(^,)|(,$)/g, "")
              .split(",")
              .map((fId) => parseInt(fId, 10)),
          };
          await facilityConfigurationApi.disableForcedLogin(facilitiesObj, env.toLowerCase());
        }
      }

      license = {
        ...license,
        ..._.pick(license, ["license", "consumerKey", "consumerSecret"]),
        lastUpdated: moment().valueOf(),
      };

      await updateLicense(companyId, license, env.toLowerCase());

      if (temp[env.toLowerCase()][licenseId].facilities === "") {
        if (temp[env.toLowerCase()][licenseId].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: "Facility Id(s) " + facilities + (action === "approve" ? ZENDESK_MESSAGE.approvedClose : ZENDESK_MESSAGE.deniedClose),
            userName: "Life Fitness",
            zendeskTicketId: temp[env.toLowerCase()][licenseId].zendeskTicketId,
            status: ZENDESK_STATUS.closed,
          };
          await resolveTicket(resolveTicketObj);
        }

        delete temp[env.toLowerCase()][licenseId];
        if (Object.keys(temp[env.toLowerCase()]).length === 0) {
          temp[env.toLowerCase()] = {};
          if ((!temp.dev || Object.keys(temp.dev).length === 0) && (!temp.prod || Object.keys(temp.prod).length === 0)) {
            temp = null;
          }
        }
      } else {
        if (temp[env.toLowerCase()][licenseId].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: "Facility Id(s) " + facilities + (action === "approve" ? " approved" : " denied"),
            userName: "Life Fitness",
            zendeskTicketId: temp[env.toLowerCase()][licenseId].zendeskTicketId,
            status: ZENDESK_STATUS.open,
          };
          await resolveTicket(resolveTicketObj);
        }
      }

      await updatePendingFacilities(companyId, temp);
    } else {
      if (temp[env.toLowerCase()][licenseId].facilities === "") {
        if (temp[env.toLowerCase()][licenseId].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: "Facility Id(s) " + facilities + (action === "approve" ? ZENDESK_MESSAGE.approvedClose : ZENDESK_MESSAGE.deniedClose),
            userName: "Life Fitness",
            zendeskTicketId: temp[env.toLowerCase()][licenseId].zendeskTicketId,
            status: ZENDESK_STATUS.closed,
          };
          await resolveTicket(resolveTicketObj);
        }

        delete temp[env.toLowerCase()][licenseId];
        if (Object.keys(temp[env.toLowerCase()]).length === 0) {
          temp[env.toLowerCase()] = {};
          if ((!temp.dev || Object.keys(temp.dev).length === 0) && (!temp.prod || Object.keys(temp.prod).length === 0)) {
            temp = null;
          }
        }
      } else {
        if (temp[env.toLowerCase()][licenseId].zendeskTicketId) {
          let resolveTicketObj = {
            closingMessage: "Facility Id(s) " + facilities + (action === "approve" ? " approved" : " denied"),
            userName: "Life Fitness",
            zendeskTicketId: temp[env.toLowerCase()][licenseId].zendeskTicketId,
            status: ZENDESK_STATUS.open,
          };
          await resolveTicket(resolveTicketObj);
        }
      }

      await updatePendingFacilities(companyId, temp);
      dispatch(getPendingFacilitiesAdminAction());
    }
  } catch (error) {
    return rejectWithValue(error.response.data.split("\n")[1].split(":")[1].trim());
  }
});

export const initLicensesAction = createAsyncThunk(LICENSES.ACTION.INIT_LICENSES, async (payload, { rejectWithValue, getState, dispatch }) => {
  return;
});

// CREATE SLICE
const licensesSlice = createSlice({
  name: LICENSES.SLICE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(initLicensesAction.pending, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(initLicensesAction.fulfilled, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(initLicensesAction.rejected, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getLicensesAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.items = [];
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getLicensesAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
      state.items = action.payload;
    });

    builder.addCase(getLicensesAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.items = [];
    });

    builder.addCase(getLicenseAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getLicenseAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.licenseId = action.payload;
    });

    builder.addCase(getLicenseAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.items = [];
    });

    builder.addCase(addLicenseAction.pending, (state, action) => {
      console.log("%%%%%%%%%%%%%%%%% addLicenseAction.pending");
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(addLicenseAction.fulfilled, (state, action) => {
      console.log("%%%%%%%%%%%%%%%%% addLicenseAction.fulfilled: ", action);
      state.pending = false;
      state.fulfilled = true;
      state.items = [...state.items, action.payload];
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(addLicenseAction.rejected, (state, action) => {
      console.log("%%%%%%%%%%%%%%%%% addLicenseAction.rejected");
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(changeLicenseAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(changeLicenseAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.items = action.payload;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(changeLicenseAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      if (state.error && state.error.incorrectIds) {
        state.error.incorrectIds += `,${action.payload.incorrectIds}`;
      } else state.error = action.payload;
      state.items = [];
    });

    builder.addCase(moveLicenseAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(moveLicenseAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.items = state.items.map((l) => {
        if (parseInt(l.id) === parseInt(action.payload.license.id)) {
          return {
            ...l,
            [action.payload.env]: action.payload.license,
          };
        } else return l;
      });
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(moveLicenseAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.items = [];
    });

    builder.addCase(deleteLicenseAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(deleteLicenseAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.items = state.items.filter((l) => l.id !== action.payload.licenseId);
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(deleteLicenseAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.items = [];
    });

    builder.addCase(addFacilitiesAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      if (!state.rejected || !state.error?.incorrectIds) {
        state.rejected = false;
        state.error = null;
      }
    });

    builder.addCase(addFacilitiesAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.items = state.items.map((l) => {
        if (parseInt(l.id) === parseInt(action.payload.license.id)) {
          return {
            ...l,
            [action.payload.env]: action.payload.license,
          };
        } else return l;
      });
      if (!state.rejected || !state.error?.incorrectIds) {
        state.rejected = false;
        state.error = null;
      }
    });

    builder.addCase(addFacilitiesAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(removeFacilitiesAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      if (!state.rejected || !state.error?.incorrectIds) {
        state.rejected = false;
        state.error = null;
      }
    });

    builder.addCase(removeFacilitiesAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.items = state.items.map((l) => {
        if (parseInt(l.id) === parseInt(action.payload.license.id)) {
          return {
            ...l,
            [action.payload.env]: action.payload.license,
          };
        } else return l;
      });
      if (!state.rejected || !state.error?.incorrectIds) {
        state.rejected = false;
        state.error = null;
      }
    });

    builder.addCase(removeFacilitiesAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.items = [];
    });

    builder.addCase(getPendingFacilitiesAdminAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getPendingFacilitiesAdminAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.pendingFacilities = action.payload;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getPendingFacilitiesAdminAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.items = [];
    });

    builder.addCase(updatePendingFacilitiesAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(updatePendingFacilitiesAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(updatePendingFacilitiesAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.items = [];
    });
  },
});

// CREATE REDUCER
const licensesReducer = licensesSlice.reducer;
export default licensesReducer;
