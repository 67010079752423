const text = `## Real-Time Availability

Enable exercisers to plan their visit based on what type of equipment they plan to use, and whether it is currently available. 

&nbsp;

**KEY BENEFITS**

The key benefits of using Real-Time Availability can be summarized below:
`;

export default text;
