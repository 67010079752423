import { connect } from "react-redux";
// import { submit } from "redux-form";
// import { withRouter } from "react-router";

import { deleteLicense } from "../store/actions/licenses";
// import { selectGridItem } from '../store/actions/gridStore';
import { getLoadingStatus, filterLicenses } from "./selectors/licenseSelectors";
import { getSelectedItems, checkSelectedItems } from "./selectors/gridSelectors";
import { isUserAdmin, getCurrentUser } from "./selectors/userSelectors";
import { ENVIRONMENTS } from "../constants/common";
import LicensesComponent from "../components/profile/Licenses/LicensesComponent";
import { getCurrentCompany } from "./selectors/companySelectors";

const ADD_LICENSE = "license";
const PROD_LICENSE = "license_prod";
const DEV_LICENSE = "license_dev";

const mapStateToProps = (state, { /*history, location: { state: locationState = {} }, */ filteredLicenses, isDev, backToDevelopment }) => {
  console.log("***** mapStateToProps.state: ", state);
  return {
    // isLoading: getLoadingStatus(state),
    licenses: filteredLicenses,
    // selectedItems: getSelectedItems(state, { name: ENVIRONMENTS.dev }),
    areSelectedItemsEmpty: checkSelectedItems(state, { name: ENVIRONMENTS.dev }),
    canEdit: isUserAdmin(state),
    // history,
    // locationState,
    isDev,
    backToDevelopment,
    company: state.session.company,
    user: getCurrentUser(state),
  };
};

const mapDispatchToProps = {
  deleteLicense,
  // moveLicenseForm: () => submit(DEV_LICENSE),
  // submitLicenseForm: () => submit(ADD_LICENSE),
  // editDevLicenseForm: () => submit(DEV_LICENSE),
  // editProdLicenseForm: () => submit(PROD_LICENSE),
};

const mergeProps = ({ isDev, /* history, locationState: { activePanel = null },*/ company, ...stateProps }, { moveLicenseForm, deleteLicense, submitLicenseForm, editDevLicenseForm, editProdLicenseForm }) => ({
  // company,
  ...stateProps,
  activePanel: isDev === true ? "dev" : "prod",
  isDev,
  // activePanel,
  platforms: company?.platform ? company.platform.map((type) => type.trim()) : [],
  // submitLicenseForm,
  // moveLicenseForm,
  deleteLicense,
  // editDevLicenseForm,
  // editProdLicenseForm,
  removeLicense: (license) => {
    // Waiting for the `app remove` API implementation.
    // deleteLicense(license, `${license.name} was successfully deleted.`);
    //
    // selectGridItem(license.production ? ENVIRONMENTS.prod : ENVIRONMENTS.dev, license.id, false, false);
  },
});

//export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(LicensesComponent));
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LicensesComponent);
