import React from "react";
import PropTypes from "prop-types";

import "./licenseForm.scss";
import LicenseFormContainer from "../../../containers/LicenseFormContainer";
//import LicenseSubscriptionFormContainer from '../../../containers/LicenseSubscriptionFormContainer';
import LicenseSubscriptionFormComponent from "./LicenseSubscriptionFormComponent";
import LicenseFormComponent from "./LicenseFormComponent";
import { ACTIONS } from "../../../constants/common";
import { useDispatch, useSelector } from "react-redux";
import { addLicenseAction, changeLicenseAction } from "../../../redux/slice/licensesSlice";

const LicenseOuterFormComponent = (props) => {
  const sessionState = useSelector((__state__) => __state__.session);
  const dispatch = useDispatch();
  // const { page, isInvalid, updateInvalid } = props;
  // updateInvalid(isInvalid);

  const handleSubmit = (data) => {
    console.log(props.action);
    console.log(data);
    props.handleSubmit(props.action, data);
    // switch (props.action) {
    //   case ACTIONS.add:
    //     console.log("dispatch addLicenseAction() data: ", data);
    //     dispatch(addLicenseAction({ user: sessionState.user, company: sessionState.company, licenseData: { ...data, category: data.category?.value, platform: data.platform?.value } }));
    //     break;
    //   case ACTIONS.delete:
    //     console.log("dispatch deleteLicenseAction()");
    //     break;
    //   case ACTIONS.editDev:
    //   case ACTIONS.editProd:
    //     console.log("dispatch changeLicenseAction()");
    //     dispatch(changeLicenseAction({ user: sessionState.user, company: sessionState.company, licenseData: { ...data, category: data.category?.value } }));
    //     break;
    //   case ACTIONS.move:
    //     console.log("dispatch moveLicenseAction()");
    //     break;
    //   default:
    //     break;
    // }
  };

  return (
    <div className="license">
      {props.page === 1 && <LicenseFormComponent {...props} handleSubmit={handleSubmit} />}
      {props.page === 2 && <LicenseSubscriptionFormComponent {...props} handleSubmit={handleSubmit} />}
    </div>
  );
};

LicenseOuterFormComponent.propTypes = {
  page: PropTypes.number.isRequired,
  isInvalid: PropTypes.bool,
  updateInvalid: PropTypes.func,
};

export default LicenseOuterFormComponent;
