import { ref, get, child, update, remove, set } from "../config/firebase";
import { PARTNERAPI_DB_KEYS } from "../redux/Constants";

export function getLicenses(companyId) {
  return get(child(ref, `${PARTNERAPI_DB_KEYS.LICENSES}/${companyId}`)).then((LicensesSnapshot) => (LicensesSnapshot.exists() && LicensesSnapshot.val()) || {});
}

export function createLicense(companyId, license) {
  return update(ref, {
    [`${PARTNERAPI_DB_KEYS.LICENSES}/${companyId}/${license.id}/dev`]: license,
  });
}

export function updateLicense(companyId, license, licenseEnv) {
  return update(ref, {
    [`${PARTNERAPI_DB_KEYS.LICENSES}/${companyId}/${license.id}/${licenseEnv}`]: license,
  });
}

export function removeLicense(companyId, licenseId) {
  return update(ref, {
    [`${PARTNERAPI_DB_KEYS.LICENSES}/${companyId}/${licenseId}`]: null,
  });
}
