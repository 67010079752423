import React from "react";
import PropTypes from "prop-types";

import ios from "../../components/developerApi/developerSdk/WorkoutPreset/IOS/WorkoutPresetComponent";
import android from "../../components/developerApi/developerSdk/WorkoutPreset/Android/WorkoutPresetComponent";

import "./workoutPresetLayout.scss";
import { useParams } from "react-router-dom";

const WORKOUT_PRESET = {
  ios,
  android,
  web: () => <h1>Not Implemented Yet</h1>,
};

const WorkoutPresetLayout = () => {
  const { api } = useParams();
  const WorkoutPresetComponent = WORKOUT_PRESET[api];

  return (
    <div className="workout-preset-layout">
      <h2>Workout Preset</h2>
      <WorkoutPresetComponent />
    </div>
  );
};

// WorkoutPresetLayout.propTypes = {
//     api: PropTypes.string.isRequired,
// }

export default WorkoutPresetLayout;
