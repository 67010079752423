import { useParams, redirect, useLocation, Outlet } from "react-router-dom";

import BreadcrumbsComponent from "../../core/BreadcrumbsComponent/BreadcrumbsComponent";
// import DownloadSdkContainer from "../../../containers/DownloadSdkContainer";
// import ArchivesContainer from "../../../containers/ArchivesContainer";
// import WebArchivesContainer from "../../../containers/WebArchivesContainer";
// import DocumentationLayout from "../../../layouts/Documentation/DocumentationLayout";
import GettingStartedLayout from "../../../layouts/GettingStarted/GettingStartedLayout";
// import WorkoutPresetLayout from "../../../layouts/WorkoutPreset/WorkoutPresetLayout";
import MenuComponent from "../../core/MenuComponent/MenuComponent";
// import PubSubLayout from "../../../layouts/Structure/PubSubLayout";
// import FaqLayout from "../../../layouts/Faq/FaqLayout";
// import SubscriptionLayout from "../../../layouts/Subscription/SubscriptionLayout";
// import DataTypesContainer from "../../../containers/DataTypesContainer";
// import AuthenticationLayout from "../../../layouts/Authentication/AuthenticationLayout";
import OverviewLayout from "../../../layouts/Overview/OverviewLayout";
// import CompatibilityContainer from "../../../containers/CompatibilityContainer";

import "./developerSdk.scss";

const DeveloperSdkComponent = (props) => {
  const params = useParams();
  const { menuItem, api, subMenuItem } = params;
  if (!menuItem) redirect(`/api/${api}/started`);

  return (
    <div className="Developer-sdk-component container">
      <div className="content">
        <div className="sticky-wrapper">
          <BreadcrumbsComponent class="breadcrumbs" dependency={[api, menuItem, subMenuItem]} />
          <MenuComponent {...props} />
        </div>
        <div className="content-wrapper">
          {props.children}
          {/* <Switch>
            <Route path="/api/overview" component={() => <OverviewLayout />} />
            <Route
              path="/api/:api/started"
              component={() => <GettingStartedLayout api={api} />}
            />
            <Route
              path="/api/:api/structure/pub-sub"
              component={() => <PubSubLayout api={api} />}
            />
            <Route
              path="/api/:api/data-types"
              component={() => <DataTypesContainer />}
            />
            <Route
              path="/api/:api/download"
              component={() => <DownloadSdkContainer api={api} />}
            />
            <Route
              path="/api/web/archives"
              component={() => <WebArchivesContainer api={"web"} />}
            />
            <Route
              path="/api/:api/sdk"
              component={() => <ArchivesContainer api={api} />}
            />
            <Route
              path="/api/:api/documentation"
              component={() => <DocumentationLayout api={api} />}
            />
            <Route
              path="/api/:api/workout-preset"
              component={() => <WorkoutPresetLayout api={api} />}
            />
            <Route
              path="/api/:api/faq"
              component={() => <FaqLayout api={api} />}
            />
            <Route
              path="/api/:api/authentication"
              component={() => <AuthenticationLayout api={api} />}
            />
            <Route
              path="/api/:api/subscription"
              component={() => <SubscriptionLayout api={api} />}
            />
            <Route
              path="/api/web/compatibility"
              component={() => <CompatibilityContainer />}
            />
          </Switch> */}
        </div>
      </div>
    </div>
  );
};

export default DeveloperSdkComponent;
