import { COMMON_RULES } from '../validation';

export default {
    email: COMMON_RULES.email,
    firstName: COMMON_RULES.firstName,
    lastName: COMMON_RULES.lastName,
    role: [
        {
            name: 'Required',
            message: 'Role is required',
        },
    ],
};
