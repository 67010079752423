import { useState } from "react";
import { NavDropdown } from "react-bootstrap";
//import { Outlet } from "react-router-dom";

const DropDownComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { title, children, className } = props;

  const onToggleHander = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NavDropdown
      {...props}
      className={`Drop-down-component${className}`}
      onToggle={onToggleHander}
      title={
        <span>
          {title} <span className={`arrow-icon ${isOpen && "reverse"}`} />
        </span>
      }
    >
      {children}
    </NavDropdown>
  );
};

export default DropDownComponent;
