import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Form } from "react-final-form";
import ReactGA from "react-ga";

import "./reset.scss";

import FieldGroupComponent from "../../../components/core/FieldGroupComponent/FieldGroupComponent";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { SIGN_IN_PATH } from "../../../constants/routes";
import { getValidationRules } from "../../../validation/validation";

import SIGN_UP_RULES from "../../../validation/validationRules/signUp";
import { sendForgotPasswordEmailAction } from "../../../redux/slice/sessionSlice";

const ResetPasswordComponent = () => {
  const sessionState = useSelector((__state__) => __state__.session);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Reset Password Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  const handleSubmit = (input) => {
    ReactGA.event({
      category: "Reset Password Component",
      action: "Clicked Reset Password",
      label: "Button",
    });
    setIsSubmit(true);
    dispatch(sendForgotPasswordEmailAction({ input, onFulfilled: { resetPasswordComponent: { page: SIGN_IN_PATH } } }));
  };

  const onCancel = () => {
    ReactGA.event({
      category: "Reset Password Component",
      action: "Clicked Cancel",
      label: "Button",
    });
    setIsSubmit(true);
    dispatch(sendForgotPasswordEmailAction({ cancel: true, onFulfilled: { resetPasswordComponent: { page: SIGN_IN_PATH } } }));
  };

  //        const { handleSubmit, isLoading } = this.props;

  return (
    <div className="reset-password container">
      {sessionState.fulfilled && sessionState.resetPasswordComponent?.page && isSubmit === true && <Navigate to={sessionState.resetPasswordComponent?.page} />}
      {sessionState.pending && <LoadingComponent fullScreen />}
      <h2>Reset Password</h2>
      <Form
        onSubmit={handleSubmit}
        validate={getValidationRules(["email"], SIGN_UP_RULES)}
        render={(props) => (
          <div>
            <form onSubmit={props.handleSubmit}>
              <FieldGroupComponent
                label="Email"
                name="email"
                type="text"
                submissionError={sessionState.rejected && sessionState.error}
                required="true"
                formProps={{
                  placeholder: "Enter Email",
                  maxLength: 100,
                }}
              />
              <div className="buttons">
                <Button type="submit">Reset Password</Button>
                <Button className="dark" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        )}
      />
    </div>
  );
};

// ResetPasswordComponent.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   onCancel: PropTypes.func.isRequired,
//   isLoading: PropTypes.bool.isRequired,
//   setLoading: PropTypes.func.isRequired,
// };

export default ResetPasswordComponent;
