import { connect } from "react-redux";
// import { reduxForm } from 'redux-form';

import { getValidationRules } from "../validation/validation";
import AddFacilitiesComponent from "../components/profile/License/AddFacilitiesComponent";
import { setLoading } from "../store/actions/session";
import { addFacilitiesAction } from "../store/actions/licenses";
import { getLoadingStatus } from "./selectors/licenseSelectors";

const ADD_FACILITIES_FORM = "add-facilities";

const mapStateToProps = (state, { license, handleChange, value, isMove }) => ({
  isLoading: getLoadingStatus(state),
  license,
  handleChange,
  value,
  isMove,
});

const mapDispatchToProps = {
  setLoading,
  addFacilitiesAction,
};

const mergeProps = ({ history, license, handleChange, value, isMove, ...stateProps }, { addFacilitiesAction, setLoading }) => {
  return {
    setLoading,
    ...stateProps,
    license,
    isMove,
    handleChange,
    value,
    onSubmit: ({ facilities }) => {
      addFacilitiesAction(facilities, license, isMove);
      handleChange("");
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddFacilitiesComponent);
// export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
//     form: ADD_FACILITIES_FORM,
//     validate: getValidationRules([
//         'facilities',
//     ]),
// })(AddFacilitiesComponent));
