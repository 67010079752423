export const CONSOLES = [
    {
        imageUrl: './images/discover-se3-hd-console.png',
        title: 'Discover SE3HD',
    },
    {
        imageUrl: './images/discover-se3-hd-console.png',
        title: 'Discover ST',
    },
    {
        imageUrl: './images/discover-se3-and-se3-hd-console.png',
        title: 'Discover SE3',
    },
    {
        imageUrl: './images/Console_70T_200x185.png',
        title: 'Cybex 70T',
    },
    {
        imageUrl: './images/explore-console.png',
        title: 'Explore',
    },
    {
        imageUrl: './images/track-connect-console.png',
        title: 'Track Connect',
    },
]

export const SDK_CAPABILITIES = [
    {
        imageUrl: './images/sdk-capabilites-create.png',
        action: 'Create',
        desc: 'and send workout presets to console.',
    },
    {
        imageUrl: './images/sdk-capabilites-obtain.png',
        action: 'Obtain',
        desc: 'in progress workout stream every second.',
    },
    {
        imageUrl: './images/sdk-capabilites-receive.png',
        action: 'Receive',
        desc: 'workout result summary.',
    },
    {
        imageUrl: './images/sdk-capabilites-control.png',
        action: 'Control',
        desc: 'in progress workout.',
    },
]

export const LICENSE_KEY = `<key>LFLicenseKey</key>
<string>123-4567890-1234</string>`
