export default {
  name: [
    {
      name: "Required",
      func: ({ name }) => /^\s*$/.test(name),
      message: "Please enter name.",
    },
    {
      name: "Invalid",
      func: ({ name }) => /[^a-zA-Z0-9\s&,-.']+/.test(name),
      message: "Please enter valid name.",
    },
    {
      name: "Invalid",
      func: ({ name }) => !/^\s*[a-zA-Z0-9&,-.']+(\s[a-zA-Z0-9&,-.']+)*\s*$/.test(name),
      message: "Please enter valid name.",
    },
    {
      name: "Invalid",
      func: ({ name }) => /[&,-.']\s?[&,-.']/.test(name),
      message: "Please enter valid name.",
    },
  ],
  platform: [
    {
      name: "Required",
      message: "Please make a selection.",
    },
  ],
  companySize: [
    {
      name: "Required",
      message: "Please make a selection.",
    },
  ],
  industry: [
    {
      name: "Required",
      message: "Please make a selection.",
    },
  ],
  otherIndustry: [
    {
      name: "Required",
      func: ({ otherIndustry }) => /^\s*$/.test(otherIndustry),
      message: "Please enter industry.",
      when: {
        field: "industry",
        value: "Other",
      },
    },
  ],
  vertical: [
    {
      name: "Required",
      message: "Please make a selection.",
      when: {
        field: "industry",
        value: "Fitness",
      },
    },
  ],
  otherVertical: [
    {
      name: "Required",
      func: ({ otherVertical }) => /^\s*$/.test(otherVertical),
      message: "Please enter vertical.",
      when: {
        field: "vertical",
        value: "Other",
      },
    },
  ],
  country: [
    {
      name: "Required",
      message: "Please make a selection.",
    },
  ],
  address: [
    {
      name: "Required",
      func: ({ address }) => /^\s*$/.test(address),
      message: "Please enter address.",
    },
  ],
  city: [
    {
      name: "Required",
      func: ({ city }) => /^\s*$/.test(city),
      message: "Please enter city.",
    },
  ],
  state: [
    {
      name: "Required",
      func: ({ state }) => /^\s*$/.test(state),
      message: "Please make a selection.",
    },
  ],
  postalCode: [
    {
      name: "Required",
      func: ({ postalCode }) => /^\s*$/.test(postalCode),
      message: "Please enter zip code.",
    },
    {
      name: "Invalid",
      func: (values) => values.postalCode.length < 5,
      message: "Please enter valid zip code.",
    },
  ],
};
