import ReactGA from "react-ga";
import Markdown from "react-markdown";

import text1 from "./RealTimeAvailabilityText1.js";
import text2 from "./RealTimeAvailabilityText2.js";
import text3 from "./RealTimeAvailabilityText3.js";
import text4 from "./RealTimeAvailabilityText4.js";
import BENEFIT_1 from "./API_Icon_RealTime_Benefit_1.png";
import BENEFIT_2 from "./API_Icon_RealTime_Benefit_2.png";
import BENEFIT_3 from "./API_Icon_RealTime_Benefit_3.png";
import Prerequisite from "./API_RealtimeAvailability_Prerequisite@2x.png";

import "./realTimeAvailability.scss";
import { useEffect } from "react";

const RealTimeAvailabilityComponent = () => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "MMS Integrations Guides Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="real-time-component">
      <Markdown>{text1}</Markdown>
      <div className="key-benefits">
        <div className="box">
          <img src={BENEFIT_1} alt="benefit_1" />
          <br />
          Integrate real-time equipment usage into your <span className="alt">native facility app</span>
        </div>
        <div className="box">
          <img src={BENEFIT_2} alt="benefit_2" />
          <br />
          Create a <span className="alt">microsite</span> displaying real-time availability of your equipment
        </div>
        <div className="box">
          <img src={BENEFIT_3} alt="benefit_3" />
          <br />
          See the <span className="alt">time elapsed</span> for workouts in progress
        </div>
      </div>
      <Markdown>{text2}</Markdown>
      <div className="prereq">
        <img src={Prerequisite} alt="Prerequisite" />
      </div>
      <Markdown>{text3}</Markdown>
      <table>
        <thead>
          <tr>
            <th>Parameter Name</th>
            <th>Parameter Description</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>subType</td>
            <td>The subscription type for which the notification is sent</td>
            <td>
              Possible values:
              <ul>
                <li>LFUserLoggedIn</li>
                <li>LFUserWorkoutStarted</li>
                <li>LFUserWorkoutData</li>
                <li>LFUserWorkoutEnded</li>
                <li>LFUserLoggedOut</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>timestamp</td>
            <td>Timestamp of the event</td>
            <td>2020-02-24T10%3A16%3A53.512Z</td>
          </tr>
          <tr>
            <td>access_token</td>
            <td>The Developer Connect user's access-token</td>
            <td>hskroxn2o4ishm456</td>
          </tr>
          <tr>
            <td>device</td>
            <td>Body Serial of the equipment</td>
            <td>SMT-000000</td>
          </tr>
          <tr>
            <td>facilitId</td>
            <td>Developer Connect Facility Id of the equipment</td>
            <td>10001</td>
          </tr>
          <tr>
            <td>activityId</td>
            <td>Activity Id represents the type of the equipment</td>
            <td>1</td>
          </tr>
        </tbody>
      </table>
      <Markdown>{text4}</Markdown>
    </div>
  );
};

export default RealTimeAvailabilityComponent;
