import PropTypes from "prop-types";

import GridContainer from "../../../../containers/GridContainer";
import CodeComponent from "../../../core/CodeComponent/CodeComponent";
import { DATA_NAMES } from "./DataTypesConstants";

const NamespaceGridComponent = (props) => {
  const NAMESPACE_GRID_PROPS = [
    {
      title: "Namespace URI",
      className: "namespace-url",
      getter: ({ uri }) => <CodeComponent type="url" code={uri} />,
    },
    {
      title: "XSD",
      className: "namespace-xsd",
      getter: ({ xsd: { url, title } }) => (
        <a href={url} target="_self" download>
          {title}.xsd
        </a>
      ),
    },
  ];

  const TYPE_GRID_PROPS = [
    {
      ...NAMESPACE_GRID_PROPS[0],
      title: "Namespace",
    },
    {
      ...NAMESPACE_GRID_PROPS[1],
      title: "XML Schema",
    },
  ];

  const ELEMENT_GRID_PROPS = [
    {
      title: "Type",
      className: "type",
      getter: ({ type }) => <a onClick={props.onTypeClick}>{type}</a>,
    },
    ...TYPE_GRID_PROPS,
  ];

  const { namespace, name, elementName, dataName } = props;
  let gridProps = NAMESPACE_GRID_PROPS;

  if (dataName === DATA_NAMES.types) {
    gridProps = TYPE_GRID_PROPS;
  } else if (dataName === DATA_NAMES.elements) {
    gridProps = ELEMENT_GRID_PROPS;
  }

  return (
    <>
      <GridContainer
        className="namespace-grid"
        name={`${!elementName ? name : elementName}-namespace-grid`}
        gridProps={gridProps}
        items={[
          {
            type: elementName,
            uri: namespace.uri,
            xsd: {
              title: name,
              url: namespace.xmlSchemaDownloadUrl,
            },
          },
        ]}
      />
    </>
  );
};

NamespaceGridComponent.defaultProps = {
  onTypeClick: () => {},
};

NamespaceGridComponent.propTypes = {
  namespace: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  dataName: PropTypes.string,
  elementName: PropTypes.string,
  onTypeClick: PropTypes.func.isRequired,
};

export default NamespaceGridComponent;
