import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import TooltipComponent from '../TooltipComponent/TooltipComponent';

import './truncate.scss';

class TruncateComponent extends React.Component {
    constructor(props) {
        super(props);

        this.truncated = {};
        this.updated = {};

        this.ELEMENT_TAG = 'div';
        this.TEXT_ELEMENT_TAG = 'span';
    }

    componentDidMount() {
        const canvas = document.createElement('canvas');
        const docFragment = document.createDocumentFragment();
        const style = window.getComputedStyle(this.element);

        docFragment.appendChild(canvas);
        this.canvas = canvas.getContext('2d');
        this.canvas.font = [
            style['font-weight'],
            style['font-style'],
            style['font-size'],
            style['font-family'],
        ].join(' ');
        this.forceUpdate();
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        if (this.rafId) {
            window.cancelAnimationFrame(this.rafId);
        }
    }

    onResize = () => {
        if (this.rafId) {
            window.cancelAnimationFrame(this.rafId);
        }
        this.rafId = window.requestAnimationFrame(this.update.bind(this))
    };

    update = () => {
        const style = window.getComputedStyle(this.element);
        this.canvas.font = [
            style['font-weight'],
            style['font-style'],
            style['font-size'],
            style['font-family'],
            style['letter-spacing'],
        ].join(' ');
        this.forceUpdate();
    };

    measureWidth(text) {
        return Math.ceil(this.canvas.measureText(text).width);
    }

    getRenderText = () => {
        const {
            containerClassName,
            lines,
            text,
            ellipsis,
            basedOn,
            ...props
        } = this.props;

        const scopeWidth = this.element.offsetWidth;

        if (scopeWidth === 0) {
            return null;
        }

        // return if all of text can be displayed
        if (scopeWidth >= this.measureWidth(text)) {
            this.truncated[text] = false;
            return createElement(this.TEXT_ELEMENT_TAG, {}, text);
        }

        let childText = '';
        let currentPos = 1;
        let maxTextLength = text.length;
        let truncatedText = '';
        let splitPos = 0;
        let startPos = 0;
        let displayLines = lines;
        let width = 0;
        let lastIsSpace = false;
        let isPrevLineWithoutSpace = false;
        let lastPos = 0;
        let lastSpaceIndex = -1;
        let extension = '';

        while (displayLines-- > 0) {
            extension = displayLines ? '' : ellipsis + (childText ? (' ' + childText) : '');

            while (currentPos <= maxTextLength) {
                truncatedText = text.substr(startPos, currentPos);
                width = this.measureWidth(truncatedText + extension);

                if (width < scopeWidth) {
                    splitPos = truncatedText.indexOf(' ', currentPos + 1);

                    if (splitPos === -1) {
                        currentPos += 1;
                        lastIsSpace = false;
                    } else {
                        lastIsSpace = true;
                        currentPos = splitPos;
                    }
                } else {
                    do {
                        truncatedText = text.substr(startPos, currentPos);

                        if (!displayLines) {
                            currentPos--;
                        }

                        if (truncatedText[truncatedText.length - 1] === ' ') {
                            truncatedText = text.substr(startPos, currentPos - 1);
                        }

                        if (lastIsSpace && basedOn === 'words') {
                            lastSpaceIndex = truncatedText.lastIndexOf(' ');

                            if (lastSpaceIndex > -1) {
                                currentPos = lastSpaceIndex;

                                if (displayLines) {
                                    currentPos++;
                                }

                                truncatedText = text.substr(startPos, currentPos);
                            } else {
                                currentPos--;
                                truncatedText = text.substr(startPos, currentPos);
                            }
                        } else {
                            currentPos--;
                            truncatedText = text.substr(startPos, currentPos);
                        }

                        width = this.measureWidth(truncatedText + extension);
                    } while (width >= scopeWidth && truncatedText.length > 0);

                    startPos += currentPos;
                    break;
                }
            }

            if (currentPos >= maxTextLength) {
                startPos = maxTextLength;
                break;
            }

            if (lastIsSpace && !isPrevLineWithoutSpace && text.substr(lastPos, currentPos).indexOf(' ') === -1) {
                isPrevLineWithoutSpace = true;
                displayLines--;
            }

            lastPos = currentPos + 1;
        }

        if (startPos === maxTextLength) {
            this.truncated[text] = false;
            return createElement(this.TEXT_ELEMENT_TAG, props, text);
        }

        this.truncated[text] = true;
        return createElement(this.TEXT_ELEMENT_TAG, props, text.substr(0, startPos) + ellipsis + ' ');
    }

    render() {
        const {
            text,
            lines,
            containerClassName,
        } = this.props;

        let isTruncated = true;

        const renderText = this.element && lines ? this.getRenderText() : createElement(this.TEXT_ELEMENT_TAG, {}, text);
        this.element && (isTruncated = ((this.element.children[0].scrollWidth > this.element.children[0].clientWidth) || this.truncated[text]));
        const rootProps = {
            ref: (element) => { this.element = element },
            className: 'truncate-wrapper' + (containerClassName ? ` ${containerClassName}` : ''),
        };

        if (!this.updated[text] && this.element && this.element.clientWidth !== 150) {
            this.updated[text] = true;
            this.forceUpdate();
        }

        if (isTruncated) {
            return <TooltipComponent
                overlayContent={<div className={'tooltip-truncated-content'}>{text}</div>}
                className={'license-truncated-tooltip'}
                placement={'bottom'}
                children={
                    createElement(this.ELEMENT_TAG, rootProps, renderText)
                }
            />
        } else {
            return createElement(this.ELEMENT_TAG, {...rootProps, onClick: () => this.forceUpdate()}, renderText);
        }
    }
}

TruncateComponent.defaultProps = {
    lines: 1,
    ellipsis: '...',
    basedOn: 'letters',
}

TruncateComponent.propTypes = {
    text: PropTypes.string.isRequired,
    lines: PropTypes.number,
    ellipsis: PropTypes.string,
    containerClassName: PropTypes.string,
    basedOn: PropTypes.string,
}

export default TruncateComponent;
