import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";

import "./licenseForm.scss";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";
import { Form } from "react-final-form";
import { getValidationRules } from "../../../validation/validation";
import { useDispatch, useSelector } from "react-redux";
import { addFacilitiesAction } from "../../../redux/slice/licensesSlice";

const AddFacilitiesComponent = (props) => {
  const dispatch = useDispatch();
  const licensesState = useSelector((__state__) => __state__.licenses);

  const { isMove, value } = props;

  const handleSubmit = (data) => {
    console.log("**** AddFacilitiesComponent.handleSubmit() license: ", props.license);
    console.log("**** AddFacilitiesComponent.handleSubmit() data: ", data);
    dispatch(addFacilitiesAction({ ...data, licenseData: props.license, isMove }));
  };

  console.log("*************** props.licenses: ", props.license);
  return (
    <div className="facilities-entry">
      {licensesState.pending && <LoadingComponent fullscreen />}
      <Form
        validate={getValidationRules(["facilities"])}
        onSubmit={handleSubmit}
        render={(props) => (
          <form
            onSubmit={async (event) => {
              await props.handleSubmit(event);
              props.form.change("facilities", "");
              props.form.resetFieldState("facilities");
            }}
          >
            <FieldGroupComponent
              label={
                <span>
                  Facility ID <span className="light">(Separated by commas)</span>
                </span>
              }
              name="facilities"
              type="text"
              required={false}
              formProps={{
                maxLength: 50,
              }}
            />
            <Button disabled={props.invalid || props.submitting} type="submit" className="btn btn-default">
              Add
            </Button>
          </form>
        )}
      />
    </div>
  );
};

AddFacilitiesComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default AddFacilitiesComponent;
