import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { child, get, ref, GetDownloadURL } from "../../config/firebase";
import { DATA_TYPES } from "../Constants";
import { actionFulfilled, actionPending, actionRejected } from "./utils";
import { DATA_NAMES } from "../../components/developerApi/developerSdk/DataTypes/DataTypesConstants";

// INITIAL STATE
const initialState = DATA_TYPES.INITIAL_STATE;

// CREATE ACTIONS
export const getDataTypesAction = createAsyncThunk(DATA_TYPES.ACTION.GET_DATA_TYPES, async (payload, { rejectWithValue, getState, dispatch }) => {
  console.log("***** getDataTypesAction *****");
  return get(child(ref, "DATA_TYPES"))
    .then(async (snapshot) => {
      console.log("***** snapshot: ", snapshot.val());
      const DATA_TYPES_ORDER = ["user", "workoutresult", "workoutpreset"];
      const dataTypes = snapshot.val() || {};

      for (const namespaceName of DATA_TYPES_ORDER) {
        console.log("***** namespaceName: ", namespaceName);
        const namespace = dataTypes[namespaceName];
        console.log("***** namespace: ", namespace);
        namespace.xmlSchemaDownloadUrl = await GetDownloadURL(namespace.xmlSchemaFilePath);
        console.log("***** namespace.xmlSchemaDownloadUrl: ", namespace.xmlSchemaDownloadUrl);
      }
      console.log("*** before DATA_TYPES_ORDER.map()");
      const data = DATA_TYPES_ORDER.map((namespaceName) => ({ ...dataTypes[namespaceName], name: namespaceName }));
      console.log("***** getDataTypesAction return data: ", JSON.stringify(data));
      return data;
    })
    .catch((error) => {
      console.log("ERROR");
      console.error(error);
    });
});

// CREATE SLICE
const dataTypesSlice = createSlice({
  name: DATA_TYPES.SLICE,
  //name: "DevPortalDataTypes",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDataTypesAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getDataTypesAction.fulfilled, (state, action) => {
      console.log("***** fulfilled action.payload: ", JSON.stringify(action.payload));
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.items = action.payload;
      action.payload.forEach(({ name }) => {
        state.activeKeys[name] = { [DATA_NAMES.elements]: null, [DATA_NAMES.types]: null };
      });
    });

    builder.addCase(getDataTypesAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.items = [];
    });
  },
});

// CREATE REDUCER
const dataTypesReducer = dataTypesSlice.reducer;
export default dataTypesReducer;
