import { createSelector } from "reselect";
import { getCurrentCompanyId } from "./companySelectors";

const sessionSelector = (state) => state.session;

export const getCurrentUser = createSelector(sessionSelector, (session) => {
  return session.user;
});

export const getUserRole = createSelector(getCurrentUser, getCurrentCompanyId, (user, companyId) => (user.companies && companyId ? (user.companies[companyId].isAdmin ? "Admin" : "Member") : "Member"));

export const isUserAdmin = createSelector(getCurrentUser, getCurrentCompanyId, (user, companyId) => (user.companies && companyId ? user.companies[companyId].isAdmin : false));
