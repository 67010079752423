export const SDK_CAPABILITIES = [
    {
        imageUrl: './images/sdk-capabilites-create.png',
        action: 'Create',
        desc: 'and send workout presets to console.',
    },
    {
        imageUrl: './images/sdk-capabilites-obtain.png',
        action: 'Obtain',
        desc: 'in progress workout stream every second.',
    },
    {
        imageUrl: './images/sdk-capabilites-receive.png',
        action: 'Receive',
        desc: 'workout result summary.',
    },
    {
        imageUrl: './images/sdk-capabilites-control.png',
        action: 'Control',
        desc: 'in progress workout.',
    },
]
