const SETUP_COMPILE_HTML = `compile project(':lfopen2')`

const SETUP_MODIFY_HTML = `...
    <application>
        ...
        <!-- Mandatory -->
        <service android:name="com.lf.api.WorkoutManager" />
        <service android:name="com.lf.api.LFopen2Service" />
        <service android:name="com.lf.api.DeviceConnector" />

        <!-- Optional, add your own activity to handle USB cable -->
        <activity android:name="com.lf.sample.usb.usbstarterActivity">
            <intent-filter>
                <action android:name = "android.hardware.usb.action.USB_ACCESSORY_ATTACHED" />
            </intent-filter>

            <meta-data android:name =
                "android.hardware.usb.action.USB_ACCESSORY_ATTACHED"
                android:resource = "@xml/accessory_data" />
        </activity>
    </application>
...`

const SETUP_RESOURCE_FILE_HTML = `<resources>
    <usb-accessory manufacturer="Life Fitness" model="Upright Bike" version="1.0" />
    <usb-accessory manufacturer="Life Fitness" model="Recumbent Bike" version="1.0" />
    <usb-accessory manufacturer="Life Fitness" model="Cross-Trainer" version="1.0" />
    <usb-accessory manufacturer="Life Fitness" model="Treadmill" version="1.0" />
    <usb-accessory manufacturer="Life Fitness" model="Flex Strider" version="1.0" />
    <usb-accessory manufacturer="Life Fitness" model="Power Mill" version="1.0" />
</resources>`

const INIT_SET_ENV_HTML = `// set the app to use development environment
boolean isLiveEnv = false;

LfconnectDataService init = new LfconnectDataService();

init.initialize(
    Activity.this,
    "123-456-789-ABC-DEF",
    isLiveEnv,
    DataServiceConnectionListener
);`

const SETUP_EQUIPMENT_OBSERVER = `public class EquipmentConnectivityService extends Service implements EquipmentObserver {
    @Override
    public void onConnection() {}
    @Override
    public void onConnected() {}
    @Override
    public List <WorkoutPreset>
    onSendingWorkoutPreset() {
    return null;
}
    @Override
    public void onWorkoutResultReceived(WorkoutResult workoutresult) {}
    @Override
    public void onStreamReceived(WorkoutStream workoutstream) {}
    @Override
    public void onDisconnected() {}
    @Override
    public void onError(Exception e) {}
    // ...
}`

const REGISTER_OBSERVER_HTML = `WorkoutManager.getInstance().registerObserver(
    EquipmentConnectivityService.this
);`

const GETTING_WORKOUT_DATA_HTML = `public void onStreamReceived(WorkoutStream stream) {
    // read the stream data and do something:
    stream.getAccumulatedCalories();
    stream.getAccumulatedDistanceClimbed();
    stream.getCurrentSpeed();

    // distance in 1/100th km
    stream.getAccumulatedDistance();
}`

const DISPLAY_WORKOUT_SUMMARY_HTML = `public void onWorkoutResultReceived(WorkoutResult result) {
    result.getCalories();
    result.getDistanceclimbed();
    result.getAverageSpeed();
    result.getDistance();
    result.getDistanceUnit(); //UNIT.IMPERIAL or UNIT.METRIC
}`

const CONNECT_TO_BLUETOOTH_MAC_ADDRESS_HTML = `if (getPackageManager().hasSystemFeature(PackageManager.FEATURE_BLUETOOTH_LE)) {
    // Using Bluetooth Low Energy (requires Android 4.3 and above)
    // Make sure phone’s bluetooth is enabled
    Workoutmanager.getInstance().connectBluetoothLE(LFcodeContentURL);
    // use the alternative method if you decide to do
    //the LE scanning on your own.
    // Workoutmanager.getInstance().connectBluetoothLE(bluetoothDevice);
} else {
    // classic bluetooth
    WorkoutManager.getInstance().connectBluetoothClassic(LFcodeContentURL);
}`

const CONNECT_TO_BLUETOOTH_SAMPLE_MANIFEST = `<uses-permission android:name="android.permission.BLUETOOTH_ADMIN"/>
<uses-permission android:name="android.permission.BLUETOOTH"/>
<uses-permission android:name="android.permission.BLUETOOTH_PRIVILEDGE" />
<!-– for login by scanning consoles within near proximity -->
<uses-permission android:name="android.permission.ACCESS_FINE_LOCATION" />
<uses-permission android:name="android.permission.ACCESS_COARSE_LOCATION" />`

const CONNECT_TO_BLUETOOTH_FEATURES = `<uses-feature
    android:name="android.hardware.bluetooth"
    android:required="false" />

<!-– required for BLE -->
<uses-feature
    android:name="android.hardware.location.gps"
    android:required="false" />

<uses-feature
    android:name="android.hardware.bluetooth_le"
    android:required="false" />`

const CONNECT_TO_BLUETOOTH_PERMISSIONS = `private boolean requestPermissionsIfNeeded(){
    if (Build.VERSION.SDK_INT >= Build.VERSION_CODES.M) {
        if ((ContextCompat.checkSelfPermission(this,
                Manifest.permission.ACCESS_FINE_LOCATION) != PackageManager.PERMISSION_GRANTED)
            && (ContextCompat.checkSelfPermission(this,
                Manifest.permission.ACCESS_COARSE_LOCATION) != PackageManager.PERMISSION_GRANTED)
        ) {
            ActivityCompat.requestPermissions(this,
                new String[] {
                    Manifest.permission.ACCESS_COARSE_LOCATION,
                    Manifest.permission.ACCESS_FINE_LOCATION
                },
                PERMISSIONS_REQUEST_LOCATION
            );
            return true;
        }
    }
    return false;
}`

export const DOCUMENTATION_HTML_EXAMPLES = {
    setupCompile: SETUP_COMPILE_HTML,
    setupModify: SETUP_MODIFY_HTML,
    setupResourceFile: SETUP_RESOURCE_FILE_HTML,
    initSetEnv: INIT_SET_ENV_HTML,
    setupEquipmentObserver: SETUP_EQUIPMENT_OBSERVER,
    registerObserver: REGISTER_OBSERVER_HTML,
    gettingWorkoutData: GETTING_WORKOUT_DATA_HTML,
    displayWorkoutSummary: DISPLAY_WORKOUT_SUMMARY_HTML,
    connectToBluetoothMacAddress: CONNECT_TO_BLUETOOTH_MAC_ADDRESS_HTML,
    sampleManifestBluetoothConnectivity: CONNECT_TO_BLUETOOTH_SAMPLE_MANIFEST,
    connectToBluetoothFeatures: CONNECT_TO_BLUETOOTH_FEATURES,
    connectToBluetoothPermissions: CONNECT_TO_BLUETOOTH_PERMISSIONS,
}

export const GRID_PROPS = [
    {
        id: 'order',
        title: 'Order',
    },
    {
        id: 'method',
        title: 'Observer Method',
        className: 'observer-method',
    },
    {
        id: 'description',
        title: 'Description',
    },
    {
        id: 'frequency',
        title: 'Frequency',
    },
]

export const CALLBACKS = [
    {
        order: 1,
        method: 'onConnecting',
        description: 'Making connection to console',
        frequency: 'Once per session',
    },
    {
        order: 2,
        method: 'onConnected',
        description: 'Connection is established',
        frequency: 'Once per session',
    },
    {
        order: 3,
        method: 'onSendingWorkoutPreset',
        description: 'Called when the console is ready to receive workout presets from the app',
        frequency: 'Once per session',
    },
    {
        order: 4,
        method: 'onStreamReceived',
        description: 'After a workout is started, the app receives current workout information: calorie, distance, duration, etc\t',
        frequency: 'Multiple times, every second during a workout',
    },
    {
        order: 5,
        method: 'onWorkoutResultReceived',
        description: 'Called when the app receives workout result summary from console',
        frequency: 'Once per session',
    },
    {
        order: 6,
        method: 'onDisconnected',
        description: 'App is disconnected from console',
        frequency: 'Once per session',
    },
]
