import { isLoadingHandler } from '../Utils'

export const SET_FILE = 'SET_FILE'
export const IS_LOADING = 'STORAGE_IS_LOADING'

const INITIAL_STATE = {
    isLoading: false,
    files: {},
    sdkVersion: {},
}

const ACTION_HANDLERS = {
    [IS_LOADING]: isLoadingHandler,
    [SET_FILE]: (state, { payload }) => {
        const { path, file, version, api } = payload
        let { files, sdkVersion } = state

        files[path] = file
        sdkVersion[api] = version

        return {
            ...state,
            files,
            sdkVersion,
        }
    },
}

export default (state = INITIAL_STATE, action) => {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
