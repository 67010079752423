import { createSelector } from "reselect";

const companySelector = (state) => state.session;

export const getCurrentCompany = createSelector(companySelector, (session) => {
  console.log("********************* getCurrentCompany.session: ", session);
  return session.company;
});

export const getCurrentCompanyId = createSelector(companySelector, (session) => {
  console.log("********************* getCurrentCompanyId.session.company: ", session.company);
  return session.company?.firebaseId;
});

export const getCurrentCompanyPlatform = createSelector(companySelector, (session) => session.company.platform || []);

export const getAllCompanies = createSelector(companySelector, (session) => session.companies);
