export const ZENDESK_STATUS = {
    open: 'open',
    solved: 'solved',
    closed: 'closed',
}

export const ZENDESK_MESSAGE = {
    noFacilities: 'Closing ticket since all pending facilities have been removed by above user.',
    approvedClose: ' approved, hence closing the ticket.',
    deniedClose: ' denied, hence closing the ticket.',
    deletingLicense: 'Closing ticket since the license has been deleted by the above user.',
}
