import { useEffect, useState } from "react";
import ReactGA from "react-ga";

import { CALLBACK_URLS } from "./PubSubConstants";
import CodeComponent from "../../../../core/CodeComponent/CodeComponent";
import { Accordion, Image } from "react-bootstrap";
import AccordionComponent from "../../../../core/AccordionComponent/AccordionComponent";

import "./pubSub.scss";

const PubSubComponent = () => {
  const [activeKey, setActiveKey] = useState();
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Structure - Pub Sub Page" });
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <div className="pub-sub">
      <div>
        <h3 className="bold">Overview</h3>
        <p>Developer Connect users upload their workout data through various means: Lifefitness equipment, the Developer Connect Mobile Apps, and the Developer Connect website. With the Developer Connect API providing means for the developers to use the user information, many Apps have utilized this opportunity to provide the best services to the users. Users can choose to use other Apps which have integrated with Developer Connect.</p>
        <p>The users using either of these means can upload their data in different frequency. For the developers who needs user data in realtime, it will be additional overhead to keep polling for the latest data.</p>
        <p>Developer Connect API's Subscription API helps developers reduce this overhead by providing notifications whenever the user data is updated. This helps developers in concentrating on using the data rather than finding a way to get the latest data.</p>
        <p>This option is available for the Web API enrollment Type.</p>
        <p>
          The subscription API is a push model where the notifications are pushed to the subscribers based on their subscription. This is loosely based on the{" "}
          <a href="https://github.com/pubsubhubbub/" rel="noopener noreferrer" target="_blank" className="blue-text">
            PubSubHubbub
          </a>{" "}
          model.
        </p>
        <p>The notifications are sent to the subscribers in near real time.</p>
      </div>

      <h3 className="bold">What Should the Developers Do</h3>
      <div>
        <Accordion id="pub-panel" defaultActiveKey={0}>
          <AccordionComponent title="1. Subscription registration" eventKey={0} activeKey={activeKey}>
            <div>
              <p>
                The developer can subscribe to specific subscription Types (sub Types) while creating an Application in the Developer Connect API website. This option is available for the Web API enrollment Type. The developer has to enable subscription while creating an App{" "}
                <a href="../../../profile/licenses" className="blue-text">
                  Developer Application Registration
                </a>
                .
              </p>
              <p>A URL needs to be provided as the callback URL to receive notifications. Either a single URL can be provided for all the subscription Types or each subscription Type can be provided by a separate callback URL.</p>
              <div className="image">
                <Image src={require("./images/enable-subscription-image.jpg")} />
              </div>
              <h4>Subscription Types</h4>
              <p>Subscription is provided for the following events:</p>
              <ol>
                <li>
                  <b>Profile Updated</b>: [subType= Account] This provides notification for every User profile modification.
                </li>
                <li>
                  <b>Manual workout</b>: [subType=Manual] This provides notification for every manual workout posted by the user. This includes manual cardio and manual strength workouts. The manual workout posted can be through the Developer Connect website or any clients authorized by the user.
                </li>
                <li>
                  <b>Lifefitness workout</b>: [subType=Lifefitness] This provides notification for every workout completed by the user in any Lifefitness equipments.
                </li>
                <li>
                  <b>GPS workout</b>: [subType= GPS] This provides notifications for the GPS outdoor posted workouts through any of the clients authorized by the user.
                </li>
              </ol>
            </div>
          </AccordionComponent>
          <AccordionComponent title="2. Set up Callback URL" eventKey={1} activeKey={activeKey}>
            <div>
              <p>The developer can create a listener in their application to recieve the URL notifications. The URL called is a POST request. The callback URL is called with two parameters.</p>
              <ol>
                <li>
                  <b>subType</b>: The subscription Type for which the notification is sent.
                </li>
                <li>
                  <b>access_token</b>: The user access token provided by Developer Connect for the specific user. This can be used to distinguish the user which has recent updates for the subscription Type.
                </li>
              </ol>
              <p>
                Read about{" "}
                <a href="../authentication" className="blue-text">
                  OAuth
                </a>{" "}
                for details on user access token.
              </p>
              <p>Developer Connect API will only notify any change in the users data. The developer, based on the notification, should call the specific service to get the latest updated information.</p>
              <p>For example: User A has authorized for the developer application.</p>
              <p>The callback URL registered is:</p>

              <CodeComponent type="url" code={CALLBACK_URLS.registeredCallbackUrl} />

              <p>When the user profile has been updated then the callback URL is called:</p>

              <CodeComponent type="url" code={CALLBACK_URLS.calledCallbackUrl} />
            </div>
          </AccordionComponent>
        </Accordion>
      </div>
    </div>
  );
};

export default PubSubComponent;
