import { Col, Button } from "react-bootstrap";
import ReactGA from "react-ga";

import "./welcome.scss";

import { OVERVIEW_PATH, PROFILE_PATH } from "../../constants/routes";
import CONFIG from "../../config/config";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, []);

  const goToDocumentationPage = () => navigate(OVERVIEW_PATH);
  const goToAccountPage = () => navigate(PROFILE_PATH);

  return (
    <div className="background">
      <div className="Welcome-component">
        <section className="welcome">
          <div className="content">
            <h1>
              WELCOME TO
              <br />
              DEVELOPER CONNECT
            </h1>
            <p>At Life Fitness, we commit to providing the best exercising experience by empowering others. With Developer Connect technology, developers and fitness facility owners can access the Life Fitness Application Programming Interfaces (API) to be able to extend the functionalities Life Fitness brands have to offer.</p>
            <br />
            <br />
          </div>
        </section>
        <section className="section-one">
          <span className="icon-one" />
          <div className="description">
            <h3>Get Started</h3>
            <p>Ready to change the fitness industry? Developer Connect APIs provides a variety of digital solutions - for developers wishing to create powerful mobile Apple and Android applications using the Mobile APIs, and Web APIs, allowing developers to utilize the data, such as exercisers' profile, workout results, and workout libraries.</p>
            <p>Dive into step-by-step documentation explaining how to use our Web APIs and Android/iOS SDKs.</p>
            <Button onClick={goToDocumentationPage} className="documentation-button">
              View Documentation
            </Button>
          </div>
        </section>
        <hr />
        <section className="section-two-three">
          <div className="row">
            <Col xs={6} md={5} align="center">
              <div>
                <span className="icon-two" />
                <div className="description">
                  <h3 className="two">Licenses and Team Access</h3>
                  <p>Create licenses for your applications and start using our APIs. Test your application with our Dev licenses and when ready to launch, move them to production. Developing solutions for multiple clients? Add them as companies to easily manage their licenses and set team permission access.</p>
                  <Button onClick={goToAccountPage} className="account-button">
                    Manage Account
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={6} md={5} align="center">
              <div>
                <span className="icon-three" />
                <div className="description">
                  <h3 className="three">Try out the Sandbox</h3>
                  <p>Want to test the API before developing your app? We offer a detailed documentation of each of our APIs along with an option to try them out. Go on, create a license and start trying out our APIs.</p>
                  <a href={CONFIG.restURL} target="_blank">
                    <Button className="sandbox-button">Go to the Sandbox</Button>
                  </a>
                </div>
              </div>
            </Col>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Welcome;
