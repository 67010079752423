import React from "react";
import PropTypes from "prop-types";
import { Button, Image } from "react-bootstrap";

import DateComponent from "../../../core/DateComponent/DateComponent";
import SizeComponent from "../../../core/SizeComponent/SizeComponent";

import "./archive.scss";

const ArchiveComponent = (props) => {
  const { version, archive } = props;
  const { apiName, updated, notes, downloadUrl, size, compatibility } = archive.sdk;

  return (
    <div className="Archive-component">
      <div className="archive-title">
        <h4 className="bold">{apiName}</h4>
        <p>Release&nbsp;{version}</p>
      </div>
      <DateComponent className="date" date={updated} />
      <div className="compatible">
        <p>Compatible with</p>
        <div className="row">
          {compatibility.map(({ image, title }, i) => (
            <div className="image" key={i}>
              <Image src={require(`./images/${image}`)} />
              <p>{title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="notes">
        <label>Release Notes</label>
        {notes.map((note, i) => (
          <span key={i}>{note}</span>
        ))}
      </div>
      <div className="buttons">
        {size && (
          <Button className="download" href={downloadUrl}>
            Download
          </Button>
        )}
        {size && (
          <Button className="docs" href={archive.doc.downloadUrl} target="_blank">
            View Documentation
          </Button>
        )}
      </div>
      {size && (
        <div className="size">
          Size <SizeComponent bytes={Number(size)} />
        </div>
      )}
    </div>
  );
};

ArchiveComponent.propTypes = {
  version: PropTypes.string.isRequired,
  archive: PropTypes.object.isRequired,
};

export default ArchiveComponent;
