import { connect } from "react-redux";
// import { reduxForm } from "redux-form";

import LicensesTeamComponent from "../components/profile/LicensesTeam/LicensesTeamComponent";
import { register } from "../store/actions/session";
import { /* getLoadingStatus, */ filterLicenses } from "./selectors/licenseSelectors";
import { getAllCompanies, getCurrentCompanyId } from "./selectors/companySelectors";
import { selectCompany } from "../store/actions/companies";
import { getLicenses } from "../store/actions/licenses";
import { getTeamMembers } from "../store/actions/teamMembers";

const mapStateToProps = (state, { /* history, location: { state: locationState = {} }, */ isDev }) => ({
  // isLoading: getLoadingStatus(state), EPEDRO
  licenses: filterLicenses(state),
  companies: getAllCompanies(state),
  currentCompanyId: getCurrentCompanyId(state),
  isDev,
  // history,
  // locationState,
});

const mapDispatchToProps = {
  getTeamMembers,
  selectCompany,
  getLicenses,
  register,
};

const mergeProps = ({ currentCompanyId, /*history, locationState: { activePanel = null }, */ ...stateProps }, { register, selectCompany, getTeamMembers, getLicenses }) => ({
  ...stateProps,
  currentCompanyId,
  // activePanel,
  register,
  getMembers: (currentCompanyId) => {
    getTeamMembers(currentCompanyId);
  },
  getLicenses: (currentCompanyId) => {
    getLicenses(currentCompanyId);
  },
  onCompanySelect: selectCompany,
});

/*const mergeProps = ({ history, location, ...state }, { register }) => ({
    ...state,
    onCancel: () => {
        const { state } = location
        let path = LANDING_PATH

        if (state && state.prevPath) {
            path = state.prevPath
        }

        history.push(path)
    },
    onSubmit: register,
})*/

// export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
//     form: 'select-company',
// })(LicensesTeamComponent));
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LicensesTeamComponent);
