import React from "react";
import Recaptcha from "react-google-recaptcha";
import PropTypes from "prop-types";
import CONFIG from "../../../config/config";

const { siteKey } = CONFIG.captcha;

const CaptchaComponent = (props) => {
  const { input, formProps } = props;
  return (
    <div>
      <Recaptcha
        {...input}
        {...formProps}
        sitekey={siteKey}
        onChange={(response) => input.onChange(Boolean(response))}
      />
    </div>
  );
};

// CaptchaComponent.propTypes = {
//     input: PropTypes.object.isRequired,
//     formProps: PropTypes.object.isRequired,
// }

export default CaptchaComponent;
