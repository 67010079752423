export const CALLBACK_URLS = {
    sandbox_oauthEndpoint: 'https://api-vtqa.halo.fitness/partnerapi/oauthAuthorize',
    production_oauthEndpoint: 'https://api.halo.fitness/partnerapi/oauthAuthorize',
    sandbox_tokenEndpoint: 'https://api-vtqa.halo.fitness/partnerapi/oauthtoken',
    production_tokenEndpoint: 'https://api.halo.fitness/partnerapi/oauthtoken',
    sandbox_revokeToken: 'https://api-vtqa.halo.fitness/partnerapi/oauthrevoke',
    production_revokeToken: 'https://api.halo.fitness/partnerapi/oauthrevoke',
    sandbox_refreshToken: 'https://api-vtqa.halo.fitness/partnerapi/oauthrefresh',
    production_refreshToken: 'https://api.halo.fitness/partnerapi/oauthrefresh',
}
