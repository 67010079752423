import { isLoadingHandler } from '../Utils';

export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const UPDATE_CURRENT_COMPANY = 'UPDATE_CURRENT_COMPANY';
export const UNSET_CURRENT_COMPANY = 'UNSET_CURRENT_COMPANY';
export const IS_LOADING = 'COMPANY_IS_LOADING';

const INITIAL_STATE = {
    isLoading: false,
    items: [],
    currentCompany: {},
};

const ACTION_HANDLERS = {
    [IS_LOADING]: isLoadingHandler,
    [SET_COMPANIES]: (state, { payload: { companies } }) => ({
        ...state,
        items: companies,
    }),
    [SET_CURRENT_COMPANY]: (state, { payload: { companyId } }) => ({
        ...state,
        currentCompany: state.items.filter(({ firebaseId }) => firebaseId === companyId)[0] || {},
    }),
    [UPDATE_CURRENT_COMPANY]: (state, { payload: { params } }) => ({
        ...state,
        currentCompany: { ...state.currentCompany, ...params },
    }),
    [UNSET_CURRENT_COMPANY]: (state) => ({
        ...state,
        currentCompany: {},
    }),
};

export default (state = INITIAL_STATE, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
