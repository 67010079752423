import { connect } from "react-redux";

import { getValidationRules } from "../validation/validation";
import UpdatePwdComponent from "../components/profile/Profile/UpdatePwdComponent";
import SIGN_UP_RULES from "../validation/validationRules/signUp";
import { getCurrentUser } from "./selectors/userSelectors";

const UPDATE_PWD_FORM = "update-password";

const mapStateToProps = (state) => ({
  currentUserInfo: getCurrentUser(state),
});

const mapDispatchToProps = {};

const mergeProps = ({ currentUserInfo, ...stateProps }) => {
  return {
    currentUserInfo,
    validationRules: getValidationRules(["password", "confirmPassword", "currentPassword"], Object.assign(SIGN_UP_RULES)),
    // onSubmit: (params) => {
    //   let newParams = {
    //     ...params,
    //     email: currentUserInfo.email,
    //     uid: currentUserInfo.uid,
    //   };
    //   const action = updatePasswordAction;
    //   return action(newParams);
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UpdatePwdComponent);
// export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
//     form: UPDATE_PWD_FORM,
//     validate: getValidationRules([
//         'password',
//         'confirmPassword',
//         'currentPassword',
//     ], Object.assign(SIGN_UP_RULES)),
// })(UpdatePwdComponent));
