import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap";

import PanelComponent from "../../core/PanelComponent/PanelComponent";

import "./companyPanelGroup.scss";

const CompanyPanelGroupComponent = (props) => {
  // const onPanelEntering = (activeKey) => {
  //     this.setState({
  //         [`${activeKey}GridUpdate`]: true,
  //     });
  // }

  const { activeKey, handleSelect, companies, onEdit, viewLicenses, user } = props;
  return (
    <div className="panel-group">
      <Accordion id="companies-accordion" onSelect={handleSelect}>
        {companies.map((company, i) => (
          <PanelComponent
            key={company.firebaseId}
            eventKey={company.firebaseId}
            activeKey={activeKey}
            title={company.name}
            industry={company.industry}
            viewLicenses={viewLicenses}
            eventProps={{
              onEntering: () => this.onPanelEntering(activeKey),
            }}
          >
            <div className="company-info">
              <div className="company-parameters">
                <span className="company-label"> Employees </span>
                <br />
                <span>{company.companySize || company.size}</span>
                <br />
                <br />
                <span className="company-label"> Phone </span>
                <br />
                <span>{company.phone || "-"}</span>
                <br />
              </div>
              <div className="company-parameters">
                <span className="company-label"> Industry </span>
                <br />
                {company.industry !== "Other" && <span>{company.industry}</span>}
                {company.industry === "Other" && <span>{company.otherIndustry}</span>}
                <br />
                <br />
                {company.industry === "Fitness" && <span className="company-label"> Vertical </span>}
                <br />
                {company.industry === "Fitness" && <span>{company.vertical}</span>}
              </div>
              <div className="company-parameters">
                <span className="company-label"> Address </span>
                <br />
                <span>{company.address}</span>
                <br />
                <span>
                  {company.city}, {company.state} {company.postalCode}{" "}
                </span>
                <br />
                <span>{company.country}</span>
              </div>
              {user["companies"][company.firebaseId] && user["companies"][company.firebaseId].isAdmin && (
                <div className="company-edit" onClick={() => onEdit("edit", company)}>
                  <span className="company-edit-text">
                    <span className="edit"> Edit </span>
                  </span>
                </div>
              )}
            </div>
          </PanelComponent>
        ))}
      </Accordion>
    </div>
  );
};

CompanyPanelGroupComponent.propTypes = {
  activeKey: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  viewLicenses: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default CompanyPanelGroupComponent;
