import { connect } from "react-redux";
//import { reduxForm, isInvalid } from 'redux-form'; EPEDRO

import { getValidationRules } from "../validation/validation";
import TEAM_MEMBER_RULES from "../validation/validationRules/teamMember";
import TeamMemberComponent from "../components/profile/TeamMember/TeamMemberComponent";
import { createTeamMember, editTeamMember } from "../store/actions/teamMembers";
import { setLoading } from "../store/actions/session";

const _ = require("lodash");

const mapStateToProps = (state, { companyId, member, close, updateInvalid, confirmButtonTitle, cancelButtonTitle }) => ({
  initialValues: member,
  companyId,
  member,
  close,
  confirmButtonTitle,
  cancelButtonTitle,
  // EPEDRO
  // isInvalid: isInvalid('team-member')(state),
  // EPEDRO
  updateInvalid,
});

const mapDispatchToProps = {
  setLoading,
  createTeamMember,
  editTeamMember,
};

const mergeProps = ({ companyId, member, close, updateInvalid, ...stateProps }, { createTeamMember, editTeamMember, setLoading }) => {
  let prevData = {};
  return {
    close,
    member,
    setLoading,
    updateInvalid,
    ...stateProps,
    onSubmit: (data) => {
      if (!_.isEqual(data, prevData)) {
        prevData = data;

        let promise;

        if (data.companies) {
          data.companies[companyId].isAdmin = data.role === "Admin";
          delete data.role;
        }

        if (member) {
          promise = editTeamMember(member.uid, data, companyId);
        } else {
          promise = createTeamMember(companyId, data);
        }

        return promise.then(close).finally(() => {
          prevData = {};
        });
      }
    },
  };
};

// export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
//     form: 'team-member',
//     validate: getValidationRules([
//         'email',
//         'firstName',
//         'lastName',
//         'role',
//     ], TEAM_MEMBER_RULES),
// })(TeamMemberComponent));
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TeamMemberComponent);
