import { connect } from "react-redux";

import { selectGridItem, deleteGrid } from "../store/actions/gridStore";
import { getSelectedItems, getFilteredItems, getPaginationOptions } from "./selectors/gridSelectors";
import GridComponent from "../components/core/GridComponent/GridComponent";

const mapStateToProps = (state, props) => {
  const gridItems = getFilteredItems(state, props);
  // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  // console.log("$$$$$$$$$$$$ INSIDE GridContainer.mapStateToProps.props: ", props);
  // console.log("$$$$$$$$$$$$ INSIDE GridContainer.mapStateToProps.state: ", state);
  // console.log("$$$$$$$$$$$$ INSIDE GridContainer.mapStateToProps.props.items: ", props.items);
  // console.log("$$$$$$$$$$$$ INSIDE GridContainer.mapStateToProps.gridItems: ", gridItems);
  // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");

  return {
    ...props,
    selectedItems: getSelectedItems(state, props),
    gridItems,
    paginationOptions: getPaginationOptions(state, props),
  };
};

const mapDispatchToProps = {
  selectGridItem,
  deleteGrid,
};

const mergeProps = ({ gridItems, name, ...stateProps }, { setGrid, deleteGrid, setGridItems, setSorting, setGridPagination, ...dispatchProps }) => {
  // console.log("***** GridContainer.mergeProps.gridItems: ", gridItems);
  // console.log("***** GridContainer.mergeProps.stateProps: ", stateProps);
  return {
    ...stateProps,
    ...dispatchProps,
    name,
    gridItems,
    deleteGrid: () => deleteGrid(name),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(GridComponent);
