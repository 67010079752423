import React from "react";
import { Glyphicon, Panel, Badge, Accordion } from "react-bootstrap";
import PropTypes from "prop-types";

import "./panel.scss";

const PanelComponent = (props) => {
  const { title, eventKey, children, disabled, badge, activeKey, eventProps, industry, viewLicenses } = props;
  const isOpen = activeKey === eventKey;

  return (
    <>
      <Accordion.Item id={`${title}-panel`} className={disabled && "disabled"} eventKey={eventKey}>
        <Accordion.Header>
          {title}
          {badge > -1 && <Badge className="counter">{badge}</Badge>}
          {industry && (
            <span
              className="buttonlink"
              onClick={(e) => {
                e.preventDefault();
                viewLicenses(eventKey);
              }}
            >
              View Licenses
            </span>
          )}
        </Accordion.Header>
        <Accordion.Body>{children}</Accordion.Body>
      </Accordion.Item>
      {/* 
  
            <Panel
                id={`${title}-panel`}
                className={disabled && 'disabled'}
                eventKey={eventKey}>
                <Panel.Heading>
                    <Panel.Title toggle className="panel-title">{title}</Panel.Title>
                    {badge > -1 && <Badge className="counter">{badge}</Badge>}
                    {industry && <Panel.Title className="panel-right-end glyph" onClick={() => viewLicenses(eventKey)} >
                        <span className="license-redirect">View Licenses</span>
                    </Panel.Title>}
                    <Panel.Title toggle className="glyph">
                        <Glyphicon glyph={isOpen ? 'menu-up' : 'menu-down'} />
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Collapse {...eventProps}>
                    <Panel.Body>
                        {children}
                    </Panel.Body>
                </Panel.Collapse>
            </Panel> */}
    </>
  );
};

PanelComponent.propTypes = {
  eventKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  badge: PropTypes.number,
  eventProps: PropTypes.object,
  industry: PropTypes.string,
  history: PropTypes.object,
  viewLicenses: PropTypes.func,
};

export default PanelComponent;
