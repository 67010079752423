import moment from "moment/moment";

import { firebaseAuth } from "../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getUser } from "./user";
import { setToken, setUserId, removeToken, removeUserId, removeCompanyId, isStaySignedIn } from "../storage/localStorage";
import { signUp as signUpRequest, validateEmailSignUp as validateEmailRequest } from "./cloud-functions";

const _ = require("lodash");

const saveToken = async (credentials, useCookie) => {
  const token = await credentials.getIdToken();
  setToken(token, useCookie);

  return token;
};

const doAction = async (action, credentials, params, useCookie) => {
  const user = await action(params);
  const token = await saveToken(credentials, useCookie);
  setUserId(user.uid, useCookie);

  return {
    user,
    token,
  };
};

export async function signUp(params) {
  const user = _.pick(params, ["email", "password", "userName", "firstName", "lastName"]);
  const company = _.pick(params, ["name", "platform", "companySize", "industry", "otherIndustry", "vertical", "otherVertical", "country", "address", "city", "state", "postalCode"]);
  const { email, password } = user;

  const currentDate = moment().valueOf();

  user.createdAt = currentDate;
  user.updatedAt = currentDate;

  company.name = company.name.trim();
  company.platform = company.platform.split(",");
  company.phone = params.phoneNumber;
  company.createdAt = currentDate;
  company.updatedAt = currentDate;

  let result = {};
  const response = await signUpRequest(user, company);
  if (response.ok) {
    const { user: userCredentials } = await signInWithEmailAndPassword(firebaseAuth, email.trim(), password);
    result = await doAction(() => response.json(), userCredentials);
  } else {
    result.errors = await response.json();
  }

  return result;
}

//Validate email address before hitting Next button
export async function validateEmailSignUp(params) {
  let result = {};
  const userEmail = _.pick(params, ["email"]);

  const response = await validateEmailRequest(userEmail);
  if (response.ok) {
    console.log("ok response");
  } else {
    result.errors = await response.json();
  }
  return result;
}

export async function signOut() {
  await firebaseAuth.signOut();
  await removeToken();
  await removeUserId();
  await removeCompanyId();
}

export async function signIn(email, password, useCookie) {
  const { user } = await signInWithEmailAndPassword(firebaseAuth, email, password);

  return doAction(getUser, user, user.uid, useCookie);
}

export async function refreshToken() {
  const currentUser = firebaseAuth.currentUser;

  return currentUser && currentUser.getIdToken().then((validToken) => setToken(validToken, isStaySignedIn()));
}
