import { createAction } from "redux-actions";
// import { replace } from 'react-router-redux';
import { toast } from "react-toastify";
// import { SubmissionError } from "redux-form";

import { COMPANIES_PATH } from "../../constants/routes";
import { PLATFORMS } from "../../constants/common";
import { SET_COMPANIES, SET_CURRENT_COMPANY, UPDATE_CURRENT_COMPANY, UNSET_CURRENT_COMPANY, IS_LOADING } from "../reducers/companies";
import { UPDATE_USER } from "../reducers/session";
import { getCompany, createCompany, changeCompany, getPendingFacilities, updatePendingFacilities } from "../../firebase/company";
import { removeCompanyId, setCompanyId } from "../../storage/localStorage";
import companyApi from "../../api/companyApi";

const setCompanies = createAction(SET_COMPANIES);
export const setCompany = createAction(SET_CURRENT_COMPANY);
export const updateCompany = createAction(UPDATE_CURRENT_COMPANY);
const unsetCompany = createAction(UNSET_CURRENT_COMPANY);
const setLoading = createAction(IS_LOADING);
const updateReduxUser = createAction(UPDATE_USER);

const INTERNAL_SERVER_ERROR_CODE = 500;
const SUCCESSFUL_CREATE_COMPANY = "The company has been added successfully.";
const SUCCESSFUL_UPDATE_COMPANY = "The company has been updated successfully.";

const prepareCompany = ({ id, name, platform, phone, size, companySize, industry, vertical, country, address, city, state, postalCode, otherIndustry, otherVertical }) => ({
  ...(id && { id }),
  name,
  enrollmentType: platform.map((type) => PLATFORMS[type.trim()]).join(","),
  phoneNo: phone,
  companySize: size || companySize,
  industry,
  vertical: industry === "Fitness" ? vertical : null,
  country,
  address,
  city,
  state,
  postalCode,
  otherIndustry,
  otherVertical,
});

export const getCompanies = (companyIds) => async (dispatch) => {
  let companyPromises = [];
  dispatch(setLoading(true));

  companyIds.length > 0 && companyIds.split(",").forEach((companyId) => companyPromises.push(getCompany(companyId)));

  try {
    const companies = await Promise.all(companyPromises);

    dispatch(setCompanies({ companies }));
  } catch (error) {
    //TODO handle get company errors
  } finally {
    dispatch(setLoading(false));
  }
};

export const selectCompany = (companyId) => async (dispatch) => {
  try {
    await setCompanyId(companyId);
  } finally {
    dispatch(setCompany({ companyId }));
    //TODO change path when home page will be created.
    // dispatch(replace(LICENSES_PATH));
  }
};

export const removeCurrentCompany = () => async (dispatch) => {
  try {
    await removeCompanyId();
  } finally {
    dispatch(unsetCompany());
  }
};

export const addCompany = (company) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await createCompany(company);
    const result = await response.json();

    if (response.ok) {
      dispatch(updateReduxUser({ params: { companies: result.companies } }));
      dispatch(getCompanies(Object.keys(result.companies).join(",")));
      toast(SUCCESSFUL_CREATE_COMPANY);

      // dispatch(replace(COMPANIES_PATH));
    } else if (response.status !== INTERNAL_SERVER_ERROR_CODE) {
      throw new Error(result);
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const editCompany = (company, companies) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    company.platform = typeof company.platform === "string" ? company.platform.split(",") : company.platform;
    if (company.id) {
      await companyApi.update(prepareCompany(company));
    }
    const response = await changeCompany(company);
    const result = await response.json();

    if (response.ok) {
      let companIds = "";
      let companiesLength = companies.length;
      for (var index = 0; index < companiesLength; index++) {
        companIds += companies[index].firebaseId;
        if (index !== companiesLength - 1) {
          companIds += ",";
        }
      }
      dispatch(getCompanies(companIds));
      toast(SUCCESSFUL_UPDATE_COMPANY);

      //Check if the company has license with pending facilities and update if company name changed
      let pendingApprovalObj = await getPendingFacilities(company.firebaseId);

      if (pendingApprovalObj && pendingApprovalObj.companyName !== company.name.trim()) {
        pendingApprovalObj.companyName = company.name.trim();
        await updatePendingFacilities(company.firebaseId, pendingApprovalObj);
      }
    } else if (response.status !== INTERNAL_SERVER_ERROR_CODE) {
      throw new Error(result);
    }
  } finally {
    dispatch(setLoading(false));
  }
};
