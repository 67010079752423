import React from "react";
import PropTypes from "prop-types";

// import CONFIG from '../../../config/config';
import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";
import { Button } from "react-bootstrap";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";

import "./update-profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-final-form";
import { updatePasswordAction } from "../../../redux/slice/sessionSlice";

const UpdatePwdComponent = (props) => {
  const sessionState = useSelector((__state__) => __state__.session);
  const dispatch = useDispatch();
  const { validationRules } = props;

  const handleSubmit = (e) => {
    dispatch(updatePasswordAction({ ...e, email: sessionState.user.email, uid: sessionState.user.uid }));
  };

  return (
    <>
      {sessionState.pending && <LoadingComponent fullScreen />}
      <Form
        onSubmit={handleSubmit}
        validate={validationRules}
        render={(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <FieldGroupComponent
                label="Current Password"
                name="currentPassword"
                type="password"
                required={true}
                submissionError={sessionState.rejected && sessionState.error}
                formProps={{
                  maxLength: 30,
                }}
              />
              <FieldGroupComponent
                label="New Password"
                name="password"
                type="password"
                required={true}
                submissionError={sessionState.rejected && sessionState.error}
                formProps={{
                  maxLength: 30,
                }}
              />
              <FieldGroupComponent
                label="Confirm New Password"
                name="confirmPassword"
                type="password"
                required={true}
                formProps={{
                  maxLength: 30,
                }}
              />
              <div class="buttons">
                <Button type="submit" disabled={props.invalid || props.submitting} className="tertiary">
                  Update
                </Button>
              </div>
            </form>
          );
        }}
      />
    </>
  );
};

export default UpdatePwdComponent;
