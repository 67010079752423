import ios from "../../components/developerApi/developerSdk/Documentation/IOS/DocumentationComponent";
import android from "../../components/developerApi/developerSdk/Documentation/Android/DocumentationComponent";

import "./documentationLayout.scss";
import { useParams } from "react-router-dom";

const DOCUMENTATIONS = {
  ios,
  android,
  web: () => <h1>Not Implemented Yet</h1>,
};

const DocumentationLayout = (props) => {
  const { api } = useParams();
  const DocumentationComponent = DOCUMENTATIONS[api];

  return (
    <div className="documentation-layout">
      <h2>Documentation</h2>
      <DocumentationComponent />
    </div>
  );
};

// DocumentationLayout.propTypes = {
//     api: PropTypes.string.isRequired,
// }

export default DocumentationLayout;
