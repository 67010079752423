import ReactGA from "react-ga";
import Markdown from "react-markdown";

import text1 from "./MMSIntegrationText1.js";
import text2 from "./MMSIntegrationText2.js";
import BENEFIT_1 from "./API_Icon_MMS_Benefit_1.png";
import BENEFIT_2 from "./API_Icon_MMS_Benefit_2.png";
import BENEFIT_3 from "./API_Icon_MMS_Benefit_3.png";
import BENEFIT_4 from "./API_Icon_MMS_Benefit_4.png";

import "./mmsIntegration.scss";
import { useEffect } from "react";

const MMSIntegrationComponent = () => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "MMS Integrations Guides Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="mms-component">
      <Markdown>{text1}</Markdown>
      <div className="key-benefits">
        <div className="box">
          <img src={BENEFIT_1} alt="benefit_1" />
          <br />
          Automatically <span className="alt">create</span> shadow accounts for new club members in Developer Connect
        </div>
        <div className="box">
          <img src={BENEFIT_2} alt="benefit_2" />
          <br />
          Automatically <span className="alt">send</span> out Developer Connect activation and app download emails to club members
        </div>
        <div className="box">
          <img src={BENEFIT_3} alt="benefit_3" />
          <br />
          Automatically <span className="alt">remove</span> club members from Developer Connect (and disable app access)
        </div>
        <div className="box">
          <img src={BENEFIT_4} alt="benefit_4" />
          <br />
          Automatically <span className="alt">sync</span> with MMS and keep club member information up to date (Name, Email ID, Weight, RFID)
        </div>
      </div>
      <Markdown>{text2}</Markdown>
    </div>
  );
};

export default MMSIntegrationComponent;
