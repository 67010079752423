import "./code.scss";

const CodeComponent = (props) => {
  const { title, titleClassName, code, type } = props;
  return (
    <div className={`code ${type}`}>
      {title && (
        <p
          className={titleClassName}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      <pre>{code}</pre>
    </div>
  );
};

export default CodeComponent;
