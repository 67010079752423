import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { child, get, ref, GetDownloadURL } from "../../config/firebase";
import { TOS } from "../Constants";
import { actionFulfilled, actionPending, actionRejected } from "./utils";

// INITIAL STATE
const initialState = TOS.INITIAL_STATE;

// CREATE ACTIONS
export const isTOSUpdatedAction = createAsyncThunk(TOS.ACTION.TOS_UPDATE, async (payload, { rejectWithValue, getState, dispatch }) => {
  return get(child(ref, "/VERSION/tosUpdated"))
    .then(async (snapshot) => {
      const tosUpdateDate = snapshot.val();
      return tosUpdateDate && tosUpdateDate + TOS.MILLISECONDS_IN_SIXTY_DAYS > Date.now();
    })
    .catch((error) => {
      console.error(error);
    });
});

// CREATE SLICE
const tosSlice = createSlice({
  name: TOS.SLICE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(isTOSUpdatedAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(isTOSUpdatedAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.isUpdated = action.payload;
    });

    builder.addCase(isTOSUpdatedAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.items = [];
    });
  },
});

// CREATE REDUCER
const tosReducer = tosSlice.reducer;
export default tosReducer;
