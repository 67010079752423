const text = `
&nbsp;

Sample Workout Data details in JSON format

~~~
    {
        "datetime": "2020-02-24T18:15:21.216Z",
        "userId": "2112122",
        "workout_timeInZoneString": "0:00",
        "workout_timeElapsedString": "0:34",
        "workout_avgIncline": 0.0,
        "workout_avgLevel": 0.0,
        "workout_avgMets": 0.0,
        "workout_avgRpm": 0.0,
        "workout_avgSpeed": 8.7489,
        "workout_avgStride": 0.0,
        "workout_avgStridesPerMin": 0.0,
        "workout_totalWorkoutTime": 0.0,
        "workout_timeInCoolDown": -1.0,
        "workout_timeGoalInCoolDown": 0.0,
        "workout_completeWorkoutTime": 34.0,
        "workout_cooldownTimeRemaining": 3566.0,
        "workout_currentIncline": 0.0,
        "workout_timeElapsed": 34.0,
        "workout_currentSpeed": 0.4971,
        "workout_distance": 24.7887,
        "workout_distanceClimbed": 0.0,
        "workout_stride": 0.0,
        "workout_maxStride": 0.0,
        "workout_maxHeartRate": 0,
        "workout_mets": 0,
        "workout_rank": 0,
        "workout_recoveryHR": 0,
        "workout_rpm": 0,
        "workout_score": 0,
        "workout_heartRate": 0,
        "workout_summaryTotalWorkoutTime": 0,
        "workout_targetHeartRate": 0,
        "workout_currentLevel": 0,
        "workout_timeElapsedSeconds": 34,
        "workout_avgHeartRate": 0,
        "workout_caloriesPerHour": 54,
        "workout_calories": 0,
        "workout_airForcePRTScore": 0,
        "workout_totalWattSecondsSaved": 0,
        "workout_avgWatts": 0,
        "workout_watts": 0
    }
~~~
`;

export default text;
