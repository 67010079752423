import ReactGA from "react-ga";

import MMS_INTEGRATION_IMG from "./API_Image_MMS_Thumb@2x.png";
import REAL_TIME_IMG from "./API_Image_RealTime_Thumb@2x.png";

import "./introduction.scss";
import { useEffect } from "react";

const IntroductionComponent = () => {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Introduction Guides Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="introduction-component">
      <h2>Getting Started</h2>
      <div className="section">
        <br />
        <p>
          The Life Fitness open APIs allow you to create unique digital experiences to engage your exercisers. Use this
          <br />
          Guides tab to browse your options and learn key benefits of integrating various data points. Once you determine
          <br />
          the experience you would like to create, scroll down to find step-by-step instructions to get started.
        </p>
      </div>
      <div className="outer-container">
        <div className="box">
          <img src={MMS_INTEGRATION_IMG} alt="mms" />
          <h4>MMS INTEGRATION</h4>
          <p>Sync a gym's member list with Developer Connect to associate workout data with members.</p>
          <a href="mms-integration">Learn more</a>
        </div>
        <div className="box">
          <img src={REAL_TIME_IMG} alt="realtime" />
          <h4>REAL-TIME AVAILABILITY</h4>
          <p>Enable exercisers to plan their visit based on what type of equipment they plan to use, and whether it is currently available.</p>
          <a href="real-time-availability">Learn more</a>
        </div>
      </div>
    </div>
  );
};

export default IntroductionComponent;
