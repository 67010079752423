import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { child, get, ref, GetDownloadURL } from "../../config/firebase";
import { COMPATIBILITY } from "../Constants";

// INITIAL STATE
const initialState = COMPATIBILITY.INITIAL_STATE;

// CREATE ACTIONS
export const getCompatibilityMapping = createAsyncThunk(COMPATIBILITY.ACTION.GET_COMPATIBILITY_MAPPING, async (payload, { rejectWithValue, getState, dispatch }) => {
  console.log("***** getCompatibilityMapping *****");
  return get(child(ref, "WEB_API_COMPATIBILITY"))
    .then(async (snapshot) => {
      console.log("***** snapshot: ", snapshot.val());
      const data = snapshot.val() || {};
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
});

// CREATE SLICE
const compatibilitySlice = createSlice({
  name: COMPATIBILITY.SLICE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCompatibilityMapping.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
      state.apis = {};
      state.commonApis = {};
      state.consoleSpecificApis = {};
    });

    builder.addCase(getCompatibilityMapping.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.apis = action.payload["APIS"];
      state.commonApis = action.payload["commonAPIS"];
      state.consoleSpecificApis = action.payload["consoleSpecificAPIS"];
    });

    builder.addCase(getCompatibilityMapping.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.apis = {};
      state.commonApis = {};
      state.consoleSpecificApis = {};
    });
  },
});

// CREATE REDUCER
const compatibilityReducer = compatibilitySlice.reducer;
export default compatibilityReducer;
