import { useSelector } from "react-redux";
import HeaderComponent from "../components/header/HeaderComponent";

const _ = require("lodash");

const HeaderContainer = (props) => {
  const sessionState = useSelector((__state__) => __state__.session);
  const companiesState = useSelector((__state__) => __state__.companies);
  console.log("***** sessionState: ", sessionState);
  return <HeaderComponent user={_.isEmpty(sessionState.user) ? null : sessionState.user} />;
};

export default HeaderContainer;
