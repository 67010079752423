import { connect } from "react-redux";

import { getArchives } from "../store/actions/storage";
import ArchivesComponent from "../components/developerApi/developerSdk/Archives/ArchivesComponent";
import { API_NAMES } from "../constants/common";
import { ARCHIVES } from "../constants/storage";

const mapStateToProps = ({ session: { user }, storage: { files, isLoading, sdkVersion } }, { api }) => ({
  api,
  archives: files[`${api}.${ARCHIVES}`] || {},
  isLoading,
  sdkVersion,
  user,
});

const mapDispatchToProps = {
  getArchives,
};

const mergeProps = ({ api, version, sdkVersion, user, ...stateProps }, { getArchives }) => {
  return {
    ...stateProps,
    getArchives: () => getArchives(api, "latest"),
    title: `Download ${API_NAMES[api]} SDK`,
    content: `Download the latest ${API_NAMES[api]} SDK from Life Fitness Developer Connect API.`,
    api,
    user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ArchivesComponent);
