import { COMMON_RULES } from "../validation";

export default {
  userName: [
    {
      name: "Required",
      message: "Required field",
    },
    {
      name: "Invalid",
      func: (values) => values.userName.length < 4,
      message: "User ID should be minimum 4 characters.",
    },
    {
      name: "Invalid",
      func: ({ userName }) => /[^a-zA-Z0-9]+/.test(userName),
      message: "Spaces and Symbols are not valid characters. Please use latin letters or numeric characters only.",
    },
  ],
  password: [
    {
      name: "Required",
      message: "Please enter password.",
    },
    {
      name: "Invalid",
      func: ({ password }) => /\s+/.test(password),
      message: "Password cannot contain spaces.",
    },
    {
      name: "Invalid",
      func: function (data) {
        return (
          !data.password ||
          data.password.length < 8 ||
          data.password.length > 15 ||
          !/[!@#$%^&*]{1}/.test(data.password) ||
          !/[0-9]{1}/.test(data.password) ||
          !/[a-zA-Z]{1}/.test(data.password)
        );
      },
      funcMessage: function (data) {
        !data.password && (data.password = "");
        return [
          { value: data.password.length < 8 || data.password.length > 15, message: "Minimum of 8 characters and maximum of 15 characters" },
          { value: !/[!@#$%^&*]{1}/.test(data.password), message: "At least 1 special character (!@#$%^&*)" },
          { value: !/[0-9]{1}/.test(data.password), message: "At least 1 number" },
          { value: !/[a-zA-Z]{1}/.test(data.password), message: "At least 1 uppercase or lowercase letter" },
        ];
      },
    },
  ],
  confirmPassword: [
    {
      name: "Required",
      message: "Please enter password.",
    },
    {
      name: "Invalid",
      func: (values) => values.password !== values.confirmPassword,
      message: "Passwords do not match.",
    },
  ],
  currentPassword: [
    {
      name: "Required",
      message: "Please enter a valid password.",
    },
  ],
  firstName: COMMON_RULES.firstName,
  lastName: COMMON_RULES.lastName,
  email: COMMON_RULES.email,
  acceptTerms: [
    {
      name: "Required",
      message: "Please indicate acceptance of Terms and Conditions.",
    },
  ],
  acceptSubscription: [
    {
      name: "Required",
      message: "Please indicate acceptance of Subscription Agreement.",
    },
  ],
  captcha: [
    {
      name: "Required",
      message: "Please make a selection.",
    },
  ],
};
