// TODO remove after API implementation
export const CATEGORIES = [
    'Gaming',
    'Nutrition',
    'Personal Training',
    'Rewards',
    'Tracking',
    'Wellness',
    'Other',
]

export const PERSONAL_SUBSCRIPTIONS = [
    {
        name: 'lifefitnessCallback',
        label: 'URL',
        placeholder: 'LF Workout Posted',
        fieldPlaceholder: 'Enter URL',
    },
    {
        name: 'manualCallback',
        label: 'URL',
        placeholder: 'Manual workout',
        fieldPlaceholder: 'Enter URL',
    },
    {
        name: 'gpsCallback',
        label: 'URL',
        placeholder: 'GPS workout',
        fieldPlaceholder: 'Enter URL',
    },
    {
        name: 'accountCallback',
        label: 'URL',
        placeholder: 'Profile updated',
        fieldPlaceholder: 'Enter URL',
    },
]

export const CARDIO_SUBSCRIPTIONS = [
    {
        name: 'lfUserLoginCallback',
        label: 'URL',
        placeholder: 'User Logged In',
        fieldPlaceholder: 'Enter URL',
    },
    {
        name: 'lfUserWorkoutStartCallback',
        label: 'URL',
        placeholder: 'User Workout Started',
        fieldPlaceholder: 'Enter URL',
    },
    {
        name: 'lfUserWorkoutEndCallback',
        label: 'URL',
        placeholder: 'User Workout Ended',
        fieldPlaceholder: 'Enter URL',
    },
    {
        name: 'lfUserLogoutCallback',
        label: 'URL',
        placeholder: 'User Logged Out',
        fieldPlaceholder: 'Enter URL',
    },
    {
        name: 'workoutdataCallback',
        label: 'URL',
        placeholder: 'User Workout Data (Sent every second)',
        fieldPlaceholder: 'Enter URL',
    },
]

export const STRENGTH_SUBSCRIPTIONS = [
    {
        name: 'strengthAssetLoginCallback',
        label: 'URL',
        placeholder: 'User Logged In',
        fieldPlaceholder: 'Enter URL',
    },
    {
        name: 'strengthResultsCallback',
        label: 'URL',
        placeholder: 'User Workout Results',
        fieldPlaceholder: 'Enter URL',
    },
    {
        name: 'strengthAssetLogoutCallback',
        label: 'URL',
        placeholder: 'User Logged Out',
        fieldPlaceholder: 'Enter URL',
    },
]

export const ASSET_SUBSCRIPTIONS = [
    {
        name: 'assetStatsCallback',
        label: 'URL',
        placeholder: 'Asset Stats',
        fieldPlaceholder: 'Enter URL',
    },
]

export const FACILITY_SUBSCRIPTIONS = [
    {
        name: 'facilitiesSubscribedFor',
        label: 'Facilities',
        alwaysEnable: true,
        placeholder: 'comma-separated list of facility IDs',
    },
    {
        name: 'lfUserLoginCallback',
        placeholder: 'User Login',
    },
    {
        name: 'lfUserWorkoutStartCallback',
        placeholder: 'Workout Started',
    },
    {
        name: 'lfUserWorkoutEndCallback',
        placeholder: 'Workout Ended',
    },
    {
        name: 'lfUserLogoutCallback',
        placeholder: 'User Logout',
    },
    {
        name: 'workoutdataCallback',
        placeholder: 'Realtime Workout Data (every second)',
    },
]

export const DESCRIPTION_LIMIT = 5000
