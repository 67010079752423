import { createSelector } from "reselect";

const _ = require("lodash");

const getProp = ([state, props, key]) => {
  // console.log("************** getProp.state: ", state);
  // console.log("************** getProp.key: ", key);
  // console.log("************** getProp.name: ", props.name);
  const grid = state.gridStore.grids[props.name];

  return grid && grid[key];
};

const itemsSelector = (...args) => getProp([...args, "items"]) || [];
const sortingSelector = (...args) => getProp([...args, "sortBy"]) || {};
const paginationSelector = (...args) => getProp([...args, "pagination"]) || {};
const selectedItemsSelector = (...args) => getProp([...args, "selectedItems"]);

export const getSelectedItems = createSelector(selectedItemsSelector, (selectedItems) => selectedItems);

export const checkSelectedItems = createSelector(getSelectedItems, (selectedItems) => !_.some(selectedItems, (value) => value));

export const getFilteredItems = createSelector(itemsSelector, sortingSelector, paginationSelector, (items, { props, directions }, { enabled, activePage, itemsPerPage }) => {
  let gridItems = items;
  if (props && props.length > 0) {
    gridItems = _.orderBy(
      gridItems,
      props.map((prop) => (item) => {
        let value = item[prop];

        return value !== null ? (isNaN(value) ? value.toString().toLowerCase() : value) : "";
      }),
      directions
    );
  }

  if (enabled) {
    if (isNaN(activePage)) activePage = 1;
    const firstItemIndex = (activePage - 1) * itemsPerPage;
    const lastItemIndex = firstItemIndex + itemsPerPage;
    gridItems = gridItems.slice(firstItemIndex, lastItemIndex);
  }

  return gridItems;
});

export const getPaginationOptions = createSelector(paginationSelector, (options) => options);
