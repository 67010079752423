// import React from 'react'
// import PropTypes from 'prop-types'
import { Form } from "react-bootstrap";
import "../fieldGroup.scss";
import { useState } from "react";

const InputComponent = (props) => {
  const { input, type, formProps, className, readOnly, onChange } = props;
  let passwordField = input.name === "confirmPassword" || input.name === "password" || input.name === "currentPassword";
  let searchField = input.name === "search";

  let __type__ = {
    type,
  };
  if (type === "textarea") {
    __type__ = {
      as: type,
    };
  }

  return (
    <>
      {passwordField && (
        <div className="pwdContainer">
          <Form.Control type="password" {...formProps} {...input} readOnly={readOnly} className={className !== "form-group" && className} />
        </div>
      )}
      {!passwordField && (
        <div className={searchField ? "searchContainer" : ""}>
          {searchField && <span className="searchField" />}
          {!onChange && <Form.Control {...__type__} {...formProps} {...input} readOnly={readOnly} className={className !== "form-group" && className} />}
          {onChange && (
            <Form.Control
              {...__type__}
              {...formProps}
              {...input}
              readOnly={readOnly}
              className={className !== "form-group" && className}
              onChange={(e) => {
                input.onChange(e);
                onChange(e);
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

// InputComponent.defaultProps = {
//     className: '',
//     input: {},
//     type: 'text',
//     formProps: {},
//     readOnly: false,
// }

// InputComponent.propTypes = {
//     className: PropTypes.string.isRequired,
//     input: PropTypes.object.isRequired,
//     type: PropTypes.string,
//     formProps: PropTypes.object.isRequired,
//     readOnly: PropTypes.bool,
//     value: PropTypes.string,
// }

export default InputComponent;
