import { useSelector } from "react-redux";
import FieldGroupComponent from "../../../components/core/FieldGroupComponent/FieldGroupComponent";

import { PLATFORMS, COMPANY_SIZES, INDUSTRIES, VERTICALS, COUNTRIES } from "../../../constants/companyConstants";
import { useState } from "react";

const CompanyFormFieldsLayout = (props) => {
  const sessionState = useSelector((__state__) => __state__.session);

  const [otherFields, setOtherFields] = useState({
    industry: sessionState.company?.industry?.value === "Other",
    vertical: sessionState.company?.vertical?.value === "Other",
    isFitnessIndustry: sessionState.company?.industry?.value === "Fitness",
    values: {
      industry: sessionState.company?.industry?.value || "",
      vertical: sessionState.company?.vertical?.value || "",
    },
    ...props.otherFields,
  });

  const onIndustryChange = (selectedOption) => {
    setOtherFields({
      ...otherFields,
      industry: selectedOption.value === "Other",
      isFitnessIndustry: selectedOption.value === "Fitness",
      values: {
        ...otherFields.values,
        industry: selectedOption.value,
      },
    });
  };

  const onVerticalChange = (selectedOption) => {
    setOtherFields({
      ...otherFields,
      vertical: selectedOption.value === "Other",
      values: {
        ...otherFields.values,
        vertical: selectedOption.value,
      },
    });
  };

  return (
    <div>
      <FieldGroupComponent
        label="Company Name"
        name="name"
        type="text"
        required={true}
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 100,
        }}
      />
      <FieldGroupComponent
        label="Address"
        name="address"
        type="text"
        required={true}
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 100,
        }}
      />
      <FieldGroupComponent
        label="City"
        name="city"
        type="text"
        required={true}
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 100,
        }}
      />
      <FieldGroupComponent
        label="State"
        name="state"
        type="text"
        required={true}
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 100,
        }}
      />
      <FieldGroupComponent
        label="Country"
        name="country"
        type="select"
        required={true}
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          id: "country",
          options: COUNTRIES,
        }}
      />
      <FieldGroupComponent
        label="Postal / ZIP Code"
        name="postalCode"
        type="text"
        required={true}
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 20,
        }}
      />
      <FieldGroupComponent
        label="Phone Number"
        name="phoneNumber"
        type="text"
        required={false}
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          maxLength: 20,
        }}
      />
      <FieldGroupComponent
        label="Enrollment Type"
        name="platform"
        placeholder={"Select Options"}
        type="select"
        required={true}
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          id: "platform",
          isMulti: true,
          options: PLATFORMS,
        }}
      />
      <FieldGroupComponent
        label="Number of Employees"
        name="companySize"
        type="select"
        required={true}
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          id: "companySize",
          options: COMPANY_SIZES,
        }}
      />
      <FieldGroupComponent
        label="Industry"
        name="industry"
        type="select"
        required={true}
        value={otherFields.values.industry}
        submissionError={sessionState.rejected && sessionState.error}
        formProps={{
          onChange: onIndustryChange,
          id: "industry",
          options: INDUSTRIES,
        }}
      />
      {otherFields.industry && (
        <FieldGroupComponent
          name="otherIndustry"
          type="text"
          submissionError={sessionState.rejected && sessionState.error}
          formProps={{
            maxLength: 100,
          }}
        />
      )}
      {otherFields.isFitnessIndustry && (
        <FieldGroupComponent
          label="Fitness Vertical"
          name="vertical"
          type="select"
          value={otherFields.values.vertical}
          submissionError={sessionState.rejected && sessionState.error}
          formProps={{
            onChange: onVerticalChange,
            id: "vertical",
            options: VERTICALS,
          }}
        />
      )}
      {otherFields.vertical && otherFields.isFitnessIndustry && (
        <FieldGroupComponent
          name="otherVertical"
          type="text"
          submissionError={sessionState.rejected && sessionState.error}
          formProps={{
            maxLength: 100,
          }}
        />
      )}
    </div>
  );
};

export default CompanyFormFieldsLayout;
