import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";

import { CALLBACK_URLS } from "./AuthenticationConstants";
import { LFCONNECT_URLS } from "./../GettingStarted/Web/GettingStartedConstants";
import CodeComponent from "../../../core/CodeComponent/CodeComponent";
import { Accordion, Image, Table } from "react-bootstrap";
import AccordionComponent from "../../../core/AccordionComponent/AccordionComponent";

import "./authenticationComponent.scss";
import "../../../core/GridComponent/grid.scss";
import "../../../core/CodeComponent/code.scss";
// import GridComponent from '../../../core/GridComponent/GridComponent';

const AuthenticationComponent = () => {
  const [activeKey, setActiveKey] = useState();
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Authentication Page" });
    ReactGA.pageview(window.location.pathname);
  });

  const handleSelect = (__activeKey__) => {
    setActiveKey(__activeKey__);
  };

  return (
    <div className="authentication-component">
      <div>
        <h3>Accessing the API Services with OAUTH2</h3>
      </div>
      <br />
      <div className="section">
        <p>Developer Connect API Website uses OAuth2 as authentication protocol in accessing the Developer Connect account-specific services. All the services require following the OAuth2 protocol.</p>
        <p>
          The services require an <b>Access Token</b> to access the Web Services. To get an Access Token, the following flow is required to be followed:
        </p>
      </div>
      <p>
        <b>To summarize, the following are the necessary steps to access OAuth2-secured services (each step is explained in detail):</b>
      </p>

      <div className="row">
        <figure>
          <Image src={require("./accesss_token_flow.png")} />
        </figure>
      </div>

      <div>
        <Accordion id="pub-panel" defaultActiveKey={0}>
          <AccordionComponent title="1. Developer Application calls the Authorization Endpoint" eventKey={0} activeKey={activeKey}>
            <div>
              <p>
                The <b>Developer Application</b> accesses the <b>Authorization Endpoint</b>, passing the Client ID and Redirection URI.
              </p>
              <br />
              <h4>SANDBOX URL</h4>

              <CodeComponent type="url" code={CALLBACK_URLS.sandbox_oauthEndpoint} />
              <br />

              <h4>PRODUCTION URL</h4>

              <CodeComponent type="url" code={CALLBACK_URLS.production_oauthEndpoint} />
              <br />

              <h4>Parameters</h4>
              <div className="grid-wrapper" align="left">
                <Table hover className="grid">
                  <thead>
                    <tr>
                      <th>Parameter Name</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="left">response_type</td>
                      <td align="left">Type of response the server is expecting. Supported value: "code"</td>
                    </tr>
                    <tr>
                      <td align="left">client_id</td>
                      <td align="left">Valid OAuth ClientID</td>
                    </tr>
                    <tr>
                      <td align="left">redirect_uri</td>
                      <td align="left">Redirection URI to be used by Developer Connect Web API server to provide Authorization Code.</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <br />
              <h4>Sample Call</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>GET</span>
                  <br />
                  <span>{CALLBACK_URLS.sandbox_oauthEndpoint}?</span>
                  <br />
                  <span>response_type=code</span>
                  <br />
                  <span>client_id=wwjk121jhkj231</span>
                  <br />
                  <span>redirect_uri=https%3A%2F%2F...</span>
                </pre>
              </div>
            </div>
          </AccordionComponent>
          <AccordionComponent title="2. Exerciser is asked to log in to Developer Connect and authorize" eventKey={1} activeKey={activeKey}>
            <div>
              <p>
                <b>Developer Connect API Website</b> serves the <b>Authorization Endpoint</b>, requesting the exerciser to verify by logging in. The user authorizes to provide the Developer App access to the user's data. A screen with Username / Password is to be shown.
              </p>
              <p>
                After a successful login, Developer Connect API Website redirects the screen to the <b>Redirection URL</b> provided, passing the Authorization Code.
              </p>
              <br />
              <h4>Sample Screen</h4>
              <Image src={require("./authorization_login.png")} />
              <p>The Redirection URI receives the code.</p>
              <br />
              <h4>Sample Output</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>https://redirect uri</span>
                  <br />
                  <span>?code=f4lbd32556eb1391745ad8ccd25f|765304</span>
                </pre>
              </div>
              <p>If Developer Connect login credentials provided are invalid the login screen shows related errors.</p>
              <br />
              <h4>Sample Screen</h4>
              <Image src={require("./authorization_login_error.png")} />
              <p>If Developer Connect is not authorized then Developer Connect redirects to the third party provided Redirection URL with parameters error as access denied and additional error description.</p>
              <br />
              <h4>Sample Output</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>https://redirect uri</span>
                  <br />
                  <span>?error=access_denied&error_description=The+user+has+not+authorized+for+this+application.</span>
                </pre>
              </div>
              <p>If the login is cancelled then Developer Connect redirects to the third party provided Redirection URL with parameters error as access denied and additional error description.</p>
              <br />
              <h4>Sample Output</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>https://redirect uri</span>
                  <br />
                  <span>?error=access_denied&error_description=The+user+revoked+access+for+this+application.</span>
                </pre>
              </div>
            </div>
          </AccordionComponent>
          <AccordionComponent title="3. Developer exchanges code for access token" eventKey={2} activeKey={activeKey}>
            <div>
              <p>
                <b>Developer Application</b> requests an Access Token from Developer Connect API Website by calling the <b>Token Endpoint</b>, passing the Authorization Code retrieved in previous step, Client ID, Client Secret. Redirection URI is optional. If you want to pass the output to redirect URL you can specify it, otherwise you will get output in response.
              </p>
              <br />
              <h4>SANDBOX URL</h4>

              <CodeComponent type="url" code={CALLBACK_URLS.sandbox_tokenEndpoint} />
              <br />

              <h4>PRODUCTION URL</h4>

              <CodeComponent type="url" code={CALLBACK_URLS.production_tokenEndpoint} />
              <br />
              <h4>Parameters</h4>
              <div className="grid-wrapper">
                <Table hover className="grid">
                  <thead>
                    <tr>
                      <th>Parameter Name</th>
                      <th>Description</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="left">grant_type</td>
                      <td align="left">Type of OAuth2 grant type used. Supported value:"authorization_code".</td>
                      <td align="left">
                        <b>mandatory</b>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">client_id</td>
                      <td align="left">Valid OAuth ClientID.</td>
                      <td align="left">
                        <b>mandatory</b>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">client_secret</td>
                      <td align="left">Valid OAuth Client Secret.</td>
                      <td align="left">
                        <b>mandatory</b>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">code</td>
                      <td align="left">Authorization code received in the previous step.</td>
                      <td align="left">
                        <b>mandatory</b>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">redirect_uri</td>
                      <td align="left">Redirection URI to be used by Developer Connect Website Server to provide the Access Token.</td>
                      <td align="left">
                        <b>optional</b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <br />
              <h4>Sample Call with Redirect URL</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>POST</span>
                  <br />
                  <span>{CALLBACK_URLS.sandbox_tokenEndpoint}?</span>
                  <br />
                  <span>grant_type=authorization_code&</span>
                  <br />
                  <span>code=24324j32kj4l32j&</span>
                  <br />
                  <span>client_id=2ijkjsfrhwhejr4e&</span>
                  <br />
                  <span>client_secret=sdfwerewrwerewrew&</span>
                  <br />
                  <span>redirect_uri=https%3A%2F%2F...</span>
                </pre>
              </div>
              <p>The Redirection URI recieves the access token, refresh token, and the expiry date</p>
              <br />
              <h4>Sample Output</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>https://redirect uri </span>
                  <br />
                  <span>?access_token=3fa6928e053ee37ece8ea7681dbe62ae|6c7cd904122e623ce625613d6af337c4</span>
                  <br />
                  <span>&token_type=bearer</span>
                  <br />
                  <span>&refresh_token=6c7cd904122e623ce625613d6af337c4|31b51d3a6750a092a4286b321bbdf29a|391334</span>
                  <br />
                  <span>&expires_in=31622400</span>
                </pre>
              </div>
              <br />
              <h4>Sample Call without redirect URL</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>POST</span>
                  <br />
                  <span>{CALLBACK_URLS.sandbox_tokenEndpoint}?</span>
                  <br />
                  <span>grant_type=authorization_code&</span>
                  <br />
                  <span>code=24324j32kj4l32j&</span>
                  <br />
                  <span>client_id=2ijkjsfrhwhejr4e&</span>
                  <br />
                  <span>client_secret=sdfwerewrwerewrew</span>
                </pre>
              </div>
              <p>Return a json response with access token, refresh token and the expiry date</p>
              <br />
              <h4>Sample Output</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>&#123;</span>
                  <br />
                  <span>"access_token":"3fa6928e053ee37ece8ea7681dbe62ae|6c7cd904122e623ce625613d6af337c4",</span>
                  <br />
                  <span>"token_type":"bearer",</span>
                  <br />
                  <span>"refresh_token":"6c7cd904122e623ce625613d6af337c4|31b51d3a6750a092a4286b321bbdf29a|391334"</span>
                  <br />
                  <span>"expires_in":31622400</span>
                  <br />
                  <span>&#125;</span>
                </pre>
              </div>
            </div>
          </AccordionComponent>
          <AccordionComponent title="4. Accessing LFopen Website API services using the Access Token" eventKey={3} activeKey={activeKey}>
            <div>
              <p>Developer Application can now access the Developer Connect API services, passing the Access Token in every request. Developer Connect API Website validates the access token every call.</p>
              <br />
              <h4>Sample Service Call</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>GET</span>
                  <br />
                  <span>{LFCONNECT_URLS.sandbox_api}/api2/user</span>
                  <br />
                  <span>HEADER</span>
                  <br />
                  <span>Authorization: Bearer 324324324324324324</span>
                </pre>
              </div>
            </div>
          </AccordionComponent>
          <AccordionComponent title="5. Requesting a new Token after Access Token expires" eventKey={4} activeKey={activeKey}>
            <div>
              <p>
                The Developer Connect API Website validates if the access token is expired on every service call; if so, the service will then return an error indicating that the token is expired. The
                <b> Developer Application</b> can call the <b>Refresh Token Endpoint</b> passing the refresh token provided in (3). Developer Connect API Website validates the Refresh Token and responds with a newly issued Access Token and Refresh Token. Redirection URI is optional. If you want to pass the output to redirect URL you can specify it, otherwise you will get output in response.
              </p>
              <br />
              <h4>Sample Call with Redirect URL</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>POST</span>
                  <br />
                  <span>{CALLBACK_URLS.sandbox_refreshToken}?</span>
                  <br />
                  <span>grant_type=refresh_token&</span>
                  <br />
                  <span>client_id=2ijkjsfrhwhejr4e&</span>
                  <br />
                  <span>client_secret=sdfwerewrwerewrew&</span>
                  <br />
                  <span>refresh_token=dskjfksdjflksjdf32432jkjdkjksjdf&</span>
                  <br />
                  <span>redirect_uri=https%3A%2F%2F...</span>
                </pre>
              </div>
              <p>The Redirection URI recieves the access token, refresh token and the expiry date</p>
              <br />
              <h4>Sample Output</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>https//:redirect uri</span>
                  <br />
                  <span>?access_token=3fa6928e053ee37ece8ea7681dbe62ae|6c7cd904122e623ce625613d6af337c4</span>
                  <br />
                  <span>&token_type=bearer</span>
                  <br />
                  <span>&refresh_token=6c7cd904122e623ce625613d6af337c4|31b51d3a6750a092a4286b321bbdf29a|391334</span>
                  <br />
                  <span>&expires_in=604800</span>
                </pre>
              </div>
              <br />
              <h4>Sample Call without redirect URL</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>POST</span>
                  <br />
                  <span>{CALLBACK_URLS.sandbox_refreshToken}?</span>
                  <br />
                  <span>grant_type=refresh_token&</span>
                  <br />
                  <span>refresh_token=dskjfksdjflksjdf32432jkjdkjksjdf</span>
                </pre>
              </div>
              <p>Return a json response with access token, refresh token and the expiry date</p>
              <br />
              <h4>Sample Output</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>&#123;</span>
                  <br />
                  <span>"access_token":"3fa6928e053ee37ece8ea7681dbe62ae|6c7cd904122e623ce625613d6af337c4",</span>
                  <br />
                  <span>"token_type":"bearer",</span>
                  <br />
                  <span>"refresh_token":"6c7cd904122e623ce625613d6af337c4|31b51d3a6750a092a4286b321bbdf29a|391334",</span>
                  <br />
                  <span>"expires_in":31622400</span>
                  <br />
                  <span>&#125;</span>
                </pre>
              </div>
            </div>
          </AccordionComponent>
          <AccordionComponent title="6. Revoking OAccess Tokens and Refresh Tokens" eventKey={5} activeKey={activeKey}>
            <div>
              <p>
                Revocation endpoint invalidates access and refresh tokens upon client request. The
                <b> Developer Application</b> can call (as a GET or POST call) to <b>Token Revokation End point</b> passing either an access token (the short-lived session token issued by Developer Connect Web API) or a refresh token (the long-lived persistent token). Revoking an access token is analogous to simply logging out, but revoking a refresh token terminates an app's authorization to call APIs on behalf of the user and revokes all outstanding access tokens issued against that refresh token. After revoking an access token, the <b>Developer Application</b> can later retrieve a new access token using refresh token (by calling to /refreshaccess endpoint). However, when revoking the refresh token, the <b>Developer Application</b> has to start the OAuth flow from the beginning in-order to obtain a new access token.
              </p>
              <br />
              <h4>SANDBOX URL</h4>
              <CodeComponent type="url" code={CALLBACK_URLS.sandbox_revokeToken} />
              <br />
              <h4>PRODUCTION URL</h4>
              <CodeComponent type="url" code={CALLBACK_URLS.production_revokeToken} />
              <br />
              <h4>Parameters</h4>
              <div className="grid-wrapper">
                <Table hover className="grid">
                  <thead>
                    <tr>
                      <th>Parameter Name</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="left">token</td>
                      <td align="left">This parameter is mandatory. The Developer Application can send either an access token or a refresh token.</td>
                    </tr>
                    <tr>
                      <td align="left">token_type_hint</td>
                      <td align="left">This parameter is optional. A hint about the type of the token submitted for revocation. The Developer Application can pass this parameter in order to help the authorization server to optimize the token lookup. If the server is unable to locate the token using the given hint, it WILL extend its search across all of its supported token types. Supported values: "access_token", "refresh_token"</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <br />
              <h4>Sample Call</h4>
              <span>(Break lines added below for formatting only)</span>
              <div className="code http">
                <pre>
                  <span>POST</span>
                  <br />
                  <span>{CALLBACK_URLS.sandbox_revokeToken}?</span>
                  <br />
                  <span>token=3fa6928e053ee37ece8ea7681dbe62ae4fs23sdgwew</span>
                  <br />
                  <span>&token_type_hint=refresh_token</span>
                </pre>
              </div>
            </div>
          </AccordionComponent>
        </Accordion>
      </div>
    </div>
  );
};

export default AuthenticationComponent;
