import web from "../../components/developerApi/developerSdk/GettingStarted/Web/GettingStartedComponent";
import platform from "../../components/developerApi/developerSdk/GettingStarted/Platform/GettingStartedComponent";

import "./gettingStartedLayout.scss";
import { useParams } from "react-router-dom";

const GETTING_STARTED = {
  web,
  android: platform,
  ios: platform,
};

const GettingStartedLayout = () => {
  const { api } = useParams();
  const GettingStartedComponent = GETTING_STARTED[api];

  return (
    <div className="getting-started-layout">
      <h2>Getting Started</h2>
      <GettingStartedComponent isAndroid={api === "android"} />
    </div>
  );
};

export default GettingStartedLayout;
